<script>
import Content from '@/components/dialog/content/Content';

import BodyValueInput from '@/components/dialog/body/ValueInput';

import FooterSrdInformation from '@/components/dialog/footer/SrdInformation';

export default {
  name: 'RewardClaim',
  mixins: [Content],
  data: () => ({
    contentID: 'reward.claim',
    contentAction: 'common.action.reward.claim',
    contentActioned: 'common.actioned.reward.claim',
    contentActioning: 'common.actioning.reward.claim',
    hideStepperHeader: true,
  }),
  computed: {
    steps() {
      return [
        {
          id: 1,
          title: this.$t('trade.content.step.input', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterSrdInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.action', {
                action: this.$t(this.contentAction),
              }),
              action: this.claim,
            },
          ],
        },
        {
          id: 2,
          title: this.$t('trade.content.step.complete', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterSrdInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.confirm'),
              action: this.close,
            },
          ],
        },
      ];
    },
    currentBodyProps() {
      let result = this.defaultProps;

      if (this.currentBody === BodyValueInput) {
        result = Object.assign({}, result, {
          inputonly: true,
          readonly: true,
          disabledCurrency: true,
          disabledMessage: true,
          disabledSlider: true,
          amountValue: this.maxAmount.balanceFormat(
            '{}',
            6,
            undefined,
            false,
            false
          ),
          inputTitle: this.$t('trade.content.information.unclaimedToken', {
            token: 'BiFi',
          }),
          disabledRules: [
            () => (this.currentStepID === 2 ? true : undefined),
            () =>
              this.maxAmount.lte(0)
                ? {
                    text: 'trade.content.message.nonexistent.action',
                  }
                : undefined,
          ],
        });
      }

      return result;
    },
    currentFooterProps() {
      let result = this.defaultProps;

      if (this.currentFooter === FooterSrdInformation) {
        // TODO
        result = Object.assign({}, result, {
          // label: 'APY',
          // value: '- %'
        });
      }

      return result;
    },
    actionButtonDisabled() {
      return (
        this.currentStepID !== 2 &&
        (this.transactionPending ||
          !!this.emergencyMessage ||
          !this.$store.getters.isWalletUsable)
      );
    },
  },
  methods: {
    claim() {
      const from = this.$store.getters.walletAddress;
      const to = this.tokenSendAddress;
      const signature = this.$config.signature.si.claim;
      const args = [from];
      const value = '0x0';

      this.addTemporaryTransaction();

      this.$sendTransaction({ from, to, signature, args, value })
        .then(transactionHash => {
          this.addTransaction(
            this.contentAction,
            undefined,
            transactionHash,
            undefined,
            () => {
              this.nextStep();

              this.setMessage(
                'success',
                'common.message.transaction.successfullyAction'
              );
            }
          );
        })
        .catch(error => {
          this.addTransaction(this.contentAction, undefined, undefined, error);

          this.setMessage('error', this.$getTransactionErrorMessage(error));

          this.nextStep();
        })
        .finally(() => this.resolveTemporaryTransaction());
    },
  },
  mounted() {
    this.setMessage('info', 'trade.content.message.allClaim');
  },
};
</script>

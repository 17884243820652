<template>
  <v-layout
    class="dialog-content"
    :class="{ 'no-header': hideStepperHeader }"
    column
  >
    <div class="dialog-header">
      <v-btn
        class="ml-4 mr-n2"
        icon
        :dark="$store.getters.darkMode"
        @click="dialogClose"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
      <div
        class="dialog-header-title-button-wrapper"
        v-if="$vuetify.breakpoint.smAndDown && contentPair.length"
      >
        <div
          class="dialog-header-title-button"
          :class="{ selected: contentID === menu.id }"
          v-ripple="contentID !== menu.id"
          v-text="menu.title"
          @click="$emit('pairMenuChange', menu.id)"
          v-for="(menu, menuIndex) in contentPair"
          :key="menuIndex"
        />
      </div>
      <div class="dialog-header-title" v-text="title" v-else />
      <div
        class="dialog-header-subtitle"
        v-html="$t(subtitle)"
        v-if="$vuetify.breakpoint.mdAndUp && subtitle"
      />
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn
        class="dialog-header-action"
        color="#1f7bff"
        text
        small
        :dark="$store.getters.darkMode"
        v-text="$t(headerAction.title)"
        @click="headerAction.action"
        v-if="headerAction.title"
      />
      <div
        class="dialog-header-info"
        v-if="$vuetify.breakpoint.mdAndUp || !contentPair.length"
      >
        <AvatarIconSet
          host=""
          prependPath=""
          :iconSet="pairTokens"
          v-if="pairTokens && pairTokens.length"
        />
        <AvatarIconSet
          host=""
          prependPath=""
          :size="36"
          :iconSet="[{ image: tokenImage }]"
          v-else
        />
        <div class="dialog-header-name" v-text="tokenTitle" />
      </div>
    </div>
    <div
      class="dialog-mobile-subtitle"
      v-html="$t(subtitle)"
      v-if="$vuetify.breakpoint.smAndDown && subtitle"
    />
    <v-stepper
      class="stepper"
      :dark="$store.getters.darkMode"
      v-model="currentStep"
    >
      <v-stepper-header class="stepper-header" v-if="!hideStepperHeader">
        <template v-for="(step, stepIndex) in computedSteps">
          <v-stepper-step
            class="stepper-step transition-all"
            :color="color"
            :complete="currentStep > stepIndex + 1"
            :step="stepIndex + 1"
            :rules="step.rules || []"
            v-if="$vuetify.breakpoint.mdAndUp || currentStep <= stepIndex + 1"
            :key="`${stepIndex}-step`"
          >
            <div class="stepper-step-title" v-text="step.title" />
          </v-stepper-step>

          <v-divider
            class="stepper-step-divider"
            v-if="
              stepIndex !== computedSteps.length - 1 &&
                ($vuetify.breakpoint.mdAndUp || currentStep <= stepIndex + 1)
            "
            :key="`${stepIndex}-divider`"
          />
        </template>
      </v-stepper-header>
      <v-stepper-items class="stepper-item-wrapper">
        <v-stepper-content
          class="stepper-item"
          :step="stepIndex + 1"
          v-for="(step, stepIndex) in computedSteps"
          :key="`${stepIndex}-content`"
        >
          <v-fade-transition hide-on-leave>
            <component
              :is="currentBody"
              v-bind="currentBodyProps"
              @messagebutton="messagebutton"
              @setInputValue="setInputValue"
              @previousSlide="previousSlide"
              @previousStep="previousStep"
              @nextSlide="nextSlide"
              @nextStep="nextStep"
              v-if="stepIndex + 1 === currentStep"
            />
          </v-fade-transition>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-fade-transition>
      <div class="loader-wrapper" v-if="transactionPending">
        <Loader
          class="loader"
          color="#47b465"
          secondColor="#1f7bff"
          :borderWeight="2"
          :size="80"
        />
        <a
          class="loader-title link transition-all"
          rel="noopener"
          target="_blank"
          :href="transactionLink"
          v-if="transactionLink"
        >
          <span v-text="transactionTitle" />
          <v-icon class="loader-link-icon" color="#2380fb" small
            >mdi-open-in-new</v-icon
          >
        </a>
        <div
          class="loader-title"
          v-text="transactionTitle"
          v-else-if="transactionTitle"
        />
        <div
          class="loader-title"
          v-t="'common.message.transaction.processing'"
        />
      </div>
    </v-fade-transition>
    <v-fade-transition>
      <div class="emergency-message-wrapper" v-if="emergencyMessage">
        <v-icon
          class="emergency-message-icon"
          v-text="emergencyMessageIcon"
          v-if="emergencyMessageIcon"
        />
        <div class="emergency-message-title" v-t="emergencyMessage" />
        <div
          class="emergency-message-description"
          v-html="$t(emergencyMessageDescription)"
        />
      </div>
    </v-fade-transition>
    <div class="dialog-footer">
      <div class="dialog-footer-info">
        <v-fade-transition hide-on-leave>
          <component
            :is="currentFooter"
            v-bind="currentFooterProps"
            v-if="currentFooter"
            @previousSlide="previousSlide"
            @previousStep="previousStep"
            @nextSlide="nextSlide"
            @nextStep="nextStep"
          />
        </v-fade-transition>
      </div>
      <MessageContent
        :disabledRules="disabledRules"
        :message="message"
        :messageDefaultArgs="messageDefaultArgs"
        @messagebutton="messagebutton"
        v-if="$vuetify.breakpoint.smAndDown && currentStepID > 0"
      />
      <div class="dialog-footer-button-wrapper">
        <v-btn
          class="dialog-footer-button"
          :color="color"
          tile
          depressed
          :disabled="actionButtonDisabled"
          @click="currentButtonAction"
        >
          <span
            class="dialog-footer-button-title"
            v-text="currentButtonTitle"
          />
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

import Loader from '@/components/Loader';

import MessageContent from '@/components/dialog/body/MessageContent';

import BodyTokenPowerSetting from '@/components/dialog/body/TokenPowerSetting';
import BodyValueInput from '@/components/dialog/body/ValueInput';

export default {
  name: 'Content',
  components: {
    AvatarIconSet,
    Loader,
    MessageContent,
  },
  props: {
    token: Object,
    color: {
      type: String,
      default: '',
    },
    tokenImage: {
      type: String,
      default: '',
    },
    tokenID: {
      type: String,
      default: '',
    },
    tokenTitle: {
      type: String,
      default: '',
    },
    tokenAddress: {
      type: String,
      default: '',
    },
    tokenSendAddress: {
      type: String,
      default: '',
    },
    tokenBalance: {
      type: Object,
    },
    tokenAllowance: {
      type: Object,
    },
    tokenPrice: {
      type: Object,
    },
    pairTokens: {
      type: Array,
      default: () => [],
    },
    isSrdToken: {
      type: Boolean,
      default: false,
    },
    isToken: {
      type: Boolean,
      default: false,
    },
    tokenReplaceString: {
      type: String,
      default: '',
    },
    tokenUnitString: {
      type: String,
      default: '',
    },
    contentPair: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      temporaryTransaction: false,
      currentStep: 1,
      currentSlide: 0,
      inputValue: '',
      message: {
        type: '',
        text: '',
        textArgs: undefined,
        button: '',
        tooltip: '',
      },
      contentID: 'content',
      contentAction: '',
      contentActioned: '',
      contentActioning: '',
      subtitle: '',
      headerAction: {
        title: '',
        action: () => {},
      },
      hideStepperHeader: false,
      emergencyMessage: '',
      emergencyMessageIcon: '',
      emergencyMessageDescription: '',
    };
  },
  computed: {
    title() {
      return (
        this.$t('trade.content.title', {
          action: this.$t(this.contentAction),
        }) || ''
      );
    },
    steps() {
      return [];
    },
    pendingTransaction() {
      let result = undefined;

      if (
        this.$store.getters.transactions &&
        this.$store.getters.transactions.length
      ) {
        result = this.$store.getters.transactions.find(
          transaction =>
            transaction.status === 0 &&
            transaction.contentID === this.contentID &&
            transaction.token === this.tokenID
        );
      }

      return result;
    },
    transactionPending() {
      return !!this.pendingTransaction || this.temporaryTransaction;
    },
    transactionLink() {
      let result = '';

      if (this.pendingTransaction && this.pendingTransaction.transactionHash) {
        result = this.$getTransactionExplorer(
          this.$store.getters.walletNetwork,
          this.pendingTransaction.transactionHash
        );
      }

      return result;
    },
    transactionTitle() {
      let result = '';

      if (this.pendingTransaction) {
        if (
          this.pendingTransaction.action === 'common.action.tokenApprove' ||
          this.pendingTransaction.action === 'common.action.claim' ||
          this.pendingTransaction.action === 'common.action.pool.claim' ||
          this.pendingTransaction.action === 'common.action.stake.claim' ||
          this.pendingTransaction.action === 'common.action.reward.claim' ||
          this.pendingTransaction.action === 'common.action.claimToken'
        ) {
          result = this.$t(this.pendingTransaction.action);
        } else {
          result = `${this.$curr.getBalanceFormatedTokenValue(
            this.pendingTransaction.value,
            this.pendingTransaction.token,
            this.pendingTransaction.token,
            this.tokenReplaceString
          )} ${this.$t(this.pendingTransaction.action)}`;
        }
      }

      return result;
    },
    computedSteps() {
      const result = [];

      for (const step of this.steps) {
        if (!this.$execFunc(step.disableRule)) {
          result.push(step);
        }
      }

      return result;
    },
    currentStepObject() {
      return this.computedSteps[this.currentStep - 1] || undefined;
    },
    currentStepID() {
      return (this.currentStepObject || {}).id || -1;
    },
    currentBody() {
      return (
        ((this.currentStepObject || { bodies: [] }).bodies || [])[
          this.currentSlide
        ] || undefined
      );
    },
    currentBodyProps() {
      return this.defaultProps;
    },
    currentFooter() {
      return (
        ((this.currentStepObject || { footers: [] }).footers || [])[
          this.currentSlide
        ] || undefined
      );
    },
    currentFooterProps() {
      return this.defaultProps;
    },
    currentButton() {
      return (
        ((this.currentStepObject || { buttons: [] }).buttons || [])[
          this.currentSlide
        ] || undefined
      );
    },
    currentButtonTitle() {
      return (this.currentButton || {}).title || '';
    },
    currentButtonAction() {
      return (this.currentButton || {}).action || (() => {});
    },
    actionButtonDisabled() {
      return (
        this.currentStepID !== 2 &&
        (this.transactionPending ||
          !!this.emergencyMessage ||
          !this.isActionableByInput ||
          !this.$store.getters.isWalletUsable)
      );
    },
    isActionableByInput() {
      let result = false;

      if (this.currentBody === BodyValueInput) {
        if (this.inputValue) {
          try {
            let bigNumber = new this.$BBN(this.inputValue);
            let bigLimitNumber = new this.$BBN(this.informationValueString);

            if (bigNumber.gt(0) && bigNumber.lte(bigLimitNumber)) {
              result = true;
            }
          } catch (error) {
            result = false;
          }
        }
      } else if (this.currentBody === BodyTokenPowerSetting) {
        if (this.inputValue) {
          try {
            let bigNumber = new this.$BBN(this.inputValue);
            let bigLimitNumber = this.$curr.getFormatedValue(
              this.$config.uintMaxValue
            );

            if (bigNumber.gt(0) && bigNumber.lte(bigLimitNumber)) {
              result = true;
            }
          } catch (error) {
            result = false;
          }
        }
      } else {
        result = true;
      }

      return result;
    },
    defaultProps() {
      return {
        token: this.token,
        tokenImage: this.tokenImage,
        tokenID: this.tokenID,
        tokenTitle: this.tokenTitle,
        tokenReplaceString: this.tokenReplaceString,
        tokenUnitString: this.tokenUnitString,
        tokenAddress: this.tokenAddress,
        tokenSendAddress: this.tokenSendAddress,
        isSrdToken: this.isSrdToken,
        pairTokens: this.pairTokens,
        transactionPending: this.transactionPending,
        inputMessage: this.message,
        inputMessageDefaultArgs: this.messageDefaultArgs,
      };
    },
    depositInterestRate() {
      let result = '0%';

      if (this.token && this.token.deposit && this.token.deposit.interestRate) {
        result = this.token.deposit.interestRate.balanceFormat(
          '{}%',
          2,
          6,
          true
        );
      }

      return result;
    },
    borrowInterestRate() {
      let result = '0%';

      if (this.token && this.token.borrow && this.token.borrow.interestRate) {
        result = this.token.borrow.interestRate.balanceFormat(
          '{}%',
          2,
          6,
          true
        );
      }

      return result;
    },
    collateralRate() {
      let result;

      if (this.token && this.token.collateralRate) {
        result = this.token.collateralRate;
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    collateralRateString() {
      return this.collateralRate.mul(100).balanceFormat('{}%', 2, 6, true);
    },
    tokenPriceString() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.tokenPrice,
          'usd',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    limitOfAction() {
      let result;

      if (this.token && this.token.limitOfAction) {
        result = this.$curr.getFormatedTokenValue(
          this.token.limitOfAction || new this.$BBN('0'),
          'usd',
          this.tokenID
        );
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    decimals() {
      const token = this.$curr.getToken(this.tokenID);
      const result = new this.$BBN(token ? token.decimals : 18);

      return result;
    },
    multiplyValueByDecimals() {
      return new this.$BBN(10).pow(this.decimals);
    },
    maxAmount() {
      let result;

      if (this.isSrdToken) {
        if (
          this.contentID === 'pool.deposit' ||
          this.contentID === 'stake.deposit'
        ) {
          result = this.tokenBalance;
        } else {
          result = this.token.maxAmount[this.contentID]
            .mul(this.multiplyValueByDecimals)
            .idiv(1)
            .div(this.multiplyValueByDecimals)
            || new this.$BBN('0');
        }
      } else if (this.token && this.token.maxAmount && this.contentID) {
        if (this.contentID === 'deposit') {
          result = this.tokenBalance;
        } else {
          let contentID = this.contentID;

          if (contentID !== 'reward.claim') {
            const splitedContentIDs = contentID.split('.');
            contentID =
              (splitedContentIDs.length > 1 && splitedContentIDs[1]) ||
              splitedContentIDs[0];
          }

          result = this.token.maxAmount[contentID]
            .mul(this.multiplyValueByDecimals)
            .idiv(1)
            .div(this.multiplyValueByDecimals)
            || new this.$BBN('0');
        }
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    informationValue() {
      let result;

      if (this.currentStepID === 0) {
        result = this.$curr.getFormatedValue(this.$config.uintMaxValue);
      } else if (this.currentStepID === 1 || this.currentStepID === 2) {
        if (this.contentID) {
          if (this.isSrdToken) {
            if (
              this.contentID === 'pool.deposit' ||
              this.contentID === 'stake.deposit'
            ) {
              result = this.tokenBalance;
            } else {
              result = this.maxAmount;
            }
          } else if (
            this.contentID === 'withdraw' ||
            this.contentID === 'borrow' ||
            this.contentID === 'repay' ||
            this.contentID === 'btc.withdraw' ||
            this.contentID === 'btc.borrow' ||
            this.contentID === 'btc.repay'
          ) {
            result = this.maxAmount;
          } else if (this.contentID === 'deposit') {
            result = this.tokenBalance;
          } else {
            result = new this.$BBN('0');
          }
        } else {
          result = new this.$BBN('0');
        }
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    borrowLimit() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.$store.getters.borrowData.borrowLimit,
          'usd',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    changeBorrowLimit() {
      let result = '';

      if (this.inputValue) {
        let changeValue = this.$curr.getFormatedTokenValue(
          this.inputValue,
          this.tokenID,
          this.$store.getters.currency
        );

        if (
          this.contentID === 'deposit' ||
          this.contentID === 'withdraw' ||
          this.contentID === 'btc.withdraw'
        ) {
          changeValue = changeValue.mul(this.collateralRate);
        }

        if (
          this.contentID === 'withdraw' ||
          this.contentID === 'borrow' ||
          this.contentID === 'btc.withdraw' ||
          this.contentID === 'btc.borrow'
        ) {
          changeValue = changeValue.negate();
        }

        result = this.$curr.getWordFormatedValue(
          this.$curr
            .getFormatedTokenValue(
              this.$store.getters.borrowData.borrowLimit,
              'usd',
              this.$store.getters.currency
            )
            .plus(changeValue),
          this.$i18n.locale,
          this.$store.getters.replaceString,
          2,
          true
        );
      }

      return result;
    },
    borrowLimitRateValue() {
      let result;

      try {
        if (
          this.$store.getters.borrowData.totalBorrow.gt(0) &&
          this.$store.getters.borrowData.borrowLimit.gt(0)
        ) {
          result = this.$store.getters.borrowData.totalBorrow
            .div(this.$store.getters.borrowData.borrowLimit)
            .mul(100);
        } else {
          result = new this.$BBN('0');
        }
      } catch (error) {
        result = new this.$BBN('0');
      }

      return result;
    },
    changeBorrowLimitRateValue() {
      let result = new this.$BBN(-1);

      if (this.inputValue) {
        result = result.plus(1);

        const changeValue = this.$curr.getFormatedTokenValue(
          this.inputValue,
          this.tokenID,
          'usd'
        );

        let changeBorrow = new this.$BBN('0');
        let changeBorrowLimit = new this.$BBN('0');

        if (this.contentID === 'deposit') {
          changeBorrowLimit = changeValue.mul(this.collateralRate);
        } else if (
          this.contentID === 'withdraw' ||
          this.contentID === 'btc.withdraw'
        ) {
          changeBorrowLimit = changeValue.negate().mul(this.collateralRate);
        } else if (
          this.contentID === 'borrow' ||
          this.contentID === 'btc.borrow'
        ) {
          changeBorrow = changeValue;
        } else if (this.contentID === 'repay') {
          changeBorrow = changeValue.negate();
        }

        try {
          const max = this.$store.getters.borrowData.borrowLimit.plus(
            changeBorrowLimit
          );

          if (max.gt(0)) {
            result = result.plus(
              this.$store.getters.borrowData.totalBorrow
                .plus(changeBorrow)
                .div(max)
                .mul(100)
            );
          } else {
            result = result.minus(1);
          }
        } catch (error) {
          /**/
        }
      }

      return result;
    },
    borrowLimitRate() {
      return this.borrowLimitRateValue.balanceFormat('{}%', 2, 6, true);
    },
    changeBorrowLimitRate() {
      let result = '';

      if (this.changeBorrowLimitRateValue.gte(0)) {
        result = this.changeBorrowLimitRateValue.balanceFormat(
          '{}%',
          2,
          6,
          true
        );
      }

      return result;
    },
    graphValue() {
      let result = 0;

      try {
        if (this.changeBorrowLimitRateValue.gte(0)) {
          result = this.changeBorrowLimitRateValue.toNum();
        } else {
          result = this.borrowLimitRateValue.toNum();
        }
      } catch (error) {
        /**/
      }

      return result;
    },
    isOverLimitOfAction() {
      let result = false;

      try {
        if (
          this.inputValue &&
          (this.contentID === 'withdraw' ||
            this.contentID === 'borrow' ||
            this.contentID === 'btc.withdraw' ||
            this.contentID === 'btc.borrow') &&
          this.limitOfAction.gt('0')
        ) {
          result = this.limitOfAction.lt(this.inputValue);
        }
      } catch (error) {
        /**/
      }

      return result;
    },
    isOverBalance() {
      let result = false;

      try {
        if (this.inputValue && this.contentID === 'repay') {
          result = this.tokenBalance.lt(this.inputValue);
        }
      } catch (error) {
        /**/
      }

      return result;
    },
    isOverAllowance() {
      let result = false;

      try {
        if (this.isToken && this.inputValue) {
          result = this.tokenAllowance.lt(this.inputValue);
        }
      } catch (error) {
        /**/
      }

      return result;
    },
    inputValueString() {
      return new this.$BBN(this.inputValue).toStr();
    },
    maxAmountString() {
      return this.maxAmount.toStr();
    },
    informationValueString() {
      return this.informationValue.toStr();
    },
    isMaxInputValue() {
      return this.inputValueString === this.maxAmountString;
    },
    messageDefaultArgs() {
      return {
        action: this.$t(this.contentAction),
        actioned: this.$t(this.contentActioned),
        actioning: this.$t(this.contentActioning),
      };
    },
    disabledRules() {
      return [];
    },
  },
  watch: {
    isOverBalance() {
      this.messageCheck();
    },
    isOverAllowance() {
      this.messageCheck();
    },
    isMaxInputValue() {
      this.messageCheck();
    },
    currentStep() {
      this.checkSkip();
    },
  },
  methods: {
    initializing() {
      this.initializeSlide();
      this.initializeStep();
      this.initializeInputValue();
      this.initializeMessage();
      this.checkSkip();

      this.temporaryTransaction = false;
    },
    nextSlide() {
      this.currentSlide += 1;
    },
    previousSlide() {
      this.currentSlide -= 1;
    },
    initializeSlide() {
      this.currentSlide = 0;
    },
    nextStep() {
      if (this.currentStep >= this.computedSteps.length) {
        this.initializeStep();
      } else {
        this.initializeSlide();
        this.currentStep += 1;
      }
    },
    previousStep() {
      if (this.currentStep <= 1) {
        this.initializeStep();
      } else {
        this.initializeSlide();
        this.currentStep -= 1;
      }
    },
    initializeStep() {
      this.currentStep = 1;
    },
    setInputValue(value) {
      this.inputValue = (value && new this.$BBN(value).toStr()) || '';
    },
    initializeInputValue() {
      this.inputValue = '';
    },
    setMessage(type = 'info', text = '', textArgs, button = '', tooltip = '') {
      this.message.type = type;
      this.message.text = text;
      this.message.textArgs = textArgs;
      this.message.button = button;
      this.message.tooltip = tooltip;
    },
    initializeMessage() {
      this.message.type = '';
      this.message.text = '';
      this.message.textArgs = undefined;
      this.message.button = '';
      this.message.tooltip = '';
    },
    dialogClose() {
      this.$emit('dialogClose');
    },
    close() {
      this.$emit('close');
    },
    checkSkip() {
      if (
        this.currentStepObject &&
        typeof this.currentStepObject === 'object' &&
        this.$execFunc(this.currentStepObject.skipRule)
      ) {
        this.nextStep();
      }
    },
    messagebutton() {},
    messageCheck() {},
    cycleSyncToken(checkFunc, then, err, final, count = 0) {
      if (count < this.$config.expectedBlock.cycle) {
        if (this.$execFunc(checkFunc)) {
          this.$execFunc(then);
          this.$execFunc(final);
        } else {
          setTimeout(
            () => this.cycleSyncToken(checkFunc, then, err, final, count + 1),
            this.$config.expectedBlock.time
          );
        }
      } else {
        this.$execFunc(err);
        this.$execFunc(final);
      }
    },
    cycleSyncAllowanceCheck(checkValue) {
      return this.tokenAllowance.gte(checkValue);
    },
    cycleSyncTokenError() {
      const message = 'common.message.wallet.error.timeoutActionSync';

      this.addTransaction(message, undefined, undefined, {
        message,
        code: -9999,
      });

      this.setMessage('error', message, {
        action: 'common.action.token',
      });
    },
    addTransaction(action, value, transactionHash, error, thenFunc) {
      if (this.tokenID && this.contentID && action) {
        const token = this.tokenID;
        const contentID = this.contentID;

        let status = 0;
        let code = undefined;
        let message = undefined;

        if (error || !transactionHash) {
          status = 2;

          if (typeof error === 'object') {
            code = error.code;
            message = error.locale || error.message;

            if (error.code === this.$config.metamask.code.userCancel) {
              status = 3;
              message = 'common.message.transaction.error.cancel';
            }
          } else {
            if (error) {
              message = `${error}`;
            } else {
              message = 'common.message.transaction.error.unknown';
            }
          }
        }

        this.$store.dispatch('addTransaction', {
          token,
          contentID,
          action,
          value,
          transactionHash,
          status,
          code,
          message,
          thenFunc,
        });
      }
    },
    addTemporaryTransaction() {
      this.temporaryTransaction = true;
    },
    resolveTemporaryTransaction() {
      this.temporaryTransaction = false;
    },
  },
  mounted() {
    this.initializing();
  },
};
</script>

<style scoped>
.dialog-header {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0px 24px;
  border-bottom: solid 1px #d5d5d5;
}

.dialog-header-title {
  font-size: 20px;
  color: #000000d9;
}

.dialog-header-subtitle,
.dialog-mobile-subtitle {
  font-size: 12px;
  color: #00000040;
}
.dialog-header-subtitle {
  padding-left: 20px;
}
.dialog-mobile-subtitle {
  padding: 5px 12px;
}

.dialog-header-action {
  margin-right: 12px;
}

.dialog-header-info {
  display: flex;
  align-items: center;
}

.dialog-header-avatar {
  margin-right: 20px;
}

.dialog-header-name {
  margin-left: 8px;
  font-size: 14px;
  color: #000000d9;
}

.stepper {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.stepper-header {
  border-bottom: solid 1px #d5d5d5;
  box-shadow: none !important;
}

.stepper-step {
}

.stepper-step-title {
  font-size: 14px;
}

.stepper-step-divider {
  margin: 0px 20px !important;
}

.stepper-item-wrapper {
  flex: 1;
}

.stepper-item {
  height: 100%;
  padding: 0px;
}

.loader-wrapper {
  position: absolute;
  display: flex;
  top: 144px;
  bottom: 110px;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  user-select: none;
  z-index: 2;
}
.dialog-content.no-header .loader-wrapper {
  top: 72px;
}
.loader {
  margin-bottom: 35px;
}
.loader-title {
  font-size: 14px;
  text-align: center;
  color: #000000d9;
}
.loader-title.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.loader-title.link:hover {
  opacity: 0.8;
}
.loader-title.link:active {
  opacity: 0.6;
}
.loader-link-icon {
  margin-left: 10px;
}

.emergency-message-wrapper {
  position: absolute;
  display: flex;
  top: 144px;
  bottom: 110px;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000000d9;
  text-align: center;
  background-color: #fff;
  user-select: none;
  z-index: 2;
}
.dialog-content.no-header .emergency-message-wrapper {
  top: 72px;
}
.emergency-message-icon {
  font-size: 120px;
  color: inherit !important;
}
.emergency-message-title {
  padding-top: 30px;
  padding-bottom: 12px;
  font-size: 20px;
}
.emergency-message-description {
  font-size: 14px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  padding: 0px 16px;
  border-top: solid 1px #d5d5d5;
}

.dialog-footer-info {
  width: 100%;
  height: 100%;
}

.dialog-footer-button-wrapper {
  padding-left: 16px;
}

.dialog-footer-button {
  width: 200px !important;
  height: 68px !important;
}

.dialog-footer-button-title {
  font-size: 20px;
  text-align: center;
}

/* dark mode */

#app.dark .dialog-header-title,
#app.dark .dialog-header-name,
#app.dark .loader-title,
#app.dark .emergency-message-wrapper {
  color: #ffffffd9;
}

#app.dark .dialog-header-subtitle,
#app.dark .dialog-mobile-subtitle {
  color: #ffffff40;
}

#app.dark .stepper {
  background-color: #383c52;
}

#app.dark .loader-wrapper {
  background-color: #383c52;
}

#app.dark .emergency-message-wrapper {
  background-color: #383c52;
}

#app.dark .stepper-step-divider {
  border-color: #00000040 !important;
}

#app.dark .stepper-step-title {
  color: #ffffff40 !important;
}

/* mobile */

#app.mobile .dialog-header {
  flex-direction: row-reverse;
  height: 50px;
  padding: 0px 14px;
  background-color: #0000001a;
}

#app.mobile .dialog-header-title {
  padding-left: 10px;
  font-size: 14px;
}

#app.mobile .dialog-header-title-button-wrapper {
  height: 100%;
  margin-left: -14px;
  font-size: 12px;
  color: #000000d9;
}

#app.mobile.dark .dialog-header-title-button-wrapper {
  color: #ffffffd9;
}

#app.mobile .dialog-header-title-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 14px;
  border-right: 1px solid #0000001f;
  cursor: pointer;
}

#app.mobile.dark .dialog-header-title-button {
  border-color: #4b506d;
}

#app.mobile .dialog-header-title-button.selected {
  background-color: #fff;
  cursor: default;
}

#app.mobile.dark .dialog-header-title-button.selected {
  background-color: #393c52;
}

#app.mobile .dialog-header-title-button.selected:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #fff;
}

#app.mobile.dark .dialog-header-title-button.selected:after {
  background-color: #393c52;
}

#app.mobile .dialog-header-avatar {
  margin-right: 10px;
}

#app.mobile .stepper-header {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  border: none;
}

#app.mobile .dialog-content:not(.no-header) .stepper:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  max-width: 100px;
  height: 39px;
  background-image: linear-gradient(to right, #ffffff00 8%, #fff 78%);
}

#app.mobile.dark .dialog-content:not(.no-header) .stepper:after {
  background-image: linear-gradient(to right, #383c5200 8%, #383c52 78%);
}

#app.mobile .stepper-step,
#app.mobile .stepper-step-divider {
  display: inline-flex;
}

#app.mobile .stepper-step {
  padding: 10px 12px;
}

#app.mobile .stepper-step-title {
  font-size: 12px;
}

#app.mobile .stepper-step-divider {
  flex: 0;
  min-width: 30px;
  margin: 0px 10px !important;
}

#app.mobile .loader-wrapper {
  top: 90px;
  bottom: 58px;
}
#app.mobile .dialog-content.no-header .loader-wrapper {
  top: 50px;
}

#app.mobile .emergency-message-wrapper {
  top: 90px;
  bottom: 58px;
}
#app.mobile .dialog-content.no-header .emergency-message-wrapper {
  top: 50px;
}

#app.mobile .dialog-footer {
  flex-direction: column;
  height: unset;
  padding: 0px;
  background-color: #0000001a;
}

#app.mobile .dialog-footer-info {
  height: unset;
}

#app.mobile .dialog-footer-button-wrapper {
  width: 100%;
  padding-left: 0px;
}

#app.mobile .dialog-footer-button {
  width: 100% !important;
  height: 58px !important;
}

#app.mobile .dialog-footer-button-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>

<style>
.dialog > .dialog-content-wrapper .stepper .stepper-item > .v-stepper__wrapper {
  height: 100%;
}

.dialog > .dialog-content-wrapper .dialog-footer .description {
  font-size: 14px;
  color: #000000d9;
}

.dialog > .dialog-content-wrapper .dialog-footer .link {
  margin-left: 20px;
  font-size: 12px;
  color: #1f7bff;
}

.dialog > .dialog-content-wrapper .dialog-footer .link.disabled {
  color: #797979;
  cursor: not-allowed;
}

.stepper-step .v-stepper__step__step {
  margin-right: 8px !important;
  font-size: 14px !important;
}

.stepper-step .v-stepper__label {
  display: flex !important;
}

/* dark mode */

#app.dark .dialog > .dialog-content-wrapper .dialog-footer .description {
  color: #ffffffd9;
}

#app.dark .dialog > .dialog-content-wrapper .dialog-footer .link.disabled {
  color: #868686;
}

#app.dark
  .stepper-step:not(.v-stepper__step--active):not(.v-stepper__step--complete)
  .v-stepper__step__step {
  color: #ffffff40 !important;
  background-color: #00000040 !important;
}

#app.mobile .stepper-step .v-stepper__step__step {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
  font-size: 12px !important;
}

/* mobile */

#app.mobile .dialog > .dialog-content-wrapper .dialog-footer .description {
  font-size: 10px;
}

#app.mobile .dialog > .dialog-content-wrapper .dialog-footer .link {
  margin-left: 10px;
  font-size: 10px;
}
</style>

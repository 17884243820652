<template>
  <v-simple-table
    class="card-table"
    :class="{ vertical: vertical }"
    :dark="$store.getters.darkMode"
  >
    <div class="card-table-loading" v-if="loading">
      <v-progress-circular
        class="card-table-loading-progress"
        indeterminate
        :size="80"
        :width="7"
      />
    </div>
    <colgroup v-if="colgroup && colgroup.length">
      <col
        :span="col.span"
        :style="col.style"
        v-for="(col, colIndex) in colgroup"
        :key="colIndex"
      />
    </colgroup>
    <thead v-if="!vertical">
      <tr>
        <template v-for="(head, headIndex) in header">
          <th
            :class="head.class || ''"
            :colspan="head.colspan || 1"
            v-if="!(head.onlyDesktop && $vuetify.breakpoint.smAndDown)"
            :key="headIndex"
          >
            <v-avatar
              class="header-avatar prepend"
              color="#0000001a"
              v-if="head.image"
            >
              <v-img
                class="header-avatar-image"
                contain
                aspect-ratio="2"
                :src="head.image"
              />
            </v-avatar>
            <div class="header-text" v-html="head.title || ''" />
            <v-tooltip
              top
              transition="slide-y-reverse-transition"
              v-if="head.tooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="header-avatar append" v-bind="attrs" v-on="on">
                  <v-icon class="header-avatar-icon"
                    >mdi-help-circle-outline</v-icon
                  >
                </v-avatar>
              </template>
              <div class="header-tooltip" v-html="head.tooltip" />
            </v-tooltip>
          </th>
        </template>
      </tr>
    </thead>
    <transition-group name="card-table-row" tag="tbody">
      <template v-if="mergedRows && mergedRows.length">
        <tr
          class="transition-all"
          :class="
            `${row.comingSoon ? 'coming-soon' : ''} ${
              row.disabled || row.comingSoon ? 'disabled' : ''
            } ${
              $vuetify.breakpoint.smAndDown &&
              !row.disabled &&
              !row.comingSoon &&
              typeof ((actions || [])[0] || {}).action === 'function'
                ? 'actionable'
                : ''
            } ${row.class || ''}`
          "
          @click="
            $execFunc(
              $vuetify.breakpoint.smAndDown && !row.disabled && !row.comingSoon
                ? (actions[0] || {}).action
                : undefined,
              $event,
              row
            )
          "
          v-for="(row, rowIndex) in mergedRows"
          :key="row.id || `index-${rowIndex}`"
        >
          <template v-for="(column, columnIndex) in row.columns">
            <td
              :class="`${column.class || ''} ${column.type || ''}`.trim()"
              v-if="
                !(
                  getHeaderByColumn(columnIndex).onlyDesktop &&
                  $vuetify.breakpoint.smAndDown
                )
              "
              :key="columnIndex"
            >
              <v-avatar
                class="avatar"
                :size="$vuetify.breakpoint.smAndDown ? '24' : '40'"
                :color="
                  column.src || column.image
                    ? column.color || '#0000001a'
                    : '#fff'
                "
                v-if="column.type === 'avatar'"
              >
                <v-img
                  class="avatar-image"
                  contain
                  max-width="32"
                  max-height="32"
                  aspect-ratio="2"
                  :src="column.src"
                  v-if="column.src"
                />
                <v-img
                  class="avatar-image"
                  contain
                  max-width="32"
                  max-height="32"
                  aspect-ratio="2"
                  :src="column.image"
                  v-else-if="column.image"
                />
                <span
                  class="white--text headline"
                  v-text="`${column.text}`.slice(0, 1).toUpperCase()"
                  v-else
                />
              </v-avatar>
              <div
                class="avatar-text"
                v-else-if="column.type === 'avatar-text'"
              >
                <v-avatar
                  class="avatar"
                  :size="$vuetify.breakpoint.smAndDown ? '24' : '40'"
                  :color="
                    column.src || column.image
                      ? column.color || '#0000001a'
                      : '#fff'
                  "
                >
                  <v-img
                    class="avatar-image"
                    contain
                    max-width="32"
                    max-height="32"
                    aspect-ratio="2"
                    :src="column.src"
                    v-if="column.src"
                  />
                  <v-img
                    class="avatar-image"
                    contain
                    max-width="32"
                    max-height="32"
                    aspect-ratio="2"
                    :src="column.image"
                    v-else-if="column.image"
                  />
                  <span
                    class="white--text headline"
                    v-text="`${column.text}`.slice(0, 1).toUpperCase()"
                    v-else
                  />
                </v-avatar>
                <div v-text="column.text || ''" v-if="column.text" />
              </div>
              <div v-else-if="column.type === 'text'">
                <v-tooltip
                  bottom
                  transition="slide-y-transition"
                  :disabled="row.comingSoon || !column.tooltip"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div class="tooltip-wrapper" v-bind="attrs" v-on="on">
                      <div v-text="column.text || ''" v-if="column.text" />
                      <div
                        class="subtext"
                        v-text="column.subtext || ''"
                        v-if="column.subtext"
                      />
                    </div>
                  </template>
                  <span v-text="column.tooltip" v-if="column.tooltip" />
                </v-tooltip>
              </div>
              <v-layout
                class="action-wrapper"
                column
                justify-center
                align-center
                v-else-if="column.type === 'action'"
              >
                <template v-for="(action, actionIndex) in actions">
                  <v-btn
                    class="action"
                    text
                    tile
                    :disabled="row.disabled || row.comingSoon"
                    @click.stop.prevent="action.action($event, row)"
                    v-if="!$execFunc(action.getIsHide, row.id)"
                    :key="actionIndex"
                  >
                    <span
                      class="action-title"
                      :style="{
                        color: action.color || column.color || color || '',
                      }"
                      v-text="row.comingSoon ? '-' : action.title"
                    />
                  </v-btn>
                </template>
              </v-layout>
              <v-layout
                class="action-wrapper"
                column
                justify-center
                align-center
                v-else-if="column.type === 'action-replace'"
              >
                <v-btn
                  class="action"
                  text
                  tile
                  :disabled="row.disabled || row.comingSoon"
                  @click.stop.prevent="
                    ((actions || [])[0] || {}).action($event, row)
                  "
                >
                  <div class="action-replace-wrapper">
                    <v-icon
                      class="action-title-icon prepend"
                      v-text="column.replace.prependIcon"
                      v-if="column.replace.prependIcon"
                    />
                    <div class="action-title" v-text="column.replace.title" />
                    <v-icon
                      class="action-title-icon append"
                      v-text="column.replace.appendIcon"
                      v-if="column.replace.appendIcon"
                    />
                  </div>
                </v-btn>
              </v-layout>
              <Button
                :buttonClass="column.buttonClass || ''"
                :disabled="row.disabled || row.comingSoon"
                :image="column.image"
                :text="column.text"
                :action="column.action"
                v-else-if="column.type === 'button'"
              />
              <Button
                :buttonClass="column.buttonClass || ''"
                fab
                :disabled="row.disabled || row.comingSoon"
                :image="column.image"
                :text="column.text"
                :action="column.action"
                v-else-if="column.type === 'fab-button'"
              />
              <div v-html="column.html" v-else-if="column.type === 'html'" />
              <div v-else-if="column.type === 'columns'">
                <template
                  v-if="
                    column.columns &&
                      typeof column.columns === 'object' &&
                      column.columns.length
                  "
                >
                  <div
                    :class="innerColumn.class || ''"
                    v-for="(innerColumn, innerColumnIndex) in column.columns"
                    :key="innerColumnIndex"
                  >
                    <v-avatar
                      class="inner-avatar prepend"
                      color="#0000001a"
                      v-if="innerColumn.prependImage"
                    >
                      <v-img
                        class="inner-avatar-image"
                        contain
                        aspect-ratio="2"
                        :src="innerColumn.prependImage"
                      />
                    </v-avatar>
                    <div
                      class="inner-text"
                      v-text="innerColumn.text"
                      v-if="innerColumn.text"
                    />
                    <v-avatar
                      class="inner-avatar append"
                      color="#0000001a"
                      v-if="innerColumn.appendImage"
                    >
                      <v-img
                        class="inner-avatar-image"
                        contain
                        aspect-ratio="2"
                        :src="innerColumn.appendImage"
                      />
                    </v-avatar>
                    <v-tooltip
                      top
                      transition="slide-y-reverse-transition"
                      v-if="innerColumn.tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar
                          class="inner-avatar append"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon class="inner-avatar-icon"
                            >mdi-help-circle-outline</v-icon
                          >
                        </v-avatar>
                      </template>
                      <div class="inner-tooltip" v-html="innerColumn.tooltip" />
                    </v-tooltip>
                    <div
                      class="inner-subtext"
                      v-text="innerColumn.subtext"
                      v-if="innerColumn.subtext"
                    />
                  </div>
                </template>
              </div>
            </td>
          </template>
        </tr>
      </template>
      <tr class="no-transition" key="no-data-key" v-else>
        <td class="text-center" :colspan="totalColspan">
          <span v-text="nodata" />
        </td>
      </tr>
      <tr class="footer" :class="footerClass" key="footer-key" v-if="footer">
        <td :colspan="totalColspan">
          <slot name="footer" />
        </td>
      </tr>
    </transition-group>
  </v-simple-table>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'CardTable',
  components: {
    Button,
  },
  props: {
    colgroup: {
      type: Array,
      default: () => [],
    },
    header: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    comingSoonRows: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    footerClass: {
      type: String,
      default: '',
    },
    nodata: {
      type: String,
      default: 'No Data',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    totalColspan() {
      let result = 1;

      if (this.vertical) {
        result = (((this.mergedRows || [])[0] || {}).columns || []).length || 1;
      } else {
        result =
          this.header
            .map(th => th.colspan || 1)
            .reduce((precols, currentcols) => precols + currentcols, 0) || 1;
      }

      return result;
    },
    mergedRows() {
      return (this.rows || []).concat(
        (this.comingSoonRows || []).map(row =>
          Object.assign({}, row, { comingSoon: true })
        )
      );
    },
  },
  methods: {
    getHeaderByColumn(index) {
      let columnIndex = 0;
      let headerIndex = 0;

      if (this.header.length && index && index < this.totalColspan) {
        for (; columnIndex < index; ) {
          let currenctHeader = this.header[headerIndex];

          if (currenctHeader && currenctHeader.colspan > 1) {
            columnIndex += currenctHeader.colspan - 1;
          }

          columnIndex += 1;
          headerIndex += 1;
        }
      }

      return this.header[headerIndex] || {};
    },
  },
};
</script>

<style scoped>
.card-table {
  border-radius: 0px !important;
  overflow: hidden;
}

.card-table thead tr {
  height: 40px;
}

.card-table thead tr th {
  padding: 0px 16px;
  font-size: 14px;
  font-weight: 500;
  color: #000000d9 !important;
}

.card-table thead tr th span {
  display: inline;
}

.card-table thead tr th.border-bottom-bold {
  border-bottom-width: 3px !important;
  border-bottom-color: #d5d5d5 !important;
}

.card-table thead tr th.border-bottom-deposit,
.card-table thead tr th.border-bottom-borrow {
  position: relative;
}

.card-table thead tr th.border-bottom-deposit:after,
.card-table thead tr th.border-bottom-borrow:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 1px;
  right: 1px;
  height: 3px;
}

.card-table thead tr th.border-bottom-deposit:after {
  background-color: #1f7bff;
}

.card-table thead tr th.border-bottom-borrow:after {
  background-color: #44ccd7;
}

.card-table.vertical thead {
  display: none;
}

.card-table tr:not(.disabled):hover {
  background-color: transparent !important;
}

.card-table tr > *:first-child {
  padding-left: 24px;
}

.card-table tbody tr {
  height: 80px;
}

.card-table tbody tr.disabled,
.card-table tbody tr.coming-soon,
.card-table tbody tr.footer.dark {
  background-color: #0000001a !important;
}

.card-table tbody tr.coming-soon {
  position: relative;
  transform: scale(1);
}

.card-table tbody tr.footer {
  height: unset !important;
}

.card-table tbody tr.disabled > *,
.card-table tbody tr.coming-soon > * {
  opacity: 0.35;
}

.card-table tbody tr.footer td {
  height: unset !important;
  padding: 16px 30px;
}

.card-table tbody tr td {
  padding: 0px 14px;
  font-size: 14px;
  color: #000000d9;
  word-break: break-word;
}
.card-table tbody tr:not(:last-child) td {
  border-bottom: solid 1px #d5d5d5;
}

.card-table tbody tr td.text,
.card-table tbody tr td.avatar-text,
.card-table tbody tr td.columns {
  font-size: 14px;
  color: #000000d9;
}
.card-table tbody tr td.text .subtext,
.card-table tbody tr td.text .inner-subtext {
  font-size: 12px;
  color: #00000040;
}

.card-table tbody tr td.avatar {
  width: 40px;
  user-select: none;
}
.card-table tbody tr td.avatar .avatar {
}
.card-table tbody tr td.avatar .avatar .avatar-image {
}
.card-table tbody tr td.avatar-text {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 12px;
  word-break: keep-all;
  text-align: center;
}
.card-table tbody tr td.avatar-text .avatar {
  margin-bottom: 6px;
}

.card-table tbody tr td.action,
.card-table tbody tr td.action-replace {
  width: 80px;
}
.card-table tbody tr td.action .action-wrapper,
.card-table tbody tr td.action-replace .action-wrapper {
  width: calc(100% + 30px);
  height: 100%;
  margin: 0px -14px;
}
.card-table tbody tr td.action .action,
.card-table tbody tr td.action-replace .action {
  flex: 1;
  width: 100%;
}
.card-table tbody tr td.action .action-title,
.card-table tbody tr td.action-replace .action-title {
  letter-spacing: 0;
  text-transform: none;
  text-indent: 0;
}
.card-table tbody tr td.action-replace .action-title {
  padding-top: 4px;
}

.card-table tbody tr td.decrease {
  color: #e02020;
}

.card-table tbody tr td.increase {
  color: #47b465;
}

.card-table tbody tr td.subtext-decrease .subtext,
.card-table tbody tr td.subtext-decrease .inner-subtext {
  color: #e02020;
}

.card-table tbody tr td.subtext-increase .subtext,
.card-table tbody tr td.subtext-increase .inner-subtext {
  color: #47b465;
}

.card-table-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000033;
  z-index: 2;
}

.header-text,
.inner-text {
  display: inline-block;
}

.header-avatar,
.inner-avatar {
  width: 24px !important;
  min-width: 24px !important;
  max-width: 24px !important;
  height: 24px !important;
  min-height: 24px !important;
  max-height: 24px !important;
}

.header-avatar.prepend,
.inner-avatar.prepend {
  margin: 0px 6px 0px 0px;
}

.header-avatar.append,
.inner-avatar.append {
  margin: 0px 0px 0px 6px;
}

.header-avatar-image,
.inner-avatar-image {
  width: 22px !important;
  min-width: 22px !important;
  max-width: 22px !important;
  height: 22px !important;
  min-height: 22px !important;
  max-height: 22px !important;
}

.header-avatar-icon,
.inner-avatar-icon {
  font-size: 22px;
  color: #000000;
  opacity: 0.15;
}

.header-avatar-icon:hover,
.inner-avatar-icon:hover {
  opacity: 0.5;
}

.header-tooltip {
  text-align: center;
}

.card-table-row-enter:not(.no-transition),
.card-table-row-enter:not(.no-transition) *,
.card-table-row-leave-to:not(.no-transition),
.card-table-row-leave-to:not(.no-transition) * {
  opacity: 0 !important;
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: 0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.card-table-row-enter.no-transition,
.card-table-row-enter.no-transition *,
.card-table-row-leave-to.no-transition,
.card-table-row-leave-to.no-transition * {
  display: none;
}

/* dark mode */

#app.dark .card-table {
  background-color: #383c52;
}

#app.dark .card-table thead tr th {
  color: #ffffffd9 !important;
}

#app.dark .card-table thead tr th.border-bottom-bold {
  border-bottom-color: #4b506d !important;
}

#app.dark .card-table tbody tr td {
  color: #ffffffd9;
}

#app.dark .card-table tbody tr td.avatar .avatar {
  background-color: #00000040 !important;
}

#app.dark .card-table tbody tr td.text,
#app.dark .card-table tbody tr td.html,
#app.dark .card-table tbody tr td.avatar-text,
#app.dark .card-table tbody tr td.columns {
  color: #ffffffd9;
}

#app.dark .card-table tbody tr td.text .subtext,
#app.dark .card-table tbody tr td.html .subtext,
#app.dark .card-table tbody tr td .inner-subtext {
  color: #ffffff40;
}

#app.dark .card-table-loading {
  background-color: #ffffff33;
}

#app.dark .header-avatar-icon,
#app.dark .inner-avatar-icon {
  color: #ffffff;
}

/* mobile */

#app.mobile .card-table thead tr th {
  height: 40px;
  font-size: 9px;
}

#app.mobile .card-table tbody tr {
  height: 54px;
}

#app.mobile
  .card-table
  tbody
  tr.actionable:not(.coming-soon):not(.footer):hover {
  opacity: 0.5;
}

#app.mobile .card-table tbody tr.coming-soon:after {
  font-size: 12px;
}

#app.mobile .card-table tr th,
#app.mobile .card-table tr td {
  padding: 0px 6px;
}

#app.mobile .card-table tbody tr.footer td {
  padding: 12px 6px;
}

#app.mobile .card-table tbody tr td.avatar {
  width: 24px;
}

#app.mobile .card-table tbody tr td.text,
#app.mobile .card-table tbody tr td.html,
#app.mobile .card-table tbody tr td.columns {
  font-size: 10px;
}

#app.mobile .card-table tbody tr td.avatar-text {
  font-size: 9px;
}

#app.mobile .card-table tbody tr td.text .subtext,
#app.mobile .card-table tbody tr td.html .subtext,
#app.mobile .card-table tbody tr td .inner-subtext {
  padding-top: 2px;
}

#app.mobile .header-avatar,
#app.mobile .inner-avatar {
  width: 16px !important;
  min-width: 16px !important;
  max-width: 16px !important;
  height: 16px !important;
  min-height: 16px !important;
  max-height: 16px !important;
}

#app.mobile .header-avatar.prepend,
#app.mobile .inner-avatar.prepend {
  margin-right: 4px;
}

#app.mobile .header-avatar.append,
#app.mobile .inner-avatar.append {
  margin-left: 4px;
}

#app.mobile .header-avatar-image,
#app.mobile .inner-avatar-image {
  width: 14px !important;
  min-width: 14px !important;
  max-width: 14px !important;
  height: 14px !important;
  min-height: 14px !important;
  max-height: 14px !important;
}

#app.mobile .header-avatar-icon,
#app.mobile .inner-avatar-icon {
  font-size: 14px;
}
</style>

<style>
.card-table .card-table-description {
  display: block;
  font-size: 11px;
  color: #00000040;
  word-break: break-word;
}

.card-table .card-table-description.decrease {
  color: #e02020;
}

.card-table .card-table-description.increase {
  color: #47b465;
}

.card-table td.columns > * {
  padding: 4px 0px;
}

.card-table td.columns > * > * {
  padding: 4px 0px;
}

.card-table .deposit-color {
  color: #1f7bff;
}

.card-table .borrow-color {
  color: #44ccd7;
}

.card-table .card-table-link {
  display: block;
  max-width: 173px;
  font-size: 10px;
  color: #32c5ff;
  word-break: break-all;
  word-wrap: normal;
  cursor: pointer;
  user-select: none;
}

.card-table .card-table-text {
  display: block;
  font-size: 14px;
  word-break: break-word;
  word-wrap: normal;
}

.card-table .card-footer-text {
  font-size: 14px;
  font-weight: bold;
  color: #000;
}

.card-table .card-footer-link {
  text-align: right;
  font-size: 14px;
  color: #1f7bff;
  text-decoration: underline;
}

.card-table .card-footer-link:hover {
  opacity: 0.7;
}

.card-table .card-footer-link:active {
  opacity: 0.5;
}

/* dark mode */

#app.dark .card-table .card-table-description {
  color: #ffffff40;
}

#app.dark .card-table .card-footer-text {
  color: #fff;
}

/* mobile */

#app.mobile .card-table .card-footer-text,
#app.mobile .card-table .card-footer-link {
  font-size: 9px;
}
</style>

<template>
  <div class="pool transition-all" @mouseleave="mouseleave">
    <v-card class="pool-card" outlined tile :dark="$store.getters.darkMode">
      <v-list class="pool-list dex" tile>
        <v-list-item
          class="pool-list-item v-list-item--link"
          :to="dex.to"
          @mouseenter="mouseenterDex(dex.to)"
          v-for="(dex, index) in $config.dexes"
          :key="index"
        >
          <v-list-item-title class="pool-list-item-title">
            <span v-text="dex.title" />
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      <v-divider vertical />
      <template v-for="(dex, dexIndex) in $config.dexes">
        <v-expand-x-transition :key="dexIndex">
          <v-card
            class="pool-list-card"
            tile
            outlined
            elevation="0"
            v-if="selectedDex === dex.to"
          >
            <v-list class="pool-list token" tile>
              <v-list-item
                class="pool-list-item"
                :to="`${selectedDex}${token.to}`"
                v-for="(token, tokenIndex) in $config.dexTokens"
                :key="tokenIndex"
              >
                <v-list-item-title
                  class="pool-list-item-title"
                  v-text="token.title"
                />
              </v-list-item>
            </v-list>
          </v-card>
        </v-expand-x-transition>
      </template>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Pool',
  data: () => ({
    selectedDex: '',
  }),
  methods: {
    mouseenterDex(dex) {
      this.selectedDex = dex || '';
    },
    mouseleave() {
      this.selectedDex = '';
    },
  },
};
</script>

<style scoped>
.pool {
  padding-top: 23.375px;
}
.pool:hover {
  padding-right: 115px;
}
.pool-card {
  position: relative;
  display: flex;
}
.pool-list {
  padding-top: 0px;
  padding-bottom: 0px;
  background: none !important;
}
.pool-list-card {
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 100%;
}
.pool-list-item {
}
.pool-list-item.selected {
}
.pool-list-item-title {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

/* dark mode */
#app.dark .pool-card,
#app.dark .pool-list-card {
  background-color: #383c52 !important;
}
</style>

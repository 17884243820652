var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inflow"},[_c('div',{staticClass:"inflow-title",domProps:{"textContent":_vm._s(_vm.title)}}),_c('div',{staticClass:"inflow-address-copy-wrapper"},[_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.address),expression:"address",arg:"copy"}],staticClass:"inflow-address-copy",attrs:{"text":"","dark":_vm.$store.getters.darkMode,"disabled":!_vm.address}},[_c('span',{staticClass:"inflow-address-copy-title",domProps:{"textContent":_vm._s(
          (_vm.address && ((_vm.address.slice(0, 8)) + "..." + (_vm.address.slice(-8)))) ||
            'No Address'
        )}}),_c('v-icon',{staticClass:"inflow-address-copy-icon"},[_vm._v("mdi-content-copy")])],1)],1),_c('div',{staticClass:"inflow-description",domProps:{"innerHTML":_vm._s(_vm.description)}}),_c('div',{staticClass:"inflow-value-title",domProps:{"textContent":_vm._s(_vm.valueTitle)}}),_c('div',{staticClass:"inflow-value-wrapper"},[_c('div',{staticClass:"inflow-value",domProps:{"textContent":_vm._s(_vm.value)}}),_c('div',{staticClass:"inflow-value-unit",domProps:{"textContent":_vm._s(_vm.valueUnit)}})]),_c('div',{staticClass:"inflow-fee-description",domProps:{"innerHTML":_vm._s(_vm.feeDescription)}}),_c('div',{staticClass:"inflow-step-wrapper"},[_vm._l((_vm.steps),function(step,stepIndex){return [(stepIndex !== 0)?_c('div',{key:("div-" + stepIndex),staticClass:"inflow-step-divider transition-all",class:{ complete: step.status > 0 }}):_vm._e(),_c('div',{key:stepIndex,staticClass:"inflow-step transition-all",class:("step-" + (step.status))},[_c('div',{staticClass:"inflow-step-icon-wrapper transition-all"},[(step.status === 1)?_c('Loader',{staticClass:"inflow-step-icon loader",attrs:{"color":"#47b465","borderWeight":4,"size":30,"small":""}}):_c('v-icon',{staticClass:"inflow-step-icon transition-all",domProps:{"textContent":_vm._s(
              step.status === 0
                ? 'mdi-checkbox-blank-circle-outline'
                : 'mdi-check-circle'
            )}})],1),(step.title)?_c('div',{staticClass:"inflow-step-title",domProps:{"innerHTML":_vm._s(step.title)}}):_vm._e()])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="get">
    <AvatarIconSet :size="180" :iconSet="[{ image: tokenImage }]" />
    <div
      class="get-description description"
      v-html="$t('trade.btc.description')"
    />
  </div>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

export default {
  name: 'Get',
  components: {
    AvatarIconSet,
  },
  props: {
    tokenImage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.get {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.get-description {
  padding-top: 30px;
  text-align: center;
}

/* mobile */
#app.mobile .get {
  padding: 24px 10%;
}
</style>

<template>
  <v-expansion-panels
    class="card-expansion"
    :disabled="disabled"
    :value="0"
    :dark="$store.getters.darkMode"
  >
    <v-expansion-panel class="card-expansion-panel" :class="cardClass">
      <v-expansion-panel-header
        class="card-expansion-panel-header transition-all"
        :class="{ disabled: disabled }"
      >
        <slot name="header" />
      </v-expansion-panel-header>
      <v-expansion-panel-content class="card-expansion-panel-content">
        <v-expand-transition>
          <template v-if="!disabled">
            <slot name="content" />
          </template>
        </v-expand-transition>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Card',
  props: {
    cardClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
};
</script>

<style scoped>
.card-expansion {
}

.card-expansion-panel {
  color: #000000d9 !important;
  font-weight: 500;
  border: solid 1px #d5d5d5;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  overflow: hidden;
}

.card-expansion-panel-header {
  min-height: 0px !important;
  padding: 20px 24px;
}

.card-expansion-panel-header.disabled {
  opacity: 0.25;
}

.card-expansion-panel-content {
}

.card-background-image {
  position: absolute;
}

.card-background-image.top {
  top: 0;
}

.card-background-image.bottom {
  bottom: 0;
}

.card-background-image.left {
  left: 0;
}

.card-background-image.right {
  right: 0;
}

.card-title {
  margin-bottom: 24px;
  font-size: 20px;
}

.card-small-title {
  font-size: 14px;
}

.card-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

.card-text-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #ffffff80;
}

.card-highlight-value {
  font-size: 32px;
  transform-origin: 50% calc(100% - 8px);
}

.card-highlight-value .decimal {
  color: #ffffff80;
}

/* dark mode */

#app.dark .card-expansion-panel {
  color: #ffffffd9 !important;
  background-color: #383c52 !important;
}

#app.dark .card-text-description {
  color: #00000080;
}

#app.dark .card-highlight-value .decimal {
  color: #00000080;
}

/* mobile */

#app.mobile .card-expansion-panel {
  margin-top: 20px !important;
}
</style>

<style>
.card-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>

<template>
  <Card class="coin-status">
    <div slot="body">
      <CardTable
        footer
        footerClass="dark"
        :vertical="$vuetify.breakpoint.smAndDown"
        :nodata="$t('market.coinStatus.nodata')"
        :header="header"
        :rows="rows"
        :comingSoonRows="comingSoonRows"
      >
        <v-layout slot="footer" justify-space-between align-center>
          <div
            class="card-footer-text"
            v-text="$t('common.message.comingSoonToken')"
          />
          <a
            class="card-footer-link transition-all"
            rel="noopener"
            target="_blank"
            :href="$config.link.productRequest"
            v-text="$t('common.message.gainOpinionToken')"
          />
        </v-layout>
      </CardTable>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/card/Card';
import CardTable from '@/components/card/CardTable';

export default {
  name: 'CoinStatus',
  components: {
    Card,
    CardTable,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    comingSoonData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    header() {
      let result = [];

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = [
          {
            title: this.$t('market.coinStatus.header.market'),
            class: 'text-left border-bottom-bold',
            colspan: 2,
          },
          {
            title: this.$t('market.coinStatus.header.marketShare'),
            class: 'text-right border-bottom-bold',
          },
          {
            title: this.$t('market.coinStatus.header.price'),
            class: 'text-right border-bottom-bold',
          },
          {
            title: this.$t('market.coinStatus.header.totalDeposit'),
            class: 'text-right border-bottom-bold border-bottom-deposit',
          },
          {
            title: this.$t('market.coinStatus.header.depositInterest'),
            class: 'text-right border-bottom-bold border-bottom-deposit',
          },
          {
            title: this.$t('market.coinStatus.header.totalBorrowAmount'),
            class: 'text-right border-bottom-bold border-bottom-borrow',
          },
          {
            title: this.$t('market.coinStatus.header.borrowInterest'),
            class: 'text-right border-bottom-bold border-bottom-borrow',
          },
        ];
      }

      return result;
    },
    rows() {
      let result = [];

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = this.data.map(token => ({
          disabled: token.disabled,
          columns: [
            {
              type: 'avatar',
              image: token.image,
              text: token.title,
            },
            {
              type: 'text',
              text: token.title,
            },
            {
              type: 'text',
              text: token.deposit.value.share,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.price,
              class: 'text-right',
              tooltip: token.priceDetail,
            },
            {
              type: 'text',
              text: token.deposit.value.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.deposit.interestRate.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.borrow.value.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.borrow.interestRate.value,
              class: 'text-right',
            },
          ],
        }));
      } else {
        result = this.data.map(token => ({
          disabled: token.disabled,
          columns: [
            {
              type: 'avatar-text',
              image: token.image,
              text: token.title,
            },
            {
              type: 'columns',
              columns: [
                {
                  text: this.$t('market.coinStatus.header.totalDeposit'),
                  class: 'deposit-color',
                },
                {
                  text: this.$t('market.coinStatus.header.depositInterest'),
                  class: 'deposit-color',
                },
                {
                  text: this.$t('market.coinStatus.header.totalBorrowAmount'),
                  class: 'borrow-color',
                },
                {
                  text: this.$t('market.coinStatus.header.borrowInterest'),
                  class: 'borrow-color',
                },
                {
                  text: this.$t('market.coinStatus.header.marketShare'),
                },
                {
                  text: this.$t('market.coinStatus.header.price'),
                },
              ],
              class: 'text-left',
            },
            {
              type: 'columns',
              columns: [
                {
                  text: token.deposit.value.value,
                  class: 'deposit-color',
                },
                {
                  text: token.deposit.interestRate.value,
                  class: 'deposit-color',
                },
                {
                  text: token.borrow.value.value,
                  class: 'borrow-color',
                },
                {
                  text: token.borrow.interestRate.value,
                  class: 'borrow-color',
                },
                {
                  text: token.deposit.value.share,
                },
                {
                  text: token.price,
                },
              ],
              class: 'text-right',
            },
          ],
        }));
      }

      return result;
    },
    comingSoonRows() {
      let result = [];

      if (this.$vuetify.breakpoint.mdAndUp) {
        result = this.comingSoonData.map(token => ({
          columns: [
            {
              type: 'avatar',
              image: token.image,
              text: token.title,
            },
            {
              type: 'text',
              text: token.title,
            },
            {
              type: 'text',
              text: token.deposit.value.share,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.price,
              class: 'text-right',
              tooltip: token.priceDetail,
            },
            {
              type: 'text',
              text: token.deposit.value.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.deposit.interestRate.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.borrow.value.value,
              class: 'text-right',
            },
            {
              type: 'text',
              text: token.borrow.interestRate.value,
              class: 'text-right',
            },
          ],
        }));
      } else {
        result = this.comingSoonData.map(token => ({
          columns: [
            {
              type: 'avatar-text',
              image: token.image,
              text: token.title,
            },
            {
              type: 'columns',
              columns: [
                {
                  text: this.$t('market.coinStatus.header.totalDeposit'),
                  class: 'deposit-color',
                },
                {
                  text: this.$t('market.coinStatus.header.depositInterest'),
                  class: 'deposit-color',
                },
                {
                  text: this.$t('market.coinStatus.header.totalBorrowAmount'),
                  class: 'borrow-color',
                },
                {
                  text: this.$t('market.coinStatus.header.borrowInterest'),
                  class: 'borrow-color',
                },
                {
                  text: this.$t('market.coinStatus.header.marketShare'),
                },
                {
                  text: this.$t('market.coinStatus.header.price'),
                },
              ],
              class: 'text-left',
            },
            {
              type: 'columns',
              columns: [
                {
                  text: token.deposit.value.value,
                  class: 'deposit-color',
                },
                {
                  text: token.deposit.interestRate.value,
                  class: 'deposit-color',
                },
                {
                  text: token.borrow.value.value,
                  class: 'borrow-color',
                },
                {
                  text: token.borrow.interestRate.value,
                  class: 'borrow-color',
                },
                {
                  text: token.deposit.value.share,
                },
                {
                  text: token.price,
                },
              ],
              class: 'text-right',
            },
          ],
        }));
      }

      return result;
    },
  },
};
</script>

<style scoped></style>

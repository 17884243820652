var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"dialog edit-btc","max-width":"900","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.$store.state.ui.btc.edit),callback:function ($$v) {_vm.$set(_vm.$store.state.ui.btc, "edit", $$v)},expression:"$store.state.ui.btc.edit"}},[_c('v-layout',{staticClass:"dialog-content",attrs:{"column":""}},[_c('div',{staticClass:"dialog-header"},[(_vm.$vuetify.breakpoint.smAndDown)?[_c('v-btn',{staticClass:"ml-4 mr-n2",attrs:{"icon":"","dark":_vm.$store.getters.darkMode},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-spacer')]:_vm._e(),_c('div',{staticClass:"dialog-header-title",domProps:{"textContent":_vm._s(
          _vm.$t('trade.content.title', {
            action: _vm.$t('common.action.editBtcAddress'),
          })
        )}})],2),_c('div',{staticClass:"dialog-body description"},[_c('div',{staticClass:"dialog-body-title",domProps:{"textContent":_vm._s(_vm.$t('trade.content.information.currentBtcAddress'))}}),_c('div',{staticClass:"dialog-body-address-copy-wrapper"},[_c('v-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.address),expression:"address",arg:"copy"}],staticClass:"dialog-body-address-copy",attrs:{"text":"","dark":_vm.$store.getters.darkMode,"disabled":!_vm.address}},[_c('span',{staticClass:"dialog-body-address-copy-title",domProps:{"textContent":_vm._s(
              (_vm.address && ((_vm.address.slice(0, 8)) + "..." + (_vm.address.slice(-8)))) ||
                'No Address'
            )}}),_c('v-icon',{staticClass:"dialog-body-address-copy-icon"},[_vm._v("mdi-content-copy")])],1)],1),_c('div',{staticClass:"dialog-body-title",domProps:{"textContent":_vm._s(_vm.$t('trade.content.information.btcAddressToEdited'))}}),_c('v-text-field',{staticClass:"dialog-body-input",attrs:{"filled":"","dense":"","placeholder":_vm.$t('trade.content.placeholder.btcAddressToEdited'),"rules":_vm.$rulesBtcAddress,"dark":_vm.$store.getters.darkMode},model:{value:(_vm.inputBtcAddress),callback:function ($$v) {_vm.inputBtcAddress=$$v},expression:"inputBtcAddress"}})],1),_c('v-fade-transition',[(_vm.pending)?_c('div',{staticClass:"loader-wrapper"},[_c('Loader',{staticClass:"loader",attrs:{"color":"#47b465","secondColor":"#1f7bff","borderWeight":2,"size":80}}),(_vm.pendingLink)?_c('a',{staticClass:"loader-title link transition-all",attrs:{"rel":"noopener","target":"_blank","href":_vm.pendingLink}},[_c('span',{domProps:{"textContent":_vm._s(_vm.pendingMessage)}}),_c('v-icon',{staticClass:"loader-link-icon",attrs:{"color":"#2380fb","small":""}},[_vm._v("mdi-open-in-new")])],1):(_vm.pendingMessage)?_c('div',{staticClass:"loader-title",domProps:{"textContent":_vm._s(_vm.pendingMessage)}}):_vm._e(),(_vm.pendingTransactionHash)?_c('div',{directives:[{name:"t",rawName:"v-t",value:('common.message.transaction.processing'),expression:"'common.message.transaction.processing'"}],staticClass:"loader-title"}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"dialog-footer"},[_c('div',{staticClass:"dialog-footer-info"}),_c('div',{staticClass:"dialog-footer-button-wrapper"},[_c('v-btn',{staticClass:"dialog-footer-button",attrs:{"color":"#1f7bff","tile":"","depressed":"","disabled":!!this.pending || !this.isValidInputBtcAddress},on:{"click":_vm.edit}},[_c('span',{staticClass:"dialog-footer-button-title",domProps:{"textContent":_vm._s(
              _vm.$t('trade.content.button.action', {
                action: _vm.$t('common.action.edit'),
              })
            )}})])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import circuitBreaker from './circuitBreaker';
import connection from './connection';
import darkMode from './darkMode';
import language from './language';
import localStorage from './localStorage';
import currency from './currency';
import contract from './contract';

export default {
  ...circuitBreaker,
  ...connection,
  ...darkMode,
  ...language,
  ...localStorage,
  ...currency,
  ...contract,
};

<template>
  <div class="inflow">
    <div class="inflow-title" v-text="title" />
    <div class="inflow-address-copy-wrapper">
      <v-btn
        class="inflow-address-copy"
        text
        :dark="$store.getters.darkMode"
        :disabled="!address"
        v-clipboard:copy="address"
      >
        <span
          class="inflow-address-copy-title"
          v-text="
            (address && `${address.slice(0, 8)}...${address.slice(-8)}`) ||
              'No Address'
          "
        />
        <v-icon class="inflow-address-copy-icon">mdi-content-copy</v-icon>
      </v-btn>
    </div>
    <div class="inflow-description" v-html="description" />
    <div class="inflow-value-title" v-text="valueTitle" />
    <div class="inflow-value-wrapper">
      <div class="inflow-value" v-text="value" />
      <div class="inflow-value-unit" v-text="valueUnit" />
    </div>
    <div class="inflow-fee-description" v-html="feeDescription" />
    <div class="inflow-step-wrapper">
      <template v-for="(step, stepIndex) in steps">
        <div
          class="inflow-step-divider transition-all"
          :class="{ complete: step.status > 0 }"
          :key="`div-${stepIndex}`"
          v-if="stepIndex !== 0"
        />
        <div
          class="inflow-step transition-all"
          :class="`step-${step.status}`"
          :key="stepIndex"
        >
          <div class="inflow-step-icon-wrapper transition-all">
            <Loader
              class="inflow-step-icon loader"
              color="#47b465"
              :borderWeight="4"
              :size="30"
              small
              v-if="step.status === 1"
            />
            <v-icon
              class="inflow-step-icon transition-all"
              v-text="
                step.status === 0
                  ? 'mdi-checkbox-blank-circle-outline'
                  : 'mdi-check-circle'
              "
              v-else
            />
          </div>
          <div
            class="inflow-step-title"
            v-html="step.title"
            v-if="step.title"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'Inflow',
  props: {
    title: {
      type: String,
      default: '',
    },
    address: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    feeDescription: {
      type: String,
      default: '',
    },
    valueTitle: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    valueUnit: {
      type: String,
      default: '',
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Loader,
  },
};
</script>

<style scoped>
.inflow {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
  color: #000000d9;
  text-align: center;
}
.inflow-title {
  height: 20px;
  font-size: 14px;
}
.inflow-address-copy-wrapper {
  display: flex;
  justify-content: center;
  padding: 12px 0px;
}
.inflow-address-copy {
}
.inflow-address-copy-title {
  font-size: 20px;
  letter-spacing: 0;
  text-transform: none;
}
.inflow-address-copy-icon {
  margin-left: 12px;
  font-size: 20px;
}
.inflow-description {
  height: 36px;
  font-size: 12px;
  opacity: 0.25;
}
.inflow-value-title {
  height: 20px;
  margin: 12px 0px 10px;
  font-size: 14px;
}
.inflow-value-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
}
.inflow-value {
  font-size: 50px;
  line-height: 50px;
}
.inflow-value-unit {
  padding-left: 20px;
  font-size: 18px;
  letter-spacing: 0.5px;
}
.inflow-fee-description {
  height: 38px;
  padding-top: 20px;
  font-size: 12px;
}
.inflow-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 110px;
  margin-top: 48px;
}
.inflow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  opacity: 0.25;
}
.inflow-step:not(.step-0) {
  opacity: 1;
}
.inflow-step-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.inflow-step.step-1 .inflow-step-icon-wrapper {
  transform: rotate(360deg);
}
.inflow-step.step-2 .inflow-step-icon-wrapper {
  transform: rotate(720deg);
}
.inflow-step-icon {
  font-size: 34px;
}
.inflow-step.step-2 .inflow-step-icon-wrapper .inflow-step-icon {
  color: #47b465;
}
.inflow-step-title {
  padding-top: 16px;
  font-size: 14px;
}
.inflow-step-divider {
  position: relative;
  flex-grow: 0;
  width: 150px;
  min-width: 150px;
  height: 2px;
  margin: 15px 40px 0px;
  border-radius: 2px;
  background-color: #000000d9;
  opacity: 0.25;
  overflow: hidden;
}
.inflow-step-divider.complete {
  opacity: 1;
}
.inflow-step-divider:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0px;
  background-color: #47b465;

  -webkit-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -moz-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -ms-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -o-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
}
.inflow-step-divider.complete:after {
  width: 100%;
}

/* dark mode */
#app.dark .inflow {
  color: #fff;
}
#app.dark .inflow-step-divider {
  background-color: #fff;
}

/* mobile */
#app.mobile .inflow {
  padding: 12px;
  text-align: left;
}
#app.mobile .inflow-title {
  height: unset;
  font-size: 12px;
}
#app.mobile .inflow-address-copy-wrapper {
  justify-content: flex-start;
}
#app.mobile .inflow-address-copy {
  height: unset !important;
  margin: 0px;
  margin-left: -8px;
  padding: 4px 8px !important;
}
#app.mobile .inflow-address-copy-title {
  font-size: 12px;
}
#app.mobile .inflow-address-copy-icon {
  margin-left: 6px;
  font-size: 14px;
}
#app.mobile .inflow-description {
  height: unset;
  font-size: 10px;
}
#app.mobile .inflow-value-title {
  height: unset;
  margin: 8px 0px;
}
#app.mobile .inflow-value-wrapper {
  justify-content: flex-start;
  height: unset;
}
#app.mobile .inflow-value,
#app.mobile .inflow-value-unit {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
}
#app.mobile .inflow-value-unit {
  padding-left: 4px;
}
#app.mobile .inflow-fee-description {
  height: unset;
  padding-top: 8px;
}
#app.mobile .inflow-step-wrapper {
  flex-direction: column;
  height: unset;
  margin-top: 14px;
  margin-bottom: -10px;
}
#app.mobile .inflow-step-divider {
  display: none;
}
#app.mobile .inflow-step {
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
#app.mobile .inflow-step-title {
  padding-top: 0px;
  padding-left: 16px;
}
</style>

<style>
.inflow .inflow-fee-description * {
  opacity: 0.25;
}
</style>

<template>
  <v-dialog
    content-class="welcome"
    persistent
    v-model="$store.state.ui.welcome.show"
  >
    <v-img
      class="welcome-image"
      :src="`${$config.host.image}welcome/background.png`"
    />
    <v-layout class="welcome-content" column>
      <div class="welcome-body">
        <div
          class="welcome-body-title"
          v-text="$t('common.message.welcome.title')"
        />
        <div
          class="welcome-body-subtitle"
          v-text="$t('common.message.welcome.subtitle')"
        />
      </div>
      <v-layout class="welcome-footer" justify-end align-center>
        <v-btn
          class="welcome-footer-button close"
          color="#1f7bff"
          :large="$vuetify.breakpoint.mdAndUp"
          @click="close"
        >
          <span
            class="welcome-footer-button-title"
            v-text="$t('common.message.close')"
          />
        </v-btn>
        <v-btn
          class="welcome-footer-button link"
          color="#1f7bff"
          rel="noopener"
          target="_blank"
          :large="$vuetify.breakpoint.mdAndUp"
          :href="$t('common.link.help')"
        >
          <span
            class="welcome-footer-button-title"
            v-text="$t('common.message.welcome.seeGuide')"
          />
        </v-btn>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: 'Welcome',
  methods: {
    close() {
      this.$store.dispatch('closeWelcomePopup', {});
    },
  },
};
</script>

<style scoped>
.welcome {
}

.welcome-image {
  min-height: 300px;
}

.welcome-content {
  min-height: 200px;
  user-select: none;
  border: solid 1px #4b506d;
  border-top: none;
  overflow: hidden;
}

.welcome-body {
  padding: 24px 30px;
}

.welcome-body-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #000000d9;
}

.welcome-body-subtitle {
  padding-top: 14px;
  font-size: 16px;
  color: #000000d9;
}

.welcome-footer {
  padding: 20px;
  margin: 0px -6px;
}

.welcome-footer-button {
  margin: 0px 6px;
}

.welcome-footer-button-title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #fff;
}

/* dark mode */
#app.dark .welcome-body-title,
#app.dark .welcome-body-subtitle {
  color: #fff;
}

/* mobile */
#app.mobile .welcome-image {
  min-height: 200px;
}

#app.mobile .welcome-content {
  min-height: 150px;
}

#app.mobile .welcome-body {
  padding: 15px 20px;
}

#app.mobile .welcome-body-title {
  font-size: 16px;
}

#app.mobile .welcome-body-subtitle {
  padding-top: 10px;
  font-size: 14px;
}

#app.mobile .welcome-footer {
  padding: 20px;
  margin: 0px -4px;
}

#app.mobile .welcome-footer-button {
  margin: 0px 4px;
}

#app.mobile .welcome-footer-button-title {
  font-size: 15px;
}
</style>

<style>
.welcome {
  max-width: 877px;
  background-color: #fff;
}

/* dark */
#app.dark .welcome {
  background-color: #383c52;
}

/* mobile */
#app.mobile .welcome {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

<template>
  <div class="card">
    <slot name="header" />
    <slot name="body" />
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style scoped>
.card {
  position: relative;
  color: #000000d9;
  font-weight: 500;
  border: solid 1px #d5d5d5;
  border-radius: 3px;
  background-color: #ffffff;
  overflow: hidden;
}

/* dark mode */

#app.dark .card {
  color: #ffffffd9;
  background-color: #383c52;
}
</style>

<style>
.card .card-header {
  position: relative;
}

.card .card-header.main {
  height: 133px;
  padding: 24px;
  color: #fff;
}

.card .card-submain {
  flex: 0;
  width: 133px;
  min-width: 133px;
  margin-top: -24px;
  margin-bottom: -24px;
  background-color: #0000000d;
}

.card .card-submain:last-child {
  margin-right: -24px;
}

.card .card-background-image {
  position: absolute;
}

.card .card-background-image.top {
  top: 0;
}

.card .card-background-image.bottom {
  bottom: 0;
}

.card .card-background-image.left {
  left: 0;
}

.card .card-background-image.right {
  right: 0;
}

.card .card-title {
  margin-bottom: 24px;
  font-size: 20px;
}

.card .card-small-title {
  font-size: 14px;
}

.card .card-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

.card .card-text.highlight {
  font-weight: bold;
}

.card .card-text-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: #ffffff80;
}

.card .card-highlight-value {
  max-height: 46px;
  min-height: 46px;
  font-size: 32px;
  font-weight: 500;
  transform-origin: 50% calc(100% - 8px);
}

.card .card-highlight-value.disabled {
  opacity: 0.25;
}

.card .card-highlight-value .value,
.card .card-highlight-value .value.decimal {
  margin-bottom: -5px;
}

.card .card-highlight-value .value.decimal {
  opacity: 0.5;
}

.card .card-highlight-value .unit {
  font-size: 18px;
}

.card .card-highlight-value .unit.prefix {
  margin-right: 4px;
}

.card .card-highlight-value .unit.suffix {
  margin-left: 4px;
}

.card .card-highlight-value .abbreviation {
  margin-left: 4px;
  margin-bottom: -5px;
}

/* dark mode */

#app.dark .card .card-submain {
  background-color: #ffffff0d;
}

#app.dark .card .card-text-description {
  color: #00000080;
}

/* mobile */

#app.mobile .card .card-header.main {
  height: 80px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

#app.mobile .card .card-submain {
  width: 65px;
  min-width: 65px;
  margin-top: -16px;
}

#app.mobile .card .card-submain:last-child {
  margin-right: -16px;
}

#app.mobile .card .card-title {
  font-size: 20px;
}

#app.mobile .card .card-small-title {
  font-size: 14px;
}

#app.mobile .card .card-text {
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
}

#app.mobile .card .card-text-description {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
}

#app.mobile .card .card-highlight-value {
  max-height: 29px;
  min-height: 29px;
  font-size: 20px;
}

#app.mobile .card .card-highlight-value .value,
#app.mobile .card .card-highlight-value .value.decimal,
#app.mobile .card .card-highlight-value .abbreviation {
  margin-bottom: -2px;
}

#app.mobile .card .card-highlight-value .unit {
  font-size: 18px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-simple-table',{staticClass:"card-table",class:{ vertical: _vm.vertical },attrs:{"dark":_vm.$store.getters.darkMode}},[(_vm.loading)?_c('div',{staticClass:"card-table-loading"},[_c('v-progress-circular',{staticClass:"card-table-loading-progress",attrs:{"indeterminate":"","size":80,"width":7}})],1):_vm._e(),(_vm.colgroup && _vm.colgroup.length)?_c('colgroup',_vm._l((_vm.colgroup),function(col,colIndex){return _c('col',{key:colIndex,style:(col.style),attrs:{"span":col.span}})}),0):_vm._e(),(!_vm.vertical)?_c('thead',[_c('tr',[_vm._l((_vm.header),function(head,headIndex){return [(!(head.onlyDesktop && _vm.$vuetify.breakpoint.smAndDown))?_c('th',{key:headIndex,class:head.class || '',attrs:{"colspan":head.colspan || 1}},[(head.image)?_c('v-avatar',{staticClass:"header-avatar prepend",attrs:{"color":"#0000001a"}},[_c('v-img',{staticClass:"header-avatar-image",attrs:{"contain":"","aspect-ratio":"2","src":head.image}})],1):_vm._e(),_c('div',{staticClass:"header-text",domProps:{"innerHTML":_vm._s(head.title || '')}}),(head.tooltip)?_c('v-tooltip',{attrs:{"top":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"header-avatar append"},'v-avatar',attrs,false),on),[_c('v-icon',{staticClass:"header-avatar-icon"},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('div',{staticClass:"header-tooltip",domProps:{"innerHTML":_vm._s(head.tooltip)}})]):_vm._e()],1):_vm._e()]})],2)]):_vm._e(),_c('transition-group',{attrs:{"name":"card-table-row","tag":"tbody"}},[(_vm.mergedRows && _vm.mergedRows.length)?_vm._l((_vm.mergedRows),function(row,rowIndex){return _c('tr',{key:row.id || ("index-" + rowIndex),staticClass:"transition-all",class:((row.comingSoon ? 'coming-soon' : '') + " " + (row.disabled || row.comingSoon ? 'disabled' : '') + " " + (_vm.$vuetify.breakpoint.smAndDown &&
            !row.disabled &&
            !row.comingSoon &&
            typeof ((_vm.actions || [])[0] || {}).action === 'function'
              ? 'actionable'
              : '') + " " + (row.class || '')),on:{"click":function($event){_vm.$execFunc(
            _vm.$vuetify.breakpoint.smAndDown && !row.disabled && !row.comingSoon
              ? (_vm.actions[0] || {}).action
              : undefined,
            $event,
            row
          )}}},[_vm._l((row.columns),function(column,columnIndex){return [(
              !(
                _vm.getHeaderByColumn(columnIndex).onlyDesktop &&
                _vm.$vuetify.breakpoint.smAndDown
              )
            )?_c('td',{key:columnIndex,class:((column.class || '') + " " + (column.type || '')).trim()},[(column.type === 'avatar')?_c('v-avatar',{staticClass:"avatar",attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? '24' : '40',"color":column.src || column.image
                  ? column.color || '#0000001a'
                  : '#fff'}},[(column.src)?_c('v-img',{staticClass:"avatar-image",attrs:{"contain":"","max-width":"32","max-height":"32","aspect-ratio":"2","src":column.src}}):(column.image)?_c('v-img',{staticClass:"avatar-image",attrs:{"contain":"","max-width":"32","max-height":"32","aspect-ratio":"2","src":column.image}}):_c('span',{staticClass:"white--text headline",domProps:{"textContent":_vm._s(("" + (column.text)).slice(0, 1).toUpperCase())}})],1):(column.type === 'avatar-text')?_c('div',{staticClass:"avatar-text"},[_c('v-avatar',{staticClass:"avatar",attrs:{"size":_vm.$vuetify.breakpoint.smAndDown ? '24' : '40',"color":column.src || column.image
                    ? column.color || '#0000001a'
                    : '#fff'}},[(column.src)?_c('v-img',{staticClass:"avatar-image",attrs:{"contain":"","max-width":"32","max-height":"32","aspect-ratio":"2","src":column.src}}):(column.image)?_c('v-img',{staticClass:"avatar-image",attrs:{"contain":"","max-width":"32","max-height":"32","aspect-ratio":"2","src":column.image}}):_c('span',{staticClass:"white--text headline",domProps:{"textContent":_vm._s(("" + (column.text)).slice(0, 1).toUpperCase())}})],1),(column.text)?_c('div',{domProps:{"textContent":_vm._s(column.text || '')}}):_vm._e()],1):(column.type === 'text')?_c('div',[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":row.comingSoon || !column.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tooltip-wrapper"},'div',attrs,false),on),[(column.text)?_c('div',{domProps:{"textContent":_vm._s(column.text || '')}}):_vm._e(),(column.subtext)?_c('div',{staticClass:"subtext",domProps:{"textContent":_vm._s(column.subtext || '')}}):_vm._e()])]}}],null,true)},[(column.tooltip)?_c('span',{domProps:{"textContent":_vm._s(column.tooltip)}}):_vm._e()])],1):(column.type === 'action')?_c('v-layout',{staticClass:"action-wrapper",attrs:{"column":"","justify-center":"","align-center":""}},[_vm._l((_vm.actions),function(action,actionIndex){return [(!_vm.$execFunc(action.getIsHide, row.id))?_c('v-btn',{key:actionIndex,staticClass:"action",attrs:{"text":"","tile":"","disabled":row.disabled || row.comingSoon},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return action.action($event, row)}}},[_c('span',{staticClass:"action-title",style:({
                      color: action.color || column.color || _vm.color || '',
                    }),domProps:{"textContent":_vm._s(row.comingSoon ? '-' : action.title)}})]):_vm._e()]})],2):(column.type === 'action-replace')?_c('v-layout',{staticClass:"action-wrapper",attrs:{"column":"","justify-center":"","align-center":""}},[_c('v-btn',{staticClass:"action",attrs:{"text":"","tile":"","disabled":row.disabled || row.comingSoon},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();((_vm.actions || [])[0] || {}).action($event, row)}}},[_c('div',{staticClass:"action-replace-wrapper"},[(column.replace.prependIcon)?_c('v-icon',{staticClass:"action-title-icon prepend",domProps:{"textContent":_vm._s(column.replace.prependIcon)}}):_vm._e(),_c('div',{staticClass:"action-title",domProps:{"textContent":_vm._s(column.replace.title)}}),(column.replace.appendIcon)?_c('v-icon',{staticClass:"action-title-icon append",domProps:{"textContent":_vm._s(column.replace.appendIcon)}}):_vm._e()],1)])],1):(column.type === 'button')?_c('Button',{attrs:{"buttonClass":column.buttonClass || '',"disabled":row.disabled || row.comingSoon,"image":column.image,"text":column.text,"action":column.action}}):(column.type === 'fab-button')?_c('Button',{attrs:{"buttonClass":column.buttonClass || '',"fab":"","disabled":row.disabled || row.comingSoon,"image":column.image,"text":column.text,"action":column.action}}):(column.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(column.html)}}):(column.type === 'columns')?_c('div',[(
                  column.columns &&
                    typeof column.columns === 'object' &&
                    column.columns.length
                )?_vm._l((column.columns),function(innerColumn,innerColumnIndex){return _c('div',{key:innerColumnIndex,class:innerColumn.class || ''},[(innerColumn.prependImage)?_c('v-avatar',{staticClass:"inner-avatar prepend",attrs:{"color":"#0000001a"}},[_c('v-img',{staticClass:"inner-avatar-image",attrs:{"contain":"","aspect-ratio":"2","src":innerColumn.prependImage}})],1):_vm._e(),(innerColumn.text)?_c('div',{staticClass:"inner-text",domProps:{"textContent":_vm._s(innerColumn.text)}}):_vm._e(),(innerColumn.appendImage)?_c('v-avatar',{staticClass:"inner-avatar append",attrs:{"color":"#0000001a"}},[_c('v-img',{staticClass:"inner-avatar-image",attrs:{"contain":"","aspect-ratio":"2","src":innerColumn.appendImage}})],1):_vm._e(),(innerColumn.tooltip)?_c('v-tooltip',{attrs:{"top":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"inner-avatar append"},'v-avatar',attrs,false),on),[_c('v-icon',{staticClass:"inner-avatar-icon"},[_vm._v("mdi-help-circle-outline")])],1)]}}],null,true)},[_c('div',{staticClass:"inner-tooltip",domProps:{"innerHTML":_vm._s(innerColumn.tooltip)}})]):_vm._e(),(innerColumn.subtext)?_c('div',{staticClass:"inner-subtext",domProps:{"textContent":_vm._s(innerColumn.subtext)}}):_vm._e()],1)}):_vm._e()],2):_vm._e()],1):_vm._e()]})],2)}):_c('tr',{key:"no-data-key",staticClass:"no-transition"},[_c('td',{staticClass:"text-center",attrs:{"colspan":_vm.totalColspan}},[_c('span',{domProps:{"textContent":_vm._s(_vm.nodata)}})])]),(_vm.footer)?_c('tr',{key:"footer-key",staticClass:"footer",class:_vm.footerClass},[_c('td',{attrs:{"colspan":_vm.totalColspan}},[_vm._t("footer")],2)]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Web3 from 'web3';
import config from '@/plugins/config';
import func from '@/plugins/function';

const networkIsEthereum = network =>
  network === 'mainnet' ||
  network === 'kovan' ||
  network === 'pilab-poa2' ||
  network === 'pilab-fork';
const networkIsBsc = network =>
  !networkIsEthereum(network) && (network === 'bsc' || network === 'bsc-test');
const networkIsAvalanche = network =>
  !networkIsEthereum(network) &&
  (network === 'avalanche' || network === 'avalanche-test');
const networkIsKaia = network =>
  !networkIsEthereum(network) &&
  (network === 'kaia' || network === 'kaia-test');
const networkIsBifrost = network =>
  !networkIsEthereum(network) &&
  (network === 'bifrost' || network === 'bifrost-test');
const networkIsBifrostTestnet = network =>
  !networkIsEthereum(network) && network === 'bifrost-test';

export default {
  circuitBreaker: state => !!state.circuitBreaker,
  oracleCircuitBreaker: state => !!state.oracleCircuitBreaker,
  btcChallenge: state => !!state.wallet.btc.challenge,
  btcProcessingOutflow: state => state.wallet.btc.outflowAmount.gt('0'),
  connectionCount: state => state.connectionCount,
  contract: state => state.contract,
  darkMode: state => state.darkMode,

  wallet: state => state.wallet,
  transactions: state => state.wallet.transaction.list,
  hasCheckAlert: state => state.wallet.transaction.hasCheck,
  walletID: state => state.wallet.id,
  isWalletBiport: state => state.wallet.id === 'biport',
  isWalletMetamask: state => state.wallet.id === 'metamask',
  isWalletCoinbase: state => state.wallet.id === 'coinbase',
  isWallet: state => state.wallet.type === 'wallet',
  isWalletInstability: state =>
    (state.wallet.type !== 'wallet' &&
      ((state.wallet.id === 'biport' && !!window.biport) ||
        !!window.ethereum)) ||
    (state.wallet.type === 'wallet' &&
      (!state.wallet.address || !Web3.utils.isAddress(state.wallet.address))),
  isWalletNetworkDiff: state =>
    state.wallet.network !== state.wallet.metamask.network,
  isWalletUsable: state =>
    state.wallet.type === 'wallet' &&
    state.wallet.address &&
    Web3.utils.isAddress(state.wallet.address) &&
    state.wallet.network === state.wallet.metamask.network,
  walletType: state => state.wallet.type,
  walletNetwork: state => state.wallet.network,
  walletNetworkArea: state => func.getNetworkArea(state.wallet.network),
  isEthereum: state => networkIsEthereum(state.wallet.network),
  isBsc: state => networkIsBsc(state.wallet.network),
  isAvalanche: state => networkIsAvalanche(state.wallet.network),
  isKaia: state => networkIsKaia(state.wallet.network),
  isBifrost: state => networkIsBifrost(state.wallet.network),
  isBifrostTestnet: state => networkIsBifrostTestnet(state.wallet.network),
  walletAddress: state => state.wallet.address,
  isWalletChecked: state => state.wallet.checked,
  onlyAllowedNetworkType: () =>
    (config.allowedNetworks || []).length === 1
      ? config.allowedNetworks[0]
      : undefined,

  btcRefund: state => state.wallet.btc.refund.address,
  btcRefundPublicKeyHash: state => state.wallet.btc.refund.publicKeyHash,
  btcTransferIn: state => state.wallet.btc.transferIn.address,
  btcTransferInPublicKeyHash: state =>
    state.wallet.btc.transferIn.publicKeyHash,
  btcDeposit: state => state.wallet.btc.deposit.address,
  btcDepositPublicKeyHash: state => state.wallet.btc.deposit.publicKeyHash,
  btcRepay: state => state.wallet.btc.repay.address,
  btcRepayPublicKeyHash: state => state.wallet.btc.repay.publicKeyHash,
  btcFee: state => state.wallet.btc.fee,

  tokens: state => state.tokens,
  comingSoonTokens: state => {
    return config.tokens.filter(token => {
      let result = !!token.comingSoon;

      if (
        result &&
        (state.wallet.network === 'bsc' || state.wallet.network === 'bsc-test')
      ) {
        result = result && token.id !== 'bnb';
      }

      return result;
    });
  },

  language: state => state.language,
  languageTitle: state => {
    return (
      (config.languages.find(language => language.id === state.language) || {})
        .title ||
      config.languages[0].title ||
      ''
    );
  },

  currencyList: state => state.currencyList,
  currency: state => state.currency,
  replaceString: state => {
    let result = '{}';

    if (state.currency) {
      result =
        (
          state.currencyList.find(
            currency => currency.key === state.currency
          ) || {}
        ).replaceString || '{}';
    }

    return result;
  },
  unitPrefix: state => {
    let result = '';

    if (state.currency) {
      const replaceString =
        (
          state.currencyList.find(
            currency => currency.key === state.currency
          ) || {}
        ).replaceString || '{}';
      result = replaceString.split('{}')[0] || '';
    }

    return result;
  },
  unitSuffix: state => {
    let result = '';

    if (state.currency) {
      const replaceString =
        (
          state.currencyList.find(
            currency => currency.key === state.currency
          ) || {}
        ).replaceString || '{}';
      result = replaceString.split('{}')[1] || '';
    }

    return result;
  },
  unit: state => {
    let result = '';

    if (state.currency) {
      result =
        (
          state.currencyList.find(
            currency => currency.key === state.currency
          ) || {}
        ).unit || '';
    }

    return result;
  },

  borrowData: state => state.borrowData,

  now: state => state.now,
  sync: state => state.sync,
  syncID: state => state.sync.id,
  autoRefresh: state => state.sync.autoRefresh,
  lastestSyncedAt: state => state.sync.lastestSyncedAt,
  syncableTime: state => state.sync.lastestSyncedAt + state.sync.minTerm,
  syncMinTerm: state => state.sync.minTerm,
  syncIntervalTerm: state => state.sync.intervalTerm,

  ui: state => state.ui,
  progressStack: state => state.ui.progress.stack,
  progressShow: state => state.ui.progress.stack > 0,
};

import Vue from 'vue';
import BBN from '@bifrost-platform/bifrost-number';
import BifiCurrency from '@bifrost-platform/bifi-currency';

import config from '@/plugins/config';

const currency = new BifiCurrency();

let openBeta = false;

try {
  openBeta = process.env.VUE_APP_OPEN_BETA === 'true';
} catch (error) {
  /**/
}

if (!openBeta) {
  currency.setToken(
    'won',
    new BBN('0.000895116'),
    new BBN('1'),
    '',
    'KRW',
    '',
    '₩',
    '₩ {}',
    '',
    '#efefef'
  ); // Temporary
}

for (const token of config.tokens) {
  currency.setToken(
    token.id,
    new BBN('0'),
    new BBN('18'),
    '',
    token.title,
    token.symbol,
    token.unit,
    token.replaceString,
    token.image,
    token.color
  );
}

Vue.prototype.$curr = currency;

export default currency;

import { render, staticRenderFns } from "./OracleCircuitBreaker.vue?vue&type=template&id=23d577d8&scoped=true&"
import script from "./OracleCircuitBreaker.vue?vue&type=script&lang=js&"
export * from "./OracleCircuitBreaker.vue?vue&type=script&lang=js&"
import style0 from "./OracleCircuitBreaker.vue?vue&type=style&index=0&id=23d577d8&prod&scoped=true&lang=css&"
import style1 from "./OracleCircuitBreaker.vue?vue&type=style&index=1&id=23d577d8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d577d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VLayout,VProgressLinear})

<template>
  <div class="token-power">
    <AvatarIconSet
      host=""
      prependPath=""
      :size="120"
      :iconSet="pairTokens"
      v-if="pairTokens && pairTokens.length"
    />
    <AvatarIconSet
      host=""
      prependPath=""
      :size="180"
      :iconSet="[{ image: tokenImage }]"
      v-else
    />
    <div class="token-power-description" v-html="$t(description)" />
  </div>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

export default {
  name: 'TokenPower',
  components: {
    AvatarIconSet,
  },
  props: {
    description: {
      type: String,
      default: 'trade.tokenPower.description',
    },
    tokenSrc: {
      type: String,
      default: '',
    },
    tokenImage: {
      type: String,
      default: '',
    },
    tokenTitle: {
      type: String,
      default: '',
    },
    tokenUnitString: {
      type: String,
      default: '',
    },
    tokenAddress: {
      type: String,
      default: '',
    },
    tokenSendAddress: {
      type: String,
      default: '',
    },
    pairTokens: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    nextStep() {
      this.$emit('nextStep');
    },
  },
};
</script>

<style scoped>
.token-power {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.token-power-avatar {
  display: inline-flex;
}

.token-power-avatar-icon-text {
  font-size: 130px;
}

.token-power-description {
  padding-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #000000d9;
}

/* dark mode */

#app.dark .token-power-description {
  color: #ffffffd9;
}

/* mobile */

#app.mobile .token-power {
  padding: 24px 10%;
}

#app.mobile .token-power-description {
  font-size: 12px;
  line-height: normal;
}
</style>

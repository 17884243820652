import { render, staticRenderFns } from "./CardExpansion.vue?vue&type=template&id=15870a0c&scoped=true&"
import script from "./CardExpansion.vue?vue&type=script&lang=js&"
export * from "./CardExpansion.vue?vue&type=script&lang=js&"
import style0 from "./CardExpansion.vue?vue&type=style&index=0&id=15870a0c&prod&scoped=true&lang=css&"
import style1 from "./CardExpansion.vue?vue&type=style&index=1&id=15870a0c&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15870a0c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
installComponents(component, {VExpandTransition,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels})

<template>
  <div class="token-power description">
    <span v-t="'trade.content.information.tokenPower.description'" />
    <a
      class="link"
      :class="{ disabled: transactionPending }"
      @click="nextSlide"
      v-t="'trade.content.information.tokenPower.button'"
    />
  </div>
</template>

<script>
export default {
  name: 'TokenPower',
  props: {
    transactionPending: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nextSlide() {
      if (!this.transactionPending) {
        this.$emit('nextSlide');
      }
    },
  },
};
</script>

<style scoped>
.token-power {
  display: flex;
  align-items: center;
  height: 100%;
}

/* mobile */

#app.mobile .token-power {
  padding: 12px;
}
</style>

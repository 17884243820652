<template>
  <div
    class="header transition-all"
    :class="`${mobileNavigation ? 'open-navigation' : ''} ${networkArea}`"
  >
    <v-fade-transition>
      <div
        class="mobile-navigation-outter"
        v-if="$vuetify.breakpoint.smAndDown && mobileNavigation"
        @click="mobileNavigation = false"
      />
    </v-fade-transition>
    <div class="container">
      <div class="header-content">
        <transition name="fade-expand-x">
          <div class="hamburger-wrapper" v-if="$vuetify.breakpoint.smAndDown">
            <div class="hamburger-content">
              <v-btn
                class="hamburger"
                icon
                :dark="$store.getters.darkMode"
                @click="switchNavigation"
              >
                <v-icon
                  class="hamburger-icon"
                  v-text="mobileNavigation ? 'mdi-close' : 'mdi-menu'"
                />
              </v-btn>
            </div>
          </div>
        </transition>
        <div class="logo-wrapper">
          <Logo />
        </div>
        <transition name="fade-expand-x">
          <div class="navigation-wrapper" v-if="$vuetify.breakpoint.mdAndUp">
            <template v-for="(navigation, navigationIndex) in navigations">
              <Navigation
                :disabled="navigation.disabled"
                :menu="navigation.menu"
                :to="navigation.to"
                :href="navigation.href"
                :title="navigation.title"
                :tooltip="navigation.tooltip"
                :prependIcon="navigation.prependIcon"
                :appendIcon="navigation.appendIcon"
                :key="navigationIndex"
                v-if="!navigation.onlyMobile"
              />
            </template>
          </div>
        </transition>
        <v-spacer />
        <div class="button-wrapper">
          <template v-for="(button, buttonIndex) in buttons">
            <component
              class="button"
              :is="button.component"
              v-if="!(button.onlyDesktop && $vuetify.breakpoint.smAndDown)"
              :key="buttonIndex"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="mobile-navigation-container transition-all">
      <div class="mobile-navigation-wrapper">
        <Navigation
          :disabled="navigation.disabled"
          :to="navigation.to"
          :href="navigation.href"
          :title="navigation.title"
          :tooltip="navigation.tooltip"
          :prependIcon="navigation.prependIcon"
          :appendIcon="navigation.appendIcon"
          v-for="(navigation, navigationIndex) in navigations"
          :key="navigationIndex"
        />
      </div>
    </div>
    <transition name="fade-expand">
      <DataBar
        :borrowLimit="this.$store.getters.borrowData.borrowLimit"
        :marginCallLimit="this.$store.getters.borrowData.marginCallLimit"
        :totalDeposit="this.$store.getters.borrowData.totalDeposit"
        :totalBorrow="this.$store.getters.borrowData.totalBorrow"
      />
    </transition>
  </div>
</template>

<script>
import Logo from '@/components/header/Logo';
import Navigation from '@/components/header/Navigation';

import DataBar from '@/components/header/DataBar';

import MenuPool from '@/components/header/menu/Pool';

import NetworkButton from '@/components/header/button/Network';
import WalletButton from '@/components/header/button/Wallet';
import XBifiButton from '@/components/header/button/XBifi';

export default {
  name: 'Header',
  components: {
    Logo,
    Navigation,
    DataBar,
  },
  data() {
    return {
      defaultNavigations: [
        {
          id: 'lend',
          title: 'Lending',
          to: '/lend',
        },
        {
          id: 'reward',
          title: 'Rewards',
          to: '/reward',
        },
        {
          id: 'pool',
          title: 'Pooling',
          to: '/pool',
          menu: MenuPool,
        },
        {
          id: 'stake',
          title: 'Staking',
          to: '/stake',
        },
        {
          id: 'market',
          title: 'Market',
          to: '/market',
        },
        {
          id: 'docs',
          title: 'Docs',
          href: () => this.$t('common.link.help'),
        },
        {
          id: 'xbifi',
          title: 'BiFi X',
          appendIcon: 'open-in-new',
          href: this.$config.link.xbifi,
          onlyMobile: true,
        },
      ],
      buttons: [
        {
          onlyDesktop: true,
          component: XBifiButton,
        },
        {
          onlyDesktop: false,
          component: NetworkButton,
        },
        {
          onlyDesktop: false,
          component: WalletButton,
        },
      ],
      mobileNavigation: false,
    };
  },
  computed: {
    query() {
      let result = '';

      if (this.$store.getters.onlyAllowedNetworkType) {
        result = `?chainid=${this.$store.getters.onlyAllowedNetworkType}`;
      }

      return result;
    },
    networkArea() {
      return this.$store.getters.walletNetworkArea;
    },
    navigations() {
      const disabledRouters =
        (
          this.$config.disabledRouters.find(
            disabledRouter => disabledRouter.id === this.networkArea
          ) || {}
        ).routers || [];

      return this.defaultNavigations.map(navigation => {
        const disabled = disabledRouters.indexOf(navigation.id) >= 0;

        let to =
          (typeof navigation.to === 'function'
            ? navigation.to()
            : navigation.to) || undefined;
        let href =
          (typeof navigation.href === 'function'
            ? navigation.href()
            : navigation.href) || undefined;

        if (to) {
          to = `${to}${this.query}`;
        }

        return Object.assign({}, navigation, {
          to,
          href,
          disabled,
        });
      });
    },
  },
  watch: {
    $route() {
      this.mobileNavigation = false;
    },
  },
  methods: {
    switchNavigation() {
      this.mobileNavigation = !this.mobileNavigation;
    },
  },
};
</script>

<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 75px;
  background-color: #fff;
  border-bottom: 1px solid #d5d5d5;
  z-index: 99;
}

.header.bsc {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/bifi.finance/header/background_bsc.png');
  background-position: top center;
}

.header.avalanche {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/bifi.finance/header/background_avalanche.svg');
  background-position: top center;
}

.header.kaia {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/bifi.finance/header/background_kaia.svg');
  background-position: top center;
}

.header.bifrost {
  background-image: url('https://s3.ap-northeast-2.amazonaws.com/bifi.finance/header/background_bifrost.svg');
  background-position: top center;
}

#app.notice:not(.mobile) .header {
  top: 74px;
}

.container {
  height: 75px;
  padding-top: 0px;
  padding-bottom: 0px;
  overflow: hidden;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  margin: 0px -30px;
}

.hamburger-wrapper,
.logo-wrapper,
.navigation-wrapper,
.button-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0px 30px;
}

.hamburger-wrapper {
  justify-content: flex-start;
  text-align: left;
  margin: 0px -10px;
}

.logo-wrapper {
  justify-content: flex-start;
  text-align: left;
  margin: 0px -10px;
}

.navigation-wrapper {
  justify-content: flex-start;
  text-align: left;
  margin: 0px -12px;
}

.button-wrapper {
  justify-content: flex-end;
  text-align: right;
  margin: 0px -6px;
}

.hamburger-content {
  margin: 0px 10px;
}

.hamburger {
}

.hamburger-icon {
}

.header.header.open-navigation .hamburger-icon {
  transform: rotate(360deg);
}

.mobile-navigation-container {
  height: 0px;
  min-height: 0px;
  max-height: 0px;
  overflow: hidden;
}

.mobile-navigation-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 360px;
  padding: 15px 0px;
}

.mobile-navigation-outter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #fff;
  opacity: 0.25;
  z-index: -1;
}

/* dark mode */
#app.dark .header {
  background-color: #383c52;
}

#app.dark .mobile-navigation-outter {
  background-color: #000;
}

/* mobile */
#app.mobile .header {
  height: 56px;
}

#app.mobile .header.open-navigation {
  height: 416px;
}

#app.mobile .container {
  height: 56px;
}

#app.mobile .header-content {
  margin: 0px -5px;
}

#app.mobile .hamburger-wrapper,
#app.mobile .logo-wrapper,
#app.mobile .button-wrapper {
  margin: 0px -5px;
  padding: 0px 5px;
}

#app.mobile .hamburger-content {
  margin: 0px 5px;
}

#app.mobile .logo-wrapper {
  justify-content: center;
}

#app.mobile .header.open-navigation .mobile-navigation-container {
  height: 360px;
  min-height: 360px;
  max-height: 360px;
}
</style>

<style>
.header > .container > .header-content > .button-wrapper > .button {
  padding: 0px 6px;
}

#app.mobile .header > .container > .header-content > .button-wrapper > .button {
  padding: 0px 5px;
}
</style>

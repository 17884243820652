<template>
  <div class="wallet">
    <v-btn
      class="wallet-button transition-all"
      :class="{
        'wallet-on': isWalletUsable,
        'alert-on': alertOn,
      }"
      :color="color"
      :dark="isWalletUsable"
      :light="!isWalletUsable"
      :outlined="!isWalletUsable"
      elevation="0"
      @click="openWalletPopup"
    >
      <div class="wallet-button-title" v-text="title" />
      <div
        class="wallet-button-icon-wrapper transition-all"
        :style="{ 'background-color': alertIconColor }"
      >
        <Loader
          class="wallet-button-icon"
          color="#fff"
          secondColor="#fff"
          small
          :borderWeight="3"
          :size="$vuetify.breakpoint.smAndDown ? 16 : 24"
          v-if="alertIcon === 'loading'"
        />
        <v-icon
          class="wallet-button-icon"
          dark
          :small="$vuetify.breakpoint.smAndDown"
          v-text="alertIcon"
          v-else
        />
      </div>
    </v-btn>

    <WalletPopup
      :address="walletAddress || ''"
      :transactions="transactions"
      v-model="walletPopup"
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader';

import WalletPopup from '@/components/header/button/wallet/Popup';

export default {
  name: 'Wallet',
  components: {
    Loader,
    WalletPopup,
  },
  data: () => ({
    walletPopup: false,
  }),
  computed: {
    walletAddress() {
      return this.$store.getters.walletAddress;
    },
    isWalletUsable() {
      return this.$store.getters.isWalletUsable;
    },
    color() {
      return '#47b465';
    },
    title() {
      let result = 'Unknown';

      if (this.isWalletUsable) {
        result = `${this.walletAddress.slice(0, 4)}…${this.walletAddress.slice(
          -4
        )}`;
      } else {
        result = this.$t('header.wallet.connectWallet');
      }

      return result;
    },
    transactions() {
      return this.$store.getters.transactions.slice(-5).reverse();
    },
    lastTransaction() {
      return this.transactions[0];
    },
    hasCheckAlert() {
      return this.$store.getters.hasCheckAlert;
    },
    alertOn() {
      let result = false;

      if (
        this.isWalletUsable &&
        this.lastTransaction &&
        typeof this.lastTransaction.status === 'number' &&
        this.lastTransaction.status >= 0 &&
        this.lastTransaction.status < 4
      ) {
        if (this.lastTransaction.status === 0) {
          result = true;
        } else if (!this.hasCheckAlert) {
          result = true;
        }
      }

      return result;
    },
    alertIcon() {
      let result = '';

      if (this.alertOn && this.lastTransaction) {
        switch (this.lastTransaction.status) {
          case 0:
            result = 'loading';
            break;
          case 1:
            result = 'mdi-check-circle';
            break;
          case 2:
          case 3:
            result = 'mdi-alert-circle';
            break;
        }
      }

      return result;
    },
    alertIconColor() {
      let result = '';

      if (this.alertOn && this.lastTransaction) {
        switch (this.lastTransaction.status) {
          case 0:
          case 1:
            result = '#1a8538';
            break;
          case 2:
          case 3:
            result = '#ec5858';
            break;
        }
      }

      return result;
    },
  },
  watch: {
    walletPopup(val) {
      if (!val) {
        this.$store.dispatch('checkTransaction', {});
      }
    },
    '$store.getters.ui.walletPopup.show'(val) {
      if (val && !this.walletPopup) {
        this.openWalletPopup();
        this.$store.dispatch('setWalletPopup', { show: false });
      }
    },
  },
  methods: {
    openWalletPopup() {
      if (this.isWalletUsable) {
        this.walletPopup = true;
      } else {
        this.walletPopup = false;
        this.$store.dispatch('openWalletConnector', {});
      }
    },
  },
};
</script>

<style scoped>
.wallet {
}

.wallet-button {
  position: relative;
  min-height: 40px;
  overflow: hidden;
}

.wallet-button.wallet-on {
  min-width: 120px !important;
}

.wallet-button.alert-on {
  min-width: 160px !important;
  padding-right: 56px;
}

.wallet-button-title {
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}

.wallet-button-icon-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -56px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px;
  height: 40px;
  overflow: hidden;
  opacity: 0;
}

.wallet-button.alert-on .wallet-button-icon-wrapper {
  width: 40px;
  opacity: 1;
}

.wallet-button.alert-on:hover .wallet-button-icon-wrapper,
.wallet-button.alert-on:focus .wallet-button-icon-wrapper {
  opacity: 0.75;
}

.wallet-button-icon {
}

/* mobile */

#app.mobile .wallet-button {
  height: 26px;
  min-height: 26px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
}

#app.mobile .wallet-button.wallet-on {
  min-width: 80px !important;
}

#app.mobile .wallet-button.alert-on {
  min-width: 106px !important;
  padding-right: 32px;
}

#app.mobile .wallet-button-title {
  font-size: 12px;
}

#app.mobile .wallet-button-icon-wrapper {
  right: -32px;
  height: 26px;
}

#app.mobile .wallet-button.alert-on .wallet-button-icon-wrapper {
  width: 26px;
}
</style>

<style>
.wallet-button > .v-btn__content {
  height: 100%;
}
</style>

import func from '@/plugins/function';
import BBN from '@/plugins/bifrostNumber';

export default {
  // wallet
  syncWalletID(state, { id, then }) {
    state.wallet.id = id;

    func.execFunc(then);
  },
  syncWalletType(state, { type, then }) {
    state.wallet.type = type;

    func.execFunc(then);
  },
  syncWalletNetwork(state, { network, then }) {
    state.wallet.network = network;

    func.execFunc(then);
  },
  syncWalletAddress(state, { address, then }) {
    state.wallet.address = address;

    func.execFunc(then);
  },
  syncMetamaskNetwork(state, { network, then }) {
    state.wallet.metamask.network = network;

    func.execFunc(then);
  },

  // token
  syncToken(state, { index, balance, allowance, address, sendAddress, then }) {
    state.tokens[index].balance = balance;
    state.tokens[index].allowance = allowance;
    state.tokens[index].address = address;
    state.tokens[index].sendAddress = sendAddress;

    func.execFunc(then);
  },
  addToken(state, { id, isToken, address, sendAddress, then }) {
    state.tokens.push({
      id,
      isToken,
      address,
      sendAddress,
      balance: new BBN('0'),
      allowance: new BBN('0'),
    });

    func.execFunc(then);
  },

  // btc
  setBtcInformation(
    state,
    {
      refundPublicKeyHash,
      refundAddress,
      transferInPublicKeyHash,
      transferInAddress,
      depositPublicKeyHash,
      depositAddress,
      repayPublicKeyHash,
      repayAddress,
      then,
    }
  ) {
    state.wallet.btc.refund.publicKeyHash = refundPublicKeyHash;
    state.wallet.btc.refund.address = refundAddress;
    state.wallet.btc.transferIn.publicKeyHash = transferInPublicKeyHash;
    state.wallet.btc.transferIn.address = transferInAddress;
    state.wallet.btc.deposit.publicKeyHash = depositPublicKeyHash;
    state.wallet.btc.deposit.address = depositAddress;
    state.wallet.btc.repay.publicKeyHash = repayPublicKeyHash;
    state.wallet.btc.repay.address = repayAddress;

    func.execFunc(then);
  },
  setBtcChallenge(state, { challenge, then }) {
    state.wallet.btc.challenge = challenge;

    func.execFunc(then);
  },
  setBtcFee(state, { inflowRate, inflowMin, outflowRate, outflowMin, then }) {
    state.wallet.btc.fee.inflow.rate = inflowRate;
    state.wallet.btc.fee.inflow.min = inflowMin;
    state.wallet.btc.fee.outflow.rate = outflowRate;
    state.wallet.btc.fee.outflow.min = outflowMin;

    func.execFunc(then);
  },
  setBtcOutflowAmount(state, { amount, then }) {
    state.wallet.btc.outflowAmount = amount;

    func.execFunc(then);
  },

  // transaction
  setTransaction(state, { id = 0, hasCheck = true, list = [], then }) {
    state.wallet.transaction.id = id;
    state.wallet.transaction.hasCheck = hasCheck;
    state.wallet.transaction.list = list;

    this.commit('storeTransaction', { then });
  },
  addTransaction(
    state,
    {
      timestamp,
      token,
      contentID,
      action,
      value,
      transactionHash,
      status,
      code,
      message,
      thenFunc,
      then,
    }
  ) {
    state.wallet.transaction.list.push({
      id: ++state.wallet.transaction.id,
      timestamp,
      token,
      contentID,
      action,
      value,
      transactionHash,
      status: status || 0,
      code,
      message,
      thenFunc,
    });

    if (state.wallet.transaction.list.length > 10) {
      state.wallet.transaction.list.splice(0, 1);
    }

    state.wallet.transaction.hasCheck = false;

    this.commit('storeTransaction', { then });
  },
  setTransactionStatus(state, { index, status, code, then }) {
    state.wallet.transaction.list[index].status = status;
    state.wallet.transaction.list[index].code = code;

    state.wallet.transaction.hasCheck = false;

    this.commit('storeTransaction', { then });
  },
  checkTransaction(state, { then }) {
    state.wallet.transaction.hasCheck = true;

    this.commit('storeTransaction', { then });
  },
  storeTransaction(state, { then }) {
    if (state.wallet.address && state.wallet.network) {
      func
        .storageEach(storage =>
          storage.setItem(
            `transaction_${(`${state.wallet.address}` || '').toUpperCase()}_${(
              `${state.wallet.network}` || ''
            ).toUpperCase()}`,
            JSON.stringify(state.wallet.transaction)
          )
        )
        .then(then);
    }
  },

  // borrowData
  setBorrowData(
    state,
    { borrowLimit, marginCallLimit, totalDeposit, totalBorrow, then }
  ) {
    state.borrowData.borrowLimit = borrowLimit;
    state.borrowData.marginCallLimit = marginCallLimit;
    state.borrowData.totalDeposit = totalDeposit;
    state.borrowData.totalBorrow = totalBorrow;

    func.execFunc(then);
  },

  // wallet checked
  setCheckWallet(state, { checkWallet, then }) {
    state.wallet.checked = checkWallet;

    func.execFunc(then);
  },
};

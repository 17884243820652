import config from '@/plugins/config';
import func from '@/plugins/function';

export default {
  async syncLocalStorage({ commit }, { then, err, final }) {
    func
      .storageEach(async storage => {
        let connectionCount = storage.getItem('connectionCount');
        let darkMode = storage.getItem('darkMode');
        let language = storage.getItem('language');
        let currency = storage.getItem('currency');
        let walletID = storage.getItem('walletID');

        try {
          connectionCount = parseInt(connectionCount) || 0;

          if (isNaN(connectionCount)) {
            connectionCount = 0;
          }
        } catch (err_) {
          connectionCount = 0;
        }
        try {
          if (darkMode === 'true' || darkMode === 'false') {
            darkMode = darkMode === 'true';
          } else {
            darkMode =
              !!(
                window.matchMedia &&
                window.matchMedia('(prefers-color-scheme: Dark)').matches
              ) || false;
          }
        } catch (err_) {
          darkMode = false;
        }
        try {
          if (
            !language ||
            config.languages.findIndex(lang => lang.id === language) < 0
          ) {
            language = config.languages[0].id;
          }
        } catch (err_) {
          language = config.languages[0].id;
        }
        try {
          currency = currency || '';

          if (currency === 'undefined' || currency === 'null') {
            currency = '';
          }
        } catch (err_) {
          currency = '';
        }
        try {
          walletID = walletID || '';

          if (
            walletID === 'undefined' ||
            walletID === 'null' ||
            !(
              walletID === 'metamask' ||
              walletID === 'biport' ||
              walletID === 'coinbase'
            )
          ) {
            walletID = '';
          }
        } catch (err_) {
          walletID = '';
        }

        this.dispatch('syncProvider', { walletID });

        commit('syncLocalStorage', {
          connectionCount,
          darkMode,
          language,
          currency,
          then,
        });
      })
      .catch(err)
      .finally(final);
  },

  async syncTransaction({ commit, getters }, { then, err, final }) {
    if (getters.walletAddress && getters.walletNetwork) {
      await func
        .storageEach(async storage => {
          let transaction = storage.getItem(
            `transaction_${(`${getters.walletAddress}` || '').toUpperCase()}_${(
              `${getters.walletNetwork}` || ''
            ).toUpperCase()}`
          );

          try {
            transaction = JSON.parse(transaction);

            if (typeof transaction !== 'object') {
              transaction = {
                id: 0,
                hasCheck: true,
                list: [],
              };
            }

            if (typeof transaction.id !== 'number') {
              transaction.id = 0;
            }

            if (typeof transaction.hasCheck !== 'boolean') {
              transaction.hasCheck = true;
            }

            if (
              typeof transaction.list !== 'object' ||
              typeof transaction.list.length !== 'number'
            ) {
              transaction.list = [];
            }
          } catch (err_) {
            transaction = {
              id: 0,
              hasCheck: true,
              list: [],
            };
          }

          commit('syncTransaction', {
            transaction,
            then,
          });
        })
        .catch(err)
        .finally(final);
    } else {
      func.execFunc(err);
      func.execFunc(final);
    }
  },
};

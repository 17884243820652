<template>
  <div class="message-wrapper">
    <transition name="fade-expand">
      <div
        class="message"
        :class="(messageType && `type-${messageType}`) || ''"
        v-if="messageShow"
      >
        <v-tooltip
          bottom
          transition="slide-y-transition"
          :disabled="!messageTooltip"
        >
          <template v-slot:activator="{ on, attrs }">
            <div class="message-tooltip-wrapper" v-bind="attrs" v-on="on">
              <v-avatar
                class="message-avatar"
                :color="messageType"
                :size="messageAvatarSize"
              >
                <v-icon
                  class="message-avatar-icon"
                  :style="{ 'font-size': `${messageIconSize}px` }"
                  dark
                  v-text="messageIcon"
                />
              </v-avatar>
              <div class="message-text" v-text="messageText" />
            </div>
          </template>
          <span v-text="messageTooltip" />
        </v-tooltip>
        <transition name="fade-expand">
          <v-btn
            class="message-button"
            color="#1f7bff"
            text
            @click="
              $emit(
                'messagebutton',
                typeof message === 'object' && message.button
              )
            "
            v-if="messageButton"
          >
            <span class="message-button-text" v-text="messageButton" />
          </v-btn>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'MessageContent',
  props: {
    disabledRules: {
      type: Array,
      default: () => [],
    },
    message: {
      type: Object,
      default: () => undefined,
    },
    messageDefaultArgs: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    disabled() {
      let result = false;

      if (this.disabledRules && this.disabledRules.length > 0) {
        for (const rule of this.disabledRules) {
          result = this.$execFunc(rule);

          if (result) {
            break;
          }
        }
      }

      return result;
    },
    disabledMessage() {
      let result = '';

      if (
        this.disabled &&
        typeof this.disabled === 'object' &&
        typeof this.disabled.text === 'string'
      ) {
        result = this.disabled;
      }

      return result;
    },
    messageShow() {
      return (
        !this.readonly &&
        ((this.message &&
          typeof this.message === 'object' &&
          this.message.type &&
          this.message.text) ||
          this.disabledMessage)
      );
    },
    messageType() {
      let result = '';

      if (this.messageShow) {
        if (this.disabledMessage) {
          result = 'error';
        } else {
          result = this.message.type;
        }
      }

      return result;
    },
    messageText() {
      let result = '';

      if (this.messageShow) {
        if (this.disabledMessage) {
          result = this.$t(this.disabledMessage.text, this.messageTextArgs);
        } else {
          result = this.$t(this.message.text, this.messageTextArgs);
        }
      }

      return result;
    },
    messageTextArgs() {
      let result = undefined;

      if (this.messageShow) {
        if (this.disabledMessage) {
          result = this.disabledMessage.textArgs;
        } else {
          result = this.message.textArgs;
        }

        if (typeof result !== 'object') {
          result = {};
        }

        if (typeof this.messageDefaultArgs === 'object') {
          result = Object.assign({}, result, this.messageDefaultArgs, {
            action: (this.messageDefaultArgs['action'] || '').toLowerCase(),
            actioned: (this.messageDefaultArgs['actioned'] || '').toLowerCase(),
            actioning: (
              this.messageDefaultArgs['actioning'] || ''
            ).toLowerCase(),
          });
        }
      }

      return result;
    },
    messageButton() {
      let result = '';

      if (this.messageShow) {
        if (!this.disabledMessage && this.message.button) {
          result = this.$t(this.message.button);
        }
      }

      return result;
    },
    messageIcon() {
      let result = 'mdi-exclamation-thick';

      if (this.messageShow) {
        switch (this.messageType) {
          case 'success':
            result = 'mdi-check';
            break;
          case 'error':
            result = 'mdi-exclamation-thick';
            break;
          case 'info':
            result = 'mdi-exclamation-thick';
            break;
        }
      }

      return result;
    },
    messageAvatarSize() {
      let result = 24;

      if (this.$vuetify.breakpoint.smAndDown) {
        result = 18;
      }

      if (this.messageShow && this.messageType === 'info') {
        result *= 0.75;
      }

      return result;
    },
    messageIconSize() {
      let result = 18;

      if (this.$vuetify.breakpoint.smAndDown) {
        result = 14;
      }

      if (this.messageShow && this.messageType === 'info') {
        result *= 0.75;
      }

      return result;
    },
    messageTooltip() {
      let result = '';

      if (this.messageShow && this.message && this.message.tooltip) {
        result = this.$t(this.message.tooltip, this.messageTextArgs);
      }

      return result;
    },
  },
};
</script>

<style scoped>
.message-wrapper {
  width: 100%;
  background-color: #fff;
}

.message {
  padding: 8px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-avatar {
  background-color: #47b465;
}

.message-avatar-icon {
}

.message-tooltip-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.message-text {
  padding-left: 12px;
  font-size: 12px;
  text-align: center;
  color: #000000d9;
}

.message.type-info .message-text {
  font-size: 10px;
  color: #00000099;
}

.message-button {
  min-width: 0px !important;
  height: 28px !important;
  margin: 0px -6px 0px 6px !important;
  padding: 0px 6px !important;
}

.message-button-text {
  font-size: 10px;
}

/* dark mode */

#app.dark .message-wrapper {
  background-color: #383c52;
}

#app.dark .message-text {
  color: #ffffffd9;
}

#app.dark .message.type-info .message-text {
  color: #ffffff99;
}
</style>

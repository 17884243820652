<template>
  <div class="outflow">
    <div class="outflow-step-wrapper">
      <template v-for="(step, stepIndex) in steps">
        <div
          class="outflow-step-divider transition-all"
          :class="{ complete: step.status > 0 }"
          :key="`div-${stepIndex}`"
          v-if="stepIndex !== 0"
        />
        <div
          class="outflow-step transition-all"
          :class="`step-${step.status}`"
          :key="stepIndex"
        >
          <div class="outflow-step-icon-wrapper transition-all">
            <Loader
              class="outflow-step-icon loader"
              color="#47b465"
              :borderWeight="4"
              :size="30"
              small
              v-if="step.status === 1"
            />
            <v-icon
              class="outflow-step-icon transition-all"
              v-text="
                step.status === 0
                  ? 'mdi-checkbox-blank-circle-outline'
                  : 'mdi-check-circle'
              "
              v-else
            />
          </div>
          <div
            class="outflow-step-title"
            v-html="step.title"
            v-if="step.title"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'Outflow',
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Loader,
  },
};
</script>

<style scoped>
.outflow {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
}
.outflow-step-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 110px;
}
.outflow-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
  opacity: 0.25;
}
.outflow-step:not(.step-0) {
  opacity: 1;
}
.outflow-step-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}
.outflow-step.step-1 .outflow-step-icon-wrapper {
  transform: rotate(360deg);
}
.outflow-step.step-2 .outflow-step-icon-wrapper {
  transform: rotate(720deg);
}
.outflow-step-icon {
  font-size: 34px;
}
.outflow-step.step-2 .outflow-step-icon-wrapper .outflow-step-icon {
  color: #47b465;
}
.outflow-step-title {
  padding-top: 16px;
  font-size: 14px;
}
.outflow-step-divider {
  position: relative;
  flex-grow: 0;
  width: 150px;
  min-width: 150px;
  height: 2px;
  margin: 15px 40px 0px;
  border-radius: 2px;
  opacity: 0.25;
  overflow: hidden;
}
.outflow-step-divider.complete {
  opacity: 1;
}
.outflow-step-divider:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 0px;
  background-color: #47b465;
  -webkit-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -moz-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -ms-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  -o-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
  transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01) !important;
}
.outflow-step-divider.complete:after {
  width: 100%;
}

/* dark mode */
#app.dark .outflow {
  color: #fff;
}
#app.dark .outflow-step-divider {
  background-color: #fff;
}

/* mobile */
#app.mobile .outflow {
  padding: 12px;
  text-align: left;
}
#app.mobile .outflow-step-wrapper {
  flex-direction: column;
  height: unset;
  margin-top: -10px;
  margin-bottom: -10px;
}
#app.mobile .outflow-step-divider {
  display: none;
}
#app.mobile .outflow-step {
  flex-direction: row;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
#app.mobile .outflow-step-title {
  padding-top: 0px;
  padding-left: 16px;
}
</style>

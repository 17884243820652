<template>
  <v-btn
    class="dark-mode-switch"
    text
    tile
    block
    :dark="isDarkMode"
    @click="switchDarkMode"
  >
    <v-icon
      class="dark-mode-switch-icon transition-all"
      :class="{ dark: isDarkMode }"
      v-text="icon"
    />
    <v-switch
      class="dark-mode-switch-switch"
      :ripple="false"
      :input-value="isDarkMode"
    />
  </v-btn>
  <!-- <v-tooltip top nudge-top="8" transition="slide-y-reverse-transition" :disabled="$vuetify.breakpoint.smAndDown">
    <template v-slot:activator="{attrs, on}">
      <div class="dark-mode-switch-wrapper" v-bind="attrs" v-on="on">
        <v-btn class="dark-mode-switch" icon :dark="isDarkMode" @click="switchDarkMode">
          <v-icon class="dark-mode-switch-icon transition-all" :class="{'dark': isDarkMode}" v-text="icon" />
        </v-btn>
      </div>
    </template>
    <span class="dark-mode-switch-tooltip" v-text="tooltip" />
  </v-tooltip> -->
</template>

<script>
export default {
  name: 'DarkModeSwitch',
  computed: {
    isDarkMode() {
      return this.$store.getters.darkMode;
    },
    icon() {
      let result = 'mdi-weather-night';

      if (this.isDarkMode) {
        result = 'mdi-weather-sunny';
      }

      return result;
    },
    tooltip() {
      let result = this.$t('common.tooltip.darkMode.toDark');

      if (this.isDarkMode) {
        result = this.$t('common.tooltip.darkMode.toLight');
      }

      return result;
    },
  },
  methods: {
    switchDarkMode() {
      this.$store.dispatch('switchDarkMode', {
        then: () => this.$store.dispatch('storeDarkMode', {}),
      });
    },
  },
};
</script>

<style scoped>
.dark-mode-switch {
  justify-content: space-between;
}

.dark-mode-switch-icon {
}

.dark-mode-switch-icon.dark {
  transform: rotate(360deg);
}

.dark-mode-switch-switch {
  margin: 0px !important;
  padding: 0px !important;
}
</style>

<style>
.dark-mode-switch-switch .v-input__control {
  flex-direction: row !important;
  flex-wrap: unset !important;
  justify-content: center;
  align-items: center;
}

.dark-mode-switch-switch .v-input__control .v-input__slot {
  margin: 0px !important;
}

.dark-mode-switch-switch
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input {
  margin: 0px !important;
}

.dark-mode-switch-switch
  .v-input__control
  .v-input__slot
  .v-input--selection-controls__input
  .v-input--switch__thumb {
  background-color: #4b506d;
}
</style>

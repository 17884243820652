<template>
  <div class="data-bar-wrapper transition-all" :style="style" v-if="visible">
    <v-tooltip
      :content-class="tooltipClass"
      right
      nudge-bottom="55"
      nudge-left="108"
      transition="slide-y-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="data-bar transition-all"
          :style="barStyle"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <span
        class="data-bar-tooltip-text"
        v-html="$t('header.dataBar.debtToAssetRatio', { value: valueText })"
      />
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'DataBar',
  props: {
    totalBorrow: {
      type: Object,
    },
    borrowLimit: {
      type: Object,
    },
    marginCallLimit: {
      type: Object,
    },
    totalDeposit: {
      type: Object,
    },
    minWidth: {
      type: Number,
      default: 108,
    },
  },
  computed: {
    visible() {
      return this.$route.name === 'Lend';
    },
    style() {
      return {
        right: `${new this.$BBN('100').minus(this.value).toStr()}%`,
      };
    },
    barStyle() {
      const result = {};

      if (this.isDanger) {
        result['background-color'] = '#e02020';
      } else if (this.isWarning) {
        result['background-color'] = '#e59b00';
      } else {
        result['background-color'] = '#47b465';
      }

      return result;
    },
    tooltipClass() {
      let result = 'data-bar-tooltip';

      const minValue = (this.minWidth / window.innerWidth) * 100;

      if (this.value.lte(minValue)) {
        result = `${result} low`;
      }

      return result;
    },
    isWarning() {
      return this.totalBorrow.gte(this.borrowLimit);
    },
    isDanger() {
      return this.totalBorrow.gte(this.marginCallLimit);
    },
    value() {
      let result;

      if (this.totalDeposit.gt(0)) {
        result = this.totalBorrow.div(this.totalDeposit).mul(100);
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    valueText() {
      return this.value.balanceFormat('{}%', 2, 5);
    },
  },
};
</script>

<style scoped>
.data-bar-wrapper {
  position: absolute;
  top: 75px;
  left: 0;
  height: 8px;
  min-height: 8px;
  z-index: 1;
}

.data-bar {
  height: 100%;
  background-color: #47b465;
  cursor: default;
}

.data-bar-tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 195px;
  max-width: 195px;
  min-height: 64px;
  max-height: 64px;
  padding: 8px 4px;
  font-size: 12px;
  color: #00000080;
  border: solid 1px #00000040;
  border-radius: 15px;
  background-color: #fff;
}

.data-bar-tooltip:after {
  content: '';
  position: absolute;
  top: -6px;
  width: 10px;
  height: 10px;
  border-top: solid 1px #00000040;
  border-left: solid 1px #00000040;
  background-color: #fff;
  transform: rotate(45deg);
}

.data-bar-tooltip.low:after {
  left: 13px !important;
}

.data-bar-tooltip-text {
  position: relative;
  text-align: center;
  letter-spacing: 0;
  line-height: 1;
}

/* dark mode */

#app.dark .data-bar-tooltip,
#app.dark .data-bar-tooltip:after {
  color: #fff;
  border-color: #ffffff40;
  background-color: #383c52;
}

/* mobile */

#app.mobile .data-bar-wrapper {
  top: 56px;
}
</style>

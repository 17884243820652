<template>
  <v-dialog
    content-class="dialog edit-btc"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="$store.state.ui.btc.edit"
  >
    <v-layout class="dialog-content" column>
      <div class="dialog-header">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-btn
            class="ml-4 mr-n2"
            icon
            :dark="$store.getters.darkMode"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
        </template>
        <div
          class="dialog-header-title"
          v-text="
            $t('trade.content.title', {
              action: $t('common.action.editBtcAddress'),
            })
          "
        />
      </div>
      <div class="dialog-body description">
        <div
          class="dialog-body-title"
          v-text="$t('trade.content.information.currentBtcAddress')"
        />
        <div class="dialog-body-address-copy-wrapper">
          <v-btn
            class="dialog-body-address-copy"
            text
            :dark="$store.getters.darkMode"
            :disabled="!address"
            v-clipboard:copy="address"
          >
            <span
              class="dialog-body-address-copy-title"
              v-text="
                (address && `${address.slice(0, 8)}...${address.slice(-8)}`) ||
                  'No Address'
              "
            />
            <v-icon class="dialog-body-address-copy-icon"
              >mdi-content-copy</v-icon
            >
          </v-btn>
        </div>
        <div
          class="dialog-body-title"
          v-text="$t('trade.content.information.btcAddressToEdited')"
        />
        <v-text-field
          class="dialog-body-input"
          filled
          dense
          :placeholder="$t('trade.content.placeholder.btcAddressToEdited')"
          :rules="$rulesBtcAddress"
          :dark="$store.getters.darkMode"
          v-model="inputBtcAddress"
        />
      </div>
      <v-fade-transition>
        <div class="loader-wrapper" v-if="pending">
          <Loader
            class="loader"
            color="#47b465"
            secondColor="#1f7bff"
            :borderWeight="2"
            :size="80"
          />
          <a
            class="loader-title link transition-all"
            rel="noopener"
            target="_blank"
            :href="pendingLink"
            v-if="pendingLink"
          >
            <span v-text="pendingMessage" />
            <v-icon class="loader-link-icon" color="#2380fb" small
              >mdi-open-in-new</v-icon
            >
          </a>
          <div
            class="loader-title"
            v-text="pendingMessage"
            v-else-if="pendingMessage"
          />
          <div
            class="loader-title"
            v-t="'common.message.transaction.processing'"
            v-if="pendingTransactionHash"
          />
        </div>
      </v-fade-transition>
      <div class="dialog-footer">
        <div class="dialog-footer-info" />
        <div class="dialog-footer-button-wrapper">
          <v-btn
            class="dialog-footer-button"
            color="#1f7bff"
            tile
            depressed
            :disabled="!!this.pending || !this.isValidInputBtcAddress"
            @click="edit"
          >
            <span
              class="dialog-footer-button-title"
              v-text="
                $t('trade.content.button.action', {
                  action: $t('common.action.edit'),
                })
              "
            />
          </v-btn>
        </div>
      </div>
    </v-layout>
  </v-dialog>
</template>

<script>
import Loader from '@/components/Loader';

export default {
  name: 'DialogBtcEdit',
  components: {
    Loader,
  },
  data() {
    return {
      inputBtcAddress: '',
      address: '',
      pending: undefined,
    };
  },
  computed: {
    isValidInputBtcAddress() {
      return !this.$rulesBtcAddress.find(
        ruleAddress =>
          typeof this.$execFunc(ruleAddress, this.inputBtcAddress) === 'string'
      );
    },
    token() {
      return this.$config.tokens.find(token => token.id === 'btc');
    },
    tokenTitle() {
      return (this.token || {}).title || '';
    },
    tokenImage() {
      return (this.token || {}).image || '';
    },
    pendingTransactionHash() {
      return (this.pending || {}).transactionHash;
    },
    pendingMessage() {
      return (
        ((this.pending || {}).message &&
          this.$t((this.pending || {}).message)) ||
        ''
      );
    },
    pendingLink() {
      return (
        (this.pendingTransactionHash &&
          this.$getTransactionExplorer(
            this.$store.getters.walletNetwork,
            this.pendingTransactionHash
          )) ||
        ''
      );
    },
  },
  watch: {
    '$store.getters.ui.btc.edit'(val) {
      if (val) {
        this.initializing();
      }
    },
  },
  methods: {
    addTransaction(transactionHash, error, thenFunc) {
      let status = 0;
      let code = undefined;
      let message = undefined;

      if (error || !transactionHash) {
        status = 2;

        if (typeof error === 'object') {
          code = error.code;
          message = error.locale || error.message;

          if (error.code === this.$config.metamask.code.userCancel) {
            status = 3;
            message = 'common.message.transaction.error.cancel';
          }
        } else {
          if (error) {
            message = `${error}`;
          } else {
            message = 'common.message.transaction.error.unknown';
          }
        }
      }

      this.$store.dispatch('addTransaction', {
        token: 'btc',
        contentID: 'common.action.btc.edit',
        action: 'common.action.btc.edit',
        transactionHash,
        status,
        code,
        message,
        thenFunc,
      });
    },
    edit() {
      this.pending = {
        message: 'common.message.transaction.btc.processingEditAddress',
      };

      const address = this.$store.getters.walletAddress;
      const btcAddress = this.inputBtcAddress;

      // Backend
      this.$setBtcOutflowAddress(address, btcAddress).then(response => {
        const data = (response || {}).data || {};
        const refundAddress = data.refundAddr || '';

        if (!refundAddress) {
          throw Error('empty refundAddress');
        }
        // TODO check
        // }).catch(error => {
        //   this.addTransaction(undefined, error);
        //   this.pending = undefined;
      });

      // Contract
      const { version, hex } = this.$btcAddress2hex(btcAddress);

      if (!hex) {
        throw Error('empty hex');
      }

      const from = address;
      const to = this.$store.getters.contract.getEntryPointAddress(
        'bifi.resolver'
      );
      const signature = this.$config.signature.btc.registerRefund;
      const args = [version, hex];

      this.$sendTransaction({ from, to, signature, args })
        .then(transactionHash => {
          this.addTransaction(transactionHash, undefined, () => {
            this.close();
            this.pending = undefined;
          });
        })
        .catch(error => {
          this.addTransaction(undefined, error);
          this.pending = undefined;
        });
    },
    initializing() {
      this.inputBtcAddress = '';
      this.address = '';
      this.pending = undefined;

      this.$store.getters.contract
        .call('bifi.btcPublicKeyHashes', this.$store.getters.walletAddress)
        .then(response => {
          const refundVersion = response.refundVersion || '';

          this.address = response.refund
            ? this.$hex2btcAddress(response.refund, refundVersion)
            : '';
        });
    },
    close() {
      this.$store.dispatch('closeBtcEdit', {});
    },
  },
  mounted() {
    this.initializing();
  },
};
</script>

<style scoped>
.dialog-content {
  position: relative;
  width: 900px;
  max-width: 100%;
  height: 425px;
  max-height: 100%;
  background-color: #fff;
}

.dialog-header {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0px 24px;
  border-bottom: solid 1px #d5d5d5;
}
.dialog-header-title {
  font-size: 20px;
  color: #000000d9;
}
.dialog-header-info {
  display: flex;
  align-items: center;
}
.dialog-header-name {
  margin-left: 8px;
  font-size: 14px;
  color: #000000d9;
}

.dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 20px;
  border-radius: 0px !important;
}
.dialog-body-title {
  padding-bottom: 10px;
}
.dialog-body-address-copy-wrapper {
  padding: 10px 0px 50px;
}
.dialog-body-address-copy {
  margin-left: -16px;
}
.dialog-body-address-copy-title {
  font-size: 14px;
  letter-spacing: 0;
  text-transform: none;
}
.dialog-body-address-copy-icon {
  margin-left: 12px;
  font-size: 20px;
}
.dialog-body-input {
  flex-grow: 0;
  font-size: 14px;
}

.loader-wrapper {
  position: absolute;
  display: flex;
  top: 72px;
  bottom: 110px;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  user-select: none;
  z-index: 2;
}
.loader {
  margin-bottom: 35px;
}
.loader-title {
  font-size: 14px;
  text-align: center;
  color: #000000d9;
}
.loader-title.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.loader-title.link:hover {
  opacity: 0.8;
}
.loader-title.link:active {
  opacity: 0.6;
}
.loader-link-icon {
  margin-left: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  padding: 0px 16px;
  border-top: solid 1px #d5d5d5;
}
.dialog-footer-info {
  width: 100%;
  height: 100%;
}
.dialog-footer-info-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 24px;
}
.dialog-footer-button-wrapper {
  padding-left: 16px;
}
.dialog-footer-button {
  width: 200px !important;
  height: 68px !important;
}
.dialog-footer-button-title {
  font-size: 20px;
  text-align: center;
}

/* dark mode */
#app.dark .dialog-content {
  background-color: #383c52;
}

#app.dark .dialog-header-title,
#app.dark .dialog-header-name,
#app.dark .loader-title {
  color: #ffffffd9;
}

#app.dark .loader-wrapper {
  background-color: #383c52;
}

/* mobile */
#app.mobile .dialog-content {
  width: 100%;
  height: initial;
  min-height: 100%;
  max-height: initial;
  overflow: hidden;
}

#app.mobile .dialog-header {
  flex-direction: row-reverse;
  height: 50px;
  padding: 0px 14px;
  background-color: #0000001a;
}
#app.mobile .dialog-header-title {
  padding-left: 10px;
  font-size: 14px;
}
#app.mobile .dialog-header-title-button-wrapper {
  height: 100%;
  margin-left: -14px;
  font-size: 12px;
  color: #000000d9;
}
#app.mobile.dark .dialog-header-title-button-wrapper {
  color: #ffffffd9;
}
#app.mobile .dialog-header-title-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 14px;
  border-right: 1px solid #0000001f;
  cursor: pointer;
}
#app.mobile.dark .dialog-header-title-button {
  border-color: #4b506d;
}

#app.mobile .dialog-body {
  justify-content: flex-start;
}
#app.mobile .dialog-body-address-copy-wrapper {
  padding-bottom: 30px;
}

#app.mobile .loader-wrapper {
  top: 90px;
  bottom: 58px;
}

#app.mobile .dialog-footer {
  flex-direction: column;
  height: unset;
  padding: 0px;
  background-color: #0000001a;
}
#app.mobile .dialog-footer-info {
  height: unset;
}
#app.mobile .dialog-footer-info-title {
  padding: 12px;
}
#app.mobile .dialog-footer-button-wrapper {
  width: 100%;
  padding-left: 0px;
}
#app.mobile .dialog-footer-button {
  width: 100% !important;
  height: 58px !important;
}
#app.mobile .dialog-footer-button-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>

<style>
.edit-btc.dialog {
  max-height: 100%;
  background-color: #f7f7f7;
}

.edit-btc.dialog .description {
  font-size: 14px;
  color: #000000d9;
}

/* dark mode */
#app.dark .edit-btc.dialog {
  background-color: #2b2d3c;
}

#app.dark .edit-btc.dialog .description {
  color: #ffffffd9;
}

/* mobile */
#app.mobile .edit-btc.dialog .description {
  font-size: 10px;
}
</style>

<template>
  <div class="button-wrapper">
    <v-btn
      class="button"
      text
      :dark="$store.getters.darkMode"
      :disabled="disabled"
      @click="openPopup"
    >
      <span
        class="button-title"
        v-text="`${$vuetify.breakpoint.mdAndUp ? 'Add ' : ''}Token`"
      />
    </v-btn>

    <RegisterTokenPopup :disabled="disabled" v-model="registerTokenPopup" />
  </div>
</template>

<script>
import RegisterTokenPopup from '@/components/registerToken/Popup';

export default {
  name: 'Button',
  components: {
    RegisterTokenPopup,
  },
  data: () => ({
    registerTokenPopup: false,
  }),
  computed: {
    disabled() {
      return !this.$store.getters.isWalletUsable;
    },
  },
  methods: {
    openPopup() {
      this.registerTokenPopup = true;
    },
  },
};
</script>

<style scoped>
.button-wrapper {
}

.button {
}

.button-title {
  font-size: 12px;
  text-transform: none;
}
</style>

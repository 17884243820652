<template>
  <v-alert
    class="alert"
    tile
    dismissible
    transition="slide-y-reverse-transition"
    :type="$store.getters.ui.alert.type || undefined"
    v-model="$store.state.ui.alert.show"
  >
    <div class="alert-message">
      <template v-for="(msg, msgIndex) in messages">
        <span v-text="msg" v-if="typeof msg === 'string'" :key="msgIndex" />
        <template v-else-if="typeof msg === 'object'">
          <a
            rel="noopener"
            target="_blank"
            :href="msg.href"
            v-text="msg.text"
            v-if="msg.href"
            :key="msgIndex"
          />
          <a
            @click="msg.action"
            v-text="msg.text"
            v-else-if="msg.action"
            :key="msgIndex"
          />
          <router-link
            :to="msg.to"
            v-text="msg.text"
            v-else-if="msg.to"
            :key="msgIndex"
          />
        </template>
      </template>
    </div>
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  watch: {
    '$store.getters.ui.alert.show'(val) {
      if (val) {
        this.setTimeoutClose();
      }
    },
    '$store.getters.ui.alert.type'() {
      if (this.$store.getters.ui.alert.show) {
        this.setTimeoutClose();
      }
    },
    '$store.getters.ui.alert.message'() {
      if (this.$store.getters.ui.alert.show) {
        this.setTimeoutClose();
      }
    },
    '$store.getters.ui.alert.messageArgs'() {
      if (this.$store.getters.ui.alert.show) {
        this.setTimeoutClose();
      }
    },
  },
  data: () => ({
    timeoutID: -1,
  }),
  computed: {
    messages() {
      const message = this.$t(
        this.$store.getters.ui.alert.message,
        this.$store.getters.ui.alert.messageArgs
      );
      const link = this.$store.getters.ui.alert.link;

      let result = [message];

      if (typeof link === 'object' && link.id && typeof link.id === 'string') {
        const splitedMessages = message.split(`{${link.id}}`);

        result = splitedMessages || [];

        if (splitedMessages.length > 1) {
          for (
            let splitedMessageIndex = 0;
            splitedMessageIndex < splitedMessages.length;
            splitedMessageIndex++
          ) {
            const count = parseInt(splitedMessageIndex) + 1;

            if (count === splitedMessages.length) {
              continue;
            }

            splitedMessages.splice(count, 0, link);
            splitedMessageIndex++;
          }

          result = splitedMessages || [];
        }
      }

      return result;
    },
  },
  methods: {
    setTimeoutClose() {
      let time = 10000;

      switch (this.$store.getters.ui.alert.type) {
        case 'error':
          time = 60000;
          break;
        case 'warning':
          time = 30000;
          break;
      }

      try {
        if (this.timeoutID >= 0) {
          clearTimeout(this.timeoutID);
        }
      } catch (error) {
        /**/
      }

      this.timeoutID = setTimeout(this.close, time);
    },
    close() {
      if (this.$store.getters.ui.alert.show) {
        this.$store.dispatch('closeAlert', {});

        this.timeoutID = -1;
      }
    },
  },
};
</script>

<style scoped>
.alert {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  padding: 12px 16px !important;
  margin-bottom: 0px !important;
  z-index: 999;
}

.alert-message {
  font-size: 12px;
}

.alert-message,
.alert-message * {
  word-break: break-all;
}
</style>

<style>
.v-alert__wrapper > .v-icon {
  align-self: center;
  font-size: 20px;
}

.v-alert__wrapper > .v-alert__dismissible .v-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
}
</style>

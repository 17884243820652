<template>
  <div class="information">
    <div class="information-title" v-text="title" />
    <div class="information-value-wrapper">
      <div class="information-value" v-text="formatedValue" />
      <div class="information-sub-value" v-text="formatedCurrencyValue" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputForm',
  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tokenID: {
      type: String,
      default: '',
    },
  },
  computed: {
    formatedValue() {
      let value = this.value;

      if (!value || isNaN(value)) {
        value = 0;
      }

      return this.$curr.getBalanceFormatedTokenValue(
        value,
        this.tokenID,
        this.tokenID
      );
    },
    formatedCurrencyValue() {
      let value = this.value;

      if (!value || isNaN(value)) {
        value = 0;
      }

      return this.$curr.getBalanceFormatedTokenValue(
        value,
        this.tokenID,
        this.$store.getters.currency
      );
    },
  },
};
</script>

<style scoped>
.information {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.information-title {
  font-size: 10px;
  color: #00000080;
}

.information-value-wrapper {
  margin: -2px 0px;
  text-align: right;
}

.information-value,
.information-sub-value {
  padding: 2px 0px;
  font-size: 12px;
}

.information-value {
  color: #000000d9;
}

.information-sub-value {
  color: #00000040;
}

/* dark mode */

#app.dark .information-title {
  color: #ffffff40;
}

#app.dark .information-value {
  color: #ffffffd9;
}

#app.dark .information-sub-value {
  color: #ffffff40;
}
</style>

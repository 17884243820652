var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stake"},[_c('div',{staticClass:"stake-content"},[_c('v-layout',{staticClass:"stake-content-header",attrs:{"align-end":""}},[_c('CardOutterTitle',{directives:[{name:"t",rawName:"v-t",value:('stake.header.title'),expression:"'stake.header.title'"}]}),_c('v-spacer')],1),_c('ContentHeader',[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"stake-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('stake.totalDeposit'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.totalDeposit.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"stake-header-value",domProps:{"textContent":_vm._s(_vm.formatedTotalDeposit)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.totalDepositDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"stake-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('stake.poolRate'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.marketPoolRate.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"stake-header-value",domProps:{"textContent":_vm._s(_vm.formatedMarketPoolRate)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.marketPoolRateDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"stake-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('pool.apy'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.apy.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"stake-header-value",domProps:{"textContent":_vm._s(_vm.formatedAPY)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.apyDetail)}})])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n2"},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"body"},[_c('v-row',{staticClass:"mt-n3 mb-n5"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 3 : 12}},[_c('v-avatar',{attrs:{"color":"#0000001a","size":"80"}},[_c('v-img',{attrs:{"width":"60","height":"60","contain":"","aspect-ratio":"2","src":((_vm.$config.host.image) + "icon/bfc.svg")}})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 9 : 12}},[_c('v-layout',{staticClass:"full-height",attrs:{"column":"","justify-space-between":"","align-center":_vm.$vuetify.breakpoint.smAndDown}},[_c('div',{staticClass:"card-srd-body-label pb-4",domProps:{"textContent":_vm._s(_vm.$t('stake.profitSharing'))}}),_c('a',{staticClass:"card-srd-body-link transition-all",class:{
                      disabled: !_vm.token.stake.handler.address,
                    },attrs:{"rel":"noopener","target":"_blank","href":_vm.token.stake.handler.address
                        ? _vm.$getAddressExplorer(
                            _vm.$store.getters.walletNetwork,
                            _vm.token.stake.handler.address
                          )
                        : undefined}},[_c('div',{staticClass:"card-srd-body-link-text",domProps:{"textContent":_vm._s(
                        _vm.token.stake.handler.address
                          ? ((_vm.token.stake.handler.address.slice(
                              0,
                              8
                            )) + "..." + (_vm.token.stake.handler.address.slice(-8)))
                          : '0x000000...00000000'
                      )}}),_c('v-icon',{staticClass:"card-srd-body-link-icon"},[_vm._v("mdi-open-in-new")])],1)])],1)],1)],1)],2)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"body"},[_c('v-layout',{staticClass:"pt-2",attrs:{"justify-left":"","align-center":""}},[_c('v-img',{staticClass:"ml-4 mr-8",attrs:{"max-width":"60","src":((_vm.$config.host.image) + "icon/sushiswap.svg")}}),_c('a',{staticClass:"card-srd-body-link transition-all",class:{
                  disabled: !_vm.token.stake.address,
                },attrs:{"rel":"noopener","target":"_blank","href":_vm.token.stake.address
                    ? _vm.$config.link.sushiswap.swap.replace(
                        '{address}',
                        _vm.token.stake.address
                      )
                    : undefined},domProps:{"textContent":_vm._s(_vm.$t('stake.descriptionAcquire'))}})],1)],1)],2)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('stake.stakedBFC'))}})]),_c('template',{slot:"body"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.hasDeposit},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"card-srd-body-single-value mt-6",domProps:{"textContent":_vm._s(_vm.formatedDeposit)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.depositDetail)}})]),_c('div',{staticClass:"card-srd-body-single-sub-value mb-4",domProps:{"textContent":_vm._s(_vm.depositAsset)}})],1),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":""},domProps:{"textContent":_vm._s(_vm.$t('common.action.stakeToken', { token: 'BFC' }))}}),_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#44ccd7","depressed":"","disabled":!_vm.hasDeposit},domProps:{"textContent":_vm._s(
                _vm.$t('common.action.doAction', {
                  action: _vm.$t('common.action.withdraw'),
                })
              )},on:{"click":function($event){return _vm.entryTrade('withdraw')}}})],1)],2)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('stake.unclaimedBiFi'))}})]),(_vm.userPoolRate.gt('0'))?_c('template',{slot:"header-subtitle"},[_c('v-tooltip',{attrs:{"top":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.formatedUserPoolRate)}},'div',attrs,false),on))]}}],null,false,2128407271)},[_c('span',{domProps:{"textContent":_vm._s(_vm.userPoolRateDetail)}})])],1):_vm._e(),_c('template',{slot:"body"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.hasEarn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"card-srd-body-single-value mt-6",domProps:{"textContent":_vm._s(_vm.formatedEarn)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.earnDetail)}})]),_c('div',{staticClass:"card-srd-body-single-sub-value mb-4",domProps:{"textContent":_vm._s(_vm.earnAsset)}})],1),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":!_vm.hasEarn},domProps:{"textContent":_vm._s(
                _vm.$t('common.action.doAction', {
                  action: _vm.$t('common.action.claimToken', { token: 'BiFi' }),
                })
              )},on:{"click":function($event){return _vm.entryTrade('claim')}}})],1)],2)],1)],1)],1),_c('DialogTrade',{attrs:{"type":_vm.dialog.type,"color":_vm.dialog.color,"token":_vm.dialog.token},on:{"syncData":_vm.syncData},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
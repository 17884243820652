import func from '@/plugins/function';
import bifiCurrency from '@/plugins/bifiCurrency';

export default {
  initializeCurrencyList({ commit }, { then, err, final }) {
    try {
      commit('syncCurrencyList', { then });

      bifiCurrency.setListener('tokenList', () => {
        commit('syncCurrencyList', {});
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  syncCurrencyList({ commit }, { then, err, final }) {
    try {
      commit('syncCurrencyList', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  initializeCurrency({ commit }, { then, err, final }) {
    try {
      commit('syncCurrency', {
        then,
      });

      bifiCurrency.setListener('currency', () => {
        commit('syncCurrency', {});
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setCurrency({ commit }, { currency = 'usd', then, err, final }) {
    try {
      commit('setCurrency', { currency, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  syncCurrency({ commit }, { then, err, final }) {
    try {
      commit('syncCurrency', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  storeCurrency({ commit }, { then, err, final }) {
    try {
      commit('storeCurrency', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

<template>
  <div class="network" :class="this.networkArea">
    <v-menu content-class="network-menu" offset-y>
      <template v-slot:activator="{ attrs, on }">
        <div
          class="network-content transition-all"
          :class="{
            openable: openable,
            open: attrs['aria-expanded'] === 'true',
          }"
          :style="{ 'background-color': networkColor }"
          v-bind="attrs"
          v-on="on">
          <v-img
            class="network-image"
            contain
            :src="networkImageUrl"
            v-if="networkImageUrl" />
          <div
            class="network-title"
            v-text="networkTitle"
            v-if="networkTitle" />
          <v-icon class="network-dropdown-icon" dark :small="mobile"
            >mdi-menu-down</v-icon
          >
        </div>
      </template>
      <v-list
        class="network-menu-list"
        :style="{ 'background-color': networkColor }"
        dark
        v-if="openable">
        <v-list-item
          class="network-menu-item"
          :class="network.id"
          :href="network.href ? network.href : undefined"
          v-for="network in networkItems"
          :key="network.id">
          <v-img
            class="network-menu-item-image"
            contain
            :src="`${$config.host.image}header/${network.image}`"
            v-if="network.image" />
          <div
            class="network-menu-item-title"
            v-text="mobile ? network.title.short : network.title.full"
            v-if="mobile ? network.title.short : network.title.full" />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'Network',
  computed: {
    mobile() {
      return !!this.$vuetify.breakpoint.smAndDown;
    },
    networks() {
      // const networkAreas = this.$config.allowedNetworks.reduce((networks, network) => {
      //   const networkArea = this.$getNetworkArea(network);

      //   if (networks.length === 0 || networks.indexOf(networkArea) < 0) {
      //     networks.push(networkArea);
      //   }

      //   return networks;
      // }, []);
      const networkAreas = [
        'ethereum',
        'bsc',
        'avalanche',
        'kaia',
        'bifrost',
      ];

      const result = networkAreas.map((id) => ({
        id,
        title: {
          full: this.$getNetworkString(id, false),
          short: this.$getNetworkString(id, true),
        },
        image: this.getNetworkImage(id),
        href: this.getNetworkUrl(id),
      }));

      return result;
    },
    networkItems() {
      return this.networks.filter((network) => network.id !== this.networkArea);
    },
    openable() {
      return this.networkItems.length > 0;
    },
    networkArea() {
      return this.$store.getters.walletNetworkArea;
    },
    networkColor() {
      let result = '#47b465';

      switch (this.networkArea) {
        case 'ethereum':
          result = '#47b465';
          break;
        // TODO add bsc color
        // case 'bsc':
        //   result = '#f9b10b';
        // break;
        // TODO add avalnche color
        // case 'avalnche':
        //   result = '#f9b10b';
        // break;
      }

      return result;
    },
    networkTitle() {
      return this.$getNetworkString(
        this.$store.getters.walletNetwork,
        this.$vuetify.breakpoint.smAndDown
      );
    },
    networkIcon() {
      return this.getNetworkImage(this.networkArea);
    },
    networkImageUrl() {
      return this.networkIcon
        ? `${this.$config.host.image}header/${this.networkIcon}`
        : '';
    },
  },
  methods: {
    getNetworkImage(networkArea) {
      let result = '';

      switch (networkArea) {
        case 'ethereum':
          result = 'eth_w.svg';
          break;
        case 'bsc':
          result = 'bsc_w.svg';
          break;
        case 'avalanche':
          result = 'avalanche_w.svg';
          break;
        case 'kaia':
          result = 'kaia_w.svg';
          break;
        case 'bifrost':
          result = 'bifrost_w.svg';
          break;
      }

      return result;
    },
    getNetworkUrl(networkArea) {
      let result = '';

      // TODO change http urls to https
      switch (networkArea) {
        case 'ethereum':
          result =
            'https://app.bifi.finance/lend?chainid=mainnet&utm_source=marketing&utm_campaign=to_eth%2F';
          break;
        case 'bsc':
          result =
            'https://app.bifi.finance/lend?chainid=bsc&utm_source=marketing&utm_campaign=to_bsc%2F';
          break;
        case 'avalanche':
          result =
            'https://app.bifi.finance/lend?chainid=avalanche&utm_source=marketing&utm_campaign=to_avalanche%2F';
          break;
        case 'kaia':
          result =
            'https://app.bifi.finance/lend?chainid=kaia&utm_source=marketing&utm_campaign=to_klaytn%2F';
          break;
        case 'bifrost':
          result =
            'https://app.bifi.finance/lend?chainid=bifrost&utm_source=marketing&utm_campaign=bifrost%2F';
          break;
      }

      return result;
    },
  },
};
</script>

<style scoped>
.network {
  display: flex;
  align-items: center;
}

.network-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 180px;
  max-width: 180px;
  min-height: 40px;
  max-height: 40px;
  padding: 0px 5px;
  border-radius: 20px;
  user-select: none;
}

.network-content.openable {
  cursor: pointer !important;
}

.network-content.openable.open {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.network-content.openable:not(.open):hover {
  filter: brightness(1.1);
}

.network-image,
.network-menu-item-image {
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  margin: 0px 5px;
}

.network-title {
  min-width: 106px;
  max-width: 116px;
}

.network-title,
.network-menu-item-title {
  padding: 0px 5px;
  color: #fff;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
}

.network-dropdown-icon {
}

.network-content.openable.open .network-dropdown-icon {
  transform: rotate(180deg);
}

.network-menu {
  min-width: 180px !important;
  max-width: 180px !important;
  padding: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  user-select: none !important;
  box-shadow: unset !important;
}

.network-menu-list {
  padding: 0px !important;
}

.network-menu-item {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  min-height: 40px !important;
  max-height: 40px !important;
  padding: 0px 5px !important;
  cursor: pointer;
}

.network-menu-item-image {
}

.network-menu-item-title {
}

/* mobile */

#app.mobile .network-content {
  min-width: 80px;
  max-width: 80px;
  min-height: 20px;
  max-height: 20px;
  padding: 0px 2px;
  border-radius: 10px;
}

#app.mobile .network-image,
#app.mobile .network-menu-item-image {
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  margin: 0px 2px;
}

#app.mobile .network-title {
  min-width: 44px;
  max-width: 48px;
}

#app.mobile .network-title,
#app.mobile .network-menu-item-title {
  padding: 0px 2px;
  font-size: 8px;
}

#app.mobile .network-menu {
  min-width: 80px !important;
  max-width: 80px !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

#app.mobile .network-menu-item {
  min-height: 20px !important;
  max-height: 20px !important;
  padding: 0px 2px !important;
}
</style>

import func from '@/plugins/function';

export default {
  connect({ commit }, { then, err, final }) {
    try {
      commit('connect', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  setConnectionCount({ commit }, { connectionCount, then, err, final }) {
    try {
      commit('setConnectionCount', { connectionCount, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  storeConnectionCount({ commit }, { then, err, final }) {
    try {
      commit('storeConnectionCount', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

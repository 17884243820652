<template>
  <div class="market">
    <div class="market-content">
      <v-layout class="market-headder" justify-space-between align-end>
        <CardOutterTitle v-t="'market.title'" />
      </v-layout>
      <v-row>
        <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
          <Overview multiple :multipleData="multipleData" />
        </v-col>
        <template v-else>
          <v-col cols="6">
            <Overview
              color="#1f7bff"
              :title="$t('market.overview.totalDeposit')"
              :total="totalDeposit"
            />
          </v-col>
          <v-col cols="6">
            <Overview
              color="#44ccd7"
              :title="$t('market.overview.totalBorrow')"
              :total="totalBorrow"
            />
          </v-col>
        </template>
        <v-col cols="12">
          <CoinStatus :data="coinStatus" :comingSoonData="comingSoonStatus" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CardOutterTitle from '@/components/card/CardOutterTitle';

import Overview from '@/components/status/Overview';
import CoinStatus from '@/components/status/CoinStatus';

export default {
  name: 'Market',
  components: {
    CardOutterTitle,
    Overview,
    CoinStatus,
  },
  data: () => ({
    tokenData: [],
    totalData: {
      deposit: 0,
      borrow: 0,
    },
  }),
  computed: {
    multipleData() {
      return [
        {
          color: '#1f7bff',
          title: this.$t('market.overview.totalDeposit'),
          total: this.totalDeposit,
        },
        {
          color: '#44ccd7',
          title: this.$t('market.overview.totalBorrow'),
          total: this.totalBorrow,
        },
      ];
    },
    totalDeposit() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.deposit,
        'usd',
        this.$store.getters.currency,
        2,
        true
      );
    },
    totalBorrow() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.borrow,
        'usd',
        this.$store.getters.currency,
        2,
        true
      );
    },
    coinStatus() {
      return this.tokenData.map(token => ({
        id: token.id,
        title: token.title,
        image: `${this.$config.host.image}icon/${token.image}`,
        price: this.$curr.getWordFormatedValue(
          this.$curr.getFormatedTokenValue(
            token.price,
            'usd',
            this.$store.getters.currency
          ),
          this.$i18n.locale,
          this.$store.getters.replaceString,
          2,
          true
        ),
        priceDetail: this.$curr.getBalanceFormatedTokenValue(
          token.price,
          'usd',
          this.$store.getters.currency
        ),
        deposit: {
          value: {
            value: this.$curr.getWordFormatedValue(
              this.$curr.getFormatedTokenValue(
                token.deposit.amount,
                token.id,
                this.$store.getters.currency
              ),
              this.$i18n.locale,
              this.$store.getters.replaceString,
              2,
              true
            ),
            share: token.deposit.share,
          },
          interestRate: {
            value: token.deposit.interestRate,
          },
        },
        borrow: {
          value: {
            value: this.$curr.getWordFormatedValue(
              this.$curr.getFormatedTokenValue(
                token.borrow.amount,
                token.id,
                this.$store.getters.currency
              ),
              this.$i18n.locale,
              this.$store.getters.replaceString,
              2,
              true
            ),
          },
          interestRate: {
            value: token.borrow.interestRate,
          },
        },
      }));
    },
    comingSoonStatus() {
      return this.comingSoonTokens.map(comingSoonToken => ({
        title: comingSoonToken.title,
        image: `${this.$config.host.image}icon/${comingSoonToken.image}`,
        price: '-',
        deposit: {
          value: {
            value: '-',
            share: '0.00%',
          },
          interestRate: {
            value: '0.00%',
          },
        },
        borrow: {
          value: {
            value: '-',
          },
          interestRate: {
            value: '0.00%',
          },
        },
      }));
    },
    comingSoonTokens() {
      return this.$store.getters.comingSoonTokens;
    },
  },
  methods: {
    syncData() {
      this.$store.dispatch('addProgress', {});

      this.$store.getters.contract
        .call('bifi.market', this.$store.getters.walletAddress)
        .then(async result => {
          this.totalData.deposit = result.totalData.deposit;
          this.totalData.borrow = result.totalData.borrow;

          this.tokenData = (result.assetList || [])
            .filter(asset => !this.$getIsDisabledToken(asset.id))
            .sort(pre => (pre.id === 'btc' ? -1 : 1))
            .map(token => ({
              id: token.id,
              price: token.price,
              title:
                this.$curr.getToken(token.id).title || token.id.toUpperCase(),
              image: this.$curr.getToken(token.id).image,
              deposit: {
                amount: token.deposit.amount,
                interestRate: new this.$BBN(token.deposit.interestRate)
                  .mul('100')
                  .balanceFormat('{}%', 2, 7, true),
                share: new this.$BBN(token.deposit.share)
                  .mul('100')
                  .balanceFormat('{}%', 2, 7, true),
              },
              borrow: {
                amount: token.borrow.amount,
                interestRate: new this.$BBN(token.borrow.interestRate)
                  .mul('100')
                  .balanceFormat('{}%', 2, 7, true),
                share: new this.$BBN(token.borrow.share)
                  .mul('100')
                  .balanceFormat('{}%', 2, 7, true),
              },
            }));
        })
        .catch(error => {
          // TODO fix metamask header not found
          if (this.$isIgnorableError(error)) {
            return;
          }

          this.$store.dispatch('openAlert', {
            message: 'common.message.dataLoad.error',
            messageArgs: {
              error: `${
                typeof error === 'object'
                  ? error.locale || error.message || error
                  : error
              }`,
            },
            type: 'error',
          });
        })
        .finally(() => this.$store.dispatch('solveProgress', {}));
    },
  },
};
</script>

<style scoped>
.market {
}

.market-content {
}

.market-headder {
  padding-bottom: 16px;
}
</style>

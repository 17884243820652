<template>
  <v-snackbar
    class="snackbar"
    :class="mode || ''"
    top
    right
    :color="color"
    :multi-line="multiLine"
    :vertical="vertical"
    :timeout="-1"
    :value="id !== -1"
  >
    <v-layout class="snackbar-content" justify-space-between align-center>
      <v-layout column>
        <div v-text="$t(message)" v-if="message" />
        <a
          class="snackbar-button transition-all"
          v-text="$t(buttonMessage)"
          @click="action"
          v-if="buttonMessage"
        />
      </v-layout>
      <v-btn icon dark :disabled="!using" @click="close" v-if="!buttonMessage">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-layout>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  props: {
    multiLine: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    color: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    buttonMessage: {
      type: String,
      default: '',
    },
    buttonAction: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    using: true,
  }),
  methods: {
    close() {
      this.using = false;
      this.$store.dispatch('removeSnackbar', { id: this.id });
    },
    action() {
      this.$execFunc(this.buttonAction);
      this.using = false;
      this.$store.dispatch('removeSnackbar', { id: this.id });
    },
  },
  mounted() {
    setTimeout(() => {
      this.close();
    }, 10000);
  },
};
</script>

<style scoped>
.snackbar {
  position: relative !important;
  display: block;
  height: unset !important;
  margin: 4px 16px;
  transition: all 0.3s;
}

.snackbar-list-enter,
.snackbar-list-leave-to {
  height: 0px !important;
  margin: 0px !important;
  opacity: 0;
  transform: translateX(100px);
}

.snackbar-list-leave-active {
  position: absolute;
}

.snackbar.alert .snackbar-content {
  margin: -14px -16px;
  padding: 30px;
  font-size: 18px;
  letter-spacing: 0.5px;
  color: #000;
  border-radius: 3px;
  border: solid 1px #efefef;
  background-color: #fff;
}

.snackbar.alert .snackbar-content:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 12px;
  background-color: #1f7bff;
}

.snackbar.alert .snackbar-content .snackbar-button {
  align-self: flex-end;
  margin-top: 30px;
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #1f7bff;
}

.snackbar.alert .snackbar-content .snackbar-button:hover {
  opacity: 0.8;
}

.snackbar.alert .snackbar-content .snackbar-button:active {
  opacity: 0.6;
}

/* dark mode */

#app.dark .snackbar.alert .snackbar-content {
  color: #fff;
  border-color: #4b506d;
  background-color: #383c52;
}
</style>

<style>
.snackbar.alert .v-snack__action {
  margin: 0px !important;
}
</style>

import Vue from 'vue';
import VueGtag from 'vue-gtag';

import router from '@/router';

try {
  if (process.env.VUE_APP_GTAG_ID) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: process.env.VUE_APP_GTAG_ID,
        },
      },
      router
    );
  }
} catch (error) {
  /**/
}

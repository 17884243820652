<template>
  <div class="user-status">
    <Card>
      <div
        slot="header"
        class="card-header main"
        :style="{ 'background-color': options.color }"
      >
        <v-layout class="full-height" justify-space-between>
          <v-layout column justify-center>
            <div>
              <span
                class="card-text highlight mr-5"
                v-text="options.total.title"
              />
            </div>
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="!hasTotal"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  class="card-highlight-value transition-all"
                  :class="{ disabled: !hasTotal }"
                  :style="{
                    'transform-origin': '0px calc(100% - 8px)',
                    transform: `scale(${totalScale})`,
                  }"
                  align-end
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="unit prefix" v-text="prefix" />
                  <div class="value" v-text="totalInteger" />
                  <div class="value decimal" v-text="totalDecimal" />
                  <div class="abbreviation" v-text="totalAbbreviation" />
                  <div class="unit suffix" v-text="suffix" />
                </v-layout>
              </template>
              <span v-text="formatedTotalDetail" v-if="hasTotal" />
            </v-tooltip>
          </v-layout>
          <v-layout class="card-submain" column justify-center align-center>
            <div class="card-text" v-text="options.apy.title" />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="!hasApy"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  class="card-highlight-value transition-all"
                  :class="{
                    disabled: !hasApy,
                  }"
                  :style="{
                    transform: `scale(${apyValueScale})`,
                  }"
                  align-end
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="value" v-text="formatedApyString" />
                  <div class="unit suffix">%</div>
                </v-layout>
              </template>
              <span v-text="formatedApyDetail" v-if="hasApy" />
            </v-tooltip>
          </v-layout>
          <v-layout class="card-submain" column justify-center align-center>
            <div class="card-text" v-text="options.interest.title" />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="!hasInterest"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  class="card-highlight-value transition-all"
                  :class="{
                    disabled: !hasInterest,
                  }"
                  :style="{
                    transform: `scale(${interestValueScale})`,
                  }"
                  align-end
                  v-bind="attrs"
                  v-on="on"
                >
                  <div class="unit prefix" v-text="prefix" />
                  <div class="value" v-text="formatedInterestString" />
                  <div class="unit suffix" v-text="`${suffix}`" />
                </v-layout>
              </template>
              <span v-text="formatedInterestDetail" v-if="hasInterest" />
            </v-tooltip>
          </v-layout>
        </v-layout>
      </div>

      <div slot="body">
        <v-expand-transition>
          <CardTable
            :nodata="options.enabled.nodata"
            :header="options.enabled.header"
            :rows="enabledRows"
            :actions="options.enabled.actions"
            :color="options.color"
            v-if="enabledRows && enabledRows.length"
          />
        </v-expand-transition>
      </div>
    </Card>

    <CardExpansion
      cardClass="mt-8"
      :disabled="
        (!disabledRows || !disabledRows.length) &&
          (!comingSoonRows || !comingSoonRows.length)
      "
    >
      <div slot="header" v-text="options.disabled.title" />
      <div slot="content">
        <CardTable
          footerClass="dark"
          :footer="comingSoonFooter"
          :nodata="options.disabled.nodata"
          :header="options.disabled.header"
          :rows="disabledRows"
          :comingSoonRows="comingSoonRows"
          :actions="options.disabled.actions"
          :color="options.color"
        >
          <v-layout
            slot="footer"
            justify-space-between
            align-center
            v-if="comingSoonFooter"
          >
            <div
              class="card-footer-text"
              v-text="$t('common.message.comingSoonToken')"
            />
            <a
              class="card-footer-link transition-all"
              rel="noopener"
              target="_blank"
              :href="$config.link.productRequest"
              v-text="$t('common.message.gainOpinionToken')"
            />
          </v-layout>
        </CardTable>
      </div>
    </CardExpansion>
  </div>
</template>

<script>
import Card from '@/components/card/Card';
import CardTable from '@/components/card/CardTable';
import CardExpansion from '@/components/card/CardExpansion';

export default {
  name: 'UserStatus',
  props: {
    comingSoonFooter: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: () => ({
        color: '',
        total: {
          title: '',
        },
        apy: {
          title: '',
        },
        interest: {
          title: '',
        },
        enabled: {
          nodata: '',
          header: [],
          actions: [],
        },
        disabled: {
          title: '',
          nodata: '',
          header: [],
          actions: [],
        },
      }),
    },
    total: {
      type: Object,
    },
    apy: {
      type: Object,
    },
    interest: {
      type: Object,
    },
    enabledRows: {
      type: Array,
      default: () => [],
    },
    disabledRows: {
      type: Array,
      default: () => [],
    },
    comingSoonRows: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Card,
    CardTable,
    CardExpansion,
  },
  computed: {
    // total
    hasTotal() {
      let result = false;

      if (this.total && this.total instanceof this.$BBN) {
        result = this.total.gt('0');
      }

      return result;
    },
    formatedTotalString() {
      return this.hasTotal
        ? this.$curr.getWordFormatedValue(this.total, this.$i18n.locale) || '0'
        : '0';
    },
    totalSpaceIndex() {
      return this.formatedTotalString.lastIndexOf(' ');
    },
    totalDotIndex() {
      return this.formatedTotalString.indexOf('.');
    },
    totalValueString() {
      return this.totalSpaceIndex >= 0
        ? this.formatedTotalString.slice(0, this.totalSpaceIndex)
        : this.formatedTotalString;
    },
    totalAbbreviation() {
      return this.totalSpaceIndex >= 0
        ? this.formatedTotalString.slice(this.totalSpaceIndex)
        : '';
    },
    totalInteger() {
      return this.totalDotIndex >= 0
        ? this.totalValueString.slice(0, this.totalDotIndex)
        : this.totalValueString;
    },
    totalDecimal() {
      return this.totalDotIndex >= 0
        ? this.totalValueString.slice(this.totalDotIndex)
        : '';
    },
    formatedTotalDetail() {
      return `${this.prefix}${
        this.hasTotal ? this.$formatCommas(this.total) : '0'
      }${this.suffix}`;
    },
    totalScale() {
      let result = 1;

      if (this.formatedTotalString.length > 14) {
        result = Math.max(
          1 - (this.formatedTotalString.length - 15) * Math.sin(1 / 25),
          0.3
        );
      }

      return result;
    },
    // apy
    hasApy() {
      let result = false;

      if (this.hasTotal && this.apy && this.apy instanceof this.$BBN) {
        result = this.apy.gt('0');
      }

      return result;
    },
    formatedApyString() {
      return this.hasApy ? this.apy.balanceFormat('{}', 2, 4) || '0' : '0';
    },
    apyValueScale() {
      let result = 1;

      if (this.formatedApyString.length > 4) {
        result = Math.max(1 - this.formatedApyString.length / 18, 0.3);
      }

      return result;
    },
    formatedApyDetail() {
      return `${this.hasApy ? this.apy.toStr() : '0'}%`;
    },
    // interest
    hasInterest() {
      let result = false;

      if (
        this.hasTotal &&
        this.interest &&
        this.interest instanceof this.$BBN
      ) {
        result = this.interest.gt('0');
      }

      return result;
    },
    interestDotIndex() {
      return this.formatedInterestString.indexOf('.');
    },
    interestValueString() {
      return this.formatedInterestString;
    },
    interestInteger() {
      return this.interestDotIndex >= 0
        ? this.interestValueString.slice(0, this.interestDotIndex)
        : this.totalValueString;
    },
    interestDecimal() {
      return this.interestDotIndex >= 0
        ? this.interestValueString.slice(this.interestDotIndex)
        : '';
    },
    formatedInterestString() {
      return this.hasInterest
        ? this.$curr.getWordFormatedValue(this.interest, this.$i18n.locale) ||
            '0'
        : '0';
    },
    interestValueScale() {
      let result = 1;

      if (this.formatedInterestString.length > 4) {
        result = Math.max(1 - this.formatedInterestString.length / 18, 0.3);
      }

      return result;
    },
    formatedInterestDetail() {
      return `${this.prefix}${this.hasInterest ? this.interest.toStr() : '0'}${
        this.suffix
      }`;
    },
  },
};
</script>

<style scoped>
.value,
.abbreviation {
  white-space: nowrap;
}
</style>

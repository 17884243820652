var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reward"},[_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('Card',{staticClass:"full-height"},[_c('div',{class:("pa-" + (_vm.$vuetify.breakpoint.mdAndUp ? 5 : 3)),attrs:{"slot":"body"},slot:"body"},[_c('v-layout',{staticClass:"pb-3",attrs:{"align-center":""}},[_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"#0000001a","size":"24"}},[_c('v-img',{attrs:{"width":"22","height":"22","contain":"","aspect-ratio":"2","src":((_vm.$config.host.image) + "icon/bifi.png")}})],1),_c('div',{staticClass:"reward-description-title",domProps:{"textContent":_vm._s(_vm.$t('reward.summary.title'))}})],1),_c('div',{staticClass:"reward-description",domProps:{"innerHTML":_vm._s(_vm.description)}})],1)])],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 12}},[_c('Card',{staticClass:"full-height"},[_c('v-layout',{staticClass:"full-height",attrs:{"slot":"body","column":"","justify-center":"","align-center":""},slot:"body"},[_c('div',{staticClass:"reward-info-key",domProps:{"textContent":_vm._s(_vm.$t('reward.summary.totalReward'))}}),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.reward.total.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"reward-info-value",domProps:{"textContent":_vm._s(_vm.totalReward)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.totalRewardDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 6}},[_c('Card',{staticClass:"full-height"},[_c('v-layout',{staticClass:"full-height",attrs:{"slot":"body","column":"","justify-center":"","align-center":""},slot:"body"},[_c('div',{staticClass:"reward-info-key",domProps:{"textContent":_vm._s(_vm.$t('reward.summary.dailyReward'))}}),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.reward.daily.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"reward-info-value",domProps:{"textContent":_vm._s(_vm.dailyReward)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.dailyRewardDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 6}},[_c('Card',{staticClass:"full-height"},[_c('v-layout',{staticClass:"full-height",attrs:{"slot":"body","column":"","justify-center":"","align-center":""},slot:"body"},[_c('div',{staticClass:"reward-info-key",domProps:{"textContent":_vm._s(_vm.$t('reward.summary.remainingReward'))}}),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.reward.remaining.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"reward-info-value",domProps:{"textContent":_vm._s(_vm.remainingReward)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.remainingRewardDetail)}})])],1)],1)],1)],1),_c('v-layout',{staticClass:"reward-header",attrs:{"align-end":""}},[_c('CardOutterTitle',{directives:[{name:"t",rawName:"v-t",value:('reward.title'),expression:"'reward.title'"}]}),_c('v-spacer')],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 8 : 12}},[_c('Card',{staticClass:"reward-table"},[_c('CardTable',{attrs:{"slot":"body","vertical":_vm.$vuetify.breakpoint.smAndDown,"nodata":_vm.$t('reward.table.nodata'),"header":_vm.header,"rows":_vm.marketRows},slot:"body"})],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 4 : 12}},[_c('CardSrd',{staticClass:"reward-my-info"},[_c('div',{staticClass:"reward-my-info-title",attrs:{"slot":"header-title"},domProps:{"textContent":_vm._s(_vm.$t('reward.mine.title'))},slot:"header-title"}),_c('template',{slot:"body"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.amount.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"reward-my-info-value",domProps:{"textContent":_vm._s(_vm.myAmount)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.myAmountDetail)}})]),_c('div',{staticClass:"reward-my-info-sub-value",domProps:{"textContent":_vm._s(_vm.myAsset)}})],1),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":!_vm.hasAmount},domProps:{"textContent":_vm._s(_vm.$t('reward.mine.button'))},on:{"click":_vm.entryTrade}})],1)],2)],1)],1),_c('DialogTrade',{attrs:{"type":_vm.dialog.type,"color":_vm.dialog.color,"token":_vm.dialog.token},on:{"syncData":_vm.syncData},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
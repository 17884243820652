<script>
import Content from '@/components/dialog/content/Content';

import BodyTokenPower from '@/components/dialog/body/TokenPower';
import BodyValueInput from '@/components/dialog/body/ValueInput';
import BodyBtcOutflow from '@/components/dialog/body/btc/Outflow';

import FooterInformation from '@/components/dialog/footer/Information';

export default {
  name: 'BtcBorrow',
  mixins: [Content],
  data() {
    return {
      contentID: 'btc.borrow',
      contentAction: 'common.action.btc.borrow',
      contentActioned: 'common.actioned.btc.borrow',
      contentActioning: 'common.actioning.btc.borrow',
      subtitle: 'trade.content.subtitle.btc',
      tokenPowerStep: false,
      hideStepperHeader: true,
      flow: {
        status: 0,
        unitID: '',
        eth: '',
        btc: '',
        amount: new this.$BBN('0'),
      },
      interval: {
        id: -1,
        term: this.$config.expectedBlock.time,
      },
      minFee: new this.$BBN('0'),
    };
  },
  computed: {
    steps() {
      return [
        {
          id: 0,
          title: this.$t('trade.content.step.action', {
            action: this.$t('common.action.tokenApprove'),
          }),
          bodies: [BodyTokenPower],
          buttons: [
            {
              title: this.$t('trade.content.button.action', {
                action: this.$t('common.action.approve'),
              }),
              action: this.enableTokenPowerMax,
            },
          ],
          disableRule: () =>
            (!this.tokenPowerStep &&
              (!this.isToken || this.tokenAllowance.gt('0'))) ||
            this.flow.status >= 1,
          skipRule: () =>
            (!this.tokenPowerStep && this.tokenAllowance.gt('0')) ||
            this.flow.status >= 1,
        },
        {
          id: 1,
          title: this.$t('trade.content.step.input', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.action', {
                action: this.$t(this.contentAction),
              }),
              action: this.borrow,
            },
          ],
          skipRule: () => this.flow.status >= 1,
        },
        {
          id: 2,
          title: this.$t('trade.content.step.complete', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyBtcOutflow],
          footers: [FooterInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.confirm'),
              action: this.close,
            },
          ],
        },
      ];
    },
    currentBodyProps() {
      let result = this.defaultProps;

      if (this.currentBody === BodyTokenPower) {
        result = Object.assign({}, result, {
          description: 'trade.tokenPower.btcDescription',
          pairTokens: [
            {
              image: this.tokenImage,
            },
            {
              image: `${this.$config.host.image}icon/${this.bfcImage}`,
            },
          ],
        });
      } else if (this.currentBody === BodyValueInput) {
        result = Object.assign({}, result, {
          inputonly: true,
          amountValue: this.inputValue,
          maxAmount: this.maxAmountString,
          inputTitle: this.$t('trade.btc.userBitcoinAddress'),
          inputTitleButtons: [
            {
              title: `${(this.$store.getters.btcRefund || '').slice(0, 8)}...${(
                this.$store.getters.btcRefund || ''
              ).slice(-8)}`,
              appendIcon: 'mdi-content-copy',
              action: this.copyBtcRefund,
            },
            {
              icon: 'mdi-pencil',
              action: this.openBtcEdit,
            },
          ],
          // TODO btc add description of fee (uniswap real time pricing warning) (every action)
          inputDescription: `${'Fee'}: ${this.$store.getters.btcFee.outflow.rate.balanceFormat(
            '{}%'
          )} <span>(${'Minimum Fee'}: ${this.minFee.balanceFormat(
            '{} BFC',
            2
          )})</span>`,
          inputPlaceholderDescription: this.$t(
            'trade.content.placeholder.inputDescription',
            { action: this.$t(this.contentAction) }
          ),
          disabledRules: this.disabledRules,
        });
      } else if (this.currentBody === BodyBtcOutflow) {
        result = Object.assign({}, result, {
          steps: this.flows,
        });
      }

      return result;
    },
    currentFooterProps() {
      let result = this.defaultProps;

      if (this.currentFooter === FooterInformation) {
        result = Object.assign({}, result, {
          informationData: this.informationData,
          graphTextData: this.graphTextData,
          graphValue: this.graphValue,
        });
      }

      return result;
    },
    informationData() {
      return [
        {
          title: this.$t('trade.content.information.interestRate', {
            action: this.$t('common.action.borrow'),
          }),
          value: this.borrowInterestRate,
        },
        {
          title: this.$t('trade.content.information.tokenPrice'),
          value: this.tokenPriceString,
        },
      ];
    },
    graphTextData() {
      return [
        {
          title: this.$t('trade.content.information.borrowLimit'),
          value: this.borrowLimit,
          change: this.changeBorrowLimit,
        },
        {
          title: this.$t('trade.content.information.usingBorrowLimit'),
          value: this.borrowLimitRate,
          change: this.changeBorrowLimitRate,
        },
      ];
    },
    actionButtonDisabled() {
      return (
        this.currentStepID !== 2 &&
        (this.transactionPending ||
          !!this.emergencyMessage ||
          !!this.isInsufficientFee ||
          !this.isActionableByInput ||
          !this.$store.getters.isWalletUsable ||
          (this.currentStepID === 1 && this.isOverLimitOfAction))
      );
    },
    disabledRules() {
      return [
        () => (this.currentStepID === 2 ? true : undefined),
        () =>
          this.informationValue.lte(0)
            ? {
                text: 'trade.content.message.nonexistent.action',
              }
            : undefined,
      ];
    },
    bfcToken() {
      const configToken =
        this.$config.tokens.find(token => token.id === 'bfc') || {};
      const storeToken =
        this.$store.getters.tokens.find(token => token.id === 'bfc') || {};

      return Object.assign({}, configToken, storeToken);
    },
    bfcAddress() {
      return this.bfcToken.address || '';
    },
    bfcImage() {
      return this.bfcToken.image || '';
    },
    flows() {
      const result = [
        {
          status: 0,
          title: this.$t('common.action.startingAction', {
            action: this.$t('common.action.borrow'),
          }),
        },
        {
          status: 0,
          title: this.$t('common.action.verifyingAction', {
            action: this.$t('common.action.borrow'),
          }),
        },
        {
          status: 0,
          title: this.$t('common.action.actionComplete', {
            action: this.$t('common.action.borrow'),
          }),
        },
      ];

      if (this.flow.status >= 1) {
        result[0].status = 1;
        result[0].title = this.$t('common.action.actionStarted', {
          action: this.$t('common.action.borrow'),
        });
      }
      if (this.flow.status >= 2) {
        result[0].status = 2;
        result[0].title = this.$t('common.action.actionStarted', {
          action: this.$t('common.action.borrow'),
        });
        result[1].status = 1;
      }
      if (this.flow.status >= 3) {
        result[1].status = 2;
        result[1].title = this.$t('common.action.actionComplete', {
          action: this.$t('common.action.verification'),
        });
        result[2].status = 2;
      }

      return result;
    },
    isInsufficientFee() {
      let result = false;

      try {
        result = this.bfcToken.balance.lt(this.minFee);
      } catch (error) {
        /**/
      }

      return result;
    },
  },
  watch: {
    inputValue() {
      this.messageCheck();

      const amount = new this.$BBN(this.inputValue)
        .mul('100000000')
        .toHex(true);
      const args = { amount };
      this.$store.getters.contract
        .call('bifi.btcCalcOutflowFee', this.$store.getters.walletAddress, args)
        .then(response => {
          this.minFee = new this.$BBN(response.fee.bfc || '0');
        });
    },
    'flow.status'() {
      this.checkSkip();
      this.syncEmergencyMessage();
    },
    isOverLimitOfAction() {
      this.messageCheck();
    },
    isInsufficientFee() {
      this.messageCheck();
    },
    '$store.getters.btcChallenge'() {
      this.syncEmergencyMessage();
    },
    '$store.getters.btcProcessingOutflow'() {
      this.syncEmergencyMessage();
    },
  },
  methods: {
    initializing() {
      this.initializeSlide();
      this.initializeStep();
      this.initializeInputValue();
      this.initializeMessage();
      this.initializeFlow();
      this.checkSkip();

      this.temporaryTransaction = false;
    },
    initializeFlow() {
      this.flow.status = 0;
      this.flow.unitID = '';
      this.flow.eth = '';
      this.flow.btc = '';
      this.flow.amount = new this.$BBN('0');
    },
    syncEmergencyMessage() {
      if (this.$store.getters.btcChallenge) {
        this.emergencyMessage =
          'common.message.transaction.btc.processingReviewing';
        this.emergencyMessageIcon = 'mdi-history';
        this.emergencyMessageDescription =
          'common.message.transaction.btc.processingReviewingDescription';
      } else if (
        this.flow.status <= 0 &&
        this.$store.getters.btcProcessingOutflow
      ) {
        this.emergencyMessage =
          'common.message.transaction.btc.processingOutflow';
        this.emergencyMessageIcon = 'mdi-pause-circle';
        this.emergencyMessageDescription =
          'common.message.transaction.btc.processingOutflowDescription';
      } else {
        this.emergencyMessage = '';
        this.emergencyMessageIcon = '';
        this.emergencyMessageDescription = '';
      }
    },
    enableTokenPowerMax() {
      const from = this.$store.getters.walletAddress;
      const to = this.bfcAddress;
      const numTokens = new this.$BBN(this.$config.uintMaxValue).toHex(true);
      const tokenSendAddress = this.$store.getters.contract.getEntryPointAddress(
        'bifi.bitcoin'
      );

      this.addTemporaryTransaction();

      this.$approveErc20({ from, to, numTokens, tokenSendAddress })
        .then(transactionHash => {
          this.addTransaction(
            'common.action.tokenApprove',
            undefined,
            transactionHash,
            undefined,
            () => {
              this.addTemporaryTransaction();

              this.cycleSyncToken(
                () => this.tokenAllowance.gt('0'),
                () => {
                  this.enableTokenPowerStep();
                  this.nextStep();
                },
                this.cycleSyncTokenError,
                this.resolveTemporaryTransaction
              );
            }
          );
        })
        .catch(error => {
          this.addTransaction(
            'common.action.tokenApprove',
            undefined,
            undefined,
            error
          );

          this.setMessage('error', this.$getTransactionErrorMessage(error), {
            action: 'common.action.tokenApprove',
          });
        })
        .finally(() => this.resolveTemporaryTransaction());
    },
    borrow() {
      const web3 = this.$getWeb3();
      const from = this.$store.getters.walletAddress;
      const to = this.tokenSendAddress;
      const abi = this.$config.abi.btc.handlerViewProxy;

      const innerArgs = [
        this.$curr.getUnformatedValue(this.inputValue).toHex(true),
        false,
      ];
      const args = [
        web3.utils.toHex(
          this.$ethereumJsAbi.simpleEncode(
            this.$config.signature.btc.borrow,
            ...innerArgs
          )
        ),
      ];

      this.addTemporaryTransaction();

      this.$sendTransaction({ from, to, abi, args })
        .then(transactionHash => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            transactionHash,
            undefined,
            () => {
              this.nextStep();

              this.setMessage(
                'success',
                'common.message.transaction.successfullyAction'
              );
            }
          );
        })
        .catch(error => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            undefined,
            error
          );

          this.setMessage('error', this.$getTransactionErrorMessage(error));
        })
        .finally(() => this.resolveTemporaryTransaction());
    },
    messageCheck() {
      // Limit of Action
      let type = 'error';
      let message = 'trade.content.message.limitOfAction';
      let messageArgs = {
        value: `${this.$curr.getBalanceFormatedTokenValue(
          this.limitOfAction,
          this.tokenID,
          this.tokenID,
          this.tokenReplaceString
        )}`,
      };

      if (this.isOverLimitOfAction) {
        this.setMessage(type, message, messageArgs);

        return;
      } else if (this.message.type === type && this.message.text === message) {
        this.initializeMessage();
      }

      // Fee
      message = 'trade.content.message.insufficient.fee';
      messageArgs = {
        token: 'BFC',
      };

      if (this.isInsufficientFee) {
        this.setMessage(type, message, messageArgs);

        return;
      } else if (this.message.type === type && this.message.text === message) {
        this.initializeMessage();
      }
    },
    copyBtcRefund() {
      this.$copyText(
        this.$store.getters.btcRefund,
        document.getElementsByClassName('value-input')[0]
      );
    },
    openBtcEdit() {
      this.$store.dispatch('openBtcEdit', {});

      this.close();
    },
    async syncTransactionStatus() {
      const { unitID, status } = await this.$relayer.getFlowData(
        'borrow',
        this.$store.getters,
        this.flow.unitID
      );

      this.flow.unitID = unitID;
      this.flow.status = status;
    },
  },
  async mounted() {
    this.initializing();

    try {
      await this.syncTransactionStatus();
      this.interval.id = setInterval(
        this.syncTransactionStatus,
        this.interval.term
      );
    } catch (error) {
      /**/
    }

    this.syncEmergencyMessage();
  },
  beforeDestroy() {
    try {
      clearInterval(this.interval.id);
    } catch (error) {
      /**/
    }
  },
};
</script>

<template>
  <v-fade-transition>
    <v-progress-linear
      class="progress"
      height="4"
      :indeterminate="true"
      v-if="$store.getters.progressShow"
    />
  </v-fade-transition>
</template>

<script>
export default {
  name: 'Progress',
  computed: {},
  watch: {},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
.progress {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  margin-top: 0px !important;
  z-index: 100;
}
</style>

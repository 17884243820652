import Vue from 'vue';
import axios from '@/plugins/axios';
import config from '@/plugins/config';

/* CONSTANT */
const pathByType = {
  in: config.path.relayer.getInflow || '',
  out: config.path.relayer.getOutflow || '',
};
const typeByAction = {
  deposit: 'in',
  repay: 'in',
  borrow: 'out',
  withdraw: 'out',
};
const publicKeyHashNameSpaceByAction = {
  deposit: 'btcDepositPublicKeyHash',
  repay: 'btcRepayPublicKeyHash',
  borrow: 'walletAddress',
  withdraw: 'walletAddress',
};
// TODO change naming of pkh

/* FUNCTION */
const getFlow = (type, publicKeyHash, action = '') => {
  if (!type) {
    throw new Error('Require type');
  }
  if (!publicKeyHash) {
    throw new Error('Require publicKeyHash');
  }
  const path = pathByType[type];
  if (!path) {
    throw new Error('Require valid type');
  }

  return axios.get(
    `${config.host.relayer || ''}${path
      .replace('{publicKeyHash}', publicKeyHash)
      .replace('{action}', action)}`
  );
};

const getFlowUnitID = async (flows = []) => {
  let result = '';

  try {
    if (flows.length) {
      result = (flows[flows.length - 1] || {})['unit_id'];
    }
  } catch (error) {
    console.error('getFlowUnitID', error);
  }

  return result;
};

const getFlowStatus = async (getters, unitID) => {
  if (
    !getters ||
    typeof getters !== 'object' ||
    !(getters.contract && getters.walletAddress && unitID)
  ) {
    return 0;
  }

  let result = 0;

  try {
    const submittedHeight = parseInt(
      await getters.contract.call(
        'bifi.btcSubmittedUnit',
        getters.walletAddress,
        { unitID }
      )
    );

    if (submittedHeight > 0) {
      const confirmGuarantee = parseInt(
        await getters.contract.call(
          'bifi.btcConfirmGuarantee',
          getters.walletAddress
        )
      );
      const currentHeight = parseInt(
        await getters.contract.call(
          'bifi.btcCurrentHeight',
          getters.walletAddress
        )
      );

      if (submittedHeight + confirmGuarantee <= currentHeight) {
        result = 3;
      } else {
        result = 2;
      }
    } else {
      result = 1;
    }
  } catch (error) {
    console.error('getFlowStatus', error);
  }

  return result;
};

const getFlowData = async (action, getters, unitID) => {
  let result = {
    unitID,
    status: 0,
  };

  try {
    if (!action) {
      throw new Error('Require action');
    }
    const type = typeByAction[action];
    const publicKeyHashNameSpace = publicKeyHashNameSpaceByAction[action];
    if (!(type && publicKeyHashNameSpace)) {
      throw new Error('Require valid action');
    }
    const publicKeyHash = getters[publicKeyHashNameSpace];
    if (!publicKeyHash) {
      throw new Error('Cannot find publicKeyHash');
    }

    if (unitID) {
      result.status = await getFlowStatus(getters, unitID);
    } else {
      const flows =
        ((await getFlow(type, publicKeyHash, action)) || {}).data || [];

      result.unitID = await getFlowUnitID(flows);

      if (flows.length && !result.unitID) {
        result.status = 1;
      } else {
        result.status = await getFlowStatus(getters, result.unitID);

        if (result.status === 3) {
          result.unitID = '';
          result.status = 0;
        }
      }
    }
  } catch (error) {
    console.error('getFlowData', action, unitID, ':', error);
    result.unitID = '';
    result.status = 0;
  }

  return result;
};

/* EXPORT */
const relayer = {
  getFlow,
  getFlowUnitID,
  getFlowStatus,
  getFlowData,
};

Vue.prototype.$relayer = relayer;

export default relayer;

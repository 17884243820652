<template>
  <div class="simple-stacked-bar">
    <div class="simple-stacked-bar-wrapper">
      <div
        class="simple-stacked-bar-bar"
        :style="{
          width: `${value}%`,
          'background-color': color,
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SimpleStackedBar',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    color() {
      let result = '#1f7bff';

      if (this.value >= 85) {
        result = '#f70000';
      } else if (this.value >= 70) {
        result = '#ff6d1f';
      }

      return result;
    },
  },
};
</script>

<style scoped>
.simple-stacked-bar {
  width: 100%;
}

.simple-stacked-bar-wrapper {
  position: relative;
  width: 100%;
  height: 12px;
  overflow: hidden;
  background-color: #00000026;
}

.simple-stacked-bar-bar {
  position: absolute;
  left: 0;
  width: 0%;
  max-width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 1;

  -webkit-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01);
  -moz-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01);
  -ms-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01);
  -o-transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01);
  transition: all 0.3s cubic-bezier(0, 0.49, 0.46, 1.01);
}
</style>

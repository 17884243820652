<template>
  <div class="content-header">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentHeader',
};
</script>

<style>
.content-header {
  margin-bottom: 18px;
  padding: 30px;
  color: #fff;
  background-image: linear-gradient(78deg, #44ccd7 8%, #1f7bff 92%);
}

/* dark mode */

#app.dark .content-header {
  background-image: linear-gradient(100deg, #27656b 5%, #12407d 72%);
}

/* mobile */

#app.mobile .content-header {
  margin-left: -10px;
  margin-right: -10px;
  padding: 20px;
}
</style>

<template>
  <v-btn
    class="button"
    :class="buttonClass"
    small
    :outlined="outlined"
    :fab="fab"
    :block="block"
    :dark="dark"
    :color="color"
    :min-width="minWidth"
    :max-width="maxWidth"
    :to="to"
    @click="action"
  >
    <v-img :class="imageClass" max-width="16px" :src="image" v-if="image" />
    <slot />
    <span
      :class="`${textClass} ${image && text ? 'ml-4' : ''}`"
      v-text="text"
      v-if="!fab"
    />
  </v-btn>
</template>

<script>
export default {
  name: 'Button',
  props: {
    buttonClass: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    fab: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#1f7bff',
    },
    minWidth: {
      type: Number,
      default: 0,
    },
    maxWidth: {
      type: Number,
      default: 100000,
    },
    to: Object,
    action: {
      type: Function,
      default: () => {},
    },
    image: {
      type: String,
      default: '',
    },
    imageClass: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    textClass: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.button {
  height: 40px !important;
  padding: 0px 17px !important;
  border-radius: 3px !important;
  overflow: hidden;
}
</style>

import func from '@/plugins/function';

export default {
  // language
  setLanguage(state, { language, then }) {
    state.language = language;

    func.execFunc(then);
  },

  storeLanguage(state, { then }) {
    func
      .storageEach(storage => storage.setItem('language', state.language))
      .then(then);
  },
};

<script>
import Content from '@/components/dialog/content/Content';

import BodyValueInput from '@/components/dialog/body/ValueInput';

import FooterWithdrawMessage from '@/components/dialog/footer/WithdrawMessage';

export default {
  name: 'PoolWithdraw',
  mixins: [Content],
  data: () => ({
    contentID: 'pool.withdraw',
    contentAction: 'common.action.pool.withdraw',
    contentActioned: 'common.actioned.pool.withdraw',
    contentActioning: 'common.actioning.pool.withdraw',
  }),
  computed: {
    steps() {
      return [
        {
          id: 1,
          title: this.$t('trade.content.step.input', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterWithdrawMessage],
          buttons: [
            {
              title: this.$t('trade.content.button.action', {
                action: this.$t(this.contentAction),
              }),
              action: this.withdraw,
            },
          ],
        },
        {
          id: 2,
          title: this.$t('trade.content.step.complete', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterWithdrawMessage],
          buttons: [
            {
              title: this.$t('trade.content.button.confirm'),
              action: this.close,
            },
          ],
        },
      ];
    },
    currentBodyProps() {
      let result = this.defaultProps;

      if (this.currentBody === BodyValueInput) {
        result = Object.assign({}, result, {
          disabledCurrency: true,
          amountValue: this.inputValue,
          maxAmount: this.maxAmountString,
          inputTitle: this.$t('trade.content.placeholder.input', {
            action: this.$t(this.contentAction),
          }),
          inputPlaceholderDescription: this.$t(
            'trade.content.placeholder.inputDescription',
            { action: this.$t(this.contentAction) }
          ),
          informationTitle: this.$t(
            'trade.content.information.depositedToken',
            { token: 'BFC-ETH' }
          ),
          informationValue: this.informationValueString,
          disabledRules: [
            () => (this.currentStepID === 2 ? true : undefined),
            () =>
              this.informationValue.lte(0)
                ? {
                    text: 'trade.content.message.nonexistent.action',
                  }
                : undefined,
          ],
        });
      }

      return result;
    },
    currentFooterProps() {
      let result = this.defaultProps;

      if (this.currentFooter === FooterWithdrawMessage) {
        result = Object.assign({}, result, {
          message: this.$t('trade.content.information.withdrawMessage'),
        });
      }

      return result;
    },
  },
  methods: {
    withdraw() {
      const from = this.$store.getters.walletAddress;
      const to = this.tokenSendAddress;
      const signature = this.$config.signature.srd.withdraw;
      const args = [this.$curr.getUnformatedValue(this.inputValue).toHex(true)];
      const value = '0x0';

      this.addTemporaryTransaction();

      this.$sendTransaction({ from, to, signature, args, value })
        .then(transactionHash => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            transactionHash,
            undefined,
            () => {
              this.nextStep();

              this.setMessage(
                'success',
                'common.message.transaction.successfullyAction'
              );
            }
          );
        })
        .catch(error => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            undefined,
            error
          );

          this.setMessage('error', this.$getTransactionErrorMessage(error));

          this.nextStep();
        })
        .finally(() => this.resolveTemporaryTransaction());
    },
  },
};
</script>

<template>
  <v-dialog
    content-class="dialog trade"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    @input="dialogInput"
  >
    <v-layout class="dialog-content-wrapper" column>
      <component
        ref="content"
        :is="currentContent"
        v-bind="{
          color: this.color,
          tokenImage: this.tokenImage,
          tokenID: this.tokenID,
          tokenTitle: this.tokenTitle,
          tokenAddress: tokenAddress,
          tokenSendAddress: this.tokenSendAddress,
          tokenBalance: this.tokenBalance,
          tokenAllowance: this.tokenAllowance,
          tokenPrice: this.tokenPrice,
          pairTokens: this.pairTokens,
          isSrdToken: this.isSrdToken,
          isToken: this.isToken,
          tokenReplaceString: this.tokenReplaceString,
          tokenUnitString: this.tokenUnitString,
          token: this.token,
          contentPair: this.currentContentPair,
        }"
        @pairMenuChange="pairMenuChange"
        @setMarketAmountStatus="setMarketAmountStatus"
        @dialogClose="dialogInput(false)"
        @close="close"
      />
    </v-layout>
  </v-dialog>
</template>

<script>
import ContentDeposit from '@/components/dialog/content/Deposit';
import ContentWithdraw from '@/components/dialog/content/Withdraw';
import ContentBorrow from '@/components/dialog/content/Borrow';
import ContentRepay from '@/components/dialog/content/Repay';
import ContentBtcDeposit from '@/components/dialog/content/btc/Deposit';
import ContentBtcWithdraw from '@/components/dialog/content/btc/Withdraw';
import ContentBtcBorrow from '@/components/dialog/content/btc/Borrow';
import ContentBtcRepay from '@/components/dialog/content/btc/Repay';
import ContentPoolDeposit from '@/components/dialog/content/pool/Deposit';
import ContentPoolWithdraw from '@/components/dialog/content/pool/Withdraw';
import ContentPoolClaim from '@/components/dialog/content/pool/Claim';
import ContentStakeDeposit from '@/components/dialog/content/stake/Deposit';
import ContentStakeWithdraw from '@/components/dialog/content/stake/Withdraw';
import ContentStakeClaim from '@/components/dialog/content/stake/Claim';
import ContentRewardClaim from '@/components/dialog/content/reward/Claim';

export default {
  name: 'Trade',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    token: Object,
  },
  data() {
    return {
      contents: {
        deposit: ContentDeposit,
        withdraw: ContentWithdraw,
        borrow: ContentBorrow,
        repay: ContentRepay,
        'btc.deposit': ContentBtcDeposit,
        'btc.withdraw': ContentBtcWithdraw,
        'btc.borrow': ContentBtcBorrow,
        'btc.repay': ContentBtcRepay,
        'pool.deposit': ContentPoolDeposit,
        'pool.withdraw': ContentPoolWithdraw,
        'pool.claim': ContentPoolClaim,
        'stake.deposit': ContentStakeDeposit,
        'stake.withdraw': ContentStakeWithdraw,
        'stake.claim': ContentStakeClaim,
        'reward.claim': ContentRewardClaim,
      },
      marketAmountStatus: {
        show: false,
        value: '',
      },
    };
  },
  computed: {
    currentContent() {
      let result = undefined;

      if (this.value && this.type && this.contents[this.type]) {
        result = this.contents[this.type];
      }

      return result;
    },
    tokenImage() {
      let result = '';

      if (this.token && this.token.image) {
        result = `${this.$config.host.image}icon/${this.token.image}`;
      }

      return result;
    },
    tokenID() {
      let result = '';

      if (this.token && this.token.id) {
        result = this.token.id;
      }

      return result;
    },
    tokenTitle() {
      let result = '';

      if (this.token && this.token.title) {
        result = this.token.title;
      }

      return result;
    },
    tokenAddress() {
      let result = '';

      if (this.token && this.token.address) {
        result = this.token.address;
      }

      return result;
    },
    tokenSendAddress() {
      let result = '';

      if (this.token && this.token.handler && this.token.handler.address) {
        result = this.token.handler.address;
      }

      return result;
    },
    tokenBalance() {
      let result;

      if (this.tokenID) {
        result = this.$getTokenBalance(
          this.$store.getters.tokens,
          this.tokenID
        );
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    tokenAllowance() {
      let result;

      if (this.tokenID) {
        result = this.$getTokenAllowance(
          this.$store.getters.tokens,
          this.tokenID
        );
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    tokenPrice() {
      let result;

      if (this.token && this.token.price) {
        result = this.token.price;
      } else {
        result = new this.$BBN('0');
      }

      return result;
    },
    pairTokens() {
      let result = [];

      if (
        this.token &&
        this.token.pairTokens &&
        typeof this.token.pairTokens === 'object' &&
        this.token.pairTokens.length
      ) {
        result = this.token.pairTokens.map(pairToken =>
          Object.assign({}, pairToken, {
            image: `${this.$config.host.image}icon/${pairToken.image}`,
          })
        );
      }

      return result;
    },
    isSrdToken() {
      return !!(this.token && this.token.isSrdToken);
    },
    isToken() {
      return !!(this.token && this.token.isToken);
    },
    tokenReplaceString() {
      let result = '';

      if (this.token && this.token.replaceString) {
        result = this.token.replaceString;
      }

      return result;
    },
    tokenUnitString() {
      let result = '';

      if (this.token && this.token.unitString) {
        result = this.token.unitString;
      }

      return result;
    },
    contentPairs() {
      return [
        [
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.deposit'),
              }) || '',
            id: 'deposit',
          },
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.withdraw'),
              }) || '',
            id: 'withdraw',
          },
        ],
        [
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.borrow'),
              }) || '',
            id: 'borrow',
          },
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.repay'),
              }) || '',
            id: 'repay',
          },
        ],
        [
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.btc.deposit'),
              }) || '',
            id: 'btc.deposit',
          },
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.btc.withdraw'),
              }) || '',
            id: 'btc.withdraw',
          },
        ],
        [
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.btc.borrow'),
              }) || '',
            id: 'btc.borrow',
          },
          {
            title:
              this.$t('trade.content.title', {
                action: this.$t('common.action.btc.repay'),
              }) || '',
            id: 'btc.repay',
          },
        ],
      ];
    },
    currentContentPair() {
      let result = [];

      if (this.type) {
        result =
          this.contentPairs.find(
            pair => pair.findIndex(content => content.id === this.type) > -1
          ) || [];
      }

      return result;
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.$nextTick(() => this.initializing());
      }
    },
  },
  methods: {
    dialogInput(value) {
      if (!value) {
        this.close(true);
      }
    },
    initializing() {
      if (this.$refs.content) {
        this.$execFunc(this.$refs.content.initializing);
      }

      this.marketAmountStatus.show = false;
      this.marketAmountStatus.value = '';
    },
    setMarketAmountStatus(show, value) {
      this.marketAmountStatus.show = show;
      this.marketAmountStatus.value = value;
    },
    pairMenuChange(id) {
      if (id) {
        this.$emit('typeChange', id);
      }
    },
    close(force) {
      this.$emit('input', false);

      if (force) {
        if (
          this.$store.getters.transactions &&
          this.$store.getters.transactions.length &&
          this.$store.getters.transactions.findIndex(
            transaction => transaction.status === 0
          ) >= 0
        ) {
          this.$store.dispatch('addSnackbar', {
            message: 'common.message.transaction.checkWalletPopup.message',
            multiLine: true,
            vertical: true,
            mode: 'alert',
            color: 'info',
            buttonMessage: 'common.message.transaction.checkWalletPopup.button',
            buttonAction: () => this.$store.dispatch('openWalletPopup', {}),
          });
        }
      } else {
        this.$emit('syncData');
      }
    },
  },
  mounted() {
    this.initializing();
  },
};
</script>

<style scoped>
.dialog-content-wrapper {
  position: relative;
  width: 900px;
  max-width: 100%;
  height: 700px;
  max-height: 100%;
  background-color: #fff;
}

.market-amount-status-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding: 0px 30px;
}

.market-amount-status-title {
  font-size: 12px;
  color: #fff;
}

.market-amount-status-value {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  color: #fff;
}

/* dark mode */

#app.dark .dialog-content-wrapper {
  background-color: #383c52;
}

#app.dark .market-amount-status-title,
#app.dark .market-amount-status-value {
  color: #000;
}

/* mobile */

#app.mobile .dialog-content-wrapper {
  width: 100%;
  height: initial;
  min-height: 100%;
  max-height: initial;
  overflow: hidden;
}
</style>

<style>
.trade.dialog {
  max-height: 100%;
  background-color: #f7f7f7;
}

/* dark mode */

#app.dark .trade.dialog {
  background-color: #2b2d3c;
}
</style>

<template>
  <v-dialog
    content-class="setting-popup"
    max-width="320"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card class="setting-popup-content" tile :dark="dark">
      <v-card-title class="setting-popup-header" v-text="title" />
      <v-list class="setting-popup-body">
        <v-list-item
          class="setting-popup-body-list-item"
          :input-value="itemValue === item[itemKey]"
          :ripple="itemValue !== item[itemKey]"
          v-for="(item, itemIndex) in list"
          @click="$emit('itemInput', item[itemKey])"
          :key="itemIndex"
        >
          <div class="setting-popup-body-list-item-checkbox-wrapper">
            <v-fade-transition>
              <v-icon
                class="setting-popup-body-list-item-checkbox"
                v-if="itemValue === item[itemKey]"
                >mdi-checkbox-marked-circle</v-icon
              >
            </v-fade-transition>
          </div>
          <div class="setting-popup-body-list-item-title" v-text="item.title" />
          <div
            class="setting-popup-body-list-item-subtitle"
            v-text="item.subtitle"
            v-if="item.subtitle"
          />
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SettingPopup',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    itemValue: {
      type: String,
      default: '',
    },
    itemKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    dark() {
      return !!this.$store.getters.darkMode;
    },
  },
};
</script>

<style scoped>
.setting-popup-content {
  background-color: #fcfdff;
}

.setting-popup-header {
}

.setting-popup-body {
  background-color: #fcfdff;
}

.setting-popup-body-list-item {
}

.setting-popup-body-list-item-checkbox-wrapper {
  min-width: 40px;
  padding-right: 16px;
}

.setting-popup-body-list-item-checkbox {
  color: #2380fb !important;
  caret-color: #2380fb !important;
}

.setting-popup-body-list-item-title {
  flex: 1;
  text-align: left;
}

.setting-popup-body-list-item-subtitle {
  text-align: right;
  opacity: 0.5;
}

/* dark mode */
#app.dark .setting-popup-content,
#app.dark .setting-popup-body {
  background-color: #383c52;
}

#app.dark .setting-popup-body-list-item-checkbox {
  color: #fff !important;
  caret-color: #fff !important;
}

/* mobile */
#app.mobile .setting-popup-header {
  padding: 12px 16px 6px !important;
  font-size: 18px;
}

#app.mobile .setting-popup-body-list-item {
  min-height: 40px !important;
  padding: 0px 12px !important;
}
</style>

<style>
.setting-popup {
  border: 1px solid #d5d5d5;
  border-radius: 0px !important;
  box-shadow: 0 3px 6px 0 #00000029;
}

/* mobile */

#app.mobile .setting-popup {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

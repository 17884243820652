<template>
  <div class="not-found full-height">
    <v-container class="full-height">
      <v-layout class="full-height" justify-center align-center>
        <h1 class="text-center">
          Oops, we couldn’t find the page you were looking for.
        </h1>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
.not-found {
}

/* dark mode */

#app.dark .not-found {
  color: #fff;
}
</style>

<script>
import PoolContent from '@/views/pool/PoolContent';

export default {
  name: 'BifiEth',
  mixins: [PoolContent],
  data: () => ({
    selectedToken: 'bifieth',
  }),
};
</script>

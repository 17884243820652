var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{staticClass:"overview",class:{ multiple: _vm.multiple },style:(_vm.style)},[_c('div',{attrs:{"slot":"body"},slot:"body"},[(_vm.multiple)?[_vm._l((_vm.multipleData),function(data,dataIndex){return [(
            typeof data === 'object' &&
              data.total &&
              data.total instanceof _vm.$BBN
          )?_c('v-layout',{key:dataIndex,staticClass:"bifi-content-wrapper transition-all",class:{ disabled: data.total.lte('0') },attrs:{"justify-space-between":"","align-center":""}},[_c('div',{staticClass:"bifi-title",style:({ color: data.color }),domProps:{"textContent":_vm._s(data.title)}}),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":data.total.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({staticClass:"bifi-total",style:({
                  'transform-origin': '100% calc(100% - 8px)',
                  transform: ("scale(" + _vm.totalScale + ")"),
                  color: data.color,
                }),attrs:{"justify-end":"","align-end":""}},'v-layout',attrs,false),on),[_c('div',{staticClass:"value",domProps:{"textContent":_vm._s(
                    _vm.$curr.getWordFormatedValue(
                      data.total,
                      _vm.$i18n.locale,
                      _vm.replaceString
                    )
                  )}})])]}}],null,true)},[(data.total.gt('0'))?_c('span',{domProps:{"textContent":_vm._s(_vm.replaceString.replace('{}', data.total.toStr()))}}):_vm._e()])],1):_vm._e()]})]:_c('v-layout',{staticClass:"bifi-content-wrapper transition-all",class:{ disabled: !_vm.hasTotal },attrs:{"justify-space-between":"","align-center":""}},[_c('div',{staticClass:"bifi-title",domProps:{"textContent":_vm._s(_vm.title)}}),_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.hasTotal},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-layout',_vm._g(_vm._b({staticClass:"bifi-total",style:({
              'transform-origin': '100% calc(100% - 8px)',
              transform: ("scale(" + _vm.totalScale + ")"),
            }),attrs:{"justify-end":"","align-end":""}},'v-layout',attrs,false),on),[_c('div',{staticClass:"unit prefix",domProps:{"textContent":_vm._s(_vm.prefix)}}),_c('div',{staticClass:"value",domProps:{"textContent":_vm._s(_vm.totalInteger)}}),_c('div',{staticClass:"value decimal",domProps:{"textContent":_vm._s(_vm.totalDecimal)}}),_c('div',{staticClass:"abbreviation",domProps:{"textContent":_vm._s(_vm.totalAbbreviation)}}),_c('div',{staticClass:"unit suffix",domProps:{"textContent":_vm._s(_vm.suffix)}})])]}}])},[(_vm.hasTotal)?_c('span',{domProps:{"textContent":_vm._s(_vm.formatedTotalDetail)}}):_vm._e()])],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
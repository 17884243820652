import {
  getProvider,
  getNetwork,
  getAddress,
  isAddress,
  getErc20Balance,
  getErc20Allowance,
  getBalance,
} from '@/plugins/web3';
import config from '@/plugins/config';
import func from '@/plugins/function';
import BBN from '@/plugins/bifrostNumber';

export default {
  // wallet
  async syncProvider({ commit, getters }, { walletID, then, err, final }) {
    try {
      let type = getters.contract.callRpc.getType();
      let address;
      let provider;

      try {
        provider = getProvider(walletID);
      } catch (error) {
        provider = undefined;

        walletID = '';
      }

      if (!provider) {
        if (getters.isWalletChecked) {
          const message = 'common.message.wallet.error.cant.connect.metamask';

          this.dispatch('openNeedWallet', {});

          this.dispatch('openAlert', {
            message,
            type: 'warning',
          });

          this.dispatch('checkWallet', {});

          func.execFunc(err, new Error(message));
        }

        walletID = '';
      } else if (type !== 'wallet') {
        getters.contract.setProvider({
          type: 'wallet',
          provider,
        });

        type = getters.contract.callRpc.getType();
      } else {
        getters.contract.setProvider({
          type: 'wallet',
          provider,
        });
      }

      const network = await getNetwork();
console.log('getters.contract',getters.contract);
console.log('network',network);
      commit('syncMetamaskNetwork', { network });

      if (config.allowedNetworks.indexOf(network) < 0) {
        let targetNetwork = '';

        try {
          const urlParams = new URLSearchParams(window.location.search);
          targetNetwork = urlParams.get('chainid');

          if (
            !(
              targetNetwork === 'mainnet' ||
              targetNetwork === 'bsc' ||
              targetNetwork === 'avalanche' ||
              targetNetwork === 'klaytn' ||
              targetNetwork === 'kaia' ||
              targetNetwork === 'bifrost'
            )
          ) {
            targetNetwork = '';
          }
        } catch (error) {
          /**/
        }

        if (targetNetwork) {
          const targetNetworkConfig = func.getChainConfig(targetNetwork);
          const result = await getters.contract.switchAndAddChain(
            targetNetworkConfig.id,
            targetNetworkConfig.chainName,
            targetNetworkConfig.rpcUrls,
            targetNetworkConfig.blockExplorerUrls,
            targetNetworkConfig.nativeCurrency,
            targetNetworkConfig.iconUrls &&
              typeof targetNetworkConfig.iconUrls === 'object' &&
              targetNetworkConfig.iconUrls.length
              ? targetNetworkConfig.iconUrls.map(
                  url => `${config.host.image}icon/${url}`
                )
              : undefined
          );

          if (result) {
            getters.contract.setNetwork(targetNetwork);
          } else {
            this.dispatch('openAlert', {
              message: 'common.message.wallet.error.requireChangeNetwork',
              messageArgs: {
                network: network,
                targetNetwork: targetNetwork,
              },
              type: 'warning',
            });
          }
        } else {
          this.dispatch('openAlert', {
            message: 'common.message.wallet.error.wrongNetwork',
            messageArgs: {
              network: network,
            },
            type: 'error',
          });
        }
      } else if (walletID) {
        getters.contract.setNetwork(network);

        commit('syncWalletID', {
          id: walletID,
          then: () => {
            func.storageEach(storage => {
              storage.setItem('walletID', walletID);
            });
          },
        });
        commit('syncWalletType', { type });
        commit('syncWalletNetwork', { network });

        if (type === 'wallet') {
          address = await getAddress();
        }

        if (isAddress(address)) {
          commit('syncWalletAddress', { address, then });

          try {
            if (
              typeof window.heap === 'object' &&
              typeof window.heap.identify === 'function'
            ) {
              window.heap.identify(address);
            }
          } catch (error) {
            /**/
          }

          this.dispatch('syncTransaction', {});

          if (
            getters.ui.alert.show &&
            (getters.ui.alert.type === 'error' ||
              getters.ui.alert.type === 'warning')
          ) {
            this.dispatch('closeAlert', {});
          }
        } else {
          let message = '';
          let type = 'error';

          if (address) {
            message = 'common.message.wallet.error.wrongAddress';
            type = 'error';
          } else {
            message = 'common.message.wallet.error.cant.find.address.detail';
            type = 'warning';
          }

          if (getters.isWalletChecked) {
            this.dispatch('openAlert', {
              message,
              type,
            });

            func.execFunc(err, new Error(message));
          }
        }
      }

      this.dispatch('checkWallet', {});
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // token
  async syncToken(
    { commit, getters },
    { id, isToken, address, sendAddress, then, err, final }
  ) {
    try {
      if (typeof isToken !== 'boolean') {
        isToken = func.getIsToken(id, getters.walletNetwork);
      }

      let index = getters.tokens.findIndex(token => token.id === id);

      if (index < 0) {
        commit('addToken', { id, isToken, address, sendAddress });

        index = getters.tokens.length - 1;
      }

      const token = getters.tokens[index];
      const tokenAddress = address || token.address;
      const tokenSendAddress = sendAddress || token.sendAddress;

      let balance = new BBN('0');
      let allowance = new BBN('0');

      // Exception only BTC
      if (id === 'btc') {
        if (isAddress(tokenAddress) && isAddress(getters.walletAddress)) {
          balance = new BBN(
            await getErc20Balance(getters.walletAddress, tokenAddress)
          );

          const bfcAddress = (
            getters.tokens.find(storeToken => storeToken.id === 'bfc') || {}
          ).address;
          const btcContractAddress = getters.contract.getEntryPointAddress(
            'bifi.bitcoin'
          );

          if (isAddress(bfcAddress) && isAddress(btcContractAddress)) {
            allowance = new BBN(
              await getErc20Allowance(
                getters.walletAddress,
                bfcAddress,
                btcContractAddress
              )
            );
          }
        }
      } else if (isToken) {
        if (isAddress(tokenAddress) && isAddress(getters.walletAddress)) {
          balance = new BBN(
            await getErc20Balance(getters.walletAddress, tokenAddress)
          );

          if (isAddress(tokenSendAddress)) {
            allowance = new BBN(
              await getErc20Allowance(
                getters.walletAddress,
                tokenAddress,
                tokenSendAddress
              )
            );
          }
        }
      } else {
        if (isAddress(getters.walletAddress)) {
          balance = new BBN(await getBalance(getters.walletAddress));
        }

        allowance = new BBN(config.uintMaxValue);
      }

      commit('syncToken', {
        index,
        balance,
        allowance,
        address: tokenAddress,
        sendAddress: tokenSendAddress,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // btc
  setBtcInformation(
    { commit },
    {
      refundPublicKeyHash,
      refundAddress,
      transferInPublicKeyHash,
      transferInAddress,
      depositPublicKeyHash,
      depositAddress,
      repayPublicKeyHash,
      repayAddress,
      then,
      err,
      final,
    }
  ) {
    try {
      commit('setBtcInformation', {
        refundPublicKeyHash,
        refundAddress,
        transferInPublicKeyHash,
        transferInAddress,
        depositPublicKeyHash,
        depositAddress,
        repayPublicKeyHash,
        repayAddress,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setBtcChallenge({ commit }, { challenge, then, err, final }) {
    try {
      commit('setBtcChallenge', { challenge, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setBtcFee(
    { commit },
    { inflowRate, inflowMin, outflowRate, outflowMin, then, err, final }
  ) {
    try {
      commit('setBtcFee', {
        inflowRate,
        inflowMin,
        outflowRate,
        outflowMin,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setBtcOutflowAmount({ commit }, { amount, then, err, final }) {
    try {
      commit('setBtcOutflowAmount', { amount, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // transaction
  setTransaction({ commit }, { id, hasCheck, list, then, err, final }) {
    try {
      commit('setTransaction', { id, hasCheck, list, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  resetTransaction({ commit }, { then, err, final }) {
    try {
      commit('setTransaction', {
        id: 0,
        hasCheck: true,
        list: [],
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  addTransaction(
    { commit },
    {
      token,
      contentID,
      action,
      value,
      transactionHash,
      status,
      code,
      message,
      thenFunc,
      then,
      err,
      final,
    }
  ) {
    try {
      commit('addTransaction', {
        timestamp: Date.now(),
        token,
        contentID,
        action,
        value,
        transactionHash,
        status,
        code,
        message,
        thenFunc,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setTransactionStatus(
    { commit, getters },
    { id, status, code, then, err, final }
  ) {
    try {
      const index = getters.transactions.findIndex(
        transaction => transaction.id === id
      );

      if (index > -1 && index < getters.transactions.length) {
        commit('setTransactionStatus', { index, status, code, then });
      }
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  checkTransaction({ commit }, { then, err, final }) {
    try {
      commit('checkTransaction', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  storeTransaction({ commit }, { then, err, final }) {
    try {
      commit('storeTransaction', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // borrowData
  setBorrowData(
    { commit },
    {
      borrowLimit,
      marginCallLimit,
      totalDeposit,
      totalBorrow,
      then,
      err,
      final,
    }
  ) {
    try {
      commit('setBorrowData', {
        borrowLimit,
        marginCallLimit,
        totalDeposit,
        totalBorrow,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // wallet checked
  checkWallet({ commit }, { then, err, final }) {
    try {
      commit('setCheckWallet', { checkWallet: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

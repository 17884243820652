<template>
  <v-list class="setting-list" :dark="dark">
    <template v-for="(setting, settingIndex) in settings">
      <component
        class="setting-list-item i-component"
        :is="setting.component"
        v-if="setting.type === 'component'"
        :key="settingIndex"
      />
      <v-divider v-else-if="setting.type === 'div'" :key="settingIndex" />
      <v-btn
        class="setting-list-item i-popup"
        text
        tile
        block
        @click="openPopup(setting)"
        v-else-if="setting.type === 'popup'"
        :key="settingIndex"
      >
        <v-icon class="setting-list-item-popup-icon" v-text="setting.icon" />
        <div class="setting-list-item-popup-title" v-text="setting.title" />
      </v-btn>
    </template>
    <Popup
      :title="popup.title"
      :list="popup.list"
      :itemValue="popup.itemValue"
      :itemKey="popup.itemKey"
      v-model="popup.value"
      @itemInput="itemInput(popup.itemSetter, $event)"
    />
  </v-list>
</template>

<script>
import DarkModeSwitch from '@/components/DarkModeSwitch';
import Popup from '@/components/footer/setting/Popup';

export default {
  name: 'SettingList',
  components: {
    Popup,
  },
  data() {
    return {
      rowSettings: [
        {
          type: 'popup',
          title: 'Currency',
          icon: 'mdi-currency-usd',
          value: false,
          list: () => this.currencyList,
          itemValue: () => this.$store.getters.currency,
          itemKey: 'key',
          itemSetter: this.setCurrency,
        },
        {
          type: 'popup',
          title: 'Language',
          icon: 'mdi-web',
          value: false,
          list: () => this.$config.languages,
          itemValue: () => this.$store.getters.language,
          itemKey: 'id',
          itemSetter: this.setLanguage,
        },
        {
          type: 'div',
        },
        {
          type: 'component',
          component: DarkModeSwitch,
        },
      ],
      popup: {
        value: false,
        title: '',
        list: [],
        itemValue: '',
        itemKey: '',
        itemSetter: () => {},
      },
    };
  },
  computed: {
    dark() {
      return !!this.$store.getters.darkMode;
    },
    currencyList() {
      return this.$store.getters.currencyList
        .filter(currency => currency.price.gt())
        .map(currency =>
          Object.assign({}, currency, { subtitle: currency.unit })
        );
    },
    settings() {
      return this.rowSettings.map(rowSetting => ({
        type: rowSetting.type,
        component: rowSetting.component,
        title: rowSetting.title,
        icon: rowSetting.icon,
        value: rowSetting.value,
        list:
          typeof rowSetting.list === 'function'
            ? this.$execFunc(rowSetting.list)
            : rowSetting.list,
        itemValue:
          typeof rowSetting.itemValue === 'function'
            ? this.$execFunc(rowSetting.itemValue)
            : rowSetting.itemValue,
        itemKey: rowSetting.itemKey,
        itemSetter: rowSetting.itemSetter,
      }));
    },
  },
  methods: {
    setPopup(value, popup) {
      this.popup.value = value;
      this.popup.title = popup.title;
      this.popup.list = popup.list;
      this.popup.itemValue = popup.itemValue;
      this.popup.itemKey = popup.itemKey;
      this.popup.itemSetter = popup.itemSetter;
    },
    openPopup(popup) {
      this.setPopup(true, popup);
    },
    closePopup() {
      this.setPopup(false, {
        title: '',
        list: [],
        itemValue: '',
        itemKey: '',
        itemSetter: () => {},
      });
    },
    itemInput(itemSetter, itemValue) {
      this.closePopup();
      this.$execFunc(itemSetter, itemValue);
    },
    setCurrency(currency) {
      this.$store.dispatch('setCurrency', {
        currency: currency,
        then: () => this.$store.dispatch('storeCurrency', {}),
      });
    },
    setLanguage(language) {
      this.$store.dispatch('setLanguage', {
        language: language,
        then: () => {
          this.$i18n.locale = this.$store.getters.language;
          this.$store.dispatch('storeLanguage', {});
        },
      });
    },
  },
};
</script>

<style scoped>
.setting-list {
  padding: 0px !important;
  border: 1px solid #d5d5d5;
  background-color: #fcfdff;
}

.setting-list-item {
  padding: 0px 16px 0px 12px !important;
  min-height: 36px;
  max-height: 36px;
}

.setting-list-item.i-component {
}

.setting-list-item.i-popup {
  justify-content: flex-start;
  padding: 0px 16px 0px 12px !important;
  min-height: 36px;
  max-height: 36px;
}

.setting-list-item-popup-icon {
}

.setting-list-item-popup-title {
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: normal;
  text-transform: none;
}

/* dark mode */
#app.dark .setting-list {
  background-color: #383c52;
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';
import modules from './modules';

import config from '@/plugins/config';

try {
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');
  const dark = urlParams.get('dark');

  let language = config.languages[0].id;
  let darkMode = -1;

  if (config.languages.findIndex(lg => lg.id === lang) < 0) {
    language = '';
  } else {
    language = lang;
  }

  if (dark) {
    if (dark === '1') {
      darkMode = 1;
    } else if (dark === '0') {
      darkMode = 0;
    }
  }

  let storage;
  try {
    storage = window['localStorage'];
    let x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);

    if (language) {
      storage.setItem('language', language);
    }

    if (darkMode >= 0) {
      storage.setItem('darkMode', !!darkMode);
    }
  } catch (error) {
    /**/
  }
} catch (error) {
  /**/
}

Vue.use(Vuex);

const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: modules,
});

export default store;

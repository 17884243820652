import config from '@/plugins/config';
import BiFi from '@/plugins/bifi';
import BBN from '@/plugins/bifrostNumber';

const defaultNetwork =
  (config.allowedNetworks || []).length === 1
    ? config.allowedNetworks[0]
    : 'mainnet';
const contract = new BiFi(defaultNetwork, {
  type: 'infura',
  projectID: config.infura.projectID,
});

export default {
  circuitBreaker: false,
  oracleCircuitBreaker: false,
  contract,
  connectionCount: 0,
  darkMode: false,
  wallet: {
    id: '',
    type: '',
    network: defaultNetwork,
    address: '',
    transaction: {
      id: 0,
      hasCheck: true,
      list: [],
    },
    checked: false,
    metamask: {
      network: '',
    },
    btc: {
      challenge: false,
      outflowAmount: new BBN('0'),
      refund: {
        address: '',
        publicKeyHash: '',
      },
      transferIn: {
        address: '',
        publicKeyHash: '',
      },
      deposit: {
        address: '',
        publicKeyHash: '',
      },
      repay: {
        address: '',
        publicKeyHash: '',
      },
      fee: {
        inflow: {
          rate: new BBN('0'),
          min: new BBN('0'),
        },
        outflow: {
          rate: new BBN('0'),
          min: new BBN('0'),
        },
      },
    },
  },
  tokens: [],
  language: config.languages[0].id,
  currencyList: [],
  currency: 'usd',
  borrowData: {
    borrowLimit: new BBN('0'),
    marginCallLimit: new BBN('0'),
    totalDeposit: new BBN('0'),
    totalBorrow: new BBN('0'),
  },
  sync: {
    id: -1,
    autoRefresh: false,
    lastestSyncedAt: -1,
    minTerm: 1000, // 1 sec
    intervalTerm: 600000, // 10 mins
  },
  ui: {
    progress: {
      stack: 0,
    },
    snackbar: {
      id: 0,
      show: true,
      list: [],
    },
    alert: {
      show: false,
      type: '',
      message: '',
      messageArgs: undefined,
      link: undefined,
    },
    btc: {
      register: false,
      edit: false,
    },
    needWallet: {
      show: false,
    },
    walletPopup: {
      show: false,
    },
    walletConnector: {
      show: false,
    },
    welcome: {
      show: false,
    },
    popup: {
      show: false,
      message: '',
      isConfirm: false,
      left: false,
      right: false,
      confirmAction: undefined,
      confirmActionArgs: [],
    },
  },
};

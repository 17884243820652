import vue from 'vue';
import Web3 from 'web3';

import store from '@/store';

import ethereumJsAbi from '@/plugins/ethereumJsAbi';

// bifi contract util // metamask and extra wallets process to default
export const getProvider = walletID => {
  let result = undefined;

  if (walletID === 'biport') {
    result = window.biport;
  }

  if (!result && window.ethereum) {
    result = window.ethereum;

    if (result.providers && result.providers.length) {
      switch (walletID) {
        case 'biport':
          result = result.providers.find(p => p.isBiport);
          break;
        case 'coinbase':
          result = result.providers.find(p => p.isCoinbaseWallet);
          break;
        case 'metamask':
          result = result.providers.find(
            p => !(p.isBiport || p.isCoinbaseWallet)
          );
          break;
      }
    } else {
      switch (walletID) {
        case 'biport':
          result = (result.isBiport && result) || undefined;
          break;
        case 'coinbase':
          result = (result.isCoinbaseWallet && result) || undefined;
          break;
        case 'metamask':
          result =
            (!(result.isBiport || result.isCoinbaseWallet) && result) ||
            undefined;
          break;
      }
    }
  }

  return result;
};
export const getWeb3 = () => store.getters.contract.callRpc.web3;
export const isAddress = address =>
  address && typeof address === 'string' && Web3.utils.isAddress(address);
export const fromWei = (value, unit) => Web3.utils.fromWei(value, unit);
export const toWei = (value, unit) => Web3.utils.toWei(value, unit);
export const watchAsset = (
  address,
  symbol,
  decimals = 18,
  image = '',
  type = 'ERC20'
) =>
  new Promise(async resolve => {
    resolve(
      (
        await store.getters.contract.watchAsset(
          address,
          symbol,
          decimals,
          image,
          type
        )
      )[0]
    );
  });

// bifi contract call
export const getAddress = () =>
  new Promise(async resolve => {
    resolve((await store.getters.contract.callAccounts())[0]);
  });
export const getNetwork = () =>
  new Promise(async resolve => {
    resolve(await store.getters.contract.callNetwork());
  });
export const getTransactionReceipt = transactionHash =>
  new Promise(async resolve => {
    let result = undefined;

    if (!store.getters.isWalletInstability) {
      result = await store.getters.contract.callTransactionReceipt(
        transactionHash
      );
    }

    resolve(result);
  });
export const getBalance = address =>
  new Promise(async resolve => {
    let result = '0';

    if (address && isAddress(address)) {
      result = await store.getters.contract.callBalance(address);
    }

    resolve(result);
  });
export const getErc20Balance = (fromAddress, tokenAddress) =>
  new Promise(async resolve => {
    let result = '0';

    if (
      fromAddress &&
      tokenAddress &&
      isAddress(fromAddress) &&
      isAddress(tokenAddress)
    ) {
      result = await store.getters.contract.callErc20Balance(
        tokenAddress,
        fromAddress
      );
    }

    resolve(result);
  });
export const getErc20Allowance = (
  fromAddress,
  tokenAddress,
  tokenSendAddress
) =>
  new Promise(async resolve => {
    let result = '0';

    if (
      fromAddress &&
      tokenAddress &&
      tokenSendAddress &&
      isAddress(fromAddress) &&
      isAddress(tokenAddress) &&
      isAddress(tokenSendAddress)
    ) {
      result = await store.getters.contract.callErc20Allowance(
        tokenAddress,
        fromAddress,
        tokenSendAddress
      );
    }

    resolve(result);
  });
export const getErc20Decimals = tokenAddress =>
  new Promise(async resolve => {
    let result = '0';

    if (!store.getters.isWalletInstability) {
      result = await store.getters.contract.callErc20Decimals(tokenAddress);
    }

    resolve(result);
  });
export const getCircuitBreaker = () =>
  new Promise(async resolve => {
    let result = false;

    if (!store.getters.isWalletInstability) {
      result = await store.getters.contract.call('bifi.circuitBreak');
    }

    resolve(result);
  });
export const getOracleCircuitBreaker = () =>
  new Promise(async resolve => {
    let result = false;

    if (!store.getters.isWalletInstability) {
      result = await store.getters.contract.call('bifi.getOraclePeriodDelta');
    }

    resolve(result);
  });
export const getTokenList = userAddress =>
  new Promise(async resolve => {
    let result = [];

    if (!store.getters.isWalletInstability) {
      result = await store.getters.contract.call(
        'tokenList',
        userAddress || ''
      );
    }

    resolve(result);
  });

// send
export const approveErc20 = ({
  from,
  to,
  numTokens = '0x0',
  tokenSendAddress,
}) => {
  return sendTransaction({
    from,
    to,
    signature: 'approve(address,uint)',
    args: [tokenSendAddress, numTokens],
  });
};
export const sendTransaction = ({
  from,
  to,
  signature,
  abi,
  args = [],
  value = '0x0',
  gas,
  gasPrice,
  noGas = false,
}) =>
  new Promise(async (resolve, reject) => {
    const provider = getProvider(store.getters.walletID);

    if (provider) {
      try {
        if (!isAddress(from)) {
          reject({
            message:
              'The selected wallet address is invalid. Please check wallet.',
            locale: 'common.message.transaction.error.wrong.fromAddress',
          });
          return;
        }
        if (!isAddress(to)) {
          reject({
            message: 'The address you are trying to Send is invalid.',
            locale: 'common.message.transaction.error.wrong.toAddress',
            localeArgs: {
              action: 'Send',
            },
          });
          return;
        }
        if (!(signature || abi)) {
          reject({
            message: 'Invalid method for trying to Send for Transaction.',
            locale: 'common.message.transaction.error.wrong.method',
            localeArgs: {
              action: 'Send',
            },
          });
          return;
        }

        const web3 = getWeb3();

        let data;
        if (signature) {
          data = web3.utils.toHex(
            ethereumJsAbi.simpleEncode(signature, ...args)
          );
        } else if (abi) {
          data = web3.eth.abi.encodeFunctionCall(abi, args);
        }

        if (!(gas || noGas)) {
          const estimateGasBody = {
            from,
            to,
            value,
            data,
          };
          const estimateGas = await web3.eth.estimateGas(estimateGasBody);
          gas = web3.utils.toHex(Math.floor(parseFloat(estimateGas * 1.1)));
        }

        const sendObject = {
          method: 'eth_sendTransaction',
          params: [{ from, to, value, data, gas, gasPrice }],
          from,
        };
        const transactionHash = await provider.request(sendObject);

        resolve(transactionHash);
      } catch (error) {
        reject(error);
      }
    } else {
      reject({
        message: 'Metamask not found.',
        locale: 'common.message.wallet.error.cant.find.metamask',
      });
    }
  });
export const callSendTransaction = ({
  from,
  to,
  signature,
  abi,
  args = [],
  value = '0x0',
  gas,
  gasPrice,
  noGas = false,
}) =>
  new Promise(async (resolve, reject) => {
    const provider = getProvider(store.getters.walletID);

    if (provider) {
      try {
        if (!isAddress(from)) {
          reject({
            message:
              'The selected wallet address is invalid. Please check wallet.',
            locale: 'common.message.transaction.error.wrong.fromAddress',
          });
          return;
        }
        if (!isAddress(to)) {
          reject({
            message: 'The address you are trying to Send is invalid.',
            locale: 'common.message.transaction.error.wrong.toAddress',
            localeArgs: {
              action: 'Send',
            },
          });
          return;
        }
        if (!(signature || abi)) {
          reject({
            message: 'Invalid method for trying to Send for Transaction.',
            locale: 'common.message.transaction.error.wrong.method',
            localeArgs: {
              action: 'Send',
            },
          });
          return;
        }

        const web3 = getWeb3();

        let data;
        if (signature) {
          data = web3.utils.toHex(
            ethereumJsAbi.simpleEncode(signature, ...args)
          );
        } else if (abi) {
          data = web3.eth.abi.encodeFunctionCall(abi, args);
        }

        if (!(gas || noGas)) {
          const estimateGasBody = {
            from,
            to,
            value,
            data,
          };
          const estimateGas = await web3.eth.estimateGas(estimateGasBody);
          gas = web3.utils.toHex(Math.floor(parseFloat(estimateGas * 1.1)));
        }

        const sendObject = {
          method: 'eth_call',
          params: [{ from, to, value, data, gas, gasPrice }],
          from,
        };
        const transactionHash = await provider.request(sendObject);

        resolve(transactionHash);
      } catch (error) {
        reject(error);
      }
    } else {
      reject({
        message: 'Metamask not found.',
        locale: 'common.message.wallet.error.cant.find.metamask',
      });
    }
  });

vue.prototype.$getProvider = getProvider;
vue.prototype.$getWeb3 = getWeb3;
vue.prototype.$isAddress = isAddress;
vue.prototype.$fromWei = fromWei;
vue.prototype.$toWei = toWei;
vue.prototype.$watchAsset = watchAsset;
vue.prototype.$getAddress = getAddress;
vue.prototype.$getNetwork = getNetwork;
vue.prototype.$getTransactionReceipt = getTransactionReceipt;
vue.prototype.$getBalance = getBalance;
vue.prototype.$getErc20Balance = getErc20Balance;
vue.prototype.$getErc20Allowance = getErc20Allowance;
vue.prototype.$getErc20Decimals = getErc20Decimals;
vue.prototype.$getCircuitBreaker = getCircuitBreaker;
vue.prototype.$getOracleCircuitBreaker = getOracleCircuitBreaker;
vue.prototype.$getTokenList = getTokenList;
vue.prototype.$approveErc20 = approveErc20;
vue.prototype.$sendTransaction = sendTransaction;
vue.prototype.$callSendTransaction = callSendTransaction;
vue.prototype.$Web3 = Web3;

export default Web3;

<template>
  <div class="withdraw-message">
    <div class="withdraw-message-text" v-text="message" />
  </div>
</template>

<script>
export default {
  name: 'WithdrawMessage',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.withdraw-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 20px;
}

.withdraw-message-text {
  font-size: 14px;
  color: #00000080;
}

/* dark mode */

#app.dark .withdraw-message-text {
  color: #ffffff80;
}
</style>

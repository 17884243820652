<template>
  <v-tooltip
    class="navigation-tooltip"
    bottom
    transition="slide-y-transition"
    :disabled="!tooltip"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="navigation-wrapper" v-bind="attrs" v-on="on">
        <v-menu
          content-class="navigation-munu"
          tile
          offset-y
          open-on-hover
          :disabled="disabled"
          v-if="menu"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <router-link
                class="navigation transition-all"
                :class="{
                  disabled: disabled,
                }"
                :to="disabled ? '' : to"
              >
                <v-icon
                  class="navigation-icon prepend"
                  v-text="`mdi-${prependIcon}`"
                  v-if="prependIcon"
                />
                <div class="navigation-title" v-text="title" />
                <v-icon
                  class="navigation-icon append"
                  v-text="`mdi-${appendIcon}`"
                  v-if="appendIcon"
                />
              </router-link>
            </div>
          </template>
          <component :is="menu" />
        </v-menu>
        <router-link
          class="navigation transition-all"
          :class="{
            disabled: disabled,
          }"
          :to="disabled ? '' : to"
          v-else-if="to"
        >
          <v-icon
            class="navigation-icon prepend"
            v-text="`mdi-${prependIcon}`"
            v-if="prependIcon"
          />
          <div class="navigation-title" v-text="title" />
          <v-icon
            class="navigation-icon append"
            v-text="`mdi-${appendIcon}`"
            v-if="appendIcon"
          />
        </router-link>
        <a
          class="navigation transition-all"
          :class="{
            disabled: disabled,
          }"
          rel="noopener"
          target="_blank"
          :href="disabled ? '' : href"
          v-else-if="href"
        >
          <v-icon
            class="navigation-icon prepend"
            v-text="`mdi-${prependIcon}`"
            v-if="prependIcon"
          />
          <div class="navigation-title" v-text="title" />
          <v-icon
            class="navigation-icon append"
            v-text="`mdi-${appendIcon}`"
            v-if="appendIcon"
          />
        </a>
        <a class="navigation disabled transition-all" v-else>
          <v-icon
            class="navigation-icon prepend"
            v-text="`mdi-${prependIcon}`"
            v-if="prependIcon"
          />
          <div class="navigation-title" v-text="title" />
          <v-icon
            class="navigation-icon append"
            v-text="`mdi-${appendIcon}`"
            v-if="appendIcon"
          />
        </a>
      </div>
    </template>
    <span class="navigation-tooltip-message" v-text="$t(tooltip)" />
  </v-tooltip>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    menu: Object,
    to: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.navigation-tooltip {
}

.navigation-munu {
  box-shadow: none !important;
}

.navigation-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  font-size: 18px;
  font-weight: bold;
  color: #1f7bff;
  opacity: 0.35;
}

.navigation:not(.router-link-exact-active):not(.disabled):hover {
  opacity: 0.7;
}

.navigation:not(.router-link-exact-active):not(.disabled):active {
  opacity: 0.2;
}

.navigation.router-link-active,
.navigation[aria-expanded='true'] {
  opacity: 1;
}

.navigation.router-link-exact-active {
  cursor: default;
}

.navigation.disabled {
  display: none;
  opacity: 0.1;
  cursor: default;
}

.navigation-tooltip-message {
}

.navigation-icon {
  color: #1f7bff !important;
  font-size: 20px !important;
}
.navigation-icon.prepend {
  margin-right: 6px;
}
.navigation-icon.append {
  margin-left: 6px;
}

/* dark mode */
#app.dark .navigation,
#app.dark .navigation-icon {
  color: #fff !important;
}

/* mobile */
#app.mobile .navigation {
  margin: 3px 30px;
  padding: 7px 30px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
</style>

import func from '@/plugins/function';

export default {
  setCircuitBreaker({ commit }, { circuitBreaker, then, err, final }) {
    try {
      commit('setCircuitBreaker', { circuitBreaker, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  setOracleCircuitBreaker({ commit }, { circuitBreaker, then, err, final }) {
    try {
      commit('setOracleCircuitBreaker', { circuitBreaker, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

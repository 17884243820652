<template>
  <v-layout class="card-outter-title" justify-start align-center>
    <slot />
  </v-layout>
</template>

<script>
export default {
  name: 'CardOutterTitle',
};
</script>

<style scoped>
.card-outter-title {
  font-size: 19px;
  font-weight: 500;
  color: #000000d9;
}

/* dark mode */

#app.dark .card-outter-title {
  color: #ffffffd9;
}

/* mobile */

#app.mobile .card-outter-title {
  padding-bottom: 4px;
  font-size: 12px;
}
</style>

<template>
  <div class="pool">
    <router-view ref="dexContent" />
  </div>
</template>

<script>
export default {
  name: 'Pool',
  methods: {
    syncData() {
      if (this.$store.getters.isWalletInstability || !this.$refs.dexContent) {
        return;
      }

      this.$execFunc(this.$refs.dexContent.syncData);
    },
  },
};
</script>

<style scoped>
.pool {
  color: #000;
}

/* dark mode */

#app.dark .pool {
  color: #fff;
}
</style>

<template>
  <div class="token-power-setting description">
    <span
      v-text="
        $t('trade.content.information.tokenPower.currentAllowance', {
          value: allowance,
        })
      "
    />
  </div>
</template>

<script>
export default {
  name: 'TokenPowerSetting',
  props: {
    tokenAllowance: {
      type: Object,
    },
    tokenReplaceString: {
      type: String,
      default: '',
    },
    tokenID: {
      type: String,
      default: '',
    },
  },
  computed: {
    allowance() {
      return this.$curr.getBalanceFormatedTokenValue(
        this.tokenAllowance,
        this.tokenID,
        this.tokenID
      );
    },
  },
  methods: {
    nextSlide() {
      this.$emit('nextSlide');
    },
  },
};
</script>

<style scoped>
.token-power-setting {
  display: flex;
  align-items: center;
  height: 100%;
}

/* mobile */

#app.mobile .token-power-setting {
  padding: 12px;
}
</style>

<template>
  <div class="complete">
    <v-icon class="complete-icon">mdi-check-circle</v-icon>
    <div
      class="complete-description description"
      v-html="$t('trade.btc.complete')"
    />
  </div>
</template>

<script>
export default {
  name: 'Complete',
};
</script>

<style scoped>
.complete {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 100%;
}
.complete-icon {
  font-size: 120px;
}
.complete-description {
  padding-top: 30px;
  text-align: center;
}
</style>

<template>
  <div class="x-bifi-button-wraper">
    <v-btn
      class="x-bifi-button transition-all"
      color="#3299bc"
      elevation="0"
      rel="noopener"
      target="_blank"
      :href="$config.link.xbifi"
    >
      <div class="x-bifi-button-title">BiFi X</div>
      <v-icon class="x-bifi-button-icon">mdi-open-in-new</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'XBifi',
};
</script>

<style scoped>
.x-bifi-button-wraper {
}

.x-bifi-button {
  position: relative;
  min-height: 40px;
  padding-left: 12px !important;
  padding-right: 12px !important;
  overflow: hidden;
}

.x-bifi-button-title {
  padding-right: 8px;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}
.x-bifi-button-icon {
  font-size: 18px !important;
}

/* mobile */
#app.mobile .x-bifi-button {
  height: 26px;
  min-height: 26px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 3px;
}
#app.mobile .x-bifi-button-title {
  font-size: 12px;
}
</style>

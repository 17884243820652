<template>
  <v-dialog
    content-class="register-token-popup"
    width="460"
    max-width="1000"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-card tile>
      <v-layout class="popup-content" column>
        <v-layout class="popup-header" justify-space-between align-center>
          <div
            class="popup-header-title"
            v-text="$t('footer.registerToken.popup.title')"
          />
          <v-btn
            class="popup-close-button"
            icon
            :dark="$store.getters.darkMode"
            @click="$emit('input', false)"
          >
            <v-icon class="popup-close-button-icon">mdi-close</v-icon>
          </v-btn>
        </v-layout>
        <div class="popup-body">
          <div
            class="popup-body-title"
            v-text="$t('footer.registerToken.popup.description')"
          />
          <table class="popup-body-table">
            <colgroup>
              <col />
              <col width="100%" />
              <col />
            </colgroup>
            <tbody>
              <template v-if="tokens.length > 0">
                <tr v-for="(token, tokenIndex) in tokens" :key="tokenIndex">
                  <td>
                    <AvatarIconSet
                      :size="40"
                      host=""
                      prependPath=""
                      :iconSet="[{ image: token.image }]"
                    />
                  </td>
                  <td>
                    <div class="token-title" v-text="token.symbol" />
                  </td>
                  <td>
                    <v-btn
                      class="token-add-button"
                      color="#1f7bff"
                      text
                      :dark="$store.getters.darkMode"
                      :disabled="disabled"
                      @click="registerToken(token)"
                    >
                      <span
                        class="token-add-button-title"
                        v-text="$t('footer.registerToken.popup.button')"
                      />
                    </v-btn>
                  </td>
                </tr>
              </template>
              <tr v-else>
                <td colspan="3">No token</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

export default {
  name: 'Popup',
  components: {
    AvatarIconSet,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tokens() {
      return this.$store.getters.tokens
        .filter(
          token =>
            token.address &&
            this.$isAddress(token.address) &&
            !this.$getIsDisabledToken(token.id) &&
            this.$getIsToken(token.id, this.$store.getters.walletNetwork) &&
            token.id !== 'btc'
        )
        .map(token => {
          const { title, symbol, address, decimals, image } =
            this.$curr.getToken(token.id) || {};

          let tokenTitle = title;
          let tokenSymbol = (symbol || '').slice(0, 6);

          if (tokenTitle === 'BiFi' && this.$store.getters.isBsc) {
            tokenTitle = 'BiFiB';
          }
          if (tokenSymbol === 'BiFi' && this.$store.getters.isBsc) {
            tokenSymbol = 'BiFiB';
          }

          return {
            title: tokenTitle,
            symbol: tokenSymbol,
            address: address || '',
            decimals: (decimals || new this.$BBN('18')).toNum() || 18,
            image: (image && `${this.$config.host.image}icon/${image}`) || '',
          };
        });
    },
  },
  methods: {
    registerToken(token) {
      this.$watchAsset(
        token.address,
        token.symbol,
        token.decimals,
        token.image
      );
    },
  },
};
</script>

<style scoped>
.register-token-popup {
}

.popup-content {
  background-color: #fff;
  color: #000000d9;
}

.popup-header {
  width: 100%;
  height: 60px;
  padding: 18px;
  border-bottom: solid 1px #d5d5d5;
}

.popup-header-title {
  font-size: 16px;
  font-weight: bold;
}

.popup-close-button {
  margin-right: -8px;
}

.popup-close-button-icon {
}

.popup-body {
  padding: 20px;
}

.popup-body-table {
  width: 100%;
}

.popup-body-table td {
  padding: 10px;
}

.popup-body-title {
  padding-bottom: 20px;
  font-size: 12px;
}

.token-title {
  font-size: 18px;
}

.token-add-button {
  min-width: unset !important;
  max-width: unset !important;
  margin-right: -16px;
}

.token-add-button-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: none;
}

/* dark mode */

#app.dark .popup-content {
  background-color: #383c52;
  color: #ffffffd9;
}

/* mobile */

#app.mobile .popup-body {
  padding: 16px;
}

#app.mobile .popup-body-table td {
  padding: 4px 8px;
}

#app.mobile .popup-body-title {
  padding-bottom: 16px;
}

#app.mobile .token-title {
  font-size: 13px;
}

#app.mobile .token-add-button {
  padding: 0px 12px;
}

#app.mobile .token-add-button-title {
  font-size: 12px;
}
</style>

<style>
.register-token-popup {
  border-radius: 0px !important;
  box-shadow: 0 3px 6px 0 #00000029;
}

/* mobile */

#app.mobile .register-token-popup {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register description"},[_c('div',{staticClass:"register-content"},[_c('div',{staticClass:"register-header",domProps:{"innerHTML":_vm._s(_vm.$t('trade.content.information.description.btcAddress'))}}),_c('div',{staticClass:"register-address-wrapper"},[_c('div',{staticClass:"register-address deposit"},[_c('div',{staticClass:"register-address-title",domProps:{"textContent":_vm._s(_vm.$t('trade.content.information.addressToDeposit'))}}),_c('div',{staticClass:"register-address-value",domProps:{"textContent":_vm._s(
            (_vm.depositAddress &&
              ((_vm.depositAddress.slice(0, 8)) + "..." + (_vm.depositAddress.slice(
                -8
              )))) ||
              'No Address'
          )}}),_c('div',{staticClass:"register-address-desciption",domProps:{"innerHTML":_vm._s(
            _vm.$t('trade.content.information.description.btcAddressToDeposit')
          )}})]),_c('div',{staticClass:"register-address repay"},[_c('div',{staticClass:"register-address-title",domProps:{"textContent":_vm._s(_vm.$t('trade.content.information.addressToRepay'))}}),_c('div',{staticClass:"register-address-value",domProps:{"textContent":_vm._s(
            (_vm.repayAddress &&
              ((_vm.repayAddress.slice(0, 8)) + "..." + (_vm.repayAddress.slice(-8)))) ||
              'No Address'
          )}}),_c('div',{staticClass:"register-address-desciption",domProps:{"innerHTML":_vm._s(
            _vm.$t('trade.content.information.description.btcAddressToRepay')
          )}})])])]),_c('v-divider'),_c('div',{staticClass:"register-input-wrapper"},[_c('div',{staticClass:"register-input-title",domProps:{"textContent":_vm._s(_vm.$t('trade.content.information.btcAddressToReceiveTransfer'))}}),_c('v-text-field',{staticClass:"register-input",attrs:{"filled":"","dense":"","placeholder":_vm.$t('trade.content.placeholder.btcAddressToRegister'),"rules":_vm.rulesAddress,"value":_vm.address || ''},on:{"input":_vm.input}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
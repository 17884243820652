import Vue from 'vue';

try {
  if (
    window.heap &&
    typeof window.heap === 'object' &&
    process.env.VUE_APP_HEAP_ID
  ) {
    window.heap.load(process.env.VUE_APP_HEAP_ID);

    Vue.prototype.$heap = window.heap;
  }
} catch (error) {
  /**/
}

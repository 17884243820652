import func from '@/plugins/function';

export default {
  setProgress(state, { stack, then }) {
    state.ui.progress.stack = Math.max(stack, 0);

    func.execFunc(then);
  },
  setAlert(state, { show, message, messageArgs, type, link, then }) {
    state.ui.alert.show = show || false;
    state.ui.alert.type = type || 'info';
    state.ui.alert.message = message || '';
    state.ui.alert.messageArgs = messageArgs || undefined;
    state.ui.alert.link = link || undefined;

    func.execFunc(then);
  },
  setSnackbarShow(state, { show, then }) {
    state.ui.snackbar.show = show;

    func.execFunc(then);
  },
  addSnackbar(
    state,
    {
      message,
      multiLine,
      vertical,
      mode,
      color,
      buttonMessage,
      buttonAction,
      then,
    }
  ) {
    state.ui.snackbar.list.push({
      id: ++state.ui.snackbar.id,
      multiLine: multiLine || false,
      vertical: vertical || false,
      mode: mode || '',
      color: color || 'info',
      message: message,
      buttonMessage: buttonMessage,
      buttonAction: buttonAction,
    });

    func.execFunc(then);
  },
  removeSnackbar(state, { id, then }) {
    let index = state.ui.snackbar.list.findIndex(
      snackbar => snackbar.id === id
    );

    if ((index >= 0, index < state.ui.snackbar.list.length)) {
      state.ui.snackbar.list.splice(index, 1);
    }

    func.execFunc(then);
  },
  setBtcRegister(state, { show, then }) {
    state.ui.btc.register = show;

    func.execFunc(then);
  },
  setBtcEdit(state, { show, then }) {
    state.ui.btc.edit = show;

    func.execFunc(then);
  },
  setNeedWallet(state, { show, then }) {
    state.ui.needWallet.show = show;

    func.execFunc(then);
  },
  setWalletPopup(state, { show, then }) {
    state.ui.walletPopup.show = show;

    func.execFunc(then);
  },
  setWalletConnector(state, { show, then }) {
    state.ui.walletConnector.show = show;

    func.execFunc(then);
  },
  setWelcomePopup(state, { show, then }) {
    state.ui.welcome.show = show;

    func.execFunc(then);
  },
  setPopup(
    state,
    {
      show,
      message,
      isConfirm,
      left,
      right,
      confirmAction,
      confirmActionArgs,
      then,
    }
  ) {
    state.ui.popup.show = show;
    state.ui.popup.message = message;
    state.ui.popup.isConfirm = isConfirm;
    state.ui.popup.left = left;
    state.ui.popup.right = right;
    state.ui.popup.confirmAction = confirmAction;
    state.ui.popup.confirmActionArgs = confirmActionArgs;

    func.execFunc(then);
  },
};

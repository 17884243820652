<template>
  <v-dialog
    content-class="wallet-connector"
    width="460"
    v-model="$store.state.ui.walletConnector.show"
  >
    <v-layout class="popup-content" column>
      <v-layout class="popup-header" row justify-space-between align-center>
        <div>
          <span
            class="popup-header-title"
            v-t="'header.wallet.connector.title'"
          />
        </div>
        <v-btn
          class="popup-close mr-n2"
          :dark="$store.getters.darkMode"
          icon
          @click="close"
        >
          <v-icon class="popup-close-icon">mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <v-layout class="popup-wallet" column>
        <v-btn
          class="popup-wallet-button"
          color="#1f7bff"
          height="48"
          dark
          :disabled="$store.getters.isWalletCoinbase"
          @click="connect('coinbase')"
        >
          <v-avatar
            class="popup-wallet-button-avatar"
            size="40"
            color="#0000001a"
          >
            <v-img
              class="popup-wallet-button-image"
              width="36"
              height="36"
              contain
              :src="`${$config.host.image}icon/coinbase.svg`"
            />
          </v-avatar>
          <span class="popup-wallet-button-title">Coinbase Wallet</span>
        </v-btn>
        <v-btn
          class="popup-wallet-button"
          color="#1f7bff"
          height="48"
          dark
          :disabled="$store.getters.isWalletBiport"
          @click="connect('biport')"
        >
          <v-avatar
            class="popup-wallet-button-avatar"
            size="40"
            color="#0000001a"
          >
            <v-img
              class="popup-wallet-button-image"
              width="36"
              height="36"
              contain
              :src="`${$config.host.image}icon/biport.png`"
            />
          </v-avatar>
          <span class="popup-wallet-button-title">Biport</span>
        </v-btn>
        <v-btn
          class="popup-wallet-button"
          color="#1f7bff"
          height="48"
          dark
          :disabled="$store.getters.isWalletMetamask"
          @click="connect('metamask')"
        >
          <v-avatar
            class="popup-wallet-button-avatar"
            size="40"
            color="#0000001a"
          >
            <v-img
              class="popup-wallet-button-image"
              width="36"
              height="36"
              contain
              :src="`${$config.host.image}icon/metamask.svg`"
            />
          </v-avatar>
          <span class="popup-wallet-button-title">MetaMask</span>
        </v-btn>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: 'WalletConnector',
  methods: {
    connect(walletID) {
      this.$store.dispatch('syncProvider', { walletID });
      this.close();
    },
    close() {
      this.$store.dispatch('closeWalletConnector', {});
    },
  },
};
</script>

<style scoped>
.popup-content {
  background-color: #fff;
}

.popup-header,
.popup-wallet {
  margin: 0px;
  padding: 20px;
}

.popup-header {
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #d5d5d5;
}
.popup-header-title {
  font-size: 16px;
  font-weight: bold;
  color: #000000d9;
}

.popup-close {
}
.popup-close-icon {
}

.popup-wallet {
  margin: -10px 0px;
}
.popup-wallet-button {
  width: 100%;
  margin: 10px 0px;
}
.popup-wallet-button-image {
  flex-grow: 0;
}
.popup-wallet-button-title {
  min-width: 150px;
  padding-left: 8px;
  font-size: 16px;
  letter-spacing: 0;
  text-transform: none;
}

/* dark mode */
#app.dark .popup-content {
  background-color: #383c52;
}
#app.dark .popup-header-title {
  color: #ffffffd9;
}

/* mobile */
#app.mobile .popup-wallet {
  margin: -10px 0px;
  flex-direction: column;
}
#app.mobile .popup-wallet-button {
  width: 100%;
  margin: 10px 0px;
}
</style>

<style>
.wallet-connector {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 #00000029;
}

/* mobile */
#app.mobile .wallet-connector {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

<template>
  <Card class="overview" :class="{ multiple: multiple }" :style="style">
    <div slot="body">
      <template v-if="multiple">
        <template v-for="(data, dataIndex) in multipleData">
          <v-layout
            class="bifi-content-wrapper transition-all"
            :class="{ disabled: data.total.lte('0') }"
            justify-space-between
            align-center
            :key="dataIndex"
            v-if="
              typeof data === 'object' &&
                data.total &&
                data.total instanceof $BBN
            "
          >
            <div
              class="bifi-title"
              :style="{ color: data.color }"
              v-text="data.title"
            />
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="data.total.lte('0')"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-layout
                  class="bifi-total"
                  :style="{
                    'transform-origin': '100% calc(100% - 8px)',
                    transform: `scale(${totalScale})`,
                    color: data.color,
                  }"
                  justify-end
                  align-end
                  v-bind="attrs"
                  v-on="on"
                >
                  <div
                    class="value"
                    v-text="
                      $curr.getWordFormatedValue(
                        data.total,
                        $i18n.locale,
                        replaceString
                      )
                    "
                  />
                </v-layout>
              </template>
              <span
                v-text="replaceString.replace('{}', data.total.toStr())"
                v-if="data.total.gt('0')"
              />
            </v-tooltip>
          </v-layout>
        </template>
      </template>
      <v-layout
        class="bifi-content-wrapper transition-all"
        :class="{ disabled: !hasTotal }"
        justify-space-between
        align-center
        v-else
      >
        <div class="bifi-title" v-text="title" />
        <v-tooltip bottom transition="slide-y-transition" :disabled="!hasTotal">
          <template v-slot:activator="{ on, attrs }">
            <v-layout
              class="bifi-total"
              :style="{
                'transform-origin': '100% calc(100% - 8px)',
                transform: `scale(${totalScale})`,
              }"
              justify-end
              align-end
              v-bind="attrs"
              v-on="on"
            >
              <div class="unit prefix" v-text="prefix" />
              <div class="value" v-text="totalInteger" />
              <div class="value decimal" v-text="totalDecimal" />
              <div class="abbreviation" v-text="totalAbbreviation" />
              <div class="unit suffix" v-text="suffix" />
            </v-layout>
          </template>
          <span v-text="formatedTotalDetail" v-if="hasTotal" />
        </v-tooltip>
      </v-layout>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/card/Card';

export default {
  name: 'Overview',
  components: {
    Card,
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    total: {
      type: Object,
    },
    multipleData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasTotal() {
      let result = false;

      if (this.total && this.total instanceof this.$BBN) {
        result = this.total.gt('0');
      }

      return result;
    },
    formatedTotalString() {
      return this.hasTotal
        ? this.$curr.getWordFormatedValue(this.total, this.$i18n.locale) || '0'
        : '0';
    },
    totalSpaceIndex() {
      return this.formatedTotalString.lastIndexOf(' ');
    },
    totalDotIndex() {
      return this.formatedTotalString.indexOf('.');
    },
    totalValueString() {
      return this.totalSpaceIndex >= 0
        ? this.formatedTotalString.slice(0, this.totalSpaceIndex)
        : this.formatedTotalString;
    },
    totalAbbreviation() {
      return this.totalSpaceIndex >= 0
        ? this.formatedTotalString.slice(this.totalSpaceIndex)
        : '';
    },
    totalInteger() {
      return this.totalDotIndex >= 0
        ? this.totalValueString.slice(0, this.totalDotIndex)
        : this.totalValueString;
    },
    totalDecimal() {
      return this.totalDotIndex >= 0
        ? this.totalValueString.slice(this.totalDotIndex)
        : '';
    },
    formatedTotalDetail() {
      return `${this.prefix}${
        this.hasTotal ? this.$formatCommas(this.total) : '0'
      }${this.suffix}`;
    },
    totalScale() {
      let result = 1;

      if (this.formatedTotalString.length > 14) {
        result = Math.max(
          1 - (this.formatedTotalString.length - 15) * Math.sin(1 / 25),
          0.3
        );
      }

      return result;
    },
    replaceString() {
      return this.$store.getters.replaceString;
    },
    prefix() {
      return this.$store.getters.unitPrefix;
    },
    suffix() {
      return this.$store.getters.unitSuffix;
    },
    style() {
      const result = {};

      if (this.color) {
        result['background-color'] = this.color;
      }

      return result;
    },
  },
};
</script>

<style scoped>
.overview {
  padding: 15px 24px;
}

.overview.multiple {
  padding: 7px 12px;
}

.bifi-content-wrapper {
  padding: 5px 0px;
}

.bifi-content-wrapper.disabled {
  opacity: 0.25;
}

.bifi-title {
  min-width: 130px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}

.bifi-total {
  font-size: 32px;
  font-weight: 500;
  color: #fff;
}

.bifi-total .value {
}

.bifi-total .value.decimal {
  opacity: 0.5;
}

.bifi-total .unit {
  margin-bottom: 6px;
  font-size: 18px;
}

.bifi-total .unit.prefix {
  margin-right: 4px;
}

.bifi-total .unit.suffix {
  margin-left: 4px;
}

.bifi-total .abbreviation {
  margin-left: 4px;
}

/* mobile */

#app.mobile .bifi-title {
  min-width: 80px;
  font-size: 12px;
  font-weight: normal;
}

#app.mobile .bifi-total {
  font-size: 18px;
  font-weight: bold;
}
</style>

<template>
  <div class="register description">
    <div class="register-content">
      <div
        class="register-header"
        v-html="$t('trade.content.information.description.btcAddress')"
      />
      <div class="register-address-wrapper">
        <div class="register-address deposit">
          <div
            class="register-address-title"
            v-text="$t('trade.content.information.addressToDeposit')"
          />
          <div
            class="register-address-value"
            v-text="
              (depositAddress &&
                `${depositAddress.slice(0, 8)}...${depositAddress.slice(
                  -8
                )}`) ||
                'No Address'
            "
          />
          <div
            class="register-address-desciption"
            v-html="
              $t('trade.content.information.description.btcAddressToDeposit')
            "
          />
        </div>
        <div class="register-address repay">
          <div
            class="register-address-title"
            v-text="$t('trade.content.information.addressToRepay')"
          />
          <div
            class="register-address-value"
            v-text="
              (repayAddress &&
                `${repayAddress.slice(0, 8)}...${repayAddress.slice(-8)}`) ||
                'No Address'
            "
          />
          <div
            class="register-address-desciption"
            v-html="
              $t('trade.content.information.description.btcAddressToRepay')
            "
          />
        </div>
      </div>
    </div>
    <v-divider />
    <div class="register-input-wrapper">
      <div
        class="register-input-title"
        v-text="$t('trade.content.information.btcAddressToReceiveTransfer')"
      />
      <v-text-field
        class="register-input"
        filled
        dense
        :placeholder="$t('trade.content.placeholder.btcAddressToRegister')"
        :rules="rulesAddress"
        :value="address || ''"
        @input="input"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Register',
  props: {
    address: String,
    rulesAddress: {
      type: Array,
      default: () => [],
    },
    depositAddress: String,
    repayAddress: String,
  },
  methods: {
    input(value) {
      this.$emit('setAddress', value);
    },
  },
};
</script>

<style scoped>
.register {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.register-content,
.register-input-wrapper {
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-grow: 1;
  padding: 20px 40px;
}

.register-content {
}
.register-header {
  padding-bottom: 30px;
}
.register-address-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0px -30px;
}
.register-address {
  width: 50%;
  margin: -5px 0px;
  padding: 0px 30px;
}
.register-address-title,
.register-address-value,
.register-address-desciption {
  padding-top: 5px;
  padding-bottom: 5px;
}
.register-address-title {
}
.register-address-value {
}
.register-address-desciption {
  font-size: 12px;
  opacity: 0.25;
}

.register-input-wrapper {
}
.register-input-title {
  padding-bottom: 10px;
}
.register-input {
  flex-grow: 0;
  font-size: 14px;
}

/* mobile */
#app.mobile .register-content,
#app.mobile .register-input-wrapper {
  padding: 12px;
}
#app.mobile .register-address-title,
#app.mobile .register-address-desciption {
  text-align: left;
}
#app.mobile .register-address-value,
#app.mobile .register-address-desciption,
#app.mobile .register-input {
  font-size: 10px;
}
#app.mobile .register-address-wrapper {
  flex-direction: column;
  margin: -12px 0px;
}
#app.mobile .register-address {
  width: 100%;
  padding: 12px 0px;
}
#app.mobile .register-address-value {
  padding: 0px;
}
</style>

import 'es6-promise/auto';
import 'babel-polyfill';

import Vue from 'vue';

import '@/plugins/heap';

import '@/plugins/config';
import '@/plugins/function';
import '@/plugins/btcUtils';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import vuetify from '@/plugins/vuetify';

import '@/plugins/vueGtag';
import '@/plugins/vueHeadful';
import '@/plugins/axios';
import '@/plugins/ethereumJsAbi';
import '@/plugins/vueClipboard';
import '@/plugins/moment';
import '@/plugins/bifrostNumber';
import '@/plugins/bifiCurrency';
import '@/plugins/bifi';
import '@/plugins/btcWallet';
import '@/plugins/relayer';

import '@/plugins/notice';

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  created() {
    store.dispatch('initializeCurrencyList', {
      then: () =>
        store.dispatch('initializeCurrency', {
          then: () =>
            store.dispatch('syncLocalStorage', {
              then: () => store.dispatch('connect', {}),
            }),
        }),
    });
  },
  render: (h) => h(App),
}).$mount('#app');

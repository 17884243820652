<template>
  <v-footer class="footer">
    <v-layout
      class="footer-content container"
      row
      justify-space-between
      align-center
    >
      <div class="footer-button-wrapper">
        <v-tooltip
          top
          z-index="99999"
          transition="slide-y-reverse-transition"
          nudge-top="15"
          :value="$store.state.ui.welcome.show"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="footer-button"
              text
              rel="noopener"
              target="_blank"
              :href="$t('common.link.help')"
              v-bind="attrs"
              v-on="on"
            >
              <span class="footer-button-title">Help</span>
              <v-icon class="footer-button-icon append" small :dark="dark"
                >mdi-help-circle-outline</v-icon
              >
            </v-btn>
          </template>
          <span
            class="footer-button-welcome-tooltip"
            v-text="$t('common.message.welcome.tooltip.help')"
          />
        </v-tooltip>
        <v-btn
          class="footer-button"
          text
          rel="noopener"
          target="_blank"
          :href="$t('common.link.security')"
        >
          <span class="footer-button-title">Security</span>
        </v-btn>
        <v-btn
          class="footer-button"
          text
          rel="noopener"
          target="_blank"
          :href="$config.link.governance"
        >
          <span class="footer-button-title">BDAO</span>
        </v-btn>
        <v-btn
          class="footer-button"
          text
          rel="noopener"
          target="_blank"
          :href="$config.link.gbifiSwap"
        >
          <span class="footer-button-title">GBiFi Swap</span>
        </v-btn>
        <v-btn
          class="footer-button"
          text
          rel="noopener"
          target="_blank"
          :href="$config.link.helpcenter"
        >
          <span class="footer-button-title">Help Center</span>
        </v-btn>
      </div>

      <div class="footer-action-wrapper">
        <RegisterTokenButton class="footer-action-menu-button" />
        <v-menu
          class="footer-action-menu"
          top
          left
          offset-y
          nudge-top="5"
          rounded="0"
          z-index="99999"
          transition="slide-y-reverse-transition"
          :close-on-content-click="actionButton.closeOnContentClick"
          v-for="(actionButton, actionButtonIndex) in actionButtons"
          :key="actionButtonIndex"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="footer-action-menu-button"
              icon
              :dark="dark"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                class="footer-action-menu-button-icon"
                v-text="actionButton.icon"
              />
            </v-btn>
          </template>
          <component :is="actionButton.component" />
        </v-menu>
      </div>
    </v-layout>
  </v-footer>
</template>

<script>
import SettingList from '@/components/footer/setting/List';
import FamilySiteList from '@/components/footer/FamilySiteList';

import RegisterTokenButton from '@/components/registerToken/Button';

export default {
  name: 'Footer',
  components: {
    RegisterTokenButton,
  },
  data: () => ({
    actionButtons: [
      {
        icon: 'mdi-cog',
        closeOnContentClick: false,
        component: SettingList,
      },
      {
        icon: 'mdi-dots-vertical',
        closeOnContentClick: true,
        component: FamilySiteList,
      },
    ],
  }),
  computed: {
    dark() {
      return !!this.$store.getters.darkMode;
    },
  },
};
</script>

<style scoped>
.footer {
  justify-content: space-between;
  flex-flow: row nowrap;
  height: 44px;
  padding: 0px;
  border-top: 1px solid #d5d5d5;
  background-color: #fff !important;
}

.footer-content {
  padding-top: 0px;
  padding-bottom: 0px;
}

.footer-button-wrapper,
.footer-action-wrapper {
  display: flex;
  align-items: center;
}

.footer-button-wrapper {
  margin: 0px -1px;
}

.footer-action-wrapper {
  margin: 0px -7px;
}

.footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 1px;
  color: #000;
}

.footer-button-title {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0px;
  text-indent: 0px;
  text-transform: none;
}

.footer-button-icon {
}

.footer-button-icon.prepend {
  margin-right: 4px;
}

.footer-button-icon.append {
  margin-left: 4px;
}

.footer-button-welcome-tooltip {
  margin: -10px -16px;
  padding: 10px 16px;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  background-color: #1f7bff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.footer-button-welcome-tooltip:after {
  content: '';
  position: absolute;
  bottom: calc(-100% + 20px);
  right: calc(50% - 10px);
  width: 20px;
  height: 20px;
  background-color: #1f7bff;
  transform: scaleX(0.7) rotate(45deg);
}

.footer-action-menu {
}

.footer-action-menu-button {
  margin: 0px 7px;
}

.footer-action-menu-button-icon {
}

/* dark mode */
#app.dark .footer {
  border-color: #4b506d;
  background-color: #383c52 !important;
}

#app.dark .footer-button {
  color: #fff;
}

/* mobile */
#app.mobile .footer {
  height: unset;
  padding-top: 12px;
  padding-bottom: 12px;
}

#app.mobile .footer-content {
  flex-direction: column-reverse;
}

#app.mobile .footer-button-wrapper,
#app.mobile .footer-action-wrapper {
  width: 100%;
}
#app.mobile .footer-button-wrapper {
  justify-content: flex-start;
}
#app.mobile .footer-action-wrapper {
  justify-content: flex-end;
}

#app.mobile .footer-button {
  min-width: 0px !important;
  padding: 0px 8px !important;
}

#app.mobile .footer-button-title {
  font-size: 10px;
}
</style>

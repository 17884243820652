import func from '@/plugins/function';

export default {
  // darkMode
  setDarkMode(state, { darkMode, then }) {
    state.darkMode = !!darkMode;

    func.execFunc(then);
  },

  storeDarkMode(state, { then }) {
    func
      .storageEach(storage => storage.setItem('darkMode', state.darkMode))
      .then(then);
  },
};

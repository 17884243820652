<template>
  <div class="value-input" :class="{ inputonly: inputonly }">
    <div class="value-input-wrapper input">
      <InputForm
        :title="inputTitle"
        :titleButtons="inputTitleButtons"
        :inputDescription="inputDescription"
        :placeholderDescription="inputPlaceholderDescription"
        :readonly="readonly"
        :disabledCurrency="disabledCurrency"
        :disabledSlider="disabledSlider"
        :disabledRules="disabledRules"
        :tokenID="tokenID"
        :message="$vuetify.breakpoint.mdAndUp ? inputMessage : undefined"
        :messageDefaultArgs="
          $vuetify.breakpoint.mdAndUp ? inputMessageDefaultArgs : undefined
        "
        :value="amountValue"
        :maxValue="maxAmount"
        :unitString="tokenUnitString"
        :maxValueLength="(inputonly && $vuetify.breakpoint.mdAndUp && 16) || 9"
        :fullWidth="!inputonly"
        @messagebutton="$emit('messagebutton', $event)"
        @input="setInputValue"
      />
    </div>
    <template v-if="!inputonly">
      <v-divider :vertical="$vuetify.breakpoint.mdAndUp" />
      <div class="value-input-wrapper information">
        <InputForm
          readonly
          fullWidth
          disabledMessage
          :disabledCurrency="disabledCurrency"
          :disabledRules="disabledRules"
          :tokenID="tokenID"
          :title="informationTitle"
          :value="informationValue"
          :unitString="tokenUnitString"
          :maxValueLength="3"
          v-if="$vuetify.breakpoint.mdAndUp"
        />
        <Information
          :title="informationTitle"
          :tokenID="tokenID"
          :value="informationValue"
          v-else
        />
      </div>
    </template>
  </div>
</template>

<script>
import InputForm from '@/components/dialog/body/inputForm/InputForm';
import Information from '@/components/dialog/body/Information';

export default {
  name: 'ValueInput',
  components: {
    InputForm,
    Information,
  },
  props: {
    inputonly: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabledCurrency: {
      type: Boolean,
      default: false,
    },
    disabledSlider: {
      type: Boolean,
      default: false,
    },
    disabledRules: {
      type: Array,
      default: () => [],
    },
    tokenID: {
      type: String,
      default: '',
    },
    amountValue: {
      type: String,
      default: '',
    },
    maxAmount: {
      type: String,
      default: '',
    },
    tokenUnitString: {
      type: String,
      default: '',
    },
    inputTitle: {
      type: String,
      default: '',
    },
    inputTitleButtons: {
      type: Array,
      default: () => [],
    },
    inputDescription: {
      type: String,
      default: '',
    },
    inputPlaceholderDescription: {
      type: String,
      default: '',
    },
    informationTitle: {
      type: String,
      default: '',
    },
    informationValue: {
      type: String,
      default: '',
    },
    inputMessage: {
      type: Object,
      default: () => undefined,
    },
    inputMessageDefaultArgs: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setInputValue(event) {
      this.$emit('setInputValue', event);
    },
  },
};
</script>

<style scoped>
.value-input {
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
}

.value-input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 22px;
}

.value-input-wrapper.input {
  flex: 0.6;
  max-width: 60%;
}

.value-input.inputonly .value-input-wrapper.input {
  flex: 1;
  width: 100%;
  max-width: 100%;
}

.value-input-wrapper.information {
  flex: 0.4;
  max-width: 40%;
}

/* mobiel */

#app.mobile .value-input {
  flex-direction: column;
}

#app.mobile .value-input-wrapper {
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  padding: 12px 12px;
}

#app.mobile .value-input-wrapper.information {
  background-color: #0000001a;
}
</style>

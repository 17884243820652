<template>
  <div class="token-power-setting">
    <InputForm
      disabledCurrency
      disabledSlider
      :sliderDescription="$t('trade.tokenPower.sliderDescription')"
      :inputDescription="$t('trade.tokenPower.inputDescription')"
      :tokenID="tokenID"
      :message="inputMessage"
      :messageDefaultArgs="inputMessageDefaultArgs"
      :value="tokenPowerValue"
      :maxValue="tokenPowerMaxValue"
      :unitString="tokenUnitString"
      :maxValueLength="17"
      @input="setInputValue"
    />
    <v-btn
      class="token-power-setting-previous-button"
      icon
      :small="$vuetify.breakpoint.smAndDown"
      :dark="$store.getters.darkMode"
      @click="previousSlide"
    >
      <v-icon
        :large="$vuetify.breakpoint.mdAndUp"
        :middle="$vuetify.breakpoint.smAndDown"
        >mdi-chevron-left</v-icon
      >
    </v-btn>
  </div>
</template>

<script>
import InputForm from '@/components/dialog/body/inputForm/InputForm';

export default {
  name: 'TokenPowerSetting',
  components: {
    InputForm,
  },
  props: {
    tokenID: {
      type: String,
      default: '',
    },
    tokenPowerValue: {
      type: String,
      default: '',
    },
    tokenPowerMaxValue: {
      type: String,
      default: '',
    },
    tokenUnitString: {
      type: String,
      default: '',
    },
    tokenAddress: {
      type: String,
      default: '',
    },
    tokenSendAddress: {
      type: String,
      default: '',
    },
    inputMessage: {
      type: Object,
      default: () => undefined,
    },
    inputMessageDefaultArgs: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setInputValue(event) {
      this.$emit('setInputValue', event);
    },
    previousSlide() {
      this.$emit('previousSlide');
    },
  },
};
</script>

<style scoped>
.token-power-setting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0px auto;
}

.token-power-setting-previous-button {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #0000000d;
}

/* dark mode */

#app.dark .token-power-setting-previous-button {
  background-color: #ffffff0d;
}

/* mobile */

#app.mobile .token-power-setting {
  padding: 68px 0px 24px;
}
</style>

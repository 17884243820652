<template>
  <v-app
    id="app"
    :class="{
      dark: $store.getters.darkMode,
      mobile: $vuetify.breakpoint.smAndDown,
      'circuit-break': isCircuitBreak,
      'oracle-circuit-break': isCircuitBreak,
      notice: !!notice,
    }"
  >
    <vue-headful
      :title="$t('headful.title')"
      :description="$t('headful.description')"
    />

    <transition name="fade-expand">
      <Notice
        :text="noticeText"
        :link="noticeLink"
        @close="closeNotice"
        v-if="!!noticeText"
      />
    </transition>

    <Header />

    <v-main class="app-content">
      <v-container
        class="app-content-container container"
        :class="{ 'full-height': $route.name === 'NotFound' }"
      >
        <v-fade-transition hide-on-leave>
          <router-view class="app-router-page" ref="routerView" />
        </v-fade-transition>
      </v-container>

      <CircuitBreaker v-if="isCircuitBreak" />
      <OracleCircuitBreaker v-if="isOracleCircuitBreak" />
    </v-main>

    <Footer />

    <Progress />
    <SnackbarController />
    <Alert />

    <DialogBtcRegister />
    <DialogBtcEdit />

    <WalletConnector />
    <Popup />

    <NeedWallet />

    <Welcome />
  </v-app>
</template>

<script>
import Notice from '@/components/Notice';

import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';

import Progress from '@/components/Progress';
import SnackbarController from '@/components/snackbar/SnackbarController';
import Alert from '@/components/Alert';

import DialogBtcRegister from '@/components/btc/Register';
import DialogBtcEdit from '@/components/btc/Edit';

import WalletConnector from '@/components/WalletConnector';

import Popup from '@/components/Popup';

import CircuitBreaker from '@/components/CircuitBreaker';
import OracleCircuitBreaker from '@/components/OracleCircuitBreaker';

import NeedWallet from '@/components/NeedWallet';

import Welcome from '@/components/Welcome';

export default {
  name: 'App',
  components: {
    Notice,
    Header,
    Footer,
    Progress,
    SnackbarController,
    Alert,
    DialogBtcRegister,
    DialogBtcEdit,
    WalletConnector,
    Popup,
    CircuitBreaker,
    OracleCircuitBreaker,
    NeedWallet,
    Welcome,
  },
  data: () => ({
    hasSync: false,
    whileSync: false,
    interval: {
      id: -1,
      lastest: -1,
    },
    noticeID: -1,
  }),
  computed: {
    notice() {
      return (this.$notices || []).find(notice => notice.id === this.noticeID);
    },
    noticeMessages() {
      return (this.notice || {}).messages || [];
    },
    noticeMessage() {
      return (
        this.noticeMessages.find(
          message => message.locale === this.$i18n.locale
        ) ||
        this.noticeMessages[0] ||
        {}
      );
    },
    noticeText() {
      return this.noticeMessage.text;
    },
    noticeLink() {
      return this.noticeMessage.link;
    },
    isCircuitBreakRoute() {
      return this.$config.circuitBreakerRoutes.indexOf(this.$route.name) >= 0;
    },
    isCircuitBreak() {
      return this.$store.getters.circuitBreaker && this.isCircuitBreakRoute;
    },
    isOracleCircuitBreakRoute() {
      return (
        this.$config.oracleCircuitBreakerRoutes.indexOf(this.$route.name) >= 0
      );
    },
    isOracleCircuitBreak() {
      return (
        this.$store.getters.oracleCircuitBreaker &&
        this.isOracleCircuitBreakRoute
      );
    },
  },
  watch: {
    '$store.getters.walletID'() {
      this.$nextTick(() => {
        this.finalizing();
        this.$nextTick(() => {
          this.initializing();
        });
      });
    },
    '$store.getters.walletNetwork'() {
      this.$nextTick(() => {
        this.checkRouter();
        this.$nextTick(() => this.intervalEntry(true));
      });
    },
    '$store.getters.walletAddress'() {
      this.$nextTick(() => this.intervalEntry(true));
    },
    $route() {
      this.$nextTick(() => this.intervalEntry(true));
    },
  },
  methods: {
    closeNotice() {
      if (this.noticeID >= 0) {
        this.$storageEach(storage => {
          let readNotices = storage.getItem('readNotices');

          try {
            readNotices = JSON.parse(readNotices) || [];
          } catch (error) {
            readNotices = [];
          }

          if (readNotices.indexOf(this.noticeID) < 0) {
            readNotices.push(this.noticeID);

            storage.setItem('readNotices', JSON.stringify(readNotices));
          }

          this.noticeID = -1;
        });
      }
    },
    syncNotice() {
      this.noticeID = -1;

      const now = Date.now();
      const nowUTC = now + new Date().getTimezoneOffset() * 60000;

      if (typeof this.$notices === 'object' && this.$notices.length > 0) {
        for (const notice of this.$notices) {
          const noticeID = notice.id;
          const noticeEnabled = !!notice.enabled;
          const noticeMessages = notice.messages || [];
          const noticeConditions = notice.conditions || [];
          const noticeTerm = notice.term || {};

          if (
            noticeID >= 0 &&
            noticeEnabled &&
            noticeMessages &&
            typeof noticeMessages === 'object' &&
            noticeMessages.length > 0 &&
            noticeTerm.from > 0 &&
            noticeTerm.from <= nowUTC &&
            noticeTerm.to > 0 &&
            noticeTerm.to >= nowUTC
          ) {
            let conditionPassed = true;

            if (noticeConditions.length > 0) {
              for (const noticeCondition of noticeConditions) {
                try {
                  conditionPassed = this.$execFunc(
                    noticeCondition,
                    this.$store
                  );
                } catch (error) {
                  conditionPassed = false;
                }

                if (!conditionPassed) {
                  break;
                }
              }
            }

            if (conditionPassed) {
              this.noticeID = noticeID;
            }
          }
        }
      }
    },
    refreshProvider() {
      this.$store.dispatch('syncProvider', {
        walletID: this.$store.getters.walletID,
      });
    },
    async syncTransactions() {
      try {
        if (
          !this.$store.getters.isWalletUsable ||
          !this.$store.getters.transactions ||
          !this.$store.getters.transactions.length
        ) {
          return;
        }

        for (const transaction of this.$store.getters.transactions) {
          try {
            if (
              transaction.status !== 0 ||
              !transaction.transactionHash ||
              transaction.error ||
              typeof transaction.id !== 'number'
            ) {
              continue;
            }

            const transactionReceipt = await this.$getTransactionReceipt(
              transaction.transactionHash
            );

            let status = 2;
            let code = undefined;

            if (
              typeof transactionReceipt === 'object' &&
              transactionReceipt.status
            ) {
              status = 1;
            } else {
              code = this.$config.metamask.code.revert;
            }

            this.$store.dispatch('setTransactionStatus', {
              id: transaction.id,
              status,
              code,
            });

            this.$execFunc(transaction.thenFunc);

            this.syncPageData();
          } catch (error) {
            /**/
          }
        }
      } catch (error) {
        /**/
      }
    },
    syncCircuitBreak() {
      try {
        if (this.isCircuitBreakRoute) {
          this.$getCircuitBreaker().then(result =>
            this.$store.dispatch('setCircuitBreaker', {
              circuitBreaker: result,
            })
          );
        }
      } catch (error) {
        /**/
      }
    },
    syncOracleCircuitBreak() {
      try {
        if (this.isOracleCircuitBreakRoute) {
          this.$getOracleCircuitBreaker().then(result =>
            this.$store.dispatch('setOracleCircuitBreaker', {
              circuitBreaker: result,
            })
          );
        }
      } catch (error) {
        /**/
      }
    },
    syncBtc() {
      try {
        this.$store.getters.contract
          .call('bifi.btcPublicKeyHashes', this.$store.getters.walletAddress)
          .then(response => {
            const refundVersion = response.refundVersion || 0;
            const refundPublicKeyHash = response.refund || '';
            const transferInPublicKeyHash = response.transferIn || '';
            const depositPublicKeyHash = response.deposit || '';
            const repayPublicKeyHash = response.repay || '';

            const refundAddress = refundPublicKeyHash
              ? this.$hex2btcAddress(refundPublicKeyHash, refundVersion)
              : '';
            const transferInAddress = transferInPublicKeyHash
              ? this.$hex2btcAddress(transferInPublicKeyHash, 0)
              : '';
            const depositAddress = depositPublicKeyHash
              ? this.$hex2btcAddress(depositPublicKeyHash, 0)
              : '';
            const repayAddress = repayPublicKeyHash
              ? this.$hex2btcAddress(repayPublicKeyHash, 0)
              : '';

            this.$store.dispatch('setBtcInformation', {
              refundAddress,
              transferInAddress,
              depositAddress,
              repayAddress,
              refundPublicKeyHash,
              transferInPublicKeyHash,
              depositPublicKeyHash,
              repayPublicKeyHash,
            });
          })
          .catch(() => {});

        this.$store.getters.contract
          .call('bifi.btcChallenge', this.$store.getters.walletAddress)
          .then(response =>
            this.$store.dispatch('setBtcChallenge', {
              challenge: response.challenged,
            })
          )
          .catch(() => {});

        this.$store.getters.contract
          .call('bifi.btcFee', this.$store.getters.walletAddress)
          .then(response =>
            this.$store.dispatch('setBtcFee', {
              inflowRate: new this.$BBN(response.inflow.rate || '0').mul('100'),
              inflowMin: new this.$BBN(response.inflow.min || '0'),
              outflowRate: new this.$BBN(response.outflow.rate || '0').mul(
                '100'
              ),
              outflowMin: new this.$BBN(response.outflow.min || '0'),
            })
          )
          .catch(() => {});

        this.$store.getters.contract
          .call('bifi.btcOutflow', this.$store.getters.walletAddress)
          .then(response =>
            this.$store.dispatch('setBtcOutflowAmount', {
              amount: new this.$BBN(response.pendingAmount || '0'),
            })
          )
          .catch(() => {});
      } catch (error) {
        /**/
      }
    },
    async syncTokens() {
      if (!this.hasSync && !this.whileSync) {
        this.whileSync = true;

        this.$store.dispatch('addProgress', {});
      }

      try {
        const result = await this.$getTokenList(
          this.$store.getters.walletAddress
        );

        for (const token of result) {
          if (token.id && !token.isComingSoon && !token.isDisabled) {
            // sync token currency
            try {
              if (token.address) {
                this.$curr.setTokenAddress(token.id, token.address);
              }
              if (token.price) {
                this.$curr.setTokenPrice(token.id, token.price);
              }
              if (token.decimals) {
                this.$curr.setTokenDecimals(token.id, token.decimals);
              }
            } catch (error) {
              /**/
            }

            // sync token balance, allowance
            try {
              this.$store.dispatch('syncToken', {
                id: token.id,
                isToken: token.isToken,
                address: token.address,
                sendAddress: token.sendAddress,
              });
            } catch (error) {
              /**/
            }
          }
        }

        if (!this.hasSync && result && result.length > 0) {
          this.hasSync = true;
          this.whileSync = false;

          this.$store.dispatch('solveProgress', {});

          this.syncPageData();
        }
      } catch (error) {
        /**/
      }
    },
    syncPageData() {
      if (
        this.$store.getters.isWalletInstability ||
        (this.isOracleCircuitBreakRoute &&
          this.$store.getters.oracleCircuitBreaker) ||
        (this.isCircuitBreakRoute && this.$store.getters.circuitBreaker)
      ) {
        return;
      }

      if (typeof this.$refs.routerView === 'object') {
        this.$execFunc(this.$refs.routerView.syncData);
      }
    },
    checkRouter() {
      const routerID = (this.$route.name || '').toLowerCase();
      const disabledRouters =
        (
          this.$config.disabledRouters.find(
            disabledRouter =>
              disabledRouter.id === this.$store.getters.walletNetworkArea
          ) || {}
        ).routers || [];

      if (disabledRouters.indexOf(routerID) >= 0) {
        this.$router.push('/');
      }
    },
    intervalEntry(force) {
      const now = Date.now();

      if (
        !force &&
        (!this.$store.getters.isWallet ||
          this.$store.getters.isWalletInstability) &&
        this.interval.lastest !== -1 &&
        this.interval.lastest +
          this.$config.expectedBlock.time * this.$config.expectedBlock.infura >
          now
      ) {
        return;
      }

      try {
        this.syncTransactions();
      } catch (error) {
        /**/
      }
      try {
        this.syncCircuitBreak();
      } catch (error) {
        /**/
      }
      try {
        this.syncOracleCircuitBreak();
      } catch (error) {
        /**/
      }
      try {
        this.syncTokens();
      } catch (error) {
        /**/
      }
      try {
        this.syncBtc();
      } catch (error) {
        /**/
      }
      try {
        this.syncPageData();
      } catch (error) {
        /**/
      }

      this.interval.lastest = now;
    },
    initializing() {
      // wallet event
      try {
        this.$getProvider(this.$store.getters.walletID).on('connect', () =>
          this.refreshProvider()
        );
        this.$getProvider(this.$store.getters.walletID).on('disconnect', () =>
          this.refreshProvider()
        );
        this.$getProvider(this.$store.getters.walletID).on(
          'chainChanged',
          chain => {
            if (this.$store.getters.walletNetwork) {
              this.$store.dispatch('openAlert', {
                message: 'common.message.wallet.changed.network',
                messageArgs: {
                  after: `${this.$BiFi.parseNetwork(chain)}` || 'x',
                },
                type: 'info',
              });
            }

            this.refreshProvider();
          }
        );
        this.$getProvider(this.$store.getters.walletID).on(
          'accountsChanged',
          accounts => {
            if (this.$store.getters.walletAddress) {
              this.$store.dispatch('openAlert', {
                message: 'common.message.wallet.changed.address',
                messageArgs: {
                  after: (accounts || []).join(', ') || 'x',
                },
                type: 'info',
              });
            }

            this.refreshProvider();
          }
        );
      } catch (error) {
        /**/
      }

      // intervals
      try {
        this.intervalEntry();
      } catch (error) {
        /**/
      }
      try {
        this.interval.id = setInterval(
          this.intervalEntry,
          this.$config.expectedBlock.time
        );
      } catch (error) {
        /**/
      }

      // notice
      try {
        this.syncNotice();
      } catch (error) {
        /**/
      }

      // handle unhandled rejection
      try {
        window.addEventListener('unhandledrejection', (...event) =>
          console.log('unhandledrejection', ...event)
        );
      } catch (error) {
        /**/
      }
    },
    finalizing() {
      // wallet event
      try {
        window.ethereum.removeAllListeners();
      } catch (error) {
        /**/
      }
      try {
        window.biport.removeAllListeners();
      } catch (error) {
        /**/
      }
      try {
        this.$getProvider(this.$store.getters.walletID).removeAllListeners();
      } catch (error) {
        /**/
      }

      // intervals
      try {
        clearInterval(this.interval.id);
      } catch (error) {
        /**/
      }
      this.interval.id = -1;
      this.interval.lastest = -1;
    },
  },
  mounted() {
    this.initializing();
  },
  beforeDestroy() {
    this.finalizing();
  },
};
</script>

<style src="@/assets/styles/fonts.css"></style>
<style lang="scss">
@import '@/assets/styles/variables.scss';
</style>
<style src="@/assets/styles/default.css"></style>
<style src="@/assets/styles/common.css"></style>

<template>
  <form
    class="input-form"
    :class="{
      'full-width': fullWidth,
      disabled: disabled,
      readonly: readonly,
    }"
  >
    <div class="input-form-title-wrapper">
      <div
        class="input-form-title transition-all"
        v-text="title"
        v-if="title"
      />
      <div
        class="input-form-title-button-wrapper"
        v-if="
          titleButtons &&
            typeof titleButtons === 'object' &&
            titleButtons.length
        "
      >
        <v-btn
          class="input-form-title-button transition-all"
          small
          :icon="!!titleButton.icon"
          :text="!titleButton.icon"
          :dark="$store.getters.darkMode"
          @click="$execFunc(titleButton.action, $event)"
          v-for="(titleButton, titleButtonIndex) in titleButtons"
          :key="titleButtonIndex"
        >
          <v-icon
            class="input-form-title-button-icon"
            small
            v-text="titleButton.icon"
            v-if="titleButton.icon"
          />
          <template v-else>
            <v-icon
              class="input-form-title-button-icon append"
              v-text="titleButton.prependIcon"
              v-if="titleButton.prependIcon"
            />
            <span
              class="input-form-title-button-title"
              v-text="titleButton.title"
              v-if="titleButton.title"
            />
            <v-icon
              class="input-form-title-button-icon prepend"
              v-text="titleButton.appendIcon"
              v-if="titleButton.appendIcon"
            />
          </template>
        </v-btn>
      </div>
    </div>
    <div class="input-form-description-wrapper">
      <div
        class="input-form-description"
        v-html="inputDescription"
        v-if="!readonly && inputDescription"
      />
    </div>
    <div
      class="input-form-placeholder-wrapper"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-fade-transition>
        <div
          class="input-form-placeholder"
          v-html="placeholderDescription"
          v-if="
            !readonly && placeholderDescription && (!value || value === '0')
          "
        />
      </v-fade-transition>
    </div>
    <div class="input-form-input-wrapper">
      <div
        class="input-form-unit transparent"
        :style="{ 'font-size': `${unitStringFontSize}px` }"
        v-text="unitString"
      />
      <input
        ref="inputForm"
        class="input-form-input"
        :style="{
          'font-size': `${valueFontSize}px`,
        }"
        placeholder="0"
        min="0"
        :type="$vuetify.breakpoint.mdAndUp ? 'number' : 'tel'"
        :disabled="disabled"
        :readonly="readonly"
        :value="value"
        @input="input($event.target.value)"
      />
      <div
        class="input-form-unit transition-all"
        :style="{ 'font-size': `${unitStringFontSize}px` }"
        v-text="unitString"
      />
    </div>
    <div class="input-form-currency-wrapper">
      <div
        class="input-form-currency"
        v-text="formatedCurrencyValue"
        v-if="!disabledCurrency"
      />
    </div>
    <div
      class="input-form-slider-wrapper transition-all"
      v-if="!disabledSlider"
    >
      <v-slider
        class="input-form-slider"
        hide-details
        track-color="#00000040"
        :color="computedColor"
        :thumb-color="computedColor"
        :disabled="readonly"
        :min="0"
        :max="100"
        :step="1"
        :value="sliderValue"
        @input="inputSlider"
        v-if="!(disabled || readonly)"
      >
        <template v-slot:thumb-label="props" v-if="$vuetify.breakpoint.mdAndUp">
          <span
            class="input-form-slider-thumb"
            :class="{ max: props.value === 100 }"
            v-text="`${props.value}%`"
          />
        </template>
        <template v-slot:append v-else>
          <span class="input-form-slider-text" v-text="`${sliderValue}%`" />
        </template>
      </v-slider>
      <div
        class="input-form-slider-indicator"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <template v-if="!readonly">
          <a
            class="input-form-slider-indicator-content transition-all"
            :class="{ disabeld: disabled || readonly }"
            v-ripple="!(disabled || readonly)"
            v-text="`${indicatorValue}%`"
            @click="inputIndicator(indicatorValue)"
            v-for="indicatorValue in indicatorList"
            :key="indicatorValue"
          />
        </template>
      </div>
      <div
        class="input-form-slider-description"
        v-text="sliderDescription"
        v-if="$vuetify.breakpoint.mdAndUp && !readonly && sliderDescription"
      />
    </div>
    <div class="input-form-message-wrapper" v-if="$vuetify.breakpoint.mdAndUp">
      <v-fade-transition>
        <div
          class="input-form-message"
          :class="(messageType && `type-${messageType}`) || ''"
          v-if="messageShow"
        >
          <v-tooltip
            bottom
            transition="slide-y-transition"
            :disabled="!messageTooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                class="input-form-message-tooltip-wrapper"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar
                  class="input-form-message-avatar"
                  :color="messageType"
                  :size="messageAvatarSize"
                >
                  <v-icon
                    class="input-form-message-avatar-icon"
                    :style="{ 'font-size': `${messageIconSize}px` }"
                    dark
                    v-text="messageIcon"
                  />
                </v-avatar>
                <div class="input-form-message-text" v-text="messageText" />
              </div>
            </template>
            <span v-text="messageTooltip" />
          </v-tooltip>
          <v-btn
            class="input-form-message-button"
            color="#1f7bff"
            text
            @click="
              $emit(
                'messagebutton',
                typeof message === 'object' && message.button
              )
            "
            v-if="messageButton"
          >
            <span
              class="input-form-message-button-text"
              v-text="messageButton"
            />
          </v-btn>
        </div>
      </v-fade-transition>
    </div>
  </form>
</template>

<script>
export default {
  name: 'InputForm',
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    disabledCurrency: {
      type: Boolean,
      default: false,
    },
    disabledMessage: {
      type: Boolean,
      default: false,
    },
    disabledSlider: {
      type: Boolean,
      default: false,
    },
    disabledRules: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    maxValue: {
      type: String,
      default: '',
    },
    unitString: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleButtons: {
      type: Array,
      default: () => [],
    },
    placeholderDescription: {
      type: String,
      default: '',
    },
    inputDescription: {
      type: String,
      default: '',
    },
    sliderDescription: {
      type: String,
      default: '',
    },
    tokenID: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#47b465',
    },
    warningColor: {
      type: String,
      default: '#e59b00',
    },
    dangerColor: {
      type: String,
      default: '#e02020',
    },
    dangerValue: {
      type: Number,
      default: 90,
    },
    warningValue: {
      type: Number,
      default: 76,
    },
    message: {
      type: Object,
      default: () => undefined,
    },
    messageDefaultArgs: {
      type: Object,
      default: () => {},
    },
    maxValueLength: {
      type: Number,
      default: 0,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    indicatorLength: {
      type: Number,
      default: 5,
    },
  },
  data: () => ({
    sliderValue: 0,
  }),
  computed: {
    inputable() {
      return !(this.disabled || this.readonly);
    },
    disabled() {
      let result = false;

      if (this.disabledRules && this.disabledRules.length > 0) {
        for (const rule of this.disabledRules) {
          result = this.$execFunc(rule);

          if (result) {
            break;
          }
        }
      }

      return result;
    },
    disableMessage() {
      let result = '';

      if (
        this.disabled &&
        typeof this.disabled === 'object' &&
        typeof this.disabled.text === 'string'
      ) {
        result = this.disabled;
      }

      return result;
    },
    computedColor() {
      let result = this.color;

      if (!this.maxValue || this.disabled || this.readonly) {
        result = '#efefef';
      } else if (this.maxValue > 0) {
        if (this.sliderValue >= this.dangerValue) {
          result = this.dangerColor;
        } else if (this.sliderValue >= this.warningValue) {
          result = this.warningColor;
        }
      }

      return result;
    },
    messageShow() {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        !this.disabledMessage &&
        ((this.message &&
          typeof this.message === 'object' &&
          this.message.type &&
          this.message.text) ||
          this.disableMessage)
      );
    },
    messageType() {
      let result = '';

      if (this.messageShow) {
        if (this.disableMessage) {
          result = 'error';
        } else {
          result = this.message.type;
        }
      }

      return result;
    },
    messageText() {
      let result = '';

      if (this.messageShow) {
        if (this.disableMessage) {
          result = this.$t(this.disableMessage.text, this.messageTextArgs);
        } else {
          result = this.$t(this.message.text, this.messageTextArgs);
        }
      }

      return result;
    },
    messageTextArgs() {
      let result = undefined;

      if (this.messageShow) {
        if (this.disableMessage) {
          result = this.disableMessage.textArgs;
        } else {
          result = this.message.textArgs;
        }

        if (typeof result !== 'object') {
          result = {};
        }

        if (typeof this.messageDefaultArgs === 'object') {
          result = Object.assign({}, result, this.messageDefaultArgs, {
            action: (this.messageDefaultArgs['action'] || '').toLowerCase(),
            actioned: (this.messageDefaultArgs['actioned'] || '').toLowerCase(),
            actioning: (
              this.messageDefaultArgs['actioning'] || ''
            ).toLowerCase(),
          });
        }
      }

      return result;
    },
    messageButton() {
      let result = '';

      if (this.messageShow) {
        if (!this.disableMessage && this.message.button) {
          result = this.$t(this.message.button);
        }
      }

      return result;
    },
    messageIcon() {
      let result = 'mdi-exclamation-thick';

      if (this.messageShow) {
        switch (this.messageType) {
          case 'success':
            result = 'mdi-check';
            break;
          case 'error':
            result = 'mdi-exclamation-thick';
            break;
          case 'info':
            result = 'mdi-exclamation-thick';
            break;
        }
      }

      return result;
    },
    messageAvatarSize() {
      let result = 24;

      if (this.$vuetify.breakpoint.smAndDown) {
        result = 18;
      }

      if (this.messageShow && this.messageType === 'info') {
        result *= 0.75;
      }

      return result;
    },
    messageIconSize() {
      let result = 18;

      if (this.$vuetify.breakpoint.smAndDown) {
        result = 14;
      }

      if (this.messageShow && this.messageType === 'info') {
        result *= 0.75;
      }

      return result;
    },
    messageTooltip() {
      let result = '';

      if (this.messageShow && this.message && this.message.tooltip) {
        result = this.$t(this.message.tooltip, this.messageTextArgs);
      }

      return result;
    },
    valueFontSize() {
      let result = 50;

      if (this.$vuetify.breakpoint.mdAndUp) {
        const maxLength = Math.min(this.maxValueLength, 23);

        if (maxLength && this.value.length > maxLength) {
          result = Math.max(
            result -
              (this.value.length - maxLength) *
                Math.sin((24 - maxLength) / 23) *
                3,
            12
          );
        }
      } else {
        result = 24;

        const maxLength = Math.min(this.maxValueLength, 17);

        if (maxLength && this.value.length > maxLength) {
          result = Math.max(
            result -
              (this.value.length - maxLength) *
                Math.sin((18 - maxLength) / 40) *
                3,
            8
          );
        }
      }

      return result;
    },
    unitStringFontSize() {
      const maxLength = 4;

      let result = 18;

      if (this.$vuetify.breakpoint.mdAndUp) {
        if (maxLength && this.unitString.length > maxLength) {
          result = Math.max(
            result -
              (this.unitString.length - maxLength) *
                Math.sin((24 - maxLength) / 23) *
                3,
            12
          );
        }
      } else {
        result = 12;

        if (maxLength && this.unitString.length > maxLength) {
          result = Math.max(
            result -
              (this.unitString.length - maxLength) *
                Math.sin((18 - maxLength) / 40) *
                3,
            8
          );
        }
      }

      return result;
    },
    indicatorList() {
      const result = [];

      for (let i = 0; i < this.indicatorLength; i++) {
        result.push((100 / (this.indicatorLength - 1)) * i);
      }

      return result;
    },
    formatedCurrencyValue() {
      let value = this.value;

      if (!value || isNaN(value)) {
        value = 0;
      }

      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          value,
          this.tokenID,
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
  },
  watch: {
    value(value) {
      if (value) {
        if (this.maxValue && this.maxValue !== '0') {
          try {
            const valueNumber = new this.$BBN(value)
              .div(this.maxValue)
              .mul(100)
              .toNum(true);

            if (valueNumber !== this.sliderValue) {
              this.inputSlider(valueNumber, true);
            }
          } catch (error) {
            /**/
          }
        }
      } else if (this.sliderValue !== 0) {
        this.inputSlider(0, true);
      }
    },
  },
  methods: {
    inputIndicator(value) {
      if (this.inputable) {
        this.inputSlider(value);
      }
    },
    inputSlider(value, isRipple) {
      if (this.inputable) {
        let rippleValue = this.maxValue;

        if (value < 100) {
          rippleValue = new this.$BBN(this.maxValue)
            .div(100)
            .mul(value)
            .balanceFormat(undefined, 6, false, false, false);
        }

        this.sliderValue = value;

        if (!isRipple) {
          this.input(rippleValue);
        }
      }
    },
    input(value) {
      if (this.inputable) {
        this.$emit('input', value);
      }
    },
  },
  mounted() {
    if (this.inputable) {
      setTimeout(() => {
        if (
          this.$refs.inputForm &&
          this.$refs.inputForm &&
          typeof this.$refs.inputForm.focus === 'function'
        ) {
          this.$refs.inputForm.focus();
        }
      }, 100);
    }
  },
};
</script>

<style scoped>
.input-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 100%;
}

.input-form.full-width {
  width: 100%;
}

.input-form-title-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 21px;
}

.input-form-title {
  font-size: 14px;
  color: #000000d9;
}

.input-form-title-button-wrapper {
  display: flex;
  align-items: center;
  margin-left: 18px;
  margin-right: -2px;
}

.input-form-title-button {
  margin-left: 2px;
  margin-right: 2px;
}
.input-form-title-button-title {
  font-size: 14px;
  letter-spacing: 0;
  text-transform: none;
  opacity: 0.25;
}
.input-form-title-button-icon {
  font-size: 17px;
}
.input-form-title-button-icon.append {
  margin-right: 12px;
}
.input-form-title-button-icon.prepend {
  margin-left: 12px;
}

.input-form.disabled .input-form-title {
  opacity: 0.25;
}

.input-form-placeholder-wrapper {
  height: 18px;
}

.input-form-placeholder {
  font-size: 12px;
  text-align: center;
  color: #00000040;
}

.input-form-description-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
}

.input-form-description {
  font-size: 14px;
  text-align: center;
  color: #000000d9;
}

.input-form-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0px 20px;
}

.input-form-input {
  display: inline-block;
  height: 75px;
  width: 100%;
  max-width: calc(100% - 110px);
  font-size: 50px;
  text-align: center;
  color: #000000d9;
  overflow: visible;
  transition: none !important;
}

.input-form-input:disabled,
.input-form-input:read-only {
  opacity: 0.5;
  cursor: default;
  user-select: none;
}

.input-form-input:disabled {
  opacity: 0.25;
}

.input-form.full-width .input-form-input {
  max-width: calc(100% - 90px);
}

.input-form-input:read-only {
  cursor: default;
}

.input-form-unit {
  max-width: 60px;
  padding-left: 20px;
  font-size: 18px;
  color: #000;
  opacity: 0.5;
}

.input-form.full-width .input-form-unit {
  padding-left: 10px;
}

.input-form.disabled .input-form-unit {
  opacity: 0.25;
}

.input-form-currency-wrapper {
  height: 27px;
  margin-bottom: 10px;
}

.input-form-currency {
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  color: #000;
  opacity: 0.25;
}

.input-form.disabled .input-form-currency {
  opacity: 0.25;
}

.input-form-slider-wrapper {
  width: 80%;
  height: 110px;
}

.input-form.disabled .input-form-slider-wrapper {
  opacity: 0.25;
}

.input-form-slider {
}

.input-form-slider-thumb {
  font-size: 12px;
}

.input-form-slider-thumb.max {
  font-size: 10px;
  letter-spacing: -0.4px;
}

.input-form-slider-indicator {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  height: 35px;
  margin: 3px -18px;
}

.input-form-slider-indicator-content {
  display: block;
  width: 48px;
  height: 40px;
  padding: 8px;
  font-size: 12px;
  color: #000;
  text-align: center;
  border-radius: 3px;
  opacity: 0.25;
  user-select: none;
}

.input-form-slider-indicator-content:not(.disabeld):hover {
  opacity: 1;
}

.input-form-slider-indicator-content:before {
  content: '';
  position: relative;
  display: block;
  width: 1px;
  height: 9px;
  margin: 0px auto;
  background-color: #000;
}

.input-form-slider-indicator-content.disabeld {
  cursor: default;
}

.input-form-slider-description {
  height: 32px;
  padding-top: 14px;
  font-size: 12px;
  text-align: center;
  color: #00000080;
}

.input-form-message-wrapper {
  height: 23px;
}

.input-form-message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-form-message-avatar {
  background-color: #47b465;
}

.input-form-message-avatar-icon {
}

.input-form-message-tooltip-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
}

.input-form-message-text {
  padding-left: 12px;
  font-size: 16px;
  text-align: center;
  color: #000000d9;
}

.input-form-message.type-info .input-form-message-text {
  font-size: 14px;
  color: #00000099;
}

.input-form-message-button {
  min-width: unset !important;
  margin: 0px -8px 0px 8px !important;
  padding: 0px 8px !important;
}

.input-form-message-button-text {
  font-size: 14px;
}

/* dark mode */

#app.dark .input-form-title,
#app.dark .input-form-description,
#app.dark .input-form-input,
#app.dark .input-form-message-text {
  color: #ffffffd9;
}

#app.dark .input-form-placeholder {
  color: #ffffff40;
}

#app.dark .input-form-unit,
#app.dark .input-form-currency,
#app.dark .input-form-slider-indicator-content {
  color: #fff;
}

#app.dark .input-form-slider-indicator-content:before {
  background-color: #fff;
}

#app.dark .input-form-slider-description {
  color: #ffffff80;
}

#app.dark .input-form-message.type-info .input-form-message-text {
  color: #ffffff99;
}

/* mobile */
#app.mobile .input-form {
  width: 100%;
}

#app.mobile .input-form-title-wrapper {
  flex-direction: column;
  align-items: flex-start;
  height: unset;
  padding-bottom: 12px;
}
#app.mobile .input-form-title {
  font-size: 12px;
}
#app.mobile .input-form-title-button-wrapper {
  margin-left: -8px;
  margin-right: 0px;
}
#app.mobile .input-form-title-button {
  margin-left: 0px;
  margin-right: 0px;
}
#app.mobile .input-form-title-button:not(.v-btn--icon) {
  height: unset !important;
  padding: 4px 8px !important;
}
#app.mobile .input-form-title-button-title {
  font-size: 12px;
}
#app.mobile .input-form-title-button-icon {
  font-size: 14px;
}
#app.mobile .input-form-title-button-icon.append {
  margin-right: 6px;
}
#app.mobile .input-form-title-button-icon.prepend {
  margin-left: 6px;
}

#app.mobile .input-form-description-wrapper {
  height: unset;
}

#app.mobile .input-form-description {
  font-size: 11px;
}

#app.mobile .input-form-input-wrapper {
  width: 100%;
  padding: 12px 0px 4px;
}

#app.mobile .input-form-unit {
  padding-left: 6px;
  font-size: 12px;
}

#app.mobile .input-form-input {
  max-width: calc(100% - 72px);
  height: 36px;
  font-size: 24px;
}

#app.mobile .input-form-currency-wrapper {
  height: unset;
  margin-bottom: 12px;
}

#app.mobile .input-form-currency {
  height: unset;
  font-size: 12px;
}

#app.mobile .input-form-slider-wrapper {
  width: 100%;
  height: unset;
}

#app.mobile .input-form-slider {
  align-items: center;
  height: 32px;
}

#app.mobile .input-form-slider-text {
  width: 30px;
  font-size: 12px;
  text-align: right;
  color: #000000d9;
}

#app.dark .input-form-slider-text {
  color: #ffffffd9;
}
</style>

<style>
#app.mobile .input-form .input-form-slider .v-slider__track-container {
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
}

.input-form .input-form-description * {
  opacity: 0.25;
}
</style>

<template>
  <div class="notice" v-if="text">
    <div class="notice-wrapper">
      <div class="notice-message">
        <template v-for="(txt, txtIndex) in texts">
          <span v-text="txt" v-if="typeof txt === 'string'" :key="txtIndex" />
          <template v-else-if="typeof txt === 'object'">
            <a
              rel="noopener"
              target="_blank"
              :href="txt.href"
              v-text="txt.text"
              v-if="txt.href"
              :key="txtIndex"
            />
            <a
              @click="txt.action"
              v-text="txt.text"
              v-else-if="txt.action"
              :key="txtIndex"
            />
            <router-link
              :to="txt.to"
              v-text="txt.text"
              v-else-if="txt.to"
              :key="txtIndex"
            />
          </template>
        </template>
      </div>
      <v-btn
        class="notice-button"
        color="#1f7bff"
        text
        v-text="$t('common.message.close')"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  props: {
    text: {
      type: String,
      default: '',
    },
    link: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    texts() {
      let result = [this.text];

      if (
        typeof this.link === 'object' &&
        this.link.id &&
        typeof this.link.id === 'string'
      ) {
        const splitedMessages = this.text.split(`{${this.link.id}}`);

        result = splitedMessages || [];

        if (splitedMessages.length > 1) {
          for (
            let splitedMessageIndex = 0;
            splitedMessageIndex < splitedMessages.length;
            splitedMessageIndex++
          ) {
            const count = parseInt(splitedMessageIndex) + 1;

            if (count === splitedMessages.length) {
              continue;
            }

            splitedMessages.splice(count, 0, this.link);
            splitedMessageIndex++;
          }

          result = splitedMessages || [];
        }
      }

      return result;
    },
  },
};
</script>

<style scoped>
.notice {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 74px;
  background-color: #f0f0f0;
  z-index: 100;
}

.notice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 12px;
  text-align: center;
}

.notice-message {
  font-size: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  color: #000;
}

.notice-button {
  min-width: unset !important;
  margin-left: 12px;
  padding: 0 8px !important;
  font-size: 16px !important;
  font-weight: bold;
  text-align: center;
  text-decoration: underline;
}

/* dark mode */
#app.dark .notice {
  background-color: #272836;
}

#app.dark .notice-message {
  color: #fff;
}

/* mobile */
#app.mobile .notice {
  top: 56px;
  height: 56px;
  box-shadow: 0 3px 6px 0 #00000029;
}

#app.mobile .notice-wrapper {
  padding: 0px 8px;
}

#app.mobile .notice-message {
  font-size: 10px;
  letter-spacing: 0.4px;
  line-height: 1.3;
  text-align: left;
}

#app.mobile .notice-button {
  height: unset !important;
  min-height: unset !important;
  margin-left: 8px;
  padding: 6px !important;
  font-size: 10px !important;
}
</style>

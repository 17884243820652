<template>
  <div class="snackbar-controller" v-show="$store.getters.ui.snackbar.show">
    <transition-group class="snackbar-wrapper" name="snackbar-list" tag="div">
      <Snackbar
        :id="snackbar.id"
        :color="snackbar.color"
        :multi-line="snackbar.multiLine"
        :vertical="snackbar.vertical"
        :mode="snackbar.mode"
        :message="snackbar.message"
        :buttonMessage="snackbar.buttonMessage"
        :buttonAction="snackbar.buttonAction"
        v-for="snackbar in $store.getters.ui.snackbar.list"
        :key="snackbar.id"
      />
    </transition-group>
  </div>
</template>

<script>
import Snackbar from '@/components/snackbar/Snackbar';

export default {
  name: 'SnackbarController',
  components: {
    Snackbar,
  },
};
</script>

<style scoped>
.snackbar-controller {
  position: fixed;
  top: 0;
  right: 0;
  max-width: 100vw;
  z-index: 999;
}

.snackbar-wrapper {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  max-width: calc(100% - 48px);
  margin-top: 50px;
  margin-left: auto;
}

#app.mobile .snackbar-controller {
  transform-origin: top right;
  transform: scale(0.7);
}
</style>

<template>
  <div class="lend">
    <div class="lend-content">
      <v-row>
        <v-col
          :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }"
          :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
        >
          <UserStatus
            comingSoonFooter
            :prefix="this.$store.getters.unitPrefix"
            :suffix="this.$store.getters.unitSuffix"
            :options="depositOptions"
            :total="totalDeposit"
            :apy="totalDepositApy"
            :interest="totalDepositInterest"
            :enabledRows="depositEnabledRows"
            :disabledRows="depositDisabledRows"
            :comingSoonRows="comingSoonRows"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <UserStatus
            :prefix="this.$store.getters.unitPrefix"
            :suffix="this.$store.getters.unitSuffix"
            :options="borrowOptions"
            :total="totalBorrow"
            :apy="totalBorrowApy"
            :interest="totalBorrowInterest"
            :enabledRows="borrowEnabledRows"
            :disabledRows="borrowDisabledRows"
            :comingSoonRows="comingSoonRows"
          />
        </v-col>
      </v-row>
    </div>

    <DialogTrade
      :type="dialog.type"
      :color="dialog.color"
      :token="dialog.token"
      @typeChange="typeChange"
      @syncData="syncData"
      v-model="dialog.show"
    />
  </div>
</template>

<script>
import UserStatus from '@/components/userStatus/UserStatus';
import DialogTrade from '@/components/dialog/Trade';

export default {
  name: 'Lend',
  components: {
    UserStatus,
    DialogTrade,
  },
  data() {
    return {
      tokenData: [],
      totalData: {
        deposit: {
          amount: new this.$BBN('0'),
          interest: new this.$BBN('0'),
          apy: new this.$BBN('0'),
        },
        borrow: {
          amount: new this.$BBN('0'),
          interest: new this.$BBN('0'),
          apy: new this.$BBN('0'),
        },
      },
      dialog: {
        type: '',
        color: '',
        token: undefined,
        show: false,
      },
    };
  },
  computed: {
    depositOptions() {
      return {
        color: '#1f7bff',
        total: {
          title: this.$t('lend.overview.totalDeposit'),
        },
        apy: {
          title: this.$t('lend.overview.annualInterestRate'),
        },
        interest: {
          title: this.$t('lend.overview.revenue'),
        },
        enabled: {
          nodata: this.$t('lend.userStatus.header.deposit.enabled.nodata'),
          header: [
            {
              title: this.$t(
                'lend.userStatus.header.deposit.enabled.myDeposit'
              ),
              colspan: 2,
            },
            {
              title: this.$t('lend.userStatus.header.deposit.enabled.interest'),
              class: 'text-right',
            },
            {
              title: this.$t(
                'lend.userStatus.header.deposit.enabled.depositAmount'
              ),
              class: 'text-right',
            },
            {
              onlyDesktop: true,
            },
          ],
          actions: [
            {
              title: this.$t('lend.userStatus.deposit'),
              action: this.entryDeposit,
            },
            {
              title: this.$t('lend.userStatus.withdraw'),
              action: this.entryWithdraw,
            },
          ],
        },
        disabled: {
          title: this.$t('lend.userStatus.header.deposit.disabled.title'),
          nodata: this.$t('lend.userStatus.header.deposit.disabled.nodata'),
          header: [
            {
              title: this.$t('lend.userStatus.header.deposit.disabled.coin'),
              colspan: 2,
            },
            {
              title: this.$t(
                'lend.userStatus.header.deposit.disabled.interestRate'
              ),
              class: 'text-right',
            },
            {
              title: this.$t(
                'lend.userStatus.header.deposit.disabled.depositableAmount'
              ),
              class: 'text-right',
            },
            {
              onlyDesktop: true,
            },
          ],
          actions: [
            {
              title: this.$t('lend.userStatus.deposit'),
              action: this.entryDeposit,
            },
            {
              title: this.$t('lend.userStatus.withdraw'),
              action: this.entryWithdraw,
              getIsHide: id => id !== 'btc',
            },
          ],
        },
      };
    },
    borrowOptions() {
      return {
        color: '#44ccd7',
        total: {
          title: this.$t('lend.overview.totalBorrow'),
        },
        apy: {
          title: this.$t('lend.overview.annualInterestRate'),
        },
        interest: {
          title: this.$t('lend.overview.borrowInterest'),
        },
        enabled: {
          nodata: this.$t('lend.userStatus.header.borrow.enabled.nodata'),
          header: [
            {
              title: this.$t('lend.userStatus.header.borrow.enabled.myBorrow'),
              colspan: 2,
            },
            {
              title: this.$t('lend.userStatus.header.borrow.enabled.interest'),
              class: 'text-right',
            },
            {
              title: this.$t(
                'lend.userStatus.header.borrow.enabled.depositAmount'
              ),
              class: 'text-right',
            },
            {
              title: this.$t(
                'lend.userStatus.header.borrow.enabled.usageLimit'
              ),
              class: 'text-right',
              onlyDesktop: true,
            },
            {
              onlyDesktop: true,
            },
          ],
          actions: [
            {
              title: this.$t('lend.userStatus.borrow'),
              action: this.entryBorrow,
            },
            {
              title: this.$t('lend.userStatus.repay'),
              action: this.entryRepay,
            },
          ],
        },
        disabled: {
          title: this.$t('lend.userStatus.header.borrow.disabled.title'),
          nodata: this.$t('lend.userStatus.header.borrow.disabled.nodata'),
          header: [
            {
              title: this.$t('lend.userStatus.header.borrow.disabled.coin'),
              colspan: 2,
            },
            {
              title: this.$t(
                'lend.userStatus.header.borrow.disabled.interestRate'
              ),
              class: 'text-right',
            },
            {
              title: this.$t(
                'lend.userStatus.header.borrow.disabled.borrowableAmount'
              ),
              class: 'text-right',
            },
            {
              onlyDesktop: true,
            },
          ],
          actions: [
            {
              title: this.$t('lend.userStatus.borrow'),
              action: this.entryBorrow,
            },
            {
              title: this.$t('lend.userStatus.repay'),
              action: this.entryRepay,
              getIsHide: id => id !== 'btc',
            },
          ],
        },
      };
    },
    totalDeposit() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.deposit.amount,
        'usd',
        this.$store.getters.currency
      );
    },
    totalDepositInterest() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.deposit.interest,
        'usd',
        this.$store.getters.currency
      );
    },
    totalDepositApy() {
      return this.totalData.deposit.apy;
    },
    totalBorrow() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.borrow.amount,
        'usd',
        this.$store.getters.currency
      );
    },
    totalBorrowInterest() {
      return this.$curr.getFormatedTokenValue(
        this.totalData.borrow.interest,
        'usd',
        this.$store.getters.currency
      );
    },
    totalBorrowApy() {
      return this.totalData.borrow.apy;
    },
    depositEnabledRows() {
      const result = [];

      for (const data of this.tokenData) {
        if (data.deposit.amount.gt(1e-14)) {
          const action = {
            type: 'action',
          };

          if (data.id === 'btc') {
            if (this.$store.getters.btcChallenge) {
              action.type = 'action-replace';
              action.replace = {
                prependIcon: 'mdi-history',
                title: this.$t('lend.userStatus.btc.processingReviewing'),
              };
            }
          }

          result.push({
            id: data.id,
            tokenData: data,
            class: `row-${data.id}`,
            disabled: data.deposit.disabled,
            columns: [
              {
                type: 'avatar',
                image: `${this.$config.host.image}icon/${data.image}`,
              },
              {
                type: 'text',
                text: data.title,
              },
              {
                type: 'columns',
                class: 'text-right',
                columns: [
                  {
                    text: data.deposit.interestRate.balanceFormat(
                      ' + {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/${data.image}`,
                  },
                  {
                    subtext: this.$curr.getWordFormatedValue(
                      data.deposit.interestAmount,
                      this.$i18n.locale,
                      data.replaceString,
                      2,
                      true
                    ),
                  },
                  {
                    text: data.deposit.siApy.balanceFormat(
                      ' + {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/bifi.png`,
                  },
                ],
              },
              {
                type: 'text',
                class: 'text-right',
                text: this.$curr.getWordFormatedValue(
                  this.$curr.getFormatedTokenValue(
                    data.deposit.amount,
                    data.id,
                    this.$store.getters.currency
                  ),
                  this.$i18n.locale,
                  this.$store.getters.replaceString,
                  2,
                  true
                ),
                subtext: this.$curr.getWordFormatedValue(
                  data.deposit.amount,
                  this.$i18n.locale,
                  data.replaceString,
                  2,
                  true
                ),
              },
              action,
            ],
          });
        }
      }

      return result;
    },
    depositDisabledRows() {
      const result = [];

      for (const data of this.tokenData) {
        if (data.deposit.amount.lte(1e-14)) {
          const balance = this.$getTokenBalance(
            this.$store.getters.tokens,
            data.id
          );
          const action = {
            type: 'action',
          };

          let balanceString = this.$curr.getWordFormatedValue(
            this.$curr.getFormatedTokenValue(
              balance,
              data.id,
              this.$store.getters.currency
            ),
            this.$i18n.locale,
            this.$store.getters.replaceString,
            2,
            true
          );
          let balanceCurrencyString = this.$curr.getWordFormatedValue(
            balance,
            this.$i18n.locale,
            data.replaceString,
            2,
            true
          );

          if (data.id === 'btc') {
            balanceString = '-';
            balanceCurrencyString = '-';

            if (this.$store.getters.btcChallenge) {
              action.type = 'action-replace';
              action.replace = {
                prependIcon: 'mdi-history',
                title: this.$t('lend.userStatus.btc.processingReviewing'),
              };
            }
          }

          result.push({
            id: data.id,
            tokenData: data,
            class: `row-${data.id}`,
            disabled: data.deposit.disabled,
            columns: [
              {
                type: 'avatar',
                image: `${this.$config.host.image}icon/${data.image}`,
              },
              {
                type: 'text',
                text: data.title,
              },
              {
                type: 'columns',
                class: 'text-right',
                columns: [
                  {
                    text: data.deposit.interestRate.balanceFormat(
                      ' + {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/${data.image}`,
                  },
                  {
                    text: data.deposit.siApy.balanceFormat(
                      ' + {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/bifi.png`,
                  },
                ],
              },
              {
                type: 'text',
                class: 'text-right',
                text: balanceString,
                subtext: balanceCurrencyString,
              },
              action,
            ],
          });
        }
      }

      return result;
    },
    borrowEnabledRows() {
      const result = [];

      for (const data of this.tokenData) {
        if (data.borrow.amount.gt('0')) {
          const action = {
            type: 'action',
          };

          if (data.id === 'btc') {
            if (this.$store.getters.btcChallenge) {
              action.type = 'action-replace';
              action.replace = {
                prependIcon: 'mdi-history',
                title: this.$t('lend.userStatus.btc.processingReviewing'),
              };
            }
          }

          result.push({
            id: data.id,
            tokenData: data,
            class: `row-${data.id}`,
            disabled: data.borrow.disabled,
            columns: [
              {
                type: 'avatar',
                image: `${this.$config.host.image}icon/${data.image}`,
              },
              {
                type: 'text',
                text: data.title,
              },
              {
                type: 'columns',
                class: 'text-right',
                columns: [
                  {
                    text: data.borrow.interestRate.balanceFormat(
                      ' - {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/${data.image}`,
                  },
                  {
                    subtext: this.$curr.getWordFormatedValue(
                      data.borrow.interestAmount,
                      this.$i18n.locale,
                      data.replaceString,
                      2,
                      true
                    ),
                  },
                  {
                    text: data.borrow.siApy.balanceFormat(' + {}%', 2, 6, true),
                    prependImage: `${this.$config.host.image}icon/bifi.png`,
                  },
                ],
              },
              {
                type: 'text',
                class: 'text-right',
                text: this.$curr.getWordFormatedValue(
                  this.$curr.getFormatedTokenValue(
                    data.borrow.amount,
                    data.id,
                    this.$store.getters.currency
                  ),
                  this.$i18n.locale,
                  this.$store.getters.replaceString,
                  2,
                  true
                ),
                subtext: this.$curr.getWordFormatedValue(
                  data.borrow.amount,
                  this.$i18n.locale,
                  data.replaceString,
                  2,
                  true
                ),
              },
              {
                type: 'text',
                class: 'text-right',
                text: data.borrow.usedBorrowLimit
                  .mul('100')
                  .balanceFormat('{}%', 2, 6, true),
              },
              action,
            ],
          });
        }
      }

      return result;
    },
    borrowDisabledRows() {
      const result = [];

      for (const data of this.tokenData) {
        if (data.borrow.amount.lte('0')) {
          const action = {
            type: 'action',
          };

          if (data.id === 'btc') {
            if (this.$store.getters.btcChallenge) {
              action.type = 'action-replace';
              action.replace = {
                prependIcon: 'mdi-history',
                title: this.$t('lend.userStatus.btc.processingReviewing'),
              };
            }
          }

          result.push({
            id: data.id,
            tokenData: data,
            class: `row-${data.id}`,
            disabled: data.borrow.disabled,
            columns: [
              {
                type: 'avatar',
                image: `${this.$config.host.image}icon/${data.image}`,
              },
              {
                type: 'text',
                text: data.title,
              },
              {
                type: 'columns',
                class: 'text-right',
                columns: [
                  {
                    text: data.borrow.interestRate.balanceFormat(
                      ' - {}%',
                      2,
                      6,
                      true
                    ),
                    prependImage: `${this.$config.host.image}icon/${data.image}`,
                  },
                  {
                    text: data.borrow.siApy.balanceFormat(' + {}%', 2, 6, true),
                    prependImage: `${this.$config.host.image}icon/bifi.png`,
                  },
                ],
              },
              {
                type: 'text',
                class: 'text-right',
                text: this.$curr.getWordFormatedValue(
                  this.$curr.getFormatedTokenValue(
                    data.maxAmount.borrow,
                    data.id,
                    this.$store.getters.currency
                  ),
                  this.$i18n.locale,
                  this.$store.getters.replaceString,
                  2,
                  true
                ),
                subtext: this.$curr.getWordFormatedValue(
                  data.maxAmount.borrow,
                  this.$i18n.locale,
                  data.replaceString,
                  2,
                  true
                ),
              },
              action,
            ],
          });
        }
      }

      return result;
    },
    comingSoonRows() {
      const result = [];

      for (const token of this.comingSoonTokens) {
        result.push({
          id: token.id,
          columns: [
            {
              type: 'avatar',
              image: `${this.$config.host.image}icon/${token.image}`,
            },
            {
              type: 'text',
              text: token.title,
            },
            {
              type: 'text',
              class: 'text-right',
              text: '0.00%',
            },
            {
              type: 'text',
              class: 'text-right',
              text: token.replaceString.replace('{}', '0.00'),
            },
            {
              type: 'action',
            },
          ],
        });
      }

      return result;
    },
    comingSoonTokens() {
      return this.$store.getters.comingSoonTokens;
    },
  },
  methods: {
    entryDeposit(event, row) {
      this.openDialogTrade(
        row.tokenData,
        ((row.tokenData || {}).id === 'btc' && 'btc.deposit') || 'deposit',
        '#1f7bff'
      );
    },
    entryWithdraw(event, row) {
      this.openDialogTrade(
        row.tokenData,
        ((row.tokenData || {}).id === 'btc' && 'btc.withdraw') || 'withdraw',
        '#1f7bff'
      );
    },
    entryBorrow(event, row) {
      this.openDialogTrade(
        row.tokenData,
        ((row.tokenData || {}).id === 'btc' && 'btc.borrow') || 'borrow',
        '#44ccd7'
      );
    },
    entryRepay(event, row) {
      this.openDialogTrade(
        row.tokenData,
        ((row.tokenData || {}).id === 'btc' && 'btc.repay') || 'repay',
        '#44ccd7'
      );
    },
    openDialogTrade(token, type, color) {
      if (this.$store.getters.isWalletUsable) {
        const splitedTypes = (type || '').split('.');

        if (
          splitedTypes.length >= 2 &&
          splitedTypes[0] === 'btc' &&
          (!this.$store.getters.btcRefund ||
            !this.$store.getters.btcTransferIn ||
            !this.$store.getters.btcDeposit ||
            !this.$store.getters.btcRepay)
        ) {
          this.$store.dispatch('openBtcRegister', {});
        } else {
          this.dialog.type = type || '';
          this.dialog.color = color || '';
          this.dialog.token = token || undefined;
          this.dialog.show = true;
        }
      } else {
        this.$store.dispatch('openWalletConnector', {});
      }
    },
    typeChange(id) {
      this.dialog.type = id;
    },
    syncData() {
      this.$store.dispatch('addProgress', {});
      const bifiTokenPrice = this.$curr.getToken('bifi').price;

      this.$store.getters.contract
        .call('bifi.user', this.$store.getters.walletAddress)
        .then(async result => {
          this.totalData.deposit.amount = new this.$BBN(
            result.totalData.deposit.amount
          );
          this.totalData.deposit.interest = new this.$BBN(
            result.totalData.deposit.interest
          );
          this.totalData.deposit.apy = new this.$BBN(
            result.totalData.deposit.apy
          ).mul('100');

          this.totalData.borrow.amount = new this.$BBN(
            result.totalData.borrow.amount
          );
          this.totalData.borrow.interest = new this.$BBN(
            result.totalData.borrow.interest
          );
          this.totalData.borrow.apy = new this.$BBN(
            result.totalData.borrow.apy
          ).mul('100');

          this.$store.dispatch('setBorrowData', {
            borrowLimit: new this.$BBN(result.totalData.limit.borrow),
            marginCallLimit: new this.$BBN(result.totalData.limit.marginCall),
            totalDeposit: this.totalData.deposit.amount,
            totalBorrow: this.totalData.borrow.amount,
          });

          this.tokenData = (result.assetList || [])
            .filter(asset => !this.$getIsDisabledToken(asset.id))
            .sort(pre => (pre.id === 'btc' ? -1 : 1))
            .map(token => ({
              id: token.id,
              isToken: this.$getIsToken(
                token.id,
                this.$store.getters.walletNetwork
              ),
              title: this.$curr.getToken(token.id).title,
              price: new this.$BBN(token.price),
              address: this.$store.getters.contract.getTokenAddress(token.id),
              handler: {
                id: token.handlerID,
                address: token.address,
              },
              collateralRate: new this.$BBN(token.collateralRate),
              maxAmount: {
                withdraw: new this.$BBN(token.maxAmount.withdraw),
                borrow: new this.$BBN(token.maxAmount.borrow),
                repay: new this.$BBN(token.maxAmount.repay),
              },
              deposit: {
                amount: new this.$BBN(token.deposit.amount),
                interestRate: new this.$BBN(token.deposit.interestRate).mul(
                  '100'
                ),
                interestAmount: new this.$BBN(token.deposit.interestAmount),
                siApy: new this.$BBN(token.deposit.apy)
                  .mul(this.$config.daysPerYear)
                  .mul(
                    bifiTokenPrice.div(
                      (this.$curr.getToken(token.id).price.gt('0') &&
                        this.$curr.getToken(token.id).price) ||
                        '1'
                    )
                  )
                  .mul('100'),
                disabled: this.$getIsDisabledDepositToken(token.id),
              },
              borrow: {
                amount: new this.$BBN(token.borrow.amount),
                interestRate: new this.$BBN(token.borrow.interestRate).mul(
                  '100'
                ),
                interestAmount: new this.$BBN(token.borrow.interestAmount),
                usedBorrowLimit: new this.$BBN(token.borrow.usedBorrowLimit),
                siApy: new this.$BBN(token.borrow.apy)
                  .mul(this.$config.daysPerYear)
                  .mul(
                    bifiTokenPrice.div(
                      (this.$curr.getToken(token.id).price.gt('0') &&
                        this.$curr.getToken(token.id).price) ||
                        '1'
                    )
                  )
                  .mul('100'),
                disabled: this.$getIsDisabledBorrowToken(token.id),
              },
              limitOfAction: new this.$BBN(token.limitOfAction),
              unitString: this.$curr.getToken(token.id).unit,
              replaceString: this.$curr.getToken(token.id).replaceString,
              image: this.$curr.getToken(token.id).image,
            }));
        })
        .catch(error => {
          // TODO fix metamask header not found
          if (this.$isIgnorableError(error)) {
            return;
          }

          this.$store.dispatch('openAlert', {
            message: 'common.message.dataLoad.error',
            messageArgs: {
              error: `${
                typeof error === 'object'
                  ? error.locale || error.message || error
                  : error
              }`,
            },
            type: 'error',
          });
        })
        .finally(() => this.$store.dispatch('solveProgress', {}));
    },
  },
};
</script>

<style scoped>
.lend {
}

.lend-content {
}

.lend-header {
  padding-bottom: 16px;
}
</style>

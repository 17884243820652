import Vue from 'vue';
import axios from '@/plugins/axios';
import config from '@/plugins/config';

const getBtcAddresses = address => {
  const body = {
    user_eth_addr: address,
  };

  return axios.post(
    `${config.host.btcWallet}${config.path.btcWallet.getAddresses}`,
    body
  );
};
const setBtcOutflowAddress = (address, btcAddress) => {
  const body = {
    user_eth_addr: address,
    user_refund_addr: btcAddress,
  };

  return axios.post(
    `${config.host.btcWallet}${config.path.btcWallet.setAddress}`,
    body
  );
};

Vue.prototype.$getBtcAddresses = getBtcAddresses;
Vue.prototype.$setBtcOutflowAddress = setBtcOutflowAddress;

export default {
  getBtcAddresses,
  setBtcOutflowAddress,
};

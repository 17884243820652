<template>
  <div class="stake">
    <div class="stake-content">
      <v-layout class="stake-content-header" align-end>
        <CardOutterTitle v-t="'stake.header.title'" />
        <v-spacer />
      </v-layout>
      <ContentHeader>
        <v-row class="my-n3">
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
            <v-row class="my-n3">
              <v-col cols="12">
                <div
                  class="stake-header-subtitle"
                  v-text="$t('stake.totalDeposit')"
                />
              </v-col>
              <v-col cols="12">
                <v-tooltip
                  bottom
                  transition="slide-y-transition"
                  :disabled="totalDeposit.lte('0')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="stake-header-value"
                      v-text="formatedTotalDeposit"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span v-text="totalDepositDetail" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
            <v-row class="my-n3">
              <v-col cols="12">
                <div
                  class="stake-header-subtitle"
                  v-text="$t('stake.poolRate')"
                />
              </v-col>
              <v-col cols="12">
                <v-tooltip
                  bottom
                  transition="slide-y-transition"
                  :disabled="marketPoolRate.lte('0')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="stake-header-value"
                      v-text="formatedMarketPoolRate"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span v-text="marketPoolRateDetail" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12">
            <v-row class="my-n3">
              <v-col cols="12">
                <div class="stake-header-subtitle" v-text="$t('pool.apy')" />
              </v-col>
              <v-col cols="12">
                <v-tooltip
                  bottom
                  transition="slide-y-transition"
                  :disabled="apy.lte('0')"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="stake-header-value"
                      v-text="formatedAPY"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span v-text="apyDetail" />
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </ContentHeader>
      <v-row class="mt-n2">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <CardSrd>
            <template slot="body">
              <v-row class="mt-n3 mb-n5">
                <v-col
                  class="text-center"
                  :cols="$vuetify.breakpoint.mdAndUp ? 3 : 12"
                >
                  <v-avatar color="#0000001a" size="80">
                    <v-img
                      width="60"
                      height="60"
                      contain
                      aspect-ratio="2"
                      :src="`${$config.host.image}icon/bfc.svg`"
                    />
                  </v-avatar>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.mdAndUp ? 9 : 12">
                  <v-layout
                    class="full-height"
                    column
                    justify-space-between
                    :align-center="$vuetify.breakpoint.smAndDown"
                  >
                    <div
                      class="card-srd-body-label pb-4"
                      v-text="$t('stake.profitSharing')"
                    />
                    <a
                      class="card-srd-body-link transition-all"
                      :class="{
                        disabled: !token.stake.handler.address,
                      }"
                      rel="noopener"
                      target="_blank"
                      :href="
                        token.stake.handler.address
                          ? $getAddressExplorer(
                              $store.getters.walletNetwork,
                              token.stake.handler.address
                            )
                          : undefined
                      "
                    >
                      <div
                        class="card-srd-body-link-text"
                        v-text="
                          token.stake.handler.address
                            ? `${token.stake.handler.address.slice(
                                0,
                                8
                              )}...${token.stake.handler.address.slice(-8)}`
                            : '0x000000...00000000'
                        "
                      />
                      <v-icon class="card-srd-body-link-icon"
                        >mdi-open-in-new</v-icon
                      >
                    </a>
                  </v-layout>
                </v-col>
              </v-row>
            </template>
          </CardSrd>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <CardSrd>
            <template slot="body">
              <v-layout class="pt-2" justify-left align-center>
                <v-img
                  class="ml-4 mr-8"
                  max-width="60"
                  :src="`${$config.host.image}icon/sushiswap.svg`"
                />
                <a
                  class="card-srd-body-link transition-all"
                  :class="{
                    disabled: !token.stake.address,
                  }"
                  rel="noopener"
                  target="_blank"
                  :href="
                    token.stake.address
                      ? $config.link.sushiswap.swap.replace(
                          '{address}',
                          token.stake.address
                        )
                      : undefined
                  "
                  v-text="$t('stake.descriptionAcquire')"
                />
              </v-layout>
            </template>
          </CardSrd>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <CardSrd>
            <template slot="header-title">
              <div v-text="$t('stake.stakedBFC')" />
            </template>
            <template slot="body">
              <v-tooltip
                bottom
                transition="slide-y-transition"
                :disabled="!hasDeposit"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="card-srd-body-single-value mt-6"
                    v-text="formatedDeposit"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span v-text="depositDetail" />
              </v-tooltip>
              <div
                class="card-srd-body-single-sub-value mb-4"
                v-text="depositAsset"
              />
            </template>
            <template slot="footer-button">
              <v-btn
                class="card-srd-footer-button"
                color="#1f7bff"
                depressed
                v-text="$t('common.action.stakeToken', { token: 'BFC' })"
                disabled
              />
              <v-btn
                class="card-srd-footer-button"
                color="#44ccd7"
                depressed
                :disabled="!hasDeposit"
                v-text="
                  $t('common.action.doAction', {
                    action: $t('common.action.withdraw'),
                  })
                "
                @click="entryTrade('withdraw')"
              />
            </template>
          </CardSrd>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
          <CardSrd>
            <template slot="header-title">
              <div v-text="$t('stake.unclaimedBiFi')" />
            </template>
            <template slot="header-subtitle" v-if="userPoolRate.gt('0')">
              <v-tooltip top transition="slide-y-reverse-transition">
                <template v-slot:activator="{ on, attrs }">
                  <div v-text="formatedUserPoolRate" v-bind="attrs" v-on="on" />
                </template>
                <span v-text="userPoolRateDetail" />
              </v-tooltip>
            </template>
            <template slot="body">
              <v-tooltip
                bottom
                transition="slide-y-transition"
                :disabled="!hasEarn"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="card-srd-body-single-value mt-6"
                    v-text="formatedEarn"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span v-text="earnDetail" />
              </v-tooltip>
              <div
                class="card-srd-body-single-sub-value mb-4"
                v-text="earnAsset"
              />
            </template>
            <template slot="footer-button">
              <v-btn
                class="card-srd-footer-button"
                color="#1f7bff"
                depressed
                :disabled="!hasEarn"
                v-text="
                  $t('common.action.doAction', {
                    action: $t('common.action.claimToken', { token: 'BiFi' }),
                  })
                "
                @click="entryTrade('claim')"
              />
            </template>
          </CardSrd>
        </v-col>
      </v-row>
    </div>

    <DialogTrade
      :type="dialog.type"
      :color="dialog.color"
      :token="dialog.token"
      @syncData="syncData"
      v-model="dialog.show"
    />
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader';
import CardOutterTitle from '@/components/card/CardOutterTitle';
import CardSrd from '@/components/card/CardSrd';
import DialogTrade from '@/components/dialog/Trade';

export default {
  name: 'Stake',
  components: {
    ContentHeader,
    CardOutterTitle,
    CardSrd,
    DialogTrade,
  },
  data() {
    return {
      totalDeposit: new this.$BBN('0'),
      distributedAmount: new this.$BBN('0'),
      deposit: new this.$BBN('0'),
      earn: new this.$BBN('0'),
      apy: new this.$BBN('0'),
      marketPoolRate: new this.$BBN('0'),
      userPoolRate: new this.$BBN('0'),
      yearPoolRate: new this.$BBN('0'),
      dialog: {
        type: '',
        color: '#1f7bff',
        token: {},
        show: false,
      },
      token: {
        stake: {
          isSrdToken: true,
          isToken: true,
          id: 'bfc',
          title: 'BFC',
          handler: {
            address: '',
          },
          maxAmount: {
            'stake.withdraw': new this.$BBN('0'),
          },
          price: new this.$BBN('1'),
          address: '',
          unitString: 'BFC',
          replaceString: '{} BFC',
          image: 'bfc.svg',
          color: '#efefef',
        },
        claim: {
          isSrdToken: true,
          isToken: true,
          id: 'bifi',
          title: 'BiFi',
          handler: {
            address: '',
          },
          maxAmount: {
            'stake.claim': new this.$BBN('0'),
          },
          price: new this.$BBN('1'),
          address: '',
          unitString: 'BiFi',
          replaceString: '{} BiFi',
          image: 'bifi.png',
          color: '#efefef',
        },
      },
    };
  },
  computed: {
    hasDeposit() {
      return this.deposit.gt('0');
    },
    hasEarn() {
      return this.earn.gt('0');
    },
    formatedTotalDeposit() {
      return this.$curr.getWordFormatedValue(
        this.totalDeposit,
        this.$i18n.locale,
        '{} BFC'
      );
    },
    formatedDistributedAmount() {
      return this.$curr.getWordFormatedValue(
        this.distributedAmount,
        this.$i18n.locale,
        '{} BiFi'
      );
    },
    formatedDeposit() {
      return this.$curr.getWordFormatedValue(
        this.deposit,
        this.$i18n.locale,
        '{} BFC',
        2,
        true
      );
    },
    formatedEarn() {
      return this.$curr.getWordFormatedValue(
        this.earn,
        this.$i18n.locale,
        '{} BiFi',
        2,
        true
      );
    },
    formatedAPY() {
      let result = '0 %';

      if (this.apy.gt('0.01')) {
        result = this.apy.balanceFormat('{} %', 2);
      } else if (this.apy.gt('0')) {
        result = '<0.01 %';
      }

      return result;
    },
    formatedMarketPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.marketPoolRate,
        this.$i18n.locale,
        '{} BiFi/week'
      );
    },
    formatedUserPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.userPoolRate,
        this.$i18n.locale,
        '{} BiFi/week'
      );
    },
    formatedYearPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.yearPoolRate,
        this.$i18n.locale,
        '{} BiFi/year'
      );
    },
    tokenBalance() {
      return this.$getTokenBalance(
        this.$store.getters.tokens,
        this.token.stake.id
      );
    },
    hasTokenBalance() {
      return this.tokenBalance && this.tokenBalance.gt('0');
    },
    depositAsset() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.deposit,
          'bfc',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    earnAsset() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.earn,
          'bifi',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    totalDepositDetail() {
      return this.token.stake.replaceString.replace(
        '{}',
        this.totalDeposit.toStr()
      );
    },
    depositDetail() {
      return this.token.stake.replaceString.replace('{}', this.deposit.toStr());
    },
    marketPoolRateDetail() {
      return this.marketPoolRate.balanceFormat('{} BiFi/week', 18);
    },
    userPoolRateDetail() {
      return this.userPoolRate.balanceFormat('{} BiFi/week', 18);
    },
    yearPoolRateDetail() {
      return this.yearPoolRate.balanceFormat('{} BiFi/year', 18);
    },
    apyDetail() {
      return this.apy.balanceFormat('{} %', 18);
    },
    earnDetail() {
      return this.token.claim.replaceString.replace('{}', this.earn.toStr());
    },
  },
  methods: {
    entryTrade(type) {
      if (this.$store.getters.isWalletUsable) {
        switch (type) {
          case 'deposit':
          case 'withdraw':
            this.dialog.token = this.token.stake;
            break;
          case 'claim':
            this.dialog.token = this.token.claim;
            break;
          default:
            return;
        }

        this.dialog.type = `stake.${type}`;
        this.dialog.show = true;
      } else {
        this.$store.dispatch('openWalletConnector', {});
      }
    },
    syncData() {
      this.$store.dispatch('addProgress', {});

      const tokenPrice = this.$curr.getToken('bfc').price;
      const bifiTokenPrice = this.$curr.getToken('bifi').price;

      const targetToken =
        this.$getToken(this.$store.getters.tokens, 'bfc') || {};
      const bifiToken =
        this.$getToken(this.$store.getters.tokens, 'bifi') || {};

      this.token.stake.address = targetToken.address;
      this.token.stake.handler.address = targetToken.sendAddress;
      this.token.claim.handler.address = targetToken.sendAddress;
      this.token.claim.address = bifiToken.address;

      this.$store.getters.contract
        .call('srd.stake.bfc', this.$store.getters.walletAddress)
        .then(result => {
          this.totalDeposit = new this.$BBN(result.market.totalDeposit);
          this.distributedAmount = new this.$BBN(
            result.market.distributedAmount
          );

          this.deposit = new this.$BBN(result.user.stakedTokenAmount);
          this.earn = new this.$BBN(result.user.earnedTokenAmount);

          this.marketPoolRate = new this.$BBN(result.market.weekPoolRate);
          this.userPoolRate = new this.$BBN(result.user.weekPoolRate);

          this.yearPoolRate = new this.$BBN(result.market.yearPoolRate);
          this.apy = this.yearPoolRate
            .mul(bifiTokenPrice)
            .div(this.totalDeposit.mul(tokenPrice))
            .mul('100');

          this.token.stake.maxAmount['stake.withdraw'] = this.deposit;
          this.token.claim.maxAmount['stake.claim'] = this.earn;
        })
        .catch(error => {
          // TODO fix metamask header not found
          if (this.$isIgnorableError(error)) {
            return;
          }

          this.$store.dispatch('openAlert', {
            message: 'common.message.dataLoad.error',
            messageArgs: {
              error: `${
                typeof error === 'object'
                  ? error.locale || error.message || error
                  : error
              }`,
            },
            type: 'error',
          });
        })
        .finally(() => this.$store.dispatch('solveProgress', {}));
    },
  },
};
</script>

<style scoped>
.stake {
  color: #000;
}

.stake-header {
}

.stake-header-subtitle {
  font-size: 18px;
  letter-spacing: 0.2px;
}

.stake-header-value {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
}

.stake-content {
}

.stake-content-header {
  padding-bottom: 16px;
}

/* dark mode */

#app.dark .stake {
  color: #fff;
}

/* mobile */

#app.mobile .stake-header-subtitle {
  font-size: 14px;
}

#app.mobile .stake-header-value {
  margin-top: -20px;
  font-size: 18px;
}
</style>

<script>
import DexContent from '@/views/pool/DexContent';

export default {
  name: 'Sushiswap',
  mixins: [DexContent],
  data: () => ({
    dex: 'sushiswap',
  }),
};
</script>

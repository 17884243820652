import vue from 'vue';
import { bech32 } from 'bech32';
import bs58check from 'bs58check';
import { validate } from 'bitcoin-address-validation';

// prefix
const PREFIX_ADDRESS_P2WPKH = 'bc';
// const PREFIX_ADDRESS_P2PKH = '1';
// const PREFIX_ADDRESS_P2SH = '3';
const PREFIX_HASH = 0x00;
// TESTNET
// const PREFIX_ADDRESS = 'tb';
// const PREFIX_ADDRESS = 'bcrt';
// const PREFIX_HASH = 0x6f;

// regex
const regexAddressTypeIsP2WPKH = /^(bc1).*/;
const regexAddressTypeIsP2PKH = /^(1).*/;
const regexAddressTypeIsP2SH = /^(3).*/;
const regexAddress = /^(1|bc1|3).*/;
// TESTNET
// const regexAddressType = /^(m|n).*/;
// const regexAddress = /^(m|n|bcrt).*/;

// address rules
const rulesAddress = [
  value => !!value || 'Please enter your BTC address',
  value => typeof value === 'string' || 'Please check your input',
  value =>
    regexAddress.test(value) || 'Addresses must start with "bc1" , "1" or "3"',
  // TESTNET
  // value => regexAddress.test(value) || 'Addresses must start with "m", "n" or "bcrt"',
  value => validate(value) || 'Invalid address',
];

// hex <-> bytes
const hex2bytes = hex => {
  const innerHex = hex.replace('0x', '');

  let result = [];

  for (let c = 0; c < innerHex.length; c += 2) {
    result.push(parseInt(innerHex.substr(c, 2), 16));
  }

  return result;
};
const bytes2hex = bytes =>
  `0x${Array.prototype.map
    .call(bytes, byte => `00${(byte & 0xff).toString(16)}`.slice(-2))
    .join('') || '0'}`;

// hex 2 address
const hex2address = (hex, version) => {
  let result = '';

  if (hex) {
    if (version === 0) {
      const bytes = hex2bytes(hex);
      const words = bech32.toWords(bytes);

      words.unshift(PREFIX_HASH);

      result = bech32.encode(PREFIX_ADDRESS_P2WPKH, words);
    } else if (version === 1) {
      const target = Buffer.from(hex.replace('0x', ''), 'hex');
      const buffer = Buffer.allocUnsafe(21);

      buffer.writeUInt8(PREFIX_HASH, 0);
      target.copy(buffer, 1);

      result = bs58check.encode(buffer);
    } else if (version === 2) {
      const target = Buffer.from(hex.replace('0x', ''), 'hex');
      const buffer = Buffer.allocUnsafe(21);

      buffer.writeUInt8(0x05, 0);
      target.copy(buffer, 1);

      result = bs58check.encode(buffer);
    }
  }

  return result;
};

// address 2 hex
const address2hex = address => {
  const result = {
    version: 0,
    hex: '',
  };

  if (regexAddressTypeIsP2WPKH.test(address)) {
    result.version = 0;
  } else if (regexAddressTypeIsP2PKH.test(address)) {
    result.version = 1;
  } else if (regexAddressTypeIsP2SH.test(address)) {
    result.version = 2;
  }

  if (result.version === 0) {
    const { words } = bech32.decode(address);
    words.shift();
    const bytes = bech32.fromWords(words);
    result.hex = bytes2hex(bytes);
  } else if (result.version === 1 || result.version === 2) {
    const payload = bs58check.decode(address);
    const bytes = payload.slice(1);
    result.hex = bytes2hex(bytes);
  }

  return result;
};

// type of address
const getTypeOfAddress = address =>
  regexAddressTypeIsP2PKH.test(address) ? '0x0' : '0x1';

vue.prototype.$btcAddressValidate = validate;
vue.prototype.$regexBtcAddressType = regexAddressTypeIsP2PKH;
vue.prototype.$regexBtcAddress = regexAddress;
vue.prototype.$rulesBtcAddress = rulesAddress;
vue.prototype.$hex2bytes = hex2bytes;
vue.prototype.$bytes2hex = bytes2hex;
vue.prototype.$hex2btcAddress = hex2address;
vue.prototype.$btcAddress2hex = address2hex;
vue.prototype.$getTypeOfBtcAddress = getTypeOfAddress;

export default {
  validate,
  PREFIX_ADDRESS_P2WPKH,
  // PREFIX_ADDRESS_P2PKH,
  // PREFIX_ADDRESS_P2SH,
  PREFIX_HASH,
  regexAddressTypeIsP2WPKH,
  regexAddressTypeIsP2PKH,
  regexAddressTypeIsP2SH,
  regexAddress,
  rulesAddress,
  hex2bytes,
  bytes2hex,
  hex2address,
  address2hex,
  getTypeOfAddress,
};

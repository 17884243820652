<template>
  <div class="card-divider-wrapper">
    <v-divider
      class="card-divider"
      :class="{ vertical: vertical }"
      :vertical="vertical"
    />
  </div>
</template>

<script>
export default {
  name: 'CardDivider',
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  watch: {},
  data: () => ({}),
  methods: {},
};
</script>

<style scoped>
.card-divider-wrapper {
  margin: 1px;
}

.card-divider {
  position: absolute;
}

.card-divider:not(.vertical) {
  left: 0;
  right: 0;
}

.card-divider.vertical {
  top: 0;
  bottom: 0;
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import Lend from '@/views/Lend';
import Reward from '@/views/Reward';
import Pool from '@/views/Pool';
import Stake from '@/views/Stake';
import Market from '@/views/Market';

import PoolSushiswap from '@/views/pool/Sushiswap';
import PoolUniswap from '@/views/pool/Uniswap';

import PoolBfcEth from '@/views/pool/BfcEth';
import PoolBiFiEth from '@/views/pool/BiFiEth';

import NotFound from '@/views/NotFound';

import config from '@/plugins/config';

Vue.use(VueRouter);

const routes = [
  {
    // redirect page
    path: '/',
    redirect: '/lend',
  },
  {
    path: '/lending',
    redirect: '/lend',
  },
  {
    path: '/pooling',
    redirect: '/pool',
  },
  {
    path: '/staking',
    redirect: '/stake',
  },
  {
    path: '/rewards',
    redirect: '/reward',
    // component page
  },
  {
    path: '/lend',
    name: 'Lend',
    component: Lend,
  },
  {
    path: '/pool',
    name: 'Pool',
    component: Pool,
    children: [
      {
        path: '',
        redirect: 'sushiswap',
      },
      {
        path: 'sushiswap',
        name: 'PoolSushiswap',
        component: PoolSushiswap,
        children: [
          {
            path: '',
            redirect: 'bfcEth',
          },
          {
            path: 'bfcEth',
            name: 'PoolBfcEth',
            component: PoolBfcEth,
          },
          {
            path: 'bifiEth',
            name: 'PoolBiFiEth',
            component: PoolBiFiEth,
          },
        ],
      },
      {
        path: 'uniswap',
        name: 'PoolUniswap',
        component: PoolUniswap,
        children: [
          {
            path: '',
            redirect: 'bfcEth',
          },
          {
            path: 'bfcEth',
            name: 'PoolBfcEth',
            component: PoolBfcEth,
          },
          {
            path: 'bifiEth',
            name: 'PoolBiFiEth',
            component: PoolBiFiEth,
          },
        ],
      },
    ],
    // children: [{
    //   path: '',
    //   redirect: 'bfcEth'
    // }, {
    //   path: 'bfcEth',
    //   name: 'PoolBfcEth',
    //   component: PoolBfcEth
    // }, {
    //   path: 'bifiEth',
    //   name: 'PoolBiFiEth',
    //   component: PoolBiFiEth
    // }]
  },
  {
    path: '/stake',
    name: 'Stake',
    component: Stake,
  },
  {
    path: '/reward',
    name: 'Reward',
    component: Reward,
  },
  {
    path: '/market',
    name: 'Market',
    component: Market,
    // not found page
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const navigationGuard = (to, from, next) => {
  store.dispatch('resetProgress', {});

  const fromID = (from.name || '').toLowerCase();
  const toID = (to.name || '').toLowerCase();

  if (fromID !== toID) {
    const disabledRouters =
      (
        config.disabledRouters.find(
          disabledRouter =>
            disabledRouter.id === store.getters.walletNetworkArea
        ) || {}
      ).routers || [];

    if (disabledRouters.indexOf(toID) >= 0) {
      next('/');
    }
  }

  next();
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(navigationGuard);

export default router;

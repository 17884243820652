var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{staticClass:"navigation-tooltip",attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"navigation-wrapper"},'div',attrs,false),on),[(_vm.menu)?_c('v-menu',{attrs:{"content-class":"navigation-munu","tile":"","offset-y":"","open-on-hover":"","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('router-link',{staticClass:"navigation transition-all",class:{
                disabled: _vm.disabled,
              },attrs:{"to":_vm.disabled ? '' : _vm.to}},[(_vm.prependIcon)?_c('v-icon',{staticClass:"navigation-icon prepend",domProps:{"textContent":_vm._s(("mdi-" + _vm.prependIcon))}}):_vm._e(),_c('div',{staticClass:"navigation-title",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('v-icon',{staticClass:"navigation-icon append",domProps:{"textContent":_vm._s(("mdi-" + _vm.appendIcon))}}):_vm._e()],1)],1)]}}],null,true)},[_c(_vm.menu,{tag:"component"})],1):(_vm.to)?_c('router-link',{staticClass:"navigation transition-all",class:{
          disabled: _vm.disabled,
        },attrs:{"to":_vm.disabled ? '' : _vm.to}},[(_vm.prependIcon)?_c('v-icon',{staticClass:"navigation-icon prepend",domProps:{"textContent":_vm._s(("mdi-" + _vm.prependIcon))}}):_vm._e(),_c('div',{staticClass:"navigation-title",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('v-icon',{staticClass:"navigation-icon append",domProps:{"textContent":_vm._s(("mdi-" + _vm.appendIcon))}}):_vm._e()],1):(_vm.href)?_c('a',{staticClass:"navigation transition-all",class:{
          disabled: _vm.disabled,
        },attrs:{"rel":"noopener","target":"_blank","href":_vm.disabled ? '' : _vm.href}},[(_vm.prependIcon)?_c('v-icon',{staticClass:"navigation-icon prepend",domProps:{"textContent":_vm._s(("mdi-" + _vm.prependIcon))}}):_vm._e(),_c('div',{staticClass:"navigation-title",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('v-icon',{staticClass:"navigation-icon append",domProps:{"textContent":_vm._s(("mdi-" + _vm.appendIcon))}}):_vm._e()],1):_c('a',{staticClass:"navigation disabled transition-all"},[(_vm.prependIcon)?_c('v-icon',{staticClass:"navigation-icon prepend",domProps:{"textContent":_vm._s(("mdi-" + _vm.prependIcon))}}):_vm._e(),_c('div',{staticClass:"navigation-title",domProps:{"textContent":_vm._s(_vm.title)}}),(_vm.appendIcon)?_c('v-icon',{staticClass:"navigation-icon append",domProps:{"textContent":_vm._s(("mdi-" + _vm.appendIcon))}}):_vm._e()],1)],1)]}}])},[_c('span',{staticClass:"navigation-tooltip-message",domProps:{"textContent":_vm._s(_vm.$t(_vm.tooltip))}})])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list class="family-site-list" :dark="dark">
    <v-list-item
      class="family-site-list-item"
      link
      :rel="familySite.href ? 'noopener' : undefined"
      :target="familySite.href ? '_blank' : undefined"
      :href="familySite.href ? familySite.href : undefined"
      v-for="(familySite, familySiteIndex) in familySites"
      :key="familySiteIndex"
    >
      <v-list-item-title
        class="family-site-list-item-title"
        v-text="familySite.title"
      />
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'FamilySiteList',
  data() {
    return {
      rowFamilySites: [
        {
          title: 'Bifrost',
          href: this.$config.link.bifrost,
        },
        {
          title: 'BFC Bot',
          href: this.$config.link.bfc,
        },
        {
          title: 'GitHub',
          href: this.$config.link.gitHub,
        },
        {
          title: 'Feedback',
          href: this.$config.link.productRequest,
        },
      ],
    };
  },
  computed: {
    dark() {
      return !!this.$store.getters.darkMode;
    },
    familySites() {
      return this.rowFamilySites.map(rowFamilySite => ({
        title: rowFamilySite.title,
        href: this.$execFunc(rowFamilySite.href) || rowFamilySite.href,
      }));
    },
  },
};
</script>

<style scoped>
.family-site-list {
  padding: 0px !important;
  border: 1px solid #d5d5d5;
  background-color: #fcfdff;
}

.family-site-list-item {
  min-height: 36px;
  max-height: 36px;
}

.family-site-list-item-title {
  font-size: 14px;
  line-height: normal;
  color: #000000d9;
}

/* dark mode */
#app.dark .family-site-list {
  background-color: #383c52;
}

#app.dark .family-site-list-item-title {
  font-size: 14px;
  line-height: normal;
  color: #ffffffd9;
}
</style>

<template>
  <div class="pool">
    <router-view ref="poolContent" :dex="dex" />
  </div>
</template>

<script>
export default {
  name: 'DexContent',
  data: () => ({
    dex: '',
  }),
  methods: {
    syncData() {
      if (this.$store.getters.isWalletInstability || !this.$refs.poolContent) {
        return;
      }

      this.$execFunc(this.$refs.poolContent.syncData);
    },
  },
};
</script>

<style scoped>
.pool {
  color: #000;
}

/* dark mode */
#app.dark .pool {
  color: #fff;
}
</style>

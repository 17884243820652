import func from '@/plugins/function';

export default {
  setCircuitBreaker(state, { circuitBreaker, then }) {
    state.circuitBreaker = circuitBreaker;

    func.execFunc(then);
  },
  setOracleCircuitBreaker(state, { circuitBreaker, then }) {
    state.oracleCircuitBreaker = circuitBreaker;

    func.execFunc(then);
  },
};

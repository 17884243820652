<template>
  <div class="pool-content">
    <v-layout class="pool-content-header" align-end wrap>
      <v-select
        class="pool-select"
        dense
        outlined
        hide-details
        :dark="isDarkMode"
        :items="$config.dexes"
        :menu-props="{
          'content-class': 'pool-select-menu',
          dark: isDarkMode,
          tile: true,
          'offset-y': true,
        }"
        :value="currentDex"
      >
        <template v-slot:selection="{ item }">
          <div class="pool-select-label">
            <AvatarIconSet :iconSet="[{ image: item.image }]" />
            <div class="pl-2" v-text="item.title" />
          </div>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            class="pool-select-list-item"
            :to="item.to"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-title class="pool-select-list-item-title">
              <AvatarIconSet :iconSet="[{ image: item.image }]" />
              <div class="pl-2" v-text="item.title" />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
      <v-select
        class="pool-select"
        dense
        outlined
        hide-details
        :dark="isDarkMode"
        :items="$config.dexTokens"
        :menu-props="{
          'content-class': 'pool-select-menu',
          dark: isDarkMode,
          tile: true,
          'offset-y': true,
        }"
        :value="
          $config.dexTokens.find(token => token.id === currentToken.srdTokenID)
        "
      >
        <template v-slot:selection="{}">
          <div class="pool-select-label">
            <AvatarIconSet :iconSet="[{ image: currentToken.image }]" />
            <div class="pl-2" v-text="currentToken.title" />
          </div>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item
            class="pool-select-list-item"
            :to="item.to"
            v-bind="attrs"
            v-on="on"
          >
            <v-list-item-title class="pool-select-list-item-title">
              <AvatarIconSet :iconSet="[{ image: item.image }]" />
              <div class="pl-2" v-text="item.title" />
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-select>
    </v-layout>
    <ContentHeader>
      <v-row class="my-n3">
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
          <v-row class="my-n3">
            <v-col cols="12">
              <div
                class="pool-header-subtitle"
                v-text="$t('pool.totalDeposit')"
              />
            </v-col>
            <v-col cols="12">
              <v-tooltip
                bottom
                transition="slide-y-transition"
                :disabled="totalDeposit.lte('0')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="pool-header-value"
                    v-text="formatedTotalDeposit"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span v-text="totalDepositDetail" />
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12">
          <v-row class="my-n3">
            <v-col cols="12">
              <div class="pool-header-subtitle" v-text="$t('pool.poolRate')" />
            </v-col>
            <v-col cols="12">
              <v-tooltip
                bottom
                transition="slide-y-transition"
                :disabled="marketPoolRate.lte('0')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="pool-header-value"
                    v-text="formatedMarketPoolRate"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span v-text="marketPoolRateDetail" />
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 2 : 12">
          <v-row class="my-n3">
            <v-col cols="12">
              <div class="pool-header-subtitle" v-text="$t('pool.apy')" />
            </v-col>
            <v-col cols="12">
              <v-tooltip
                bottom
                transition="slide-y-transition"
                :disabled="apy.lte('0')"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="pool-header-value"
                    v-text="formatedAPY"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span v-text="apyDetail" />
              </v-tooltip>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </ContentHeader>
    <v-row class="mt-n2">
      <transition name="fade-expand">
        <v-col cols="12" v-if="!(progressingHasDeposit || hasDeposit)">
          <CardSrd>
            <template slot="header-title">
              <div class="bold pr-1">Step 1.</div>
              <div
                class="bold"
                v-text="
                  $t('pool.step.getLiquidityToken.title', {
                    unit: currentToken.unit,
                  })
                "
              />
            </template>
            <template slot="body">
              <div
                class="card-srd-body-description"
                v-text="
                  $t('pool.step.getLiquidityToken.description', {
                    token: currentToken.title,
                    unit: currentToken.unit,
                    dex: dexTitle,
                  })
                "
              />
            </template>
            <template slot="footer-button">
              <v-btn
                class="card-srd-footer-button"
                color="#1f7bff"
                depressed
                rel="noopener"
                target="_blank"
                :href="
                  $config.link[dex].add.replace(
                    '{address}',
                    currentSingleTokenAddress
                  )
                "
                v-text="
                  $t('pool.step.getLiquidityToken.button', {
                    token: currentToken.title,
                    dex: dexTitle,
                  })
                "
                disabled
              />
            </template>
          </CardSrd>
        </v-col>
      </transition>
      <transition name="fade-expand">
        <v-col cols="12" v-if="!(progressingHasDeposit || hasDeposit)">
          <CardSrd>
            <template slot="header-title">
              <div class="bold pr-1">Step 2.</div>
              <div class="bold" v-text="$t('pool.step.stakeToken.title')" />
            </template>
            <template slot="body">
              <div
                class="card-srd-body-description"
                v-text="$t('pool.step.stakeToken.description')"
              />
            </template>
            <template slot="footer-button">
              <v-btn
                class="card-srd-footer-button"
                color="#1f7bff"
                depressed
                v-text="
                  $t('common.action.depositToken', { token: currentToken.unit })
                "
                disabled
              />
            </template>
          </CardSrd>
        </v-col>
      </transition>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <CardSrd>
          <template slot="header-title">
            <div v-text="$t('pool.liquidityDeposit')" />
          </template>
          <template slot="header-subtitle">
            <v-tooltip top transition="slide-y-reverse-transition">
              <template v-slot:activator="{ on, attrs }">
                <div v-text="formatedPairLiquidity" v-bind="attrs" v-on="on" />
              </template>
              <span v-text="pairLiquidityDetail" />
            </v-tooltip>
          </template>
          <template slot="body">
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="!hasDeposit"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="card-srd-body-single-value mt-6"
                  v-text="formatedDeposit"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span v-text="depositDetail" />
            </v-tooltip>
            <div
              class="card-srd-body-single-sub-value mb-4"
              v-text="depositAsset"
            />
          </template>
          <template slot="footer-button">
            <v-btn
              class="card-srd-footer-button"
              color="#1f7bff"
              depressed
              v-text="
                $t('common.action.depositToken', { token: currentToken.unit })
              "
              disabled
            />
            <v-btn
              class="card-srd-footer-button"
              color="#44ccd7"
              depressed
              :disabled="!hasDeposit"
              v-text="
                $t('common.action.doAction', {
                  action: $t('common.action.withdraw'),
                })
              "
              @click="entryTrade('withdraw')"
            />
          </template>
        </CardSrd>
      </v-col>
      <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12">
        <CardSrd>
          <template slot="header-title">
            <div v-text="$t('pool.unclaimedBiFi')" />
          </template>
          <template slot="header-subtitle" v-if="userPoolRate.gt('0')">
            <v-tooltip top transition="slide-y-reverse-transition">
              <template v-slot:activator="{ on, attrs }">
                <div v-text="formatedUserPoolRate" v-bind="attrs" v-on="on" />
              </template>
              <span v-text="userPoolRateDetail" />
            </v-tooltip>
          </template>
          <template slot="body">
            <v-tooltip
              bottom
              transition="slide-y-transition"
              :disabled="!hasEarn"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="card-srd-body-single-value mt-6"
                  v-text="formatedEarn"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span v-text="earnDetail" />
            </v-tooltip>
            <div
              class="card-srd-body-single-sub-value mb-4"
              v-text="earnAsset"
            />
          </template>
          <template slot="footer-button">
            <v-btn
              class="card-srd-footer-button"
              color="#1f7bff"
              depressed
              :disabled="!hasEarn"
              v-text="
                $t('common.action.doAction', {
                  action: $t('common.action.claimToken', {
                    token: claimToken.unit,
                  }),
                })
              "
              @click="entryTrade('claim')"
            />
          </template>
        </CardSrd>
      </v-col>
    </v-row>

    <DialogTrade
      :type="dialog.type"
      :color="dialog.color"
      :token="dialog.token"
      @syncData="syncData"
      v-model="dialog.show"
    />
  </div>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

import ContentHeader from '@/components/ContentHeader';
import CardSrd from '@/components/card/CardSrd';

import DialogTrade from '@/components/dialog/Trade';

export default {
  name: 'PoolContent',
  components: {
    AvatarIconSet,
    ContentHeader,
    CardSrd,
    DialogTrade,
  },
  props: {
    dex: String,
  },
  data() {
    return {
      selectedToken: '',
      totalDeposit: new this.$BBN('0'),
      distributedAmount: new this.$BBN('0'),
      hasDepositCache: false,
      deposit: new this.$BBN('0'),
      earn: new this.$BBN('0'),
      apy: new this.$BBN('0'),
      marketPoolRate: new this.$BBN('0'),
      userPoolRate: new this.$BBN('0'),
      yearPoolRate: new this.$BBN('0'),
      pairLiquidity: {
        a: new this.$BBN('0'),
        b: new this.$BBN('0'),
      },
      dialog: {
        type: '',
        color: '#1f7bff',
        token: {},
        show: false,
      },
      token: {
        sendAddress: '',
        maxAmount: new this.$BBN('0'),
        price: new this.$BBN('1'),
        address: '',
      },
      claim: {
        sendAddress: '',
        maxAmount: new this.$BBN('0'),
        price: new this.$BBN('1'),
        address: '',
      },
    };
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.darkMode;
    },
    currentDex() {
      return this.$config.dexes.find(dex => dex.id === this.dex) || {};
    },
    dexID() {
      return this.currentDex.id || '';
    },
    dexTitle() {
      return this.currentDex.title || '';
    },
    dexImage() {
      return this.currentDex.image || '';
    },
    tokenID() {
      return `${this.dex}.${this.selectedToken}`;
    },
    currentSingleTokenAddress() {
      const pairToken =
        this.$config.tokens.find(token => token.id === this.tokenID) || {};
      const singleTokenName =
        (pairToken.pairTokens || []).find(pt => pt !== 'ether') || '';

      return (
        (this.$getToken(this.$store.getters.tokens, singleTokenName) || {})
          .address || ''
      );
    },
    currentToken() {
      const configToken =
        this.$config.tokens.find(token => token.id === this.tokenID) || {};

      let pairTokens;

      if (
        typeof configToken.pairTokens === 'object' &&
        configToken.pairTokens.length > 0
      ) {
        pairTokens = [];

        for (const pairTokenID of configToken.pairTokens) {
          const pairToken = this.$config.tokens.find(
            token => token.id === pairTokenID
          );

          if (pairToken) {
            pairTokens.push(pairToken);
          }
        }
      }

      return Object.assign(
        {
          replaceString: '{}',
        },
        configToken,
        {
          pairTokens,
          isSrdToken: true,
          isToken: this.$getIsToken(
            configToken.id,
            this.$store.getters.walletNetwork
          ),
          id: configToken.id,
          handler: {
            address: this.token.sendAddress,
          },
          maxAmount: {
            'pool.withdraw': this.token.maxAmount,
          },
          price: this.token.price,
          address: this.token.address,
          unitString: configToken.unit,
        }
      );
    },
    currentPairTokens() {
      return (this.currentToken || {}).pairTokens || [];
    },
    claimToken() {
      const configToken =
        this.$config.tokens.find(srdToken => srdToken.id === 'bifi') || {};

      return Object.assign(
        {
          replaceString: '{}',
        },
        configToken,
        {
          isSrdToken: true,
          isToken: this.$getIsToken(
            configToken.id,
            this.$store.getters.walletNetwork
          ),
          id: configToken.id,
          handler: {
            address: this.claim.sendAddress,
          },
          maxAmount: {
            'pool.claim': this.claim.maxAmount,
          },
          price: this.claim.price,
          address: this.claim.address,
          unitString: configToken.unit,
        }
      );
    },
    hasDeposit() {
      return this.deposit.gt('0');
    },
    hasEarn() {
      return this.earn.gt('0');
    },
    formatedTotalDeposit() {
      return this.$curr.getWordFormatedValue(
        this.totalDeposit,
        this.$i18n.locale,
        this.currentToken.replaceString
      );
    },
    formatedDistributedAmount() {
      return this.$curr.getWordFormatedValue(
        this.distributedAmount,
        this.$i18n.locale,
        this.claimToken.replaceString
      );
    },
    formatedDeposit() {
      return this.$curr.getWordFormatedValue(
        this.deposit,
        this.$i18n.locale,
        this.currentToken.replaceString,
        2,
        true
      );
    },
    formatedEarn() {
      return this.$curr.getWordFormatedValue(
        this.earn,
        this.$i18n.locale,
        this.claimToken.replaceString,
        2,
        true
      );
    },
    formatedAPY() {
      let result = '0 %';

      if (this.apy.gt('0.01')) {
        result = this.apy.balanceFormat('{} %', 2, 6);
      } else if (this.apy.gt('0')) {
        result = '<0.01 %';
      }

      return result;
    },
    formatedMarketPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.marketPoolRate,
        this.$i18n.locale,
        `{} ${this.claimToken.unit}/week`
      );
    },
    formatedUserPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.userPoolRate,
        this.$i18n.locale,
        `{} ${this.claimToken.unit}/week`
      );
    },
    formatedYearPoolRate() {
      return this.$curr.getWordFormatedValue(
        this.yearPoolRate,
        this.$i18n.locale,
        `{} ${this.claimToken.unit}/year`
      );
    },
    formatedPairLiquidity() {
      let result = '';

      if (this.currentPairTokens && this.currentPairTokens.length === 2) {
        result = `${this.$curr.getWordFormatedValue(
          this.pairLiquidity.a.mul(this.deposit),
          this.$i18n.locale,
          this.currentPairTokens[0].replaceString || ''
        )} - ${this.$curr.getWordFormatedValue(
          this.pairLiquidity.b.mul(this.deposit),
          this.$i18n.locale,
          this.currentPairTokens[1].replaceString || ''
        )}`;
      }

      return result;
    },
    tokenBalance() {
      return this.$getTokenBalance(
        this.$store.getters.tokens,
        this.currentToken.id
      );
    },
    hasTokenBalance() {
      return this.tokenBalance && this.tokenBalance.gt('0');
    },
    depositAsset() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.deposit,
          this.tokenID,
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    earnAsset() {
      return this.$curr.getWordFormatedValue(
        this.$curr.getFormatedTokenValue(
          this.earn,
          'bifi',
          this.$store.getters.currency
        ),
        this.$i18n.locale,
        this.$store.getters.replaceString,
        2,
        true
      );
    },
    totalDepositDetail() {
      return this.currentToken.replaceString.replace(
        '{}',
        this.totalDeposit.toStr()
      );
    },
    depositDetail() {
      return this.currentToken.replaceString.replace(
        '{}',
        this.deposit.toStr()
      );
    },
    pairLiquidityDetail() {
      let result = '';

      if (this.currentPairTokens && this.currentPairTokens.length === 2) {
        result = `${(this.currentPairTokens[0].replaceString || '').replace(
          '{}',
          this.pairLiquidity.a.mul(this.deposit).toStr()
        )} - ${(this.currentPairTokens[1].replaceString || '').replace(
          '{}',
          this.pairLiquidity.b.mul(this.deposit).toStr()
        )}`;
      }

      return result;
    },
    marketPoolRateDetail() {
      return this.marketPoolRate.balanceFormat(
        `{} ${this.claimToken.unit}/week`,
        18
      );
    },
    userPoolRateDetail() {
      return this.userPoolRate.balanceFormat(
        `{} ${this.claimToken.unit}/week`,
        18
      );
    },
    yearPoolRateDetail() {
      return this.yearPoolRate.balanceFormat(
        `{} ${this.claimToken.unit}/year`,
        18
      );
    },
    apyDetail() {
      return this.apy.balanceFormat('{} %', 18);
    },
    earnDetail() {
      return this.claimToken.replaceString.replace('{}', this.earn.toStr());
    },
    progressingHasDeposit() {
      return this.$store.getters.progressShow && this.hasDepositCache;
    },
  },
  watch: {
    tokenID() {
      this.initializeData();
      this.syncData();
    },
    '$store.getters.progressShow'() {
      this.hasDepositCache = this.hasDeposit;
    },
  },
  methods: {
    entryTrade(type) {
      if (this.$store.getters.isWalletUsable) {
        switch (type) {
          case 'deposit':
          case 'withdraw':
            this.dialog.token = this.currentToken;
            break;
          case 'claim':
            this.dialog.token = this.claimToken;
            break;
          default:
            return;
        }

        this.dialog.type = `pool.${type}`;
        this.dialog.show = true;
      } else {
        this.$store.dispatch('openWalletConnector', {});
      }
    },
    syncData() {
      if (this.$store.getters.isWalletInstability) {
        return;
      }

      const currentToken = this.tokenID;

      this.$store.dispatch('addProgress', {});

      const defaultTargetAddress = this.$store.getters.contract
        .getNetwork()
        .getTokenAddress(currentToken);

      const rpc =
        this.dex === 'uniswap'
          ? this.$store.getters.contract.uniswapRpc
          : this.$store.getters.contract.sushiswapRpc;

      rpc.getPairTokenLiquidities(defaultTargetAddress).then(result => {
        this.pairLiquidity.a = new this.$BBN((result[0] || {}).liquidity);
        this.pairLiquidity.b = new this.$BBN((result[1] || {}).liquidity);
      });

      const tokenPrice = this.$curr.getToken(currentToken).price;
      const bifiTokenPrice = this.$curr.getToken('bifi').price;

      const targetToken =
        this.$getToken(this.$store.getters.tokens, currentToken) || {};
      const bifiToken =
        this.$getToken(this.$store.getters.tokens, 'bifi') || {};

      this.token.address = targetToken.address;
      this.token.sendAddress = targetToken.sendAddress;
      this.claim.sendAddress = targetToken.sendAddress;
      this.claim.address = bifiToken.address;

      this.$store.getters.contract
        .call(`srd.pool.${currentToken}`, this.$store.getters.walletAddress)
        .then(result => {
          if (currentToken !== this.tokenID) {
            this.$store.dispatch('solveProgress', {});
            return;
          }

          this.totalDeposit = new this.$BBN(result.market.totalDeposit);
          this.distributedAmount = new this.$BBN(
            result.market.distributedAmount
          );

          this.deposit = new this.$BBN(result.user.stakedTokenAmount);
          this.earn = new this.$BBN(result.user.earnedTokenAmount);

          this.marketPoolRate = new this.$BBN(result.market.weekPoolRate);
          this.userPoolRate = new this.$BBN(result.user.weekPoolRate);

          this.yearPoolRate = new this.$BBN(result.market.yearPoolRate);
          this.apy = this.yearPoolRate
            .mul(bifiTokenPrice)
            .div(this.totalDeposit.mul(tokenPrice))
            .mul('100');

          this.token.maxAmount = this.deposit;
          this.claim.maxAmount = this.earn;
        })
        .catch(error => {
          // TODO fix metamask header not found
          if (this.$isIgnorableError(error)) {
            return;
          }

          this.$store.dispatch('openAlert', {
            message: 'common.message.dataLoad.error',
            messageArgs: {
              error: `${
                typeof error === 'object'
                  ? error.locale || error.message || error
                  : error
              }`,
            },
            type: 'error',
          });
        })
        .finally(() => this.$store.dispatch('solveProgress', {}));
    },
    initializeData() {
      this.totalDeposit = new this.$BBN('0');
      this.distributedAmount = new this.$BBN('0');
      this.deposit = new this.$BBN('0');
      this.pairLiquidity.a = new this.$BBN('0');
      this.pairLiquidity.b = new this.$BBN('0');
      this.earn = new this.$BBN('0');
      this.apy = new this.$BBN('0');
      this.marketPoolRate = new this.$BBN('0');
      this.userPoolRate = new this.$BBN('0');
      this.yearPoolRate = new this.$BBN('0');

      this.token.sendAddress = '';
      this.token.maxAmount = new this.$BBN('0');
      this.token.price = new this.$BBN('1');
      this.token.address = '';
    },
  },
};
</script>

<style scoped>
.pool-content {
}

.pool-content-header {
  margin: -5px;
  padding-bottom: 16px;
}

.pool-select {
  margin: 5px;
  margin-right: 5px;
  flex: none !important;
  width: 200px;
  cursor: pointer;
}
.pool-select-label {
  display: flex;
  cursor: pointer !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}
.pool-select-list {
}
.pool-select-list-item {
}
.pool-select-list-item-title {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.pool-header {
}

.pool-header-subtitle {
  font-size: 18px;
  letter-spacing: 0.2px;
}

.pool-header-value {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0.3px;
}

/* mobile */
#app.mobile .pool-select-list-item {
  min-height: 34px !important;
  padding: 0 8px !important;
}
#app.mobile .pool-select-list-item-title {
  font-size: 10px;
}

#app.mobile .pool-header-subtitle {
  font-size: 14px;
}

#app.mobile .pool-header-value {
  margin-top: -20px;
  font-size: 18px;
}
</style>

<style>
/* dark mode */
#app.dark .pool-select-menu .v-list {
  background-color: #383c52;
}
</style>

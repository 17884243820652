<template>
  <v-dialog
    content-class="need-wallet"
    width="460"
    max-width="1000"
    v-model="$store.state.ui.needWallet.show"
  >
    <v-layout class="need-wallet-content" column>
      <v-layout class="need-wallet-header" justify-center align-center>
        <span
          class="need-wallet-header-title"
          v-html="$t('common.message.needWallet')"
        />
      </v-layout>
      <v-layout class="need-wallet-wallet-wrapper" column>
        <template v-for="(wallet, walletIndex) in wallets">
          <a
            class="need-wallet-wallet"
            rel="noopener"
            target="_blank"
            :href="wallet.link"
            :key="walletIndex"
            v-ripple
          >
            <v-avatar
              class="need-wallet-wallet-avatar"
              size="44"
              color="#0000001a"
            >
              <v-img
                class="need-wallet-wallet-image"
                width="40"
                height="40"
                contain
                :src="`${$config.host.image}icon/${wallet.image}`"
              />
            </v-avatar>
            <div class="need-wallet-wallet-title" v-text="wallet.title" />
            <v-icon
              class="need-wallet-wallet-icon"
              :dark="$store.getters.darkMode"
              >mdi-open-in-new</v-icon
            >
            <v-btn
              class="need-wallet-wallet-qr-button"
              icon
              :dark="$store.getters.darkMode"
              @click="onClickQR(wallet.id)"
            >
              <v-icon class="need-wallet-wallet-qr-icon" small @click.prevent
                >mdi-qrcode-scan</v-icon
              >
            </v-btn>
          </a>
          <v-expand-transition :key="`qr-${walletIndex}`">
            <div class="need-wallet-qr" v-if="wallet.id === openedWallet">
              <v-img
                width="200"
                height="200"
                max-width="100vw"
                max-height="100vh"
                contain
                :src="`${$config.host.image}qr/${wallet.qr}`"
              />
            </div>
          </v-expand-transition>
        </template>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
export default {
  name: 'NeedWallet',
  data() {
    return {
      type: '',
      openedWallet: '',
      configWallets: [
        {
          id: 'coinbase',
          app: 'coinbase',
          title: 'Coinbase Wallet',
          image: 'coinbase.svg',
          qr: 'coinbase_app_bifi.png',
        },
        {
          id: 'biport',
          app: 'biport',
          title: 'Biport',
          image: 'biport.png',
          qr: 'biport_app_bifi.png',
        },
        {
          id: 'metamask',
          app: 'metamask',
          title: 'MetaMask',
          image: 'metamask.svg',
          qr: 'metamask_app_bifi.png',
        },
      ],
    };
  },
  computed: {
    wallets() {
      return this.configWallets.map(wallet =>
        Object.assign({}, wallet, {
          link:
            this.$config.link.wallet[wallet.id][this.type || 'default'] ||
            this.$config.link.wallet[wallet.id].default,
        })
      );
    },
  },
  watch: {
    '$store.state.ui.needWallet.show'(val) {
      if (val) {
        this.initialize();
      }
    },
  },
  methods: {
    onClickQR(walletID = '') {
      this.openedWallet = this.openedWallet === walletID ? '' : walletID || '';
    },
    initialize() {
      const navigatorUserAgent = (
        (navigator || {}).userAgent || ''
      ).toLowerCase();
      const navigatorVendor = ((navigator || {}).vendor || '').toLowerCase();

      const chromeUserAgentRegex = /chrome/;
      const chromeVendorRegex = /google inc/;

      const firefoxUserAgentRegex = /firefox/;
      const edgeUserAgentRegex = /(edg)|(edge)/;

      const iosUserAgentRegex = /(ipad)|(iphone)|(ipod)/;
      const androidUserAgentRegex = /android/;

      if (navigatorUserAgent.match(iosUserAgentRegex)) {
        this.type = 'ios';
      } else if (navigatorUserAgent.match(androidUserAgentRegex)) {
        this.type = 'android';
      } else {
        if (navigatorUserAgent.match(firefoxUserAgentRegex)) {
          this.type = 'firefox';
        } else if (navigatorUserAgent.match(edgeUserAgentRegex)) {
          this.type = 'edge';
        } else if (
          navigatorUserAgent.match(chromeUserAgentRegex) &&
          navigatorVendor.match(chromeVendorRegex)
        ) {
          this.type = 'chrome';
        }
      }
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
.need-wallet {
}

.need-wallet-content {
  background-color: #fff;
}

.need-wallet-content {
  background-color: #fff;
  overflow: hidden;
}

.need-wallet-header,
.need-wallet-wallet-wrapper {
  margin: 0px;
  padding: 0px 20px;
}

.need-wallet-header {
  padding: 20px;
  border-bottom: 1px solid #d5d5d5;
}

.need-wallet-header-title {
  font-size: 16px;
  font-weight: bold;
  color: #000000d9;
  text-align: center;
  user-select: none;
}

.need-wallet-wallet-wrapper {
  color: #fff;
}

.need-wallet-wallet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px -20px;
  padding: 20px 20px;
  color: #000;
}

.need-wallet-wallet-image {
  flex: 0;
  min-width: 40px;
}

.need-wallet-wallet-title {
  flex: 1;
  padding-left: 24px;
}

.need-wallet-wallet-icon,
.need-wallet-wallet-qr-button {
  margin-left: 24px;
}
.need-wallet-wallet-qr-icon {
}
.need-wallet-qr {
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
}

/* dark mode */

#app.dark .need-wallet-content {
  background-color: #383c52;
}

#app.dark .need-wallet-header-title {
  color: #ffffffd9;
}

#app.dark .need-wallet-wallet {
  color: #fff;
}
</style>

<style>
.need-wallet {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 #00000029;
}

/* mobile */

#app.mobile .need-wallet {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

<template>
  <div class="avatar-icon-set" :style="computedAvatarWrapperStyle">
    <template v-if="iconSet && iconSet.length">
      <v-avatar
        class="avatar"
        :style="computedAvatarStyle"
        :size="computedAvatarSize"
        :color="backgroundColor"
        v-for="(icon, iconIndex) in iconSet"
        :key="iconIndex"
      >
        <v-img
          class="avatar-image"
          :width="computedAvatarImageSize"
          :height="computedAvatarImageSize"
          contain
          :src="`${computedPrependPath}${icon.image}`"
        />
      </v-avatar>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AvatarIconSet',
  props: {
    backgroundColor: {
      type: String,
      default: '#0000001a',
    },
    host: {
      type: String,
      default: undefined,
    },
    prependPath: {
      type: String,
      default: undefined,
    },
    size: {
      type: Number,
      default: 24,
    },
    iconSet: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedHost() {
      return this.host === undefined ? this.$config.host.image : this.host;
    },
    computedPrependPath() {
      return `${this.computedHost}${
        this.prependPath === undefined ? 'icon/' : this.prependPath
      }`;
    },
    computedAvatarSize() {
      return Math.max(
        Math.floor(this.size * (this.$vuetify.breakpoint.mdAndUp ? 1 : 0.75)),
        8
      );
    },
    computedAvatarImageSize() {
      return Math.max(Math.floor(this.computedAvatarSize * 0.92), 6);
    },
    computedMargin() {
      return Math.max(Math.min(this.computedAvatarSize * 0.17, 18), 2);
    },
    computedAvatarWrapperStyle() {
      return {
        'margin-left': `${-this.computedMargin}px`,
        'margin-right': `${-this.computedMargin}px`,
      };
    },
    computedAvatarStyle() {
      return {
        'margin-left': `${this.computedMargin}px`,
        'margin-right': `${this.computedMargin}px`,
      };
    },
  },
};
</script>

<style>
.avatar-icon-set {
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar {
}

.avatar-image {
}
</style>

import func from '@/plugins/function';

export default {
  // progress
  addProgress({ commit, getters }, { then, err, final }) {
    try {
      commit('setProgress', { stack: getters.ui.progress.stack + 1, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  solveProgress({ commit, getters }, { then, err, final }) {
    try {
      commit('setProgress', { stack: getters.ui.progress.stack - 1, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  resetProgress({ commit }, { then, err, final }) {
    try {
      commit('setProgress', { stack: 0, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // alert
  openAlert(
    { commit },
    { message, messageArgs, type, link, then, err, final }
  ) {
    try {
      commit('setAlert', {
        show: true,
        message,
        messageArgs,
        type,
        link,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeAlert({ commit }, { then, err, final }) {
    try {
      commit('setAlert', {
        show: false,
        message: '',
        messageArgs: undefined,
        type: '',
        link: undefined,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // snackbar
  setSnackbarShow({ commit }, { show, then, err, final }) {
    try {
      commit('setSnackbarShow', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  switchSnackbarShow({ commit, getters }, { then, err, final }) {
    try {
      commit('setSnackbarShow', { show: !getters.ui.snackbar.show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  addSnackbar(
    { commit, getters },
    {
      message,
      multiLine,
      vertical,
      mode,
      color,
      buttonMessage,
      buttonAction,
      then,
      err,
      final,
    }
  ) {
    try {
      const duplicatedSnacbarID =
        (
          getters.ui.snackbar.list.find(
            snacbar =>
              snacbar.multiLine === multiLine &&
              snacbar.vertical === vertical &&
              snacbar.mode === mode &&
              snacbar.color === color &&
              snacbar.message === message &&
              snacbar.buttonMessage === buttonMessage
          ) || {}
        ).id || -1;

      if (duplicatedSnacbarID >= 0) {
        commit('removeSnackbar', { id: duplicatedSnacbarID });
      }

      commit('addSnackbar', {
        message,
        multiLine,
        vertical,
        mode,
        color,
        buttonMessage,
        buttonAction,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  removeSnackbar({ commit }, { id, then, err, final }) {
    try {
      commit('removeSnackbar', { id, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // btc.register
  setBtcRegister({ commit }, { show = false, then, err, final }) {
    try {
      commit('setBtcRegister', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openBtcRegister({ commit }, { then, err, final }) {
    try {
      commit('setBtcRegister', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeBtcRegister({ commit }, { then, err, final }) {
    try {
      commit('setBtcRegister', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // btc.edit
  setBtcEdit({ commit }, { show = false, then, err, final }) {
    try {
      commit('setBtcEdit', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openBtcEdit({ commit }, { then, err, final }) {
    try {
      commit('setBtcEdit', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeBtcEdit({ commit }, { then, err, final }) {
    try {
      commit('setBtcEdit', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // needWallet
  setNeedWallet({ commit }, { show = false, then, err, final }) {
    try {
      commit('setNeedWallet', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openNeedWallet({ commit }, { then, err, final }) {
    try {
      commit('setNeedWallet', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeNeedWallet({ commit }, { then, err, final }) {
    try {
      commit('setNeedWallet', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // walletPopup
  setWalletPopup({ commit }, { show = false, then, err, final }) {
    try {
      commit('setWalletPopup', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openWalletPopup({ commit }, { then, err, final }) {
    try {
      commit('setWalletPopup', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeWalletPopup({ commit }, { then, err, final }) {
    try {
      commit('setWalletPopup', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // walletConnector
  setWalletConnector({ commit }, { show = false, then, err, final }) {
    try {
      commit('setWalletConnector', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openWalletConnector({ commit }, { then, err, final }) {
    try {
      commit('setWalletConnector', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeWalletConnector({ commit }, { then, err, final }) {
    try {
      commit('setWalletConnector', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // welcome
  setWelcomePopup({ commit }, { show = false, then, err, final }) {
    try {
      commit('setWelcomePopup', { show, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openWelcomePopup({ commit }, { then, err, final }) {
    try {
      commit('setWelcomePopup', { show: true, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closeWelcomePopup({ commit }, { then, err, final }) {
    try {
      commit('setWelcomePopup', { show: false, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  // popup
  setPopup(
    { commit },
    {
      show = false,
      message = '',
      isConfirm = false,
      left = false,
      right = false,
      confirmAction,
      confirmActionArgs = [],
      then,
      err,
      final,
    }
  ) {
    try {
      commit('setPopup', {
        show,
        message,
        isConfirm,
        left,
        right,
        confirmAction,
        confirmActionArgs,
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  openPopup(
    { commit },
    {
      message,
      isConfirm,
      left = false,
      right = false,
      confirmAction,
      confirmActionArgs,
      then,
      err,
      final,
    }
  ) {
    try {
      commit('setPopup', {
        show: true,
        message: message || '',
        isConfirm: isConfirm || false,
        left: left || false,
        right: right || false,
        confirmAction: confirmAction || undefined,
        confirmActionArgs: confirmActionArgs || [],
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  closePopup({ commit }, { then, err, final }) {
    try {
      commit('setPopup', {
        show: false,
        message: '',
        isConfirm: false,
        confirmAction: () => {},
        then,
      });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

<template>
  <v-dialog
    content-class="dialog register-btc"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    v-model="$store.state.ui.btc.register"
  >
    <v-layout class="dialog-content" column>
      <div class="dialog-header">
        <template v-if="$vuetify.breakpoint.smAndDown">
          <v-btn
            class="ml-4 mr-n2"
            icon
            :dark="$store.getters.darkMode"
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
        </template>
        <div
          class="dialog-header-title"
          v-text="
            $t('trade.content.title', {
              action: $t('common.action.issuanceBtcAddress'),
            })
          "
        />
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-spacer />
          <div class="dialog-header-info">
            <AvatarIconSet :size="36" :iconSet="[{ image: tokenImage }]" />
            <div class="dialog-header-name" v-text="tokenTitle" />
          </div>
        </template>
      </div>
      <v-stepper
        class="stepper"
        :dark="$store.getters.darkMode"
        v-model="currentStep"
      >
        <v-stepper-header class="stepper-header">
          <template v-for="(step, stepIndex) in steps">
            <v-stepper-step
              class="stepper-step transition-all"
              color="#1f7bff"
              :complete="currentStep > stepIndex + 1"
              :step="stepIndex + 1"
              v-if="$vuetify.breakpoint.mdAndUp || currentStep <= stepIndex + 1"
              :key="`${stepIndex}-step`"
            >
              <div class="stepper-step-title" v-text="step.title" />
            </v-stepper-step>

            <v-divider
              class="stepper-step-divider"
              v-if="
                stepIndex !== steps.length - 1 &&
                  ($vuetify.breakpoint.mdAndUp || currentStep <= stepIndex + 1)
              "
              :key="`${stepIndex}-divider`"
            />
          </template>
        </v-stepper-header>
        <v-stepper-items class="stepper-item-wrapper">
          <v-stepper-content
            class="stepper-item"
            :step="stepIndex + 1"
            v-for="(step, stepIndex) in steps"
            :key="`${stepIndex}-content`"
          >
            <v-fade-transition hide-on-leave>
              <component
                :is="currentBody"
                v-bind="currentBodyProps"
                @setAddress="setAddress"
                v-if="stepIndex + 1 === currentStep"
              />
            </v-fade-transition>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <v-fade-transition>
        <div class="loader-wrapper" v-if="pending">
          <Loader
            class="loader"
            color="#47b465"
            secondColor="#1f7bff"
            :borderWeight="2"
            :size="80"
          />
          <a
            class="loader-title link transition-all"
            rel="noopener"
            target="_blank"
            :href="pendingLink"
            v-if="pendingLink"
          >
            <span v-text="pendingMessage" />
            <v-icon class="loader-link-icon" color="#2380fb" small
              >mdi-open-in-new</v-icon
            >
          </a>
          <div
            class="loader-title"
            v-text="pendingMessage"
            v-else-if="pendingMessage"
          />
          <div
            class="loader-title"
            v-t="'common.message.transaction.processing'"
            v-if="pendingTransactionHash"
          />
        </div>
      </v-fade-transition>
      <div class="dialog-footer">
        <div class="dialog-footer-info">
          <v-fade-transition hide-on-leave>
            <div
              class="dialog-footer-info-title description"
              v-html="currentFooterTitle"
              v-if="currentFooterTitle"
            />
          </v-fade-transition>
        </div>
        <div class="dialog-footer-button-wrapper">
          <v-btn
            class="dialog-footer-button"
            color="#1f7bff"
            tile
            depressed
            :disabled="actionButtonDisabled"
            @click="currentButtonAction"
          >
            <span
              class="dialog-footer-button-title"
              v-text="currentButtonTitle"
            />
          </v-btn>
        </div>
      </div>
    </v-layout>
  </v-dialog>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

import Loader from '@/components/Loader';

import RegisterBodyGet from '@/components/btc/register/Get';
import RegisterBodyRegister from '@/components/btc/register/Register';
import RegisterBodyComplete from '@/components/btc/register/Complete';

export default {
  name: 'DialogBtcRegister',
  components: {
    AvatarIconSet,
    Loader,
  },
  data() {
    return {
      currentStep: 1,
      address: '',
      depositAddress: '',
      repayAddress: '',
      pending: undefined,
    };
  },
  computed: {
    steps() {
      return [
        {
          id: 0,
          title: this.$t('trade.content.step.takeBtcAddress'),
          body: RegisterBodyGet,
          button: {
            title: this.$t('trade.content.button.action', {
              action: this.$t('common.action.issuance'),
            }),
            action: this.getBtcAddresses,
          },
        },
        {
          id: 1,
          title: this.$t('trade.content.step.checkAndRegisterAddress'),
          body: RegisterBodyRegister,
          footerTitle: this.$t(
            'trade.content.information.description.issuanceBtcAddress'
          ),
          button: {
            title: this.$t('trade.content.button.action', {
              action: this.$t('common.action.register'),
            }),
            action: this.register,
          },
        },
        {
          id: 2,
          title: this.$t('common.action.actionComplete', {
            action: this.$t('common.action.register'),
          }),
          body: RegisterBodyComplete,
          button: {
            title: this.$t('trade.content.button.confirm'),
            action: this.close,
          },
        },
      ];
    },
    currentStepObject() {
      return this.steps[this.currentStep - 1] || undefined;
    },
    currentStepID() {
      return (this.currentStepObject || {}).id || -1;
    },
    currentBody() {
      return (this.currentStepObject || {}).body || undefined;
    },
    currentBodyProps() {
      return {
        tokenImage: this.tokenImage,
        tokenTitle: this.tokenTitle,
        address: this.address,
        rulesAddress: this.$rulesBtcAddress,
        depositAddress: this.depositAddress,
        repayAddress: this.repayAddress,
      };
    },
    currentFooterTitle() {
      return (this.currentStepObject || {}).footerTitle || '';
    },
    currentButton() {
      return (this.currentStepObject || {}).button || undefined;
    },
    currentButtonTitle() {
      return (this.currentButton || {}).title || '';
    },
    currentButtonAction() {
      return (this.currentButton || {}).action || (() => {});
    },
    actionButtonDisabled() {
      return (
        this.currentStepID === 1 && (!!this.pending || !this.isValidAddress)
      );
    },
    isValidAddress() {
      return !this.$rulesBtcAddress.find(
        ruleAddress =>
          typeof this.$execFunc(ruleAddress, this.address) === 'string'
      );
    },
    token() {
      return this.$config.tokens.find(token => token.id === 'btc');
    },
    tokenTitle() {
      return (this.token || {}).title || '';
    },
    tokenImage() {
      return (this.token || {}).image || '';
    },
    pendingTransactionHash() {
      return (this.pending || {}).transactionHash;
    },
    pendingMessage() {
      return (
        ((this.pending || {}).message &&
          this.$t((this.pending || {}).message)) ||
        ''
      );
    },
    pendingLink() {
      return (
        (this.pendingTransactionHash &&
          this.$getTransactionExplorer(
            this.$store.getters.walletNetwork,
            this.pendingTransactionHash
          )) ||
        ''
      );
    },
  },
  watch: {
    '$store.getters.ui.btc.register'(val) {
      if (val) {
        this.initializing();
      }
    },
  },
  methods: {
    setAddress(value) {
      this.address = value;
    },
    getBtcAddresses() {
      this.pending = {
        message: 'common.message.transaction.btc.processingIssuanceAddress',
      };

      this.$getBtcAddresses(this.$store.getters.walletAddress)
        .then(response => {
          const data = (response || {}).data || {};

          this.depositAddress = data.depositAddress || '';
          this.repayAddress = data.repayAddress || '';

          this.nextStep();
        })
        .finally(() => (this.pending = undefined));
    },
    addTransaction(transactionHash, error, thenFunc) {
      let status = 0;
      let code = undefined;
      let message = undefined;

      if (error || !transactionHash) {
        status = 2;

        if (typeof error === 'object') {
          code = error.code;
          message = error.locale || error.message;

          if (error.code === this.$config.metamask.code.userCancel) {
            status = 3;
            message = 'common.message.transaction.error.cancel';
          }
        } else {
          if (error) {
            message = `${error}`;
          } else {
            message = 'common.message.transaction.error.unknown';
          }
        }
      }

      this.$store.dispatch('addTransaction', {
        token: 'btc',
        contentID: 'common.action.btc.register',
        action: 'common.action.btc.register',
        transactionHash,
        status,
        code,
        message,
        thenFunc,
      });
    },
    register() {
      this.pending = {
        message: 'common.message.transaction.btc.processingIssuanceAddress',
      };

      const address = this.$store.getters.walletAddress;
      const btcAddress = this.address;

      this.$setBtcOutflowAddress(address, btcAddress)
        .then(response => {
          const data = (response || {}).data || {};
          const refundAddress = data.refundAddr || '';

          if (!refundAddress) {
            throw Error('empty refundAddress');
          }

          const { version, hex } = this.$btcAddress2hex(refundAddress);

          if (!hex) {
            throw Error('empty hex');
          }

          const transferInPublicKeyHash = data.transferInPubkeyHash || '';
          const depositPublicKeyHash = data.depositPubkeyHash || '';
          const repayPublicKeyHash = data.repayPubkeyHash || '';
          const v = this.$Web3.utils.padLeft(data.v, 64);
          const r = data.r;
          const s = data.s;

          const publicKeyHashes = [
            hex,
            transferInPublicKeyHash,
            depositPublicKeyHash,
            repayPublicKeyHash,
          ];
          const sig = [v, r, s];
          const from = address;
          const to = this.$store.getters.contract.getEntryPointAddress(
            'bifi.resolver'
          );
          const signature = this.$config.signature.btc.register;
          const args = [version, publicKeyHashes, sig];

          this.$sendTransaction({ from, to, signature, args })
            .then(transactionHash => {
              this.addTransaction(transactionHash, undefined, () => {
                this.pending = undefined;
                this.nextStep();
              });
            })
            .catch(error => {
              console.error('sendTransaction', error);
              this.addTransaction(undefined, error);
              this.pending = undefined;
            });
        })
        .catch(error => {
          console.error('setBtcOutflowAddress', error);
          this.addTransaction(undefined, error);
          this.pending = undefined;
        });
    },
    initializing() {
      this.initializeStep();
      this.initializeAddresses();

      this.pending = undefined;
    },
    nextStep() {
      if (this.currentStep >= this.steps.length) {
        this.initializeStep();
      } else {
        this.currentStep += 1;
      }
    },
    previousStep() {
      if (this.currentStep <= 1) {
        this.initializeStep();
      } else {
        this.currentStep -= 1;
      }
    },
    initializeStep() {
      this.currentStep = 1;
    },
    initializeAddresses() {
      this.address = '';
      this.depositAddress = '';
      this.repayAddress = '';
    },
    close() {
      this.$store.dispatch('closeBtcRegister', {});
    },
  },
  mounted() {
    this.initializing();
  },
};
</script>

<style scoped>
.dialog-content {
  position: relative;
  width: 900px;
  max-width: 100%;
  height: 700px;
  max-height: 100%;
  background-color: #fff;
}

.dialog-header {
  display: flex;
  align-items: center;
  height: 72px;
  padding: 0px 24px;
  border-bottom: solid 1px #d5d5d5;
}
.dialog-header-title {
  font-size: 20px;
  color: #000000d9;
}
.dialog-header-info {
  display: flex;
  align-items: center;
}
.dialog-header-name {
  margin-left: 8px;
  font-size: 14px;
  color: #000000d9;
}

.stepper {
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.stepper-header {
  border-bottom: solid 1px #d5d5d5;
  box-shadow: none !important;
}
.stepper-step {
}
.stepper-step-title {
  font-size: 14px;
}
.stepper-step-divider {
  margin: 0px 20px !important;
}
.stepper-item-wrapper {
  flex: 1;
}
.stepper-item {
  height: 100%;
  padding: 0px;
}

.loader-wrapper {
  position: absolute;
  display: flex;
  top: 144px;
  bottom: 110px;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  user-select: none;
  z-index: 2;
}
.loader {
  margin-bottom: 35px;
}
.loader-title {
  font-size: 14px;
  text-align: center;
  color: #000000d9;
}
.loader-title.link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.loader-title.link:hover {
  opacity: 0.8;
}
.loader-title.link:active {
  opacity: 0.6;
}
.loader-link-icon {
  margin-left: 10px;
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  padding: 0px 16px;
  border-top: solid 1px #d5d5d5;
}
.dialog-footer-info {
  width: 100%;
  height: 100%;
}
.dialog-footer-info-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 24px;
}
.dialog-footer-button-wrapper {
  padding-left: 16px;
}
.dialog-footer-button {
  width: 200px !important;
  height: 68px !important;
}
.dialog-footer-button-title {
  font-size: 20px;
  text-align: center;
}

/* dark mode */
#app.dark .dialog-content {
  background-color: #383c52;
}

#app.dark .dialog-header-title,
#app.dark .dialog-header-name,
#app.dark .loader-title {
  color: #ffffffd9;
}

#app.dark .stepper {
  background-color: #383c52;
}
#app.dark .stepper-step-divider {
  border-color: #00000040 !important;
}
#app.dark .stepper-step-title {
  color: #ffffff40 !important;
}

#app.dark .loader-wrapper {
  background-color: #383c52;
}

/* mobile */
#app.mobile .dialog-content {
  width: 100%;
  height: initial;
  min-height: 100%;
  max-height: initial;
  overflow: hidden;
}

#app.mobile .dialog-header {
  flex-direction: row-reverse;
  height: 50px;
  padding: 0px 14px;
  background-color: #0000001a;
}
#app.mobile .dialog-header-title {
  padding-left: 10px;
  font-size: 14px;
}
#app.mobile .dialog-header-title-button-wrapper {
  height: 100%;
  margin-left: -14px;
  font-size: 12px;
  color: #000000d9;
}
#app.mobile.dark .dialog-header-title-button-wrapper {
  color: #ffffffd9;
}
#app.mobile .dialog-header-title-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0px 14px;
  border-right: 1px solid #0000001f;
  cursor: pointer;
}
#app.mobile.dark .dialog-header-title-button {
  border-color: #4b506d;
}

#app.mobile .stepper-header {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 40px;
  border: none;
}
#app.mobile .stepper:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  max-width: 100px;
  height: 39px;
  background-image: linear-gradient(to right, #ffffff00 8%, #fff 78%);
}
#app.mobile.dark .stepper:after {
  background-image: linear-gradient(to right, #383c5200 8%, #383c52 78%);
}
#app.mobile .stepper-step,
#app.mobile .stepper-step-divider {
  display: inline-flex;
}
#app.mobile .stepper-step {
  padding: 10px 12px;
}
#app.mobile .stepper-step-title {
  font-size: 12px;
}
#app.mobile .stepper-step-divider {
  flex: 0;
  min-width: 30px;
  margin: 0px 10px !important;
}

#app.mobile .loader-wrapper {
  top: 90px;
  bottom: 58px;
}

#app.mobile .dialog-footer {
  flex-direction: column;
  height: unset;
  padding: 0px;
  background-color: #0000001a;
}
#app.mobile .dialog-footer-info {
  height: unset;
}
#app.mobile .dialog-footer-info-title {
  padding: 12px;
  text-align: center;
}
#app.mobile .dialog-footer-button-wrapper {
  width: 100%;
  padding-left: 0px;
}
#app.mobile .dialog-footer-button {
  width: 100% !important;
  height: 58px !important;
}
#app.mobile .dialog-footer-button-title {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>

<style>
.register-btc.dialog {
  max-height: 100%;
  background-color: #f7f7f7;
}

.register-btc.dialog
  > .dialog-content
  .stepper
  .stepper-step
  .v-stepper__step__step {
  margin-right: 8px !important;
  font-size: 14px !important;
}
.register-btc.dialog
  > .dialog-content
  .stepper
  .stepper-step
  .v-stepper__label {
  display: flex !important;
}
.register-btc.dialog
  > .dialog-content
  .stepper
  .stepper-item
  > .v-stepper__wrapper {
  height: 100%;
}

.register-btc.dialog .description {
  font-size: 14px;
  color: #000000d9;
}

/* dark mode */
#app.dark .register-btc.dialog {
  background-color: #2b2d3c;
}

#app.dark
  .register-btc.dialog
  > .dialog-content
  .stepper
  .stepper-step:not(.v-stepper__step--active):not(.v-stepper__step--complete)
  .v-stepper__step__step {
  color: #ffffff40 !important;
  background-color: #00000040 !important;
}

#app.dark .register-btc.dialog .description {
  color: #ffffffd9;
}

/* mobile */
#app.mobile
  .register-btc.dialog
  > .dialog-content
  .stepper
  .stepper-step
  .v-stepper__step__step {
  width: 20px !important;
  min-width: 20px !important;
  max-width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
  max-height: 20px !important;
  font-size: 12px !important;
}

#app.mobile .register-btc.dialog .description {
  font-size: 10px;
}
</style>

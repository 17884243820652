<template>
  <a class="logo transition-all" :href="`https://bifi.finance${query}`">
    <v-img
      class="logo-image"
      contain
      :src="
        `${$config.host.image}logo${
          $store.getters.darkMode ? '_white' : ''
        }.svg`
      "
    />
    <!-- for beta TODO remove -->
    <v-img
      class="beta-image"
      contain
      :src="
        require(`@/assets/images/beta${
          $store.getters.darkMode ? '_white' : ''
        }.svg`)
      "
    />
  </a>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    query() {
      return `?dark=${(this.$store.getters.darkMode && 1) || 0}&lang=${
        this.$i18n.locale
      }`;
    },
  },
};
</script>

<style scoped>
.logo {
  position: relative;
  width: 60px;
  margin: 0px 10px;
}

.logo:hover {
  opacity: 0.8;
}

.logo:active {
  opacity: 0.6;
}

.logo-image {
  width: 100%;
}

.beta-image {
  position: absolute;
  top: 0;
  left: 100%;
  width: 26px;
  max-width: 26px;
  height: 8.66px;
  max-height: 8.66px;
  margin-left: 9px;
}

/* mobile */

#app.mobile .logo {
  width: 36px;
  margin: 0px 5px;
}
</style>

import func from '@/plugins/function';

export default {
  setContract({ commit }, { contract, then, err, final }) {
    try {
      commit('setContract', { contract, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  resetContract({ commit }, { then, err, final }) {
    try {
      commit('setContract', { contract: undefined, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

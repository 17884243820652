<script>
import PoolContent from '@/views/pool/PoolContent';

export default {
  name: 'BfcEth',
  mixins: [PoolContent],
  data: () => ({
    selectedToken: 'bfceth',
  }),
};
</script>

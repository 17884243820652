import vue from 'vue';

import config from '@/plugins/config';
import BiFi from '@/plugins/bifi';
import BBN from '@/plugins/bifrostNumber';

const execFunc = (func, ...args) => {
  try {
    if (typeof func === 'function') {
      return func(...args);
    }
  } catch (error) {
    /**/
  }
};

const storageEach = func =>
  new Promise((resolve, reject) => {
    let storage;
    try {
      storage = window['localStorage'];
      let x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);

      resolve(func(storage));
    } catch (error) {
      reject(error);
    }
  });

const formatCommas = (value, decimalPlaceLength = 18) => {
  const [integerPlaces, decimalPlaces] = new BBN(value).toStr().split('.');
  return `${integerPlaces.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${
    decimalPlaces ? `.${decimalPlaces.slice(0, decimalPlaceLength)}` : ''
  }`;
};

const timeformatDiffNow = timestamp => {
  if (!timestamp) {
    return 'Unknown times ago';
  }

  let result = '';
  let timeValue = 0;

  try {
    timeValue = parseInt(timestamp);
  } catch (error) {
    /**/
  }

  if (`${timeValue}`.length < 13) {
    timeValue *= 1000;
  }

  if (isNaN(timeValue)) {
    timeValue = 0;
  }

  timeValue -= 1000;

  let date = new Date(Date.now() - timeValue);

  let second = Math.floor((date % 60000) / 1000);
  let minute = Math.floor((date % 3600000) / 60000);
  let hour = Math.floor((date % 86400000) / 3600000);
  let day = Math.floor(date / 86400000);
  let month = date.getMonth();
  let year = date.getFullYear() - 1970;

  if (year > 0) {
    result = `${year} years ago`;
  } else if (month > 0) {
    result = `${month} months ago`;
  } else if (day > 0) {
    result = `${day} days ago`;
  } else if (hour > 0) {
    result = `${hour} hours ago`;
  } else if (minute > 0) {
    result = `${minute} min ago`;
  } else if (second > 0) {
    result = `${second} sec ago`;
  } else {
    result = 'just now';
  }

  return result;
};

const timeformatObjectDiffNow = timestamp => {
  if (!timestamp) {
    return {
      text: 'Unknown times ago',
    };
  }

  let time = undefined;
  let text = '';
  let timeValue = 0;

  try {
    timeValue = parseInt(timestamp);
  } catch (error) {
    /**/
  }

  if (`${timeValue}`.length < 13) {
    timeValue *= 1000;
  }

  if (isNaN(timeValue)) {
    timeValue = 0;
  }

  timeValue -= 1000;

  let date = new Date(Date.now() - timeValue);

  let second = Math.floor((date % 60000) / 1000);
  let minute = Math.floor((date % 3600000) / 60000);
  let hour = Math.floor((date % 86400000) / 3600000);
  let day = Math.floor(date / 86400000);
  let month = date.getMonth() - 1;
  let year = date.getFullYear() - 1970;

  if (year > 0) {
    time = year;
    text = 'years ago';
  } else if (month > 0) {
    time = month;
    text = 'months ago';
  } else if (day > 0) {
    time = day;
    text = 'days ago';
  } else if (hour > 0) {
    time = hour;
    text = 'hours ago';
  } else if (minute > 0) {
    time = minute;
    text = 'min ago';
  } else if (second > 0) {
    time = second;
    text = 'sec ago';
  } else {
    text = 'just now';
  }

  return {
    time: time,
    text: text,
  };
};

const timeformatObjectDiff = (timestampA, timestampB) => {
  if (!timestampA || !timestampB) {
    return {
      second: 0,
      minute: 0,
      hour: 0,
      day: 0,
      month: 0,
      year: 0,
    };
  }

  let timeValueA = 0;
  let timeValueB = 0;

  try {
    timeValueA = parseInt(timestampA);
  } catch (error) {
    /**/
  }
  try {
    timeValueB = parseInt(timestampB);
  } catch (error) {
    /**/
  }

  if (`${timeValueA}`.length < 13) {
    timeValueA *= 1000;
  }
  if (`${timeValueB}`.length < 13) {
    timeValueB *= 1000;
  }

  if (isNaN(timeValueA)) {
    timeValueA = 0;
  }
  if (isNaN(timeValueB)) {
    timeValueB = 0;
  }

  let date = new Date(timeValueB - timeValueA);

  let second = Math.floor((date % 60000) / 1000);
  let minute = Math.floor((date % 3600000) / 60000);
  let hour = Math.floor((date % 86400000) / 3600000);

  date = new Date(date - 32400000);

  let day = date.getDate() - 1;
  let month = date.getMonth() - 1;
  let year = date.getFullYear() - 1970;

  return {
    second,
    minute,
    hour,
    day,
    month,
    year,
  };
};

const timeformatObject = timestamp => {
  if (!timestamp) {
    return {
      second: 0,
      minute: 0,
      hour: 0,
      day: 0,
      month: 0,
      year: 0,
    };
  }

  let timeValue = 0;

  try {
    timeValue = parseInt(timestamp);
  } catch (error) {
    /**/
  }

  if (`${timeValue}`.length < 13) {
    timeValue *= 1000;
  }

  if (isNaN(timeValue)) {
    timeValue = 0;
  }

  let date = new Date(timeValue);

  let second = Math.floor((date % 60000) / 1000);
  let minute = Math.floor((date % 3600000) / 60000);
  let hour = Math.floor((date % 86400000) / 3600000);
  let day = date.getDate();
  let month = date.getMonth();
  let year = date.getFullYear() - 1970;

  return {
    second,
    minute,
    hour,
    day,
    month,
    year,
  };
};

const timeformat = timestamp => {
  if (!timestamp) {
    return 'Unknown time';
  }

  let timeValue = 0;

  try {
    timeValue = parseInt(timestamp);
  } catch (error) {
    /**/
  }

  if (`${timeValue}`.length < 13) {
    timeValue *= 1000;
  }

  if (isNaN(timeValue)) {
    timeValue = 0;
  }

  let date = new Date(timeValue);

  let seconds = date.getSeconds();
  let minute = date.getMinutes();
  let hour = date.getHours();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  seconds = seconds < 10 ? `0${seconds}` : seconds;
  minute = minute < 10 ? `0${minute}` : minute;
  hour = hour < 10 ? `0${hour}` : hour;

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
};

const isTimeDiffBy = (time1, time2, diff) => {
  if (!time1 || !time2) {
    return false;
  }

  try {
    time1 = parseInt(time1);
    time2 = parseInt(time2);
    diff = parseInt(diff);
  } catch (error) {
    /**/
  }

  if (`${time1}`.length < 13) {
    time1 *= 1000;
  }
  if (`${time2}`.length < 13) {
    time2 *= 1000;
  }

  if (isNaN(time1) || isNaN(time2) || isNaN(diff)) {
    return false;
  }

  return time1 - time2 <= diff;
};

const getToken = (tokens, tokenID) => {
  return (tokens || []).find(token => token.id === tokenID);
};

const getTokenBalance = (tokens, tokenID) => {
  return (getToken(tokens, tokenID) || {}).balance || new BBN('0');
};

const getTokenAllowance = (tokens, tokenID) => {
  return (getToken(tokens, tokenID) || {}).allowance || new BBN('0');
};

const getTokenDecimals = (tokens, tokenID) => {
  return (getToken(tokens, tokenID) || {}).decimals || new BBN('18');
};

const getIsToken = (tokenID, network) => {
  let result = config.typeTokens.indexOf(tokenID) > -1;

  if (!result) {
    switch (getNetworkArea(network)) {
      case 'ethereum':
        result =
          tokenID !== 'ether' &&
          (tokenID === 'bnb' ||
            tokenID === 'avax' ||
            tokenID === 'kaia' ||
            tokenID === 'bfc');
        break;
      case 'bsc':
        result = tokenID !== 'bnb' || tokenID === 'ether';
        break;
      case 'avalanche':
        result = tokenID !== 'avax' || tokenID === 'ether';
        break;
      case 'kaia':
        result = tokenID !== 'kaia' || tokenID === 'ether';
        break;
      case 'bifrost':
        result = tokenID !== 'bfc' || tokenID === 'ether';
        break;
    }
  }

  return result;
};

const getIsDisabledDepositToken = tokenID => {
  const token = tokenID && config.tokens.find(token => token.id === tokenID);

  return token && typeof token === 'object' ? token.disabledDeposit : true;
};

const getIsDisabledBorrowToken = tokenID => {
  const token = tokenID && config.tokens.find(token => token.id === tokenID);

  return token && typeof token === 'object' ? token.disabledBorrow : true;
};

const getIsDisabledToken = tokenID => {
  const token = tokenID && config.tokens.find(token => token.id === tokenID);

  return token && typeof token === 'object' ? token.disabled : true;
};

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const getTransactionErrorMessage = error => {
  let message = 'common.message.transaction.error.unknown';

  if (typeof error === 'object') {
    if (error.code) {
      switch (error.code) {
        case config.metamask.code.pending:
          message = 'common.message.transaction.error.pending';
          break;
        case config.metamask.code.internal:
          message = 'common.message.transaction.error.internal';
          break;
        case config.metamask.code.parameterInvalid:
          message = 'common.message.transaction.error.parameter';
          break;
        case config.metamask.code.userCancel:
          message = 'common.message.transaction.error.cancel';
          break;
        case config.metamask.code.timeout:
          message = 'common.message.transaction.error.timeout';
          break;
      }
    } else if (error.locale) {
      message = error.locale;
    }
  }

  return message;
};

const getExplorerPrefix = network => {
  let result = '';

  switch (BiFi.parseNetwork(`${network}`.toLowerCase())) {
    case 'mainnet':
      result = '';
      break;
    case 'ropsten':
      result = 'ropsten.';
      break;
    case 'rinkeby':
      result = 'rinkeby.';
      break;
    case 'goerli':
      result = 'goerli.';
      break;
    case 'kovan':
      result = 'kovan.';
      break;
    case 'bsc':
      result = '';
      break;
    case 'bsc-test':
      result = 'testnet.';
      break;
    case 'avalanche':
      result = '';
      break;
    case 'avalanche-test':
      result = 'testnet.';
      break;
    case 'kaia':
      result = '';
      break;
    case 'kaia-test':
      result = 'testnet.';
      break;
    case 'bifrost':
      result = '';
      break;
    case 'bifrost-test':
      result = '';
      break;
    default:
      result = '';
      break;
  }

  return result;
};

const getNetworkString = (network, short) => {
  let result = '';

  switch (BiFi.parseNetwork(`${network}`.toLowerCase())) {
    case 'mainnet':
      result = short ? 'Ethereum' : 'Ethereum Mainnet';
      break;
    case 'ropsten':
      result = short ? 'Ropsten' : 'Ropsten Testnet';
      break;
    case 'rinkeby':
      result = short ? 'Rinkeby' : 'Rinkeby Testnet';
      break;
    case 'goerli':
      result = short ? 'Goerli' : 'Goerli Testnet';
      break;
    case 'kovan':
      result = short ? 'Kovan' : 'Kovan Testnet';
      break;
    case 'bsc':
      result = short ? 'BSC Net' : 'Binance Mainnet';
      break;
    case 'bsc-test':
      result = short ? 'BSC Testnet' : 'Binance Testnet';
      break;
    case 'avalanche':
      result = short ? 'AVA Net' : 'Avalanche Mainnet';
      break;
    case 'avalanche-test':
      result = short ? 'AVA Testnet' : 'Avalanche Testnet';
      break;
    case 'kaia':
      result = short ? 'KAIA' : 'Kaia Mainnet';
      break;
    case 'kaia-test':
      result = short ? 'KAIA Kairos' : 'Kaia Kairos Testnet';
      break;
    case 'bifrost':
      result = short ? 'BIFROST' : 'Bifrost Mainnet';
      break;
    case 'bifrost-test':
      result = short ? 'BIT' : 'Bifrost Testnet';
      break;
    case 'pilab-poa2':
      result = short ? 'PiLab Poa2' : 'PiLab Poa2 Testnet';
      break;
    case 'pilab-fork':
      result = short ? 'PiLab Fork' : 'PiLab Fork Testnet';
      break;
    default:
      result = '';
      break;
  }

  return result;
};

const getNetworkArea = network => {
  let result = '';

  switch (BiFi.parseNetwork(`${network}`.toLowerCase())) {
    case 'mainnet':
    case 'ropsten':
    case 'rinkeby':
    case 'goerli':
    case 'kovan':
    case 'pilab-poa2':
    case 'pilab-fork':
      result = 'ethereum';
      break;
    case 'bsc':
    case 'bsc-test':
      result = 'bsc';
      break;
    case 'avalanche':
    case 'avalanche-test':
      result = 'avalanche';
      break;
    case 'kaia':
    case 'kaia-test':
      result = 'kaia';
      break;
    case 'bifrost':
    case 'bifrost-test':
      result = 'bifrost';
      break;
    default:
      result = '';
      break;
  }

  return result;
};

const getNetworkExplorer = (network, type) => {
  let result = '';

  switch (getNetworkArea(network)) {
    case 'ethereum':
      result = config.host.etherscan[type] || '';
      break;
    case 'bsc':
      result = config.host.bscscan[type] || '';
      break;
    case 'avalanche':
      result = config.host.avalanchescan[type] || '';
      break;
    case 'kaia':
      result = config.host.kaiascan[type] || '';
      break;
    case 'bifrost-test':
      result = config.host.bifrosttestscan[type] || '';
      break;
    case 'bifrost':
      result = config.host.bifrostscan[type] || '';
      break;
    default:
      result = '';
      break;
  }

  return result;
};

const getTransactionExplorer = (network, transactionHash) => {
  return getNetworkExplorer(network, 'transaction')
    .replace('{prefix}', getExplorerPrefix(network))
    .replace('{transactionHash}', transactionHash);
};

const getAddressExplorer = (network, address) => {
  return getNetworkExplorer(network, 'address')
    .replace('{prefix}', getExplorerPrefix(network))
    .replace('{address}', address);
};

const isIgnorableError = error => {
  return !!(
    typeof error === 'object' &&
    ((error || {}).code === config.metamask.code.headerNotFound ||
      ((error || {}).code === config.metamask.code.internal &&
        typeof (error || {}).data === 'object' &&
        ((error || {}).data || {}).code ===
          config.metamask.code.headerNotFound) ||
      (error || {}).code === config.metamask.code.diffNetwork ||
      (error || {}).code === config.metamask.code.noResponse)
  );
};

const getChainConfig = id => {
  return config.chains.find(chain => chain.id === id);
};

vue.prototype.$execFunc = execFunc;
vue.prototype.$storageEach = storageEach;
vue.prototype.$formatCommas = formatCommas;
vue.prototype.$timeformatDiffNow = timeformatDiffNow;
vue.prototype.$timeformatObjectDiffNow = timeformatObjectDiffNow;
vue.prototype.$timeformatObjectDiff = timeformatObjectDiff;
vue.prototype.$timeformatObject = timeformatObject;
vue.prototype.$timeformat = timeformat;
vue.prototype.$isTimeDiffBy = isTimeDiffBy;
vue.prototype.$getToken = getToken;
vue.prototype.$getTokenBalance = getTokenBalance;
vue.prototype.$getTokenAllowance = getTokenAllowance;
vue.prototype.$getTokenDecimals = getTokenDecimals;
vue.prototype.$getIsToken = getIsToken;
vue.prototype.$getIsDisabledDepositToken = getIsDisabledDepositToken;
vue.prototype.$getIsDisabledBorrowToken = getIsDisabledBorrowToken;
vue.prototype.$getIsDisabledToken = getIsDisabledToken;
vue.prototype.$sleep = sleep;
vue.prototype.$getTransactionErrorMessage = getTransactionErrorMessage;
vue.prototype.$getExplorerPrefix = getExplorerPrefix;
vue.prototype.$getNetworkString = getNetworkString;
vue.prototype.$getNetworkArea = getNetworkArea;
vue.prototype.$getNetworkExplorer = getNetworkExplorer;
vue.prototype.$getTransactionExplorer = getTransactionExplorer;
vue.prototype.$getAddressExplorer = getAddressExplorer;
vue.prototype.$isIgnorableError = isIgnorableError;
vue.prototype.$getChainConfig = getChainConfig;

export default {
  execFunc,
  storageEach,
  formatCommas,
  timeformatDiffNow,
  timeformatObjectDiffNow,
  timeformatObjectDiff,
  timeformatObject,
  timeformat,
  isTimeDiffBy,
  getToken,
  getTokenBalance,
  getTokenAllowance,
  getTokenDecimals,
  getIsToken,
  getIsDisabledDepositToken,
  getIsDisabledBorrowToken,
  getIsDisabledToken,
  sleep,
  getTransactionErrorMessage,
  getExplorerPrefix,
  getNetworkString,
  getNetworkArea,
  getNetworkExplorer,
  getTransactionExplorer,
  getAddressExplorer,
  isIgnorableError,
  getChainConfig,
};

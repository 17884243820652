export default {
  syncLocalStorage(
    state,
    { connectionCount, darkMode, language, currency, then }
  ) {
    this.commit('setConnectionCount', { connectionCount });
    this.commit('setDarkMode', { darkMode });
    this.commit('setLanguage', { language });
    this.commit('setCurrency', { currency, then });
  },
  syncTransaction(state, { transaction, then }) {
    this.commit('setTransaction', {
      id: transaction.id,
      hasCheck: transaction.hasCheck,
      list: transaction.list,
      then,
    });
  },
};

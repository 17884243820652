import func from '@/plugins/function';

export default {
  // darkMode
  setDarkMode({ commit }, { darkMode, then, err, final }) {
    try {
      commit('setDarkMode', { darkMode, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
  switchDarkMode({ commit, getters }, { then, err, final }) {
    try {
      commit('setDarkMode', { darkMode: !getters.darkMode, then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },

  storeDarkMode({ commit }, { then, err, final }) {
    try {
      commit('storeDarkMode', { then });
    } catch (error) {
      func.execFunc(err, error);
    }

    func.execFunc(final);
  },
};

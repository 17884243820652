var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pool-content"},[_c('v-layout',{staticClass:"pool-content-header",attrs:{"align-end":"","wrap":""}},[_c('v-select',{staticClass:"pool-select",attrs:{"dense":"","outlined":"","hide-details":"","dark":_vm.isDarkMode,"items":_vm.$config.dexes,"menu-props":{
        'content-class': 'pool-select-menu',
        dark: _vm.isDarkMode,
        tile: true,
        'offset-y': true,
      },"value":_vm.currentDex},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"pool-select-label"},[_c('AvatarIconSet',{attrs:{"iconSet":[{ image: item.image }]}}),_c('div',{staticClass:"pl-2",domProps:{"textContent":_vm._s(item.title)}})],1)]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"pool-select-list-item",attrs:{"to":item.to}},'v-list-item',attrs,false),on),[_c('v-list-item-title',{staticClass:"pool-select-list-item-title"},[_c('AvatarIconSet',{attrs:{"iconSet":[{ image: item.image }]}}),_c('div',{staticClass:"pl-2",domProps:{"textContent":_vm._s(item.title)}})],1)],1)]}}])}),_c('v-select',{staticClass:"pool-select",attrs:{"dense":"","outlined":"","hide-details":"","dark":_vm.isDarkMode,"items":_vm.$config.dexTokens,"menu-props":{
        'content-class': 'pool-select-menu',
        dark: _vm.isDarkMode,
        tile: true,
        'offset-y': true,
      },"value":_vm.$config.dexTokens.find(function (token) { return token.id === _vm.currentToken.srdTokenID; })},scopedSlots:_vm._u([{key:"selection",fn:function(ref){return [_c('div',{staticClass:"pool-select-label"},[_c('AvatarIconSet',{attrs:{"iconSet":[{ image: _vm.currentToken.image }]}}),_c('div',{staticClass:"pl-2",domProps:{"textContent":_vm._s(_vm.currentToken.title)}})],1)]}},{key:"item",fn:function(ref){
      var item = ref.item;
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"pool-select-list-item",attrs:{"to":item.to}},'v-list-item',attrs,false),on),[_c('v-list-item-title',{staticClass:"pool-select-list-item-title"},[_c('AvatarIconSet',{attrs:{"iconSet":[{ image: item.image }]}}),_c('div',{staticClass:"pl-2",domProps:{"textContent":_vm._s(item.title)}})],1)],1)]}}])})],1),_c('ContentHeader',[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pool-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('pool.totalDeposit'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.totalDeposit.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pool-header-value",domProps:{"textContent":_vm._s(_vm.formatedTotalDeposit)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.totalDepositDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 5 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pool-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('pool.poolRate'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.marketPoolRate.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pool-header-value",domProps:{"textContent":_vm._s(_vm.formatedMarketPoolRate)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.marketPoolRateDetail)}})])],1)],1)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 2 : 12}},[_c('v-row',{staticClass:"my-n3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"pool-header-subtitle",domProps:{"textContent":_vm._s(_vm.$t('pool.apy'))}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":_vm.apy.lte('0')},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"pool-header-value",domProps:{"textContent":_vm._s(_vm.formatedAPY)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.apyDetail)}})])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-n2"},[_c('transition',{attrs:{"name":"fade-expand"}},[(!(_vm.progressingHasDeposit || _vm.hasDeposit))?_c('v-col',{attrs:{"cols":"12"}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{staticClass:"bold pr-1"},[_vm._v("Step 1.")]),_c('div',{staticClass:"bold",domProps:{"textContent":_vm._s(
                _vm.$t('pool.step.getLiquidityToken.title', {
                  unit: _vm.currentToken.unit,
                })
              )}})]),_c('template',{slot:"body"},[_c('div',{staticClass:"card-srd-body-description",domProps:{"textContent":_vm._s(
                _vm.$t('pool.step.getLiquidityToken.description', {
                  token: _vm.currentToken.title,
                  unit: _vm.currentToken.unit,
                  dex: _vm.dexTitle,
                })
              )}})]),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","rel":"noopener","target":"_blank","href":_vm.$config.link[_vm.dex].add.replace(
                  '{address}',
                  _vm.currentSingleTokenAddress
                ),"disabled":""},domProps:{"textContent":_vm._s(
                _vm.$t('pool.step.getLiquidityToken.button', {
                  token: _vm.currentToken.title,
                  dex: _vm.dexTitle,
                })
              )}})],1)],2)],1):_vm._e()],1),_c('transition',{attrs:{"name":"fade-expand"}},[(!(_vm.progressingHasDeposit || _vm.hasDeposit))?_c('v-col',{attrs:{"cols":"12"}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{staticClass:"bold pr-1"},[_vm._v("Step 2.")]),_c('div',{staticClass:"bold",domProps:{"textContent":_vm._s(_vm.$t('pool.step.stakeToken.title'))}})]),_c('template',{slot:"body"},[_c('div',{staticClass:"card-srd-body-description",domProps:{"textContent":_vm._s(_vm.$t('pool.step.stakeToken.description'))}})]),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":""},domProps:{"textContent":_vm._s(
                _vm.$t('common.action.depositToken', { token: _vm.currentToken.unit })
              )}})],1)],2)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('pool.liquidityDeposit'))}})]),_c('template',{slot:"header-subtitle"},[_c('v-tooltip',{attrs:{"top":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.formatedPairLiquidity)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.pairLiquidityDetail)}})])],1),_c('template',{slot:"body"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.hasDeposit},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"card-srd-body-single-value mt-6",domProps:{"textContent":_vm._s(_vm.formatedDeposit)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.depositDetail)}})]),_c('div',{staticClass:"card-srd-body-single-sub-value mb-4",domProps:{"textContent":_vm._s(_vm.depositAsset)}})],1),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":""},domProps:{"textContent":_vm._s(
              _vm.$t('common.action.depositToken', { token: _vm.currentToken.unit })
            )}}),_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#44ccd7","depressed":"","disabled":!_vm.hasDeposit},domProps:{"textContent":_vm._s(
              _vm.$t('common.action.doAction', {
                action: _vm.$t('common.action.withdraw'),
              })
            )},on:{"click":function($event){return _vm.entryTrade('withdraw')}}})],1)],2)],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 12}},[_c('CardSrd',[_c('template',{slot:"header-title"},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('pool.unclaimedBiFi'))}})]),(_vm.userPoolRate.gt('0'))?_c('template',{slot:"header-subtitle"},[_c('v-tooltip',{attrs:{"top":"","transition":"slide-y-reverse-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({domProps:{"textContent":_vm._s(_vm.formatedUserPoolRate)}},'div',attrs,false),on))]}}],null,false,2128407271)},[_c('span',{domProps:{"textContent":_vm._s(_vm.userPoolRateDetail)}})])],1):_vm._e(),_c('template',{slot:"body"},[_c('v-tooltip',{attrs:{"bottom":"","transition":"slide-y-transition","disabled":!_vm.hasEarn},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"card-srd-body-single-value mt-6",domProps:{"textContent":_vm._s(_vm.formatedEarn)}},'div',attrs,false),on))]}}])},[_c('span',{domProps:{"textContent":_vm._s(_vm.earnDetail)}})]),_c('div',{staticClass:"card-srd-body-single-sub-value mb-4",domProps:{"textContent":_vm._s(_vm.earnAsset)}})],1),_c('template',{slot:"footer-button"},[_c('v-btn',{staticClass:"card-srd-footer-button",attrs:{"color":"#1f7bff","depressed":"","disabled":!_vm.hasEarn},domProps:{"textContent":_vm._s(
              _vm.$t('common.action.doAction', {
                action: _vm.$t('common.action.claimToken', {
                  token: _vm.claimToken.unit,
                }),
              })
            )},on:{"click":function($event){return _vm.entryTrade('claim')}}})],1)],2)],1)],1),_c('DialogTrade',{attrs:{"type":_vm.dialog.type,"color":_vm.dialog.color,"token":_vm.dialog.token},on:{"syncData":_vm.syncData},model:{value:(_vm.dialog.show),callback:function ($$v) {_vm.$set(_vm.dialog, "show", $$v)},expression:"dialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
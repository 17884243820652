<template>
  <div class="circuit-breaker">
    <v-layout class="circuit-breaker-wrapper transition-all" column>
      <div
        class="circuit-breaker-title"
        v-html="$t('common.message.circuitBreaker')"
      />
      <v-progress-linear
        class="circuit-breaker-progress"
        color="white"
        height="4"
        rounded
        indeterminate
      />
    </v-layout>
  </div>
</template>

<script>
export default {
  name: 'CircuitBreaker',
};
</script>

<style scoped>
.circuit-breaker {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #00000066;
  z-index: 97;
}

.circuit-breaker-wrapper {
  max-width: 500px;
}

.circuit-breaker:active .circuit-breaker-wrapper {
  transform: scale(0.95);
}

.circuit-breaker-title {
  padding: 12px;
  color: #fff;
  font-size: 14px;
  text-align: center;
  user-select: none;
}

.circuit-breaker-progress {
}
</style>

<style>
.circuit-breaker {
  box-shadow: none !important;
}
</style>

<template>
  <Card class="full-height">
    <div class="card-srd" slot="body">
      <v-layout class="card-srd-header">
        <v-layout class="card-srd-header-title" align-center>
          <slot name="header-title" />
        </v-layout>
        <v-spacer />
        <v-layout class="card-srd-header-subtitle" justify-end align-center>
          <slot name="header-subtitle" />
        </v-layout>
      </v-layout>
      <div class="card-srd-body">
        <slot name="body" />
      </div>
      <CardDivider class="card-srd-footer-divider" v-if="footerDivider" />
      <v-layout class="card-srd-footer" justify-space-between>
        <v-layout class="card-srd-footer-button-wrapper">
          <slot name="footer-button" />
        </v-layout>
      </v-layout>
    </div>
  </Card>
</template>

<script>
import Card from '@/components/card/Card';
import CardDivider from '@/components/card/CardDivider';

export default {
  name: 'CardSrd',
  components: {
    Card,
    CardDivider,
  },
  props: {
    footerDivider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.card-srd {
  height: 100%;
  padding: 20px;
  color: #000;
}

.card-srd-header {
  margin-bottom: 12px;
}

.card-srd-header-title {
  font-weight: bold;
  letter-spacing: 0.5px;
}

.card-srd-header-subtitle {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-align: right;
}

.card-srd-body {
  max-height: 100%;
  padding-bottom: 10px;
}

.card-srd-footer-divider {
  padding: 10px 0px;
}

.card-srd-footer {
  padding-top: 10px;
}

.card-srd-footer-info-wrapper {
}

.card-srd-footer-button-wrapper {
  justify-content: flex-end;
  margin: 0px -5px;
  text-align: right;
}

/* dark mode */

#app.dark .card-srd {
  color: #fff;
}

/* mobile */

#app.mobile .card-srd {
  padding: 14px;
}

#app.mobile .card-srd-header {
  margin-bottom: 8px;
}

#app.mobile .card-srd-header-title {
  font-size: 10px;
}

#app.mobile .card-srd-header-subtitle {
  font-size: 10px;
}

#app.mobile .card-srd-body {
  padding-bottom: 6px;
}

#app.mobile .card-srd-footer-divider {
  padding: 6px 0px;
}

#app.mobile .card-srd-footer {
  padding-top: 6px;
}

#app.mobile .card-srd-footer-info-wrapper {
  font-size: 10px;
}

#app.mobile .card-srd-footer-button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  text-align: right;
}
</style>

<style>
.card-srd .card-srd-header-button {
  min-width: 142px !important;
  height: 40px !important;
  min-height: 40px;
  margin: 0px 5px;
  border-radius: 3px;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
}

.card-srd .card-srd-header-button:last-child {
  margin-right: 0px;
}

.card-srd .card-srd-body-description {
  font-size: 16px;
}

.card-srd .card-srd-body-label {
  font-size: 18px;
  letter-spacing: 0.5px;
}

.card-srd .card-srd-body-value {
  font-size: 18px;
  letter-spacing: 0.6px;
  font-weight: bold;
  text-align: right;
}

.card-srd .card-srd-body-label.large {
  font-size: 30px;
  font-weight: bold;
}

.card-srd .card-srd-body-value.large {
  font-size: 30px;
  font-weight: bold;
  text-align: right;
}

.card-srd .card-srd-body-title {
  font-size: 24px;
  font-weight: bold;
}

.card-srd .card-srd-body-single-small-value {
  font-size: 26px;
  letter-spacing: 0.87px;
}

.card-srd .card-srd-body-single-middle-value {
  font-size: 30px;
  font-weight: bold;
}

.card-srd .card-srd-body-single-value {
  font-size: 34px;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1.13px;
}

.card-srd .card-srd-body-single-sub-value {
  padding-top: 14px;
  text-align: center;
  font-size: 14px;
  opacity: 0.5;
}

.card-srd .card-srd-body-link {
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  letter-spacing: 0.67px;
  color: #1f7bff;
}

.card-srd .card-srd-body-link:hover {
  opacity: 0.8;
}

.card-srd .card-srd-body-link:active {
  opacity: 0.6;
}

.card-srd .card-srd-body-link.disabled {
  opacity: 0.3;
  cursor: default;
}

.card-srd .card-srd-body-link-icon {
  color: #1f7bff;
  margin-left: 10px;
}

.card-srd .card-srd-footer-label {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.card-srd .card-srd-footer-value {
  font-size: 18px;
  font-weight: bold;
  text-align: right;
}

.card-srd .card-srd-footer-button {
  min-width: 142px !important;
  height: 40px !important;
  min-height: 40px;
  margin: 0px 5px;
  border-radius: 3px;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-transform: none;
}

/* mobile */

#app.mobile .card-srd .card-srd-body-description {
  font-size: 12px;
}

#app.mobile .card-srd .card-srd-body-label {
  font-size: 14px;
}

#app.mobile .card-srd .card-srd-body-value {
  font-size: 14px;
  text-align: right;
}

#app.mobile .card-srd .card-srd-body-label.large {
  font-size: 24px;
}

#app.mobile .card-srd .card-srd-body-value.large {
  font-size: 24px;
  text-align: right;
}

#app.mobile .card-srd .card-srd-body-title {
  font-size: 20px;
}

#app.mobile .card-srd .card-srd-body-single-small-value {
  font-size: 22px;
}

#app.mobile .card-srd .card-srd-body-single-middle-value {
  font-size: 24px;
}

#app.mobile .card-srd .card-srd-body-single-value {
  font-size: 26px;
  letter-spacing: 1px;
  text-align: center;
}

#app.mobile .card-srd .card-srd-body-single-sub-value {
  padding-top: 8px;
  font-size: 12px;
}

#app.mobile .card-srd .card-srd-body-link {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
}

#app.mobile .card-srd .card-srd-footer-label {
  font-size: 14px;
}

#app.mobile .card-srd .card-srd-footer-value {
  font-size: 14px;
  text-align: right;
}

#app.mobile .card-srd .card-srd-footer-button {
  min-width: 100px !important;
  height: 30px !important;
  min-height: 30px;
  margin: 5px;
  padding: 0px 8px !important;
  font-size: 11px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"content-class":"dialog trade","max-width":"900","fullscreen":_vm.$vuetify.breakpoint.smAndDown,"value":_vm.value},on:{"input":_vm.dialogInput}},[_c('v-layout',{staticClass:"dialog-content-wrapper",attrs:{"column":""}},[_c(_vm.currentContent,_vm._b({ref:"content",tag:"component",on:{"pairMenuChange":_vm.pairMenuChange,"setMarketAmountStatus":_vm.setMarketAmountStatus,"dialogClose":function($event){return _vm.dialogInput(false)},"close":_vm.close}},'component',{
        color: this.color,
        tokenImage: this.tokenImage,
        tokenID: this.tokenID,
        tokenTitle: this.tokenTitle,
        tokenAddress: _vm.tokenAddress,
        tokenSendAddress: this.tokenSendAddress,
        tokenBalance: this.tokenBalance,
        tokenAllowance: this.tokenAllowance,
        tokenPrice: this.tokenPrice,
        pairTokens: this.pairTokens,
        isSrdToken: this.isSrdToken,
        isToken: this.isToken,
        tokenReplaceString: this.tokenReplaceString,
        tokenUnitString: this.tokenUnitString,
        token: this.token,
        contentPair: this.currentContentPair,
      },false))],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import Content from '@/components/dialog/content/Content';

import BodyBtcInflow from '@/components/dialog/body/btc/Inflow';

import FooterInformation from '@/components/dialog/footer/Information';

export default {
  name: 'BtcDeposit',
  mixins: [Content],
  data() {
    return {
      contentID: 'btc.deposit',
      contentAction: 'common.action.btc.deposit',
      contentActioned: 'common.actioned.btc.deposit',
      contentActioning: 'common.actioning.btc.deposit',
      subtitle: 'trade.content.subtitle.btc',
      hideStepperHeader: true,
      flow: {
        status: 0,
        unitID: '',
        eth: '',
        btc: '',
        amount: new this.$BBN('0'),
      },
      interval: {
        id: -1,
        term: this.$config.expectedBlock.time,
      },
    };
  },
  watch: {
    '$store.getters.btcChallenge'() {
      this.syncBtcChallenge();
    },
  },
  computed: {
    steps() {
      return [
        {
          id: 1,
          title: this.$t('trade.content.step.input', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyBtcInflow],
          footers: [FooterInformation],
          buttons: [
            {
              title: this.$t('common.message.close'),
              action: this.close,
            },
          ],
        },
      ];
    },
    currentBodyProps() {
      return Object.assign({}, this.defaultProps, {
        title: this.$t('trade.content.information.actionAddress', {
          action: this.$t(this.contentAction),
        }),
        address: this.$store.getters.btcDeposit,
        description: this.$t(
          'trade.content.information.description.btcAddressToDeposit'
        ),
        feeDescription: `${'Fee'}: ${this.$store.getters.btcFee.inflow.rate.balanceFormat(
          '{}%'
        )}`,
        steps: this.flows,
      });
    },
    currentFooterProps() {
      return Object.assign({}, this.defaultProps, {
        informationData: this.informationData,
        graphTextData: this.graphTextData,
        graphValue: this.graphValue,
      });
    },
    informationData() {
      return [
        {
          title: this.$t('trade.content.information.interestRate', {
            action: this.$t(this.contentAction),
          }),
          value: this.depositInterestRate,
        },
        {
          title: this.$t('trade.content.information.collateralRateString'),
          value: this.collateralRateString,
        },
        {
          title: this.$t('trade.content.information.tokenPrice'),
          value: this.tokenPriceString,
        },
      ];
    },
    graphTextData() {
      return [
        {
          title: this.$t('trade.content.information.borrowLimit'),
          value: this.borrowLimit,
          change: this.changeBorrowLimit,
        },
        {
          title: this.$t('trade.content.information.usingBorrowLimit'),
          value: this.borrowLimitRate,
          change: this.changeBorrowLimitRate,
        },
      ];
    },
    flows() {
      const result = [
        {
          status: 1,
          title: this.$t('common.action.actionInWaiting', {
            action: this.$t('common.action.transfer'),
          }),
        },
        {
          status: 0,
          title: this.$t('common.action.verifyingAction', {
            action: this.$t('common.action.deposit'),
          }),
        },
        {
          status: 0,
          title: this.$t('common.action.actionComplete', {
            action: this.$t('common.action.deposit'),
          }),
        },
      ];

      if (this.flow.status >= 1) {
        result[0].status = 2;
        result[0].title = this.$t('common.action.actionConfirmed', {
          action: this.$t('common.action.transfer'),
        });
        result[1].status = 1;
      }
      if (this.flow.status >= 2) {
        result[1].status = 2;
        result[1].title = this.$t('common.action.actionComplete', {
          action: this.$t('common.action.verification'),
        });
        result[2].status = 1;
      }
      if (this.flow.status >= 3) {
        result[2].status = 2;
      }

      return result;
    },
  },
  methods: {
    initializing() {
      this.initializeSlide();
      this.initializeStep();
      this.initializeInputValue();
      this.initializeMessage();
      this.initializeFlow();
      this.checkSkip();

      this.temporaryTransaction = false;
    },
    initializeFlow() {
      this.flow.status = 0;
      this.flow.unitID = '';
      this.flow.eth = '';
      this.flow.btc = '';
      this.flow.amount = new this.$BBN('0');
    },
    syncBtcChallenge() {
      if (this.$store.getters.btcChallenge) {
        this.emergencyMessage =
          'common.message.transaction.btc.processingReviewing';
        this.emergencyMessageIcon = 'mdi-history';
        this.emergencyMessageDescription =
          'common.message.transaction.btc.processingReviewingDescription';
      } else {
        this.emergencyMessage = '';
        this.emergencyMessageIcon = '';
        this.emergencyMessageDescription = '';
      }
    },
    async syncTransactionStatus() {
      if (this.currentBody !== BodyBtcInflow) {
        return;
      }

      const { unitID, status } = await this.$relayer.getFlowData(
        'deposit',
        this.$store.getters,
        this.flow.unitID
      );

      this.flow.unitID = unitID;
      this.flow.status = status;
    },
  },
  mounted() {
    this.initializing();
    this.syncBtcChallenge();

    try {
      this.syncTransactionStatus();
      this.interval.id = setInterval(
        this.syncTransactionStatus,
        this.interval.term
      );
    } catch (error) {
      /**/
    }
  },
  beforeDestroy() {
    try {
      clearInterval(this.interval.id);
    } catch (error) {
      /**/
    }
  },
};
</script>

<script>
import Content from '@/components/dialog/content/Content';

import BodyValueInput from '@/components/dialog/body/ValueInput';

import FooterInformation from '@/components/dialog/footer/Information';

export default {
  name: 'Withdraw',
  mixins: [Content],
  data: () => ({
    contentID: 'withdraw',
    contentAction: 'common.action.withdraw',
    contentActioned: 'common.actioned.withdraw',
    contentActioning: 'common.actioning.withdraw',
  }),
  computed: {
    steps() {
      return [
        {
          id: 1,
          title: this.$t('trade.content.step.input', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.action', {
                action: this.$t(this.contentAction),
              }),
              action: this.withdraw,
            },
          ],
        },
        {
          id: 2,
          title: this.$t('trade.content.step.complete', {
            action: this.$t(this.contentAction),
          }),
          bodies: [BodyValueInput],
          footers: [FooterInformation],
          buttons: [
            {
              title: this.$t('trade.content.button.confirm'),
              action: this.close,
            },
          ],
        },
      ];
    },
    currentBodyProps() {
      let result = this.defaultProps;

      if (this.currentBody === BodyValueInput) {
        result = Object.assign({}, result, {
          amountValue: this.inputValue,
          maxAmount: this.maxAmountString,
          inputTitle: this.$t('trade.content.placeholder.input', {
            action: this.$t(this.contentAction),
          }),
          inputPlaceholderDescription: this.$t(
            'trade.content.placeholder.inputDescription',
            { action: this.$t(this.contentAction) }
          ),
          informationTitle: this.$t(
            'trade.content.information.maxActionAmount',
            { action: this.$t(this.contentAction) }
          ),
          informationValue: this.informationValueString,
          disabledRules: this.disabledRules,
        });
      }

      return result;
    },
    currentFooterProps() {
      let result = this.defaultProps;

      if (this.currentFooter === FooterInformation) {
        result = Object.assign({}, result, {
          informationData: this.informationData,
          graphTextData: this.graphTextData,
          graphValue: this.graphValue,
        });
      }

      return result;
    },
    informationData() {
      return [
        {
          title: this.$t('trade.content.information.interestRate', {
            action: this.$t('common.action.deposit'),
          }),
          value: this.depositInterestRate,
        },
        {
          title: this.$t('trade.content.information.collateralRateString'),
          value: this.collateralRateString,
        },
        {
          title: this.$t('trade.content.information.tokenPrice'),
          value: this.tokenPriceString,
        },
      ];
    },
    graphTextData() {
      return [
        {
          title: this.$t('trade.content.information.borrowLimit'),
          value: this.borrowLimit,
          change: this.changeBorrowLimit,
        },
        {
          title: this.$t('trade.content.information.usingBorrowLimit'),
          value: this.borrowLimitRate,
          change: this.changeBorrowLimitRate,
        },
      ];
    },
    actionButtonDisabled() {
      return (
        this.currentStepID !== 2 &&
        (this.transactionPending ||
          !!this.emergencyMessage ||
          !this.isActionableByInput ||
          !this.$store.getters.isWalletUsable ||
          (this.currentStepID === 1 && this.isOverLimitOfAction))
      );
    },
    disabledRules() {
      return [
        () => (this.currentStepID === 2 ? true : undefined),
        () =>
          this.informationValue.lte(0)
            ? {
                text: 'trade.content.message.nonexistent.action',
              }
            : undefined,
      ];
    },
  },
  watch: {
    isOverLimitOfAction() {
      this.messageCheck();
    },
    isMaxInputValue() {
      this.messageCheck();
    },
  },
  methods: {
    withdraw() {
      const from = this.$store.getters.walletAddress;
      const to = this.tokenSendAddress;
      const signature = this.$config.signature.withdraw;
      const args = ['0x0', false];

      if (this.isMaxInputValue) {
        args[0] = this.$curr
          .getUnformatedValue(this.inputValue)
          .mul(this.$config.maxActionTokenRatio)
          .plus(10)
          .toHex(true);
      } else {
        args[0] = this.$curr.getUnformatedValue(this.inputValue).toHex(true);
      }

      this.addTemporaryTransaction();

      this.$sendTransaction({ from, to, signature, args })
        .then(transactionHash => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            transactionHash,
            undefined,
            () => {
              this.nextStep();

              this.setMessage(
                'success',
                'common.message.transaction.successfullyAction'
              );
            }
          );
        })
        .catch(error => {
          this.addTransaction(
            this.contentAction,
            this.inputValue,
            undefined,
            error
          );

          this.setMessage('error', this.$getTransactionErrorMessage(error));

          this.nextStep();
        })
        .finally(() => this.resolveTemporaryTransaction());
    },
    messageCheck() {
      // Limit of Action
      let type = 'error';
      let message = 'trade.content.message.limitOfAction';
      let messageArgs = {
        value: `${this.$curr.getBalanceFormatedTokenValue(
          this.limitOfAction,
          this.tokenID,
          this.tokenID,
          this.tokenReplaceString
        )}`,
      };

      if (this.isOverLimitOfAction) {
        this.setMessage(type, message, messageArgs);

        return;
      } else if (this.message.type === type && this.message.text === message) {
        this.initializeMessage();
      }

      // Max Input
      const tooltip = 'trade.content.message.allAction.description.request';

      type = 'info';
      message = 'trade.content.message.allAction.title';
      messageArgs = {
        value: `${new this.$BBN(this.$config.maxActionTokenRatio)
          .mul(100)
          .balanceFormat('{}%')} + 10wei`,
      };

      if (this.isMaxInputValue) {
        this.setMessage(type, message, messageArgs, undefined, tooltip);

        return;
      } else if (this.message.type === type && this.message.text === message) {
        this.initializeMessage();
      }
    },
  },
};
</script>

import func from '@/plugins/function';
import bifiCurrency from '@/plugins/bifiCurrency';

export default {
  syncCurrencyList(state, { then }) {
    state.currencyList = bifiCurrency.getTokenList();

    func.execFunc(then);
  },

  setCurrency(state, { currency, then }) {
    bifiCurrency.setCurrency(currency);
    state.currency = bifiCurrency.getCurrency();

    func.execFunc(then);
  },
  syncCurrency(state, { then }) {
    state.currency = bifiCurrency.getCurrency();

    func.execFunc(then);
  },
  storeCurrency(state, { then }) {
    func
      .storageEach(storage => storage.setItem('currency', state.currency))
      .then(then);
  },
};

<template>
  <div class="loader" :class="{ small: small }" :style="loaderStyle">
    <div class="circle" :style="oddCircleStyle" />
    <template v-if="!small">
      <div class="circle" :style="evenCircleStyle" />
      <div class="circle" :style="oddCircleStyle" />
      <div class="circle" :style="evenCircleStyle" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    secondColor: {
      type: String,
      default: '',
    },
    borderWeight: {
      type: Number,
      default: 3.5,
    },
    size: {
      type: Number,
      default: 160,
    },
  },
  computed: {
    loaderStyle() {
      let result = {};

      if (this.size) {
        result['width'] = `${this.size}px`;
        result['min-width'] = `${this.size}px`;
        result['max-width'] = `${this.size}px`;
        result['height'] = `${this.size}px`;
        result['min-height'] = `${this.size}px`;
        result['max-height'] = `${this.size}px`;
      }

      return result;
    },
    oddCircleStyle() {
      let result = {};

      if (this.color) {
        result['border-color'] = this.color;
      }

      if (this.borderWeight) {
        result['border-width'] = `${this.borderWeight}px`;
      }

      return result;
    },
    evenCircleStyle() {
      let result = {};

      if (this.secondColor) {
        result['border-color'] = this.secondColor;
      }

      if (this.borderWeight) {
        result['border-width'] = `${this.borderWeight}px`;
      }

      return result;
    },
  },
};
</script>

<style>
.loader {
  position: relative;
  width: 160px;
  height: 160px;
  overflow: visible;
}

.circle {
  position: absolute;
  border: 3.5px solid black;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
  border-radius: 50%;
  animation-name: preloader;
  animation-duration: 2.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  will-change: transform;
}

.loader .circle:nth-child(1) {
  width: 100%;
  height: 100%;
  border-color: #e2007c;
}

.loader .circle:nth-child(2) {
  width: 85%;
  height: 85%;
  animation-delay: -0.15s;
  border-color: white;
  margin: 7.5%;
}

.loader.small .circle:nth-child(2) {
  width: 50%;
  height: 50%;
  margin: 25%;
}

.loader .circle:nth-child(3) {
  width: 67.5%;
  height: 67.5%;
  animation-delay: -0.3s;
  border-color: #e2007c;
  margin: 16.25%;
}

.loader .circle:nth-child(4) {
  width: 50%;
  height: 50%;
  animation-delay: -0.45s;
  border-color: white;
  margin: 25%;
}

@keyframes preloader {
  50% {
    transform: rotate(360deg);
  }
}
</style>

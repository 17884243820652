import func from '@/plugins/function';

export default {
  connect(state, { then }) {
    if (state.connectionCount === 0) {
      this.dispatch('openWelcomePopup', {});
    }

    state.connectionCount++;

    this.dispatch('storeConnectionCount', {});

    func.execFunc(then);
  },

  setConnectionCount(state, { connectionCount, then }) {
    state.connectionCount = connectionCount;

    func.execFunc(then);
  },

  storeConnectionCount(state, { then }) {
    func
      .storageEach(storage =>
        storage.setItem('connectionCount', state.connectionCount)
      )
      .then(then);
  },
};

<template>
  <v-dialog
    content-class="popup"
    width="400"
    max-width="1000"
    :persistent="$store.getters.ui.popup.isConfirm"
    v-model="$store.state.ui.popup.show"
  >
    <v-layout class="popup-content" style="background-color: #fafafa;" column>
      <template
        v-for="(line, lineIndex) in $store.getters.ui.popup.message.split('\n')"
      >
        <div
          class="popup-message"
          :class="{
            'text-center': !(
              $store.getters.ui.popup.left || $store.getters.ui.popup.right
            ),
            'text-left': $store.getters.ui.popup.left,
            'text-right': $store.getters.ui.popup.right,
          }"
          v-text="line"
          :key="lineIndex"
        />
      </template>
      <v-spacer />
      <div class="mt-5">
        <template v-if="$store.getters.ui.popup.isConfirm">
          <v-layout class="mx-n3" justify-space-between align-center>
            <v-col class="py-0" cols="6">
              <Button
                class="popup-button"
                color="#47b465"
                block
                :text="$t('popup.cancel')"
                :action="close"
              />
            </v-col>
            <v-col class="py-0" cols="6">
              <Button
                class="popup-button"
                color="#47b465"
                block
                :text="$t('popup.confirm')"
                :action="confirm"
              />
            </v-col>
          </v-layout>
        </template>
        <Button
          class="popup-button"
          color="#47b465"
          block
          :text="$t('popup.close')"
          :action="close"
          v-else
        />
      </div>
    </v-layout>
  </v-dialog>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'Config',
  components: {
    Button,
  },
  methods: {
    confirm() {
      this.$execFunc(
        this.$store.getters.ui.popup.confirmAction,
        ...this.$store.getters.ui.popup.confirmActionArgs
      );
      this.$store.dispatch('closePopup', {});
    },
    close() {
      this.$store.dispatch('closePopup', {});
    },
  },
};
</script>

<style scoped>
.popup-content {
  min-height: 200px;
  padding: 30px 24px 20px;
  overflow: hidden;
}

.popup-message {
  word-break: break-all;
}
</style>

<style>
.popup {
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 #00000029;
}
</style>

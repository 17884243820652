<template>
  <div class="information">
    <div class="information-wrapper left">
      <div
        class="information-content"
        v-for="(data, dataIndex) in informationData"
        :key="dataIndex"
      >
        <div class="information-content-title" v-text="data.title" />
        <div class="information-content-value" v-text="data.value" />
      </div>
    </div>
    <v-divider class="information-divider" vertical />
    <div class="information-wrapper right">
      <div
        class="information-content"
        v-for="(data, dataIndex) in graphTextData"
        :key="dataIndex"
      >
        <div class="information-content-title" v-text="data.title" />
        <div class="information-content-value change">
          <div v-text="data.value" />
          <transition name="fade-expand-x">
            <div
              class="information-content-changed"
              v-if="data.change && data.value !== data.change"
            >
              <div class="information-content-changed-mark">>></div>
              <div
                class="information-content-changed-value"
                v-text="data.change"
              />
            </div>
          </transition>
        </div>
      </div>
      <div class="information-content">
        <SimpleStackedBar
          class="information-content-graph"
          :value="graphValue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SimpleStackedBar from '@/components/SimpleStackedBar';

export default {
  name: 'Information',
  props: {
    informationData: {
      type: Array,
      default: () => [],
    },
    graphTextData: {
      type: Array,
      default: () => [],
    },
    graphValue: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SimpleStackedBar,
  },
};
</script>

<style scoped>
.information {
  display: flex;
  width: 100%;
  height: 100%;
}

.information-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 14px;
}

.information-wrapper.left {
  width: 226px;
}

.information-wrapper.right {
  flex: 1;
}

.information-content {
  display: flex;
  width: 100%;
  font-size: 14px;
}

.information-wrapper.right .information-content {
  font-size: 14px;
}

.information-content-title,
.information-content-value {
  flex: 0.5;
}

.information-content-title {
  text-align: left;
  color: #00000080;
  white-space: nowrap;
}

.information-content-value {
  text-align: right;
  color: #000000d9;
  white-space: nowrap;
}

.information-content-value.change {
  height: 21px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.information-content-changed {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-width: 50%;
  height: 21px;
}

.information-content-changed-mark {
  height: 21px;
  margin: 0px 10px;
  color: #47b465;
}

.information-content-changed-value {
  height: 21px;
}

.information-content-bar {
  width: 100%;
  height: 8px;
  background-color: #0000001a;
}

.information-divider {
  margin: 0px 16px;
}

.information-content-graph {
  padding-top: 9px;
}

/* dark mode */

#app.dark .information-content-title {
  color: #ffffff80;
}

#app.dark .information-content-value {
  color: #ffffffd9;
}

#app.dark .information-content-bar {
  background-color: #ffffff1a;
}

/* mobile */

#app.mobile .information {
  flex-direction: column-reverse;
}

#app.mobile .information-wrapper {
  width: 100%;
  max-width: unset;
  padding: 12px;
}

#app.mobile .information-wrapper.left {
  flex-direction: row;
}

#app.mobile .information-content {
  width: unset;
  align-items: center;
}

#app.mobile .information-content-title {
  padding-bottom: 10px;
  padding-right: 10px;
  font-size: 10px;
}

#app.mobile .information-content-value {
  padding-bottom: 10px;
  font-size: 12px;
}

#app.mobile .information-content-graph {
  padding-top: 6px;
}
</style>

<template>
  <div class="srd-information">
    <div class="srd-information-label" v-text="label" />
    <div class="srd-information-value" v-text="value" />
  </div>
</template>

<script>
export default {
  name: 'SrdInformation',
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.srd-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
}

.srd-information-label {
  font-size: 12px;
  color: #00000080;
}

.srd-information-value {
  font-size: 20px;
  color: #000000d9;
}

/* dark mode */

#app.dark .srd-information-label {
  color: #ffffff80;
}

#app.dark .srd-information-value {
  color: #ffffffd9;
}
</style>

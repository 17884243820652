<template>
  <v-dialog
    content-class="wallet-popup"
    width="460"
    max-width="1000"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    :value="value"
    @input="$emit('input', $event)"
  >
    <v-layout class="popup-content" column>
      <v-layout class="popup-header" row justify-space-between align-center>
        <div>
          <span class="popup-header-title" v-t="'header.wallet.popup.title'" />
          <span
            class="popup-header-network"
            v-text="$getNetworkString(network)"
          />
        </div>
        <v-btn
          class="popup-close mr-n2"
          :dark="$store.getters.darkMode"
          icon
          @click="$emit('input', false)"
        >
          <v-icon class="popup-close-icon">mdi-close</v-icon>
        </v-btn>
      </v-layout>
      <div class="popup-body">
        <v-layout class="popup-wallet" column>
          <v-layout
            class="wallet-header"
            row
            justify-space-between
            align-center
          >
            <div class="wallet-header-description-wrapper">
              <v-btn
                class="wallet-header-button"
                text
                :dark="$store.getters.darkMode"
                @click="openWalletConnector"
              >
                <AvatarIconSet :size="32" :iconSet="[{ image: 'eth.svg' }]" />
                <div
                  class="wallet-header-description ethereum"
                  v-text="
                    $t('header.wallet.popup.connectedWith', {
                      wallet: currentWalletTitle,
                    })
                  "
                />
              </v-btn>
            </div>
            <v-tooltip top transition="slide-y-reverse-transition">
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="wallet-header-address"
                  v-text="`${address.slice(0, 4)}…${address.slice(-4)}`"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span v-text="address" />
            </v-tooltip>
          </v-layout>
          <v-layout class="wallet-footer" row justify-end align-center>
            <v-btn
              class="wallet-footer-button"
              text
              :dark="$store.getters.darkMode"
              v-clipboard:copy="address"
              v-if="address"
            >
              <v-icon class="wallet-footer-button-icon"
                >mdi-content-copy</v-icon
              >
              <span
                class="wallet-footer-button-title"
                v-t="'header.wallet.popup.copyAddress'"
              />
            </v-btn>
            <v-btn
              class="wallet-footer-button"
              text
              :dark="$store.getters.darkMode"
              rel="noopener"
              target="_blank"
              :href="$getAddressExplorer(network, address)"
              v-if="network && address"
            >
              <v-icon class="wallet-footer-button-icon">mdi-open-in-new</v-icon>
              <span
                class="wallet-footer-button-title"
                v-t="'header.wallet.popup.viewExplorer'"
              />
            </v-btn>
          </v-layout>
        </v-layout>
        <v-layout class="popup-wallet" column>
          <v-layout
            class="wallet-header"
            row
            justify-space-between
            align-center
          >
            <div class="wallet-header-description-wrapper">
              <AvatarIconSet :size="32" :iconSet="[{ image: 'btc.svg' }]" />
              <div
                class="wallet-header-description"
                v-t="'header.wallet.popup.myBtcAddress'"
              />
            </div>
            <div class="wallet-header-address-wrapper">
              <v-btn
                class="wallet-header-address-button"
                text
                :dark="$store.getters.darkMode"
                :disabled="!btcRefundAddress"
                v-clipboard:copy="btcRefundAddress"
              >
                <span
                  class="wallet-header-address-button-title"
                  v-text="
                    `${btcRefundAddress.slice(0, 4)}…${btcRefundAddress.slice(
                      -4
                    )}`
                  "
                />
                <v-icon class="wallet-header-address-button-icon" small
                  >mdi-content-copy</v-icon
                >
              </v-btn>
              <v-btn
                icon
                small
                :dark="$store.getters.darkMode"
                :disabled="!btcRefundAddress"
                @click="openBtcEdit"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-layout>
          <v-layout class="wallet-footer btc" column>
            <v-layout
              class="wallet-footer-address-wrapper"
              row
              justify-space-between
              align-center
            >
              <div
                class="wallet-footer-address-label"
                v-t="'header.wallet.popup.btcDepositAddress'"
              />
              <v-btn
                class="wallet-footer-button"
                text
                :dark="$store.getters.darkMode"
                :disabled="!btcDepositAddress"
                v-clipboard:copy="btcDepositAddress"
              >
                <span
                  class="wallet-footer-button-title"
                  v-text="
                    `${btcDepositAddress.slice(0, 4)}…${btcDepositAddress.slice(
                      -4
                    )}`
                  "
                />
                <v-icon class="wallet-footer-button-icon append" small
                  >mdi-content-copy</v-icon
                >
              </v-btn>
            </v-layout>
            <v-layout
              class="wallet-footer-address-wrapper"
              row
              justify-space-between
              align-center
            >
              <div
                class="wallet-footer-address-label"
                v-t="'header.wallet.popup.btcRepayAddress'"
              />
              <v-btn
                class="wallet-footer-button"
                text
                :dark="$store.getters.darkMode"
                :disabled="!btcRepayAddress"
                v-clipboard:copy="btcRepayAddress"
              >
                <span
                  class="wallet-footer-button-title"
                  v-text="
                    `${btcRepayAddress.slice(0, 4)}…${btcRepayAddress.slice(
                      -4
                    )}`
                  "
                />
                <v-icon class="wallet-footer-button-icon append" small
                  >mdi-content-copy</v-icon
                >
              </v-btn>
            </v-layout>
          </v-layout>
        </v-layout>
      </div>
      <v-layout class="popup-transaction" column>
        <v-layout
          class="transaction-header"
          row
          justify-space-between
          align-center
        >
          <span class="transaction-header-title" v-t="transactionTitle" />
          <a
            class="transaction-header-clear transition-all"
            v-if="transactionOn"
            @click="clearTransactions"
            v-t="'header.wallet.popup.clear'"
          />
        </v-layout>
        <table class="transaction-table" v-if="transactionOn">
          <colgroup>
            <col width="50px" />
            <col />
            <col width="24px" />
          </colgroup>
          <transition-group tag="tbody" name="transaction-table-row">
            <tr
              class="transaction-row transition-all"
              :class="{
                'transaction-pending': transaction.status === 0,
                'transaction-success': transaction.status === 1,
                'transaction-error': transaction.status === 2,
                'transaction-error': transaction.status === 3,
              }"
              v-for="transaction in transactions"
              :key="transaction.id"
            >
              <td class="transaction-column token transition-all">
                <span
                  class="column-token"
                  v-text="(transaction.token || '').toUpperCase()"
                />
              </td>
              <td class="transaction-column message transition-all">
                <div class="column-message-wrapper">
                  <v-tooltip
                    bottom
                    transition="slide-y-transition"
                    :disabled="!transaction.value"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="column-link transition-all"
                        rel="noopener"
                        target="_blank"
                        :href="
                          $getTransactionExplorer(
                            network,
                            transaction.transactionHash
                          )
                        "
                        v-bind="attrs"
                        v-on="on"
                        v-if="transaction.transactionHash"
                      >
                        <span
                          class="column-message"
                          v-text="
                            `${$t(transaction.action)} ${
                              transaction.value
                                ? new $BBN(transaction.value).balanceFormat(
                                    $curr.getToken(transaction.token)
                                      .replaceString,
                                    undefined,
                                    false
                                  )
                                : ''
                            }`
                          "
                        />
                        <v-icon class="column-link-icon" color="#2380fb" small
                          >mdi-open-in-new</v-icon
                        >
                      </a>
                      <span
                        class="column-message"
                        v-text="
                          `${$t(transaction.action)} ${
                            transaction.value
                              ? new $BBN(transaction.value).balanceFormat(
                                  $curr.getToken(transaction.token)
                                    .replaceString,
                                  undefined,
                                  false
                                )
                              : ''
                          }`
                        "
                        v-bind="attrs"
                        v-on="on"
                        v-else
                      />
                    </template>
                    <span
                      v-text="
                        new $BBN(transaction.value).balanceFormat(
                          $curr.getToken(transaction.token).replaceString,
                          undefined,
                          false
                        )
                      "
                    />
                  </v-tooltip>
                </div>
              </td>
              <td class="transaction-column icon transition-all">
                <v-tooltip
                  bottom
                  max-width="80vw"
                  transition="slide-y-transition"
                  :disabled="!transaction.message"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <Loader
                        class="column-icon"
                        color="#47b465"
                        secondColor="#47b465"
                        small
                        :borderWeight="3"
                        :size="24"
                        v-if="transaction.status === 0"
                      />
                      <v-icon
                        class="column-icon"
                        color="#47b465"
                        v-else-if="transaction.status === 1"
                        >mdi-check-circle</v-icon
                      >
                      <v-icon
                        class="column-icon"
                        :class="{ action: !!transaction.message }"
                        color="#e02020"
                        v-clipboard:copy="
                          !!transaction.message
                            ? $te(transaction.message)
                              ? $t(transaction.message, {
                                  action: $t(transaction.action),
                                })
                              : transaction.message
                            : ''
                        "
                        v-else
                        >mdi-alert-circle</v-icon
                      >
                    </div>
                  </template>
                  <div
                    style="word-break: break-word;"
                    v-html="
                      $te(transaction.message)
                        ? $t(transaction.message, {
                            action: $t(transaction.action),
                          })
                        : (transaction.message || '').replaceAll('\n', '<br />')
                    "
                    v-if="transaction.message"
                  />
                </v-tooltip>
              </td>
            </tr>
          </transition-group>
        </table>
      </v-layout>
    </v-layout>
  </v-dialog>
</template>

<script>
import AvatarIconSet from '@/components/AvatarIconSet';

import Loader from '@/components/Loader';

export default {
  name: 'Wallet',
  components: {
    AvatarIconSet,
    Loader,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    address: {
      type: String,
      default: '',
    },
    transactions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    network() {
      return this.$store.getters.walletNetwork;
    },
    currentWalletTitle() {
      return this.$store.getters.walletID;
    },
    btcRefundAddress() {
      return this.$store.getters.btcRefund || '';
    },
    btcDepositAddress() {
      return this.$store.getters.btcDeposit || '';
    },
    btcRepayAddress() {
      return this.$store.getters.btcRepay || '';
    },
    transactionOn() {
      return !!this.transactions.length;
    },
    transactionTitle() {
      let result = 'header.wallet.popup.transactionWilAppear';

      if (this.transactionOn) {
        result = 'header.wallet.popup.recentTransactions';
      }

      return result;
    },
  },
  methods: {
    openWalletConnector() {
      this.$store.dispatch('openWalletConnector', {});
      this.$emit('input', false);
    },
    openBtcEdit() {
      this.$store.dispatch('openBtcEdit', {});

      this.$emit('input', false);
    },
    clearTransactions() {
      this.$store.dispatch('resetTransaction', {});
    },
  },
};
</script>

<style scoped>
.popup-content {
  background-color: #fff;
  overflow: hidden;
}

.popup-header,
.popup-wallet,
.popup-transaction {
  margin: 0px;
  padding: 20px;
}

.popup-header {
  padding-top: 12px;
  padding-bottom: 12px;
}

.popup-header-title {
  font-size: 16px;
  font-weight: bold;
  color: #000000d9;
}

.popup-header-network {
  margin-left: 12px;
  font-size: 12px;
  color: #00000080;
}

.popup-close {
}

.popup-close-icon {
}

.popup-body {
  margin-top: -2px;
  margin-bottom: -2px;
  margin-left: 2px;
  margin-right: 2px;
}

.popup-wallet {
  margin-top: 2px;
  margin-bottom: 2px;
  color: #00000080;
  background-color: #0000001a;
}

.popup-transaction {
}

.wallet-header,
.wallet-footer {
  margin: 0px;
}

.wallet-header {
}

.wallet-header-address {
  font-size: 20px;
  font-weight: bold;
  cursor: default;
}

.wallet-header-address-wrapper {
  display: flex;
  align-items: center;
}

.wallet-header-address-button {
  min-width: none !important;
  padding: 0px 4px !important;
}

.wallet-header-address-button-title {
  font-size: 20px;
  font-weight: bold;
  text-transform: none;
}

.wallet-header-address-button-icon {
  margin-left: 8px;
}

.wallet-header-description-wrapper {
  display: flex;
  align-items: center;
}

.wallet-header-button {
  margin-left: -10.56px;
}

.wallet-header-description {
  padding-left: 12px;
  font-size: 12px;
  text-transform: none;
  letter-spacing: 0px;
}
.wallet-header-description.ethereum {
  color: #47b465;
}

.wallet-footer {
  margin: 0px -10px;
}
.wallet-footer.btc {
  margin: 0px;
}

.wallet-footer-address-wrapper {
  margin: 0px !important;
}

.wallet-footer-address-label {
  font-size: 14px;
}

.wallet-footer-button {
  margin: 0px 4px;
  padding: 0px 6px !important;
}

.wallet-footer-button-icon {
  margin-right: 8px;
}

.wallet-footer-button-icon.append {
  margin-left: 8px;
  margin-right: 0px;
}

.wallet-footer-button-title {
  font-size: 14px;
  text-transform: none;
  letter-spacing: 0;
}

.transaction-header {
  margin: 0px;
  font-size: 14px;
  color: #000000d9;
}

.transaction-header-title {
}

.transaction-header-clear {
  font-size: 12px;
  text-align: right;
  color: #1f7bff;
}

.transaction-header-clear:hover {
  opacity: 0.7;
}

.transaction-header-clear:active {
  opacity: 0.4;
}

.transaction-table {
  width: 100%;
  margin: 10px 0px -10px;
}

.transaction-row {
}

.transaction-column {
  padding: 10px 0px;
  font-size: 14px;
  color: #000000d9;
}

.transaction-column.token {
}

.transaction-column.message {
  padding: 0px 14px;
}

.transaction-column.icon {
  text-align: right;
}

.column-token {
}

.column-message-wrapper {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  overflow: hidden;
}

.column-link {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.column-link,
.column-link * {
  cursor: pointer !important;
}

.column-link:hover {
  opacity: 0.7;
}

.column-link:active {
  opacity: 0.4;
}

.column-message {
  display: block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000d9;
  cursor: default;
}

.column-link > .column-message {
  max-width: 274px;
}

.transaction-row.transaction-error .column-message {
  color: #e02020;
}

.column-link-icon {
  margin-left: 10px;
}

.column-icon {
  cursor: default;
}
.column-icon.action {
  cursor: pointer;
}
.column-icon.action:hover {
  opacity: 0.7;
}
.column-icon.action:active {
  opacity: 0.5;
}

.transaction-table-row-enter,
.transaction-table-row-enter *,
.transaction-table-row-leave-to,
.transaction-table-row-leave-to * {
  opacity: 0 !important;
  height: 0px !important;
  min-height: 0px !important;
  max-height: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  line-height: 0 !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.transaction-table-row-enter.no-transition,
.transaction-table-row-enter.no-transition *,
.transaction-table-row-leave-to.no-transition,
.transaction-table-row-leave-to.no-transition * {
  display: none;
}

/* dark mode */
#app.dark .popup-content {
  background-color: #383c52;
}

#app.dark .popup-header-title,
#app.dark .popup-wallet,
#app.dark .transaction-header,
#app.dark .transaction-column,
#app.dark .column-message {
  color: #ffffffd9;
}

#app.dark .popup-header-network {
  color: #ffffff80;
}

/* mobile */
#app.mobile .popup-content {
  display: block;
  min-height: 100%;
}

#app.mobile .column-message-wrapper {
  width: 166px;
  min-width: 166px;
  max-width: 300px;
}

#app.mobile .column-message {
  max-width: 140px;
}
</style>

<style>
.wallet-popup {
  border-radius: 0px !important;
  box-shadow: 0 3px 6px 0 #00000029;
}

/* mobile */

#app.mobile .wallet-popup {
  margin-left: 0px;
  margin-right: 0px;
}
</style>
